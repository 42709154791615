import React, { useEffect, useState } from "react";
import './App.css';
import {isMobile} from 'react-device-detect';
import {Route, Switch} from "react-router-dom";
import Initial from './components/web/initial/Initial'
import InitialMobile from './components/mobile/Initial'
import InitialIntro from "./components/web/initial/InitialIntro";
import HomeWeb from "./components/web/tax/Home"
// import HomeMobile from "./components/mobile/Home"
import Login from "./components/web/tax/Login";
import Chat from "./components/cs/Chat";
import Mine from './components/web/mine/index';
import Payment from './components/web/mine/payment'
import MyQuestions from './components/web/mine/my_questions'
import suggest from './components/web/mine/suggest'
import ShuiDetails from './components/web/tax/shuiDetails'
import Scene from "./components/web/tax/Scene";
import VideoLists from "./components/web/tax/VideoLists";
import Scene_lists from "./components/web/tax/Scene_lists";
import shuiQuestion from "./components/question/shuiQuestion";
import QuestionDetails from "./components/question/questionDetails";
import RiskAssessment from "./components/web/tax/riskAssessment";
import informLists from './components/web/inform/informLists';
import informDetails from './components/web/inform/informDetails';
import speedinessPlan from './components/web/plan/speedinessPlan'
import tax_accountant_lists from './components/web/tax_accountant_lists/tax_accountant_lists'
import UserMap from './components/web/mine/user_map'
import Map from './components/web/tax_admin/index'
import UserPool from './components/web/tax_admin/user_pool'
import UserLists from './components/web/tax_admin/user_lists'
import AgentLists from './components/web/tax_admin/tax_agent_list'
import Admin from './components/web/admin/index'
import Pool from './components/web/admin/pool'
import LargeModel from './components/web/chat/chat'
import KnowledgeBase from './components/web/chat/KnowledgeBase'
import People from './components/web/chat/people'
import UserMine from './components/web/chat/user_mine'
import IntelligentAgent from './components/web/chat/intelligent_agent'
import Monitoring from './components/web/chat/monitoring'
import CreateMonitoring from './components/web/chat/create_monitoring'
import CreateAgent from "./components/web/chat/create_agent";
import AgentParticulars from './components/web/chat/agent_particulars'
import SettingBlock from "./components/web/chat/setting_block";
import AgentShare from "./components/web/chat/agent_share";
import AgentList from './components/web/chat/agent_lists';
import EnlightenmentLists from './components/web/chat/enlightenment_lists';
import EnlightenmentInfo from './components/web/chat/enlightenment_info';
import CrmUser from "./components/web/chat/crm_user";
import CreateCrm from './components/web/chat/create_crm';
import UpdateCrm from './components/web/chat/update_crm';
import UserInformation from './components/web/chat/user_information';
import RecordingIndex from './components/web/examples/recording_index';
import UpdateEnlightenment from './components/web/chat/update_enlightenment';
import RegisterSummarize from './components/web/chat/register_summarize'

function App() {
    if(isMobile){
        return (
            // <HomeMobile/>
            <Switch>
                <Route exact path="/" component={InitialMobile}/>
            </Switch>
        );
    }
    else{
        return (
            <Switch>
                <Route exact path="/" component={Initial}/>
                <Route exact path="/initial/intro" component={InitialIntro} />
                <Route exact path="/login" component={Login}/>
                <Route exact path="/cs/chat" component={Chat} />
                <Route path="/mine" component={Mine} />
                <Route path='/mine/payment' component={Payment} />
                <Route path='/mine/my_questions' component={MyQuestions} />
                <Route path='/mine/suggest' component={suggest} />
                <Route path='/mine/user_map' component={UserMap} />

                <Route exact path="/tax" component={HomeWeb}/>
                <Route exact path="/tax/intro" component={ShuiDetails} />
                <Route exact path="/tax/scene" component={Scene} />
                <Route exact path="/tax/scene/lists" component={Scene_lists} />
                <Route exact path="/tax/video_lists" component={VideoLists} />

                <Route exact path='/tax/question' component={shuiQuestion}/>
                <Route exact path='/tax/question/details' component={QuestionDetails}/>
                <Route exact path='/tax/riskAssessment' component={RiskAssessment}/>
                
                <Route exact path='/tax/inform_lists' component={informLists} />
                <Route exact path='/tax/inform_details' component={informDetails} />
                <Route exact path='/speedinessPlan' component={speedinessPlan} />
                <Route exact path='/tax_accountant_lists' component={tax_accountant_lists} />

                <Route path='/tax_admin' component={Map} />
                <Route path='/tax_admin/user_pool' component={UserPool} />
                <Route path='/tax_admin/user_lists' component={UserLists} />
                <Route path='/tax_admin/agent_lists' component={AgentLists}/>

                <Route path='/admin' component={Admin} />
                <Route path='/admin/pool' component={Pool} />

                <Route exact path='/largemodel' component={LargeModel}/>
                <Route path='/largemodel/knowledge_base' component={KnowledgeBase}/>
                <Route path='/largemodel/people' component={People}/>
                <Route path='/largemodel/mine' component={UserMine}/>
                <Route path='/largemodel/intelligent_agent' component={IntelligentAgent}></Route>
                <Route path='/largemodel/agent_particulars' component={AgentParticulars}></Route>
                <Route path='/largemodel/monitoring' component={Monitoring}></Route>
                <Route path='/largemodel/create_monitoring' component={CreateMonitoring}></Route>
                <Route path='/largemodel/create_agent' component={CreateAgent}></Route>
                <Route path='/largemodel/setting_block' component={SettingBlock}></Route>
                <Route path='/largemodel/agent_share' component={AgentShare}></Route>
                <Route path='/largemodel/agent_list' component={AgentList}></Route>
                <Route path='/largemodel/enlightenment_lists' component={EnlightenmentLists}></Route>
                <Route path='/largemodel/enlightenment_info' component={EnlightenmentInfo}></Route>
                <Route path='/largemodel/update_enlightenment' component={UpdateEnlightenment}></Route>
                <Route path='/largemodel/register_summarize' component={RegisterSummarize}></Route>
                <Route path='/largemodel/crm_user' component={CrmUser}></Route>
                <Route path='/largemodel/create_crm' component={CreateCrm}></Route>
                <Route path='/largemodel/update_crm' component={UpdateCrm}></Route>
                <Route path='/largemodel/user_information' component={UserInformation}></Route>
                <Route path='/largemodel/recording_index' component={RecordingIndex}></Route>
            </Switch>
            // <HomeWeb/>
        );
    }
}

export default App;
