import React, {useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import Footer from "../../footer/Footer";
import KuaiIntro from "../accounting/KuaiIntro";
import ShuiIntro from "../ShuiIntro";
import white_logo from '../../../static/logo/logo_1.png';


function InitialIntro(){
    return (
        <div>
            <div style={{backgroundColor:'#3D1198',display:'flex',justifyContent:'space-between',padding:'8px 30px',boxShadow:'0 15px 9px #d6d6d6'}}>
                <div style={{ display: 'flex',width:'100%' }}>
                    <div style={{ display: 'flex', fontSize: 20,color:'#fff' }}>
                        <div>
                            <img src={white_logo} style={{ width: '26px', height: '26px', background: '#fff', borderRadius: '50%' }} alt="" />
                        </div>
                        <div style={{ paddingLeft: 8 }}>博槐互联网科技</div>
                    </div>
                    <Link to='/' style={{ fontSize: 15, cursor: "pointer", padding: '5px 8px', marginLeft: '10%', color: '#fff' }}>
                        <div>首页</div>
                    </Link>
                    <Link onClick={() => window.location.href = 'http://101.35.94.104:3070'} style={{ fontSize: 15,  cursor: "pointer", padding: '5px 8px', marginLeft: '10%', color: '#fff' }}>
                        <div>税引擎</div>
                    </Link>
                    {/* <Link onClick={() => window.location.href = 'http://huainor.ac.cn'} style={{ fontSize: 15,  cursor: "pointer", padding: '5px 8px', marginLeft: '10%', color: '#fff' }}>
                        <div>Kuai引擎</div>
                    </Link> */}
                </div>
            </div>
            <div style={{padding:'40px 10%'}}>
                <div>
                    <ShuiIntro />
                </div>
                <hr/>
                <div>
                    <KuaiIntro provenance={1} />
                </div>
            </div>
            <hr/>
            <div style={{padding:'0px 12% 0px 12%'}}>
                <Footer/>
            </div>
        </div>       
    )
}
export default InitialIntro