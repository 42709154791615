import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODEL_COLOR, SOCKET_EARTH_URL, socket } from '../../../configs/constants'
import axios from 'axios';
import { io } from 'socket.io-client'
import { Link, Route, Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import ClearIcon from '@mui/icons-material/Clear';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import subset from '../../../static/image/subset.png'
import storeroom from '../../../static/image/storeroom.png'
import storeroom1 from '../../../static/image/storeroom1.png'
import group from '../../../static/image/group.png'
import shui_engine from '../../../static/logo/logo_2.jpg'
import home from '../../../static/image/home.png'
import user_img from '../../../static/image/user_img.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import copy_icon from '../../../static/image/copy_icon.png'
import copy_i from '../../../static/image/copy_i.png'
import id_card from '../../../static/image/id_card.png'
import Divider from '@mui/material/Divider';
import copy from 'copy-to-clipboard'
import { message, Upload, Menu, Dropdown, Checkbox, Spin } from 'antd';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { InboxOutlined } from '@ant-design/icons';
import { send_socket_id } from '../../../actions/user'
import RecordingDrawer from '../examples/recording_drawer'

import {
    create_dataset,
    get_dataset,
    get_collection_list,
    get_collection_data,
    fg_upload, url_content,
    delete_dataset,
    delete_collection,
    delete_collection_data,
    manual_content,
    update_dataset,
    manual_create_content,
    update_create_collection_data,
    upload_collection,
    upload_dataset
} from '../../../actions/dataset'
const _ = require('micro-dash');

let url = SOCKET_EARTH_URL;

const who_are_you = "who_are_you"
const RECEIVE_ALERT_STATUS = "RECEIVE_ALERT_STATUS"
const TEST_SOCKET_MESSAGE = 'TEST_SOCKET_MESSAGE'
// const socket = io(url);
function KnowledgeBases() {
    const { Dragger } = Upload;
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);
    const user_type = useSelector((state) => state.user.user_type);
    const dataset_lists = useSelector((state) => state.dataset.dataset_lists);
    const company_dataset_lists = useSelector((state) => state.dataset.company_dataset_lists);
    const collection_lists = useSelector((state) => state.dataset.collection_lists);
    const user_is_enabled = useSelector((state) => state.dataset.user_is_enabled);
    const company_is_enabled = useSelector((state) => state.dataset.company_is_enabled);
    const collection_data = useSelector((state) => state.dataset.collection_data);
    const base_setting=useSelector((state) => state.user.base_setting);
    const [cutpage, setCutpage] = React.useState(0);
    const [subset_type, setSubsetType] = React.useState(0);
    const [org_id, setOrgId] = React.useState('test_org_01');
    const [is_loading, setIsLoading] = React.useState(false);
    const history=useHistory()
    // const [user_types, setUserTypes] = React.useState('');
    console.log(user_type)
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])
    useEffect(() => {
        dispatch(get_dataset({ type: 'user' }, (v) => setIsLoading(v)))
        dispatch(get_dataset({ type: 'org', org_id: org_id }, (v) => setIsLoading(v)))
    }, [])
    const root_directory = () => {
        setCutpage(0)
        setParentId('')
        dispatch(get_dataset({ type: 'user' }, (v) => setIsLoading(v)))
        dispatch(get_dataset({ type: 'org', org_id: org_id }, (v) => setIsLoading(v)))
    }
    const set_subset = (e, name, type) => {
        setCutpage(1)
        dispatch(get_collection_list({ datasetId: e }, (v) => setIsLoading(v)))
        setParentId(e)
        setSubsetName(name)
        setSubsetType(type)
    }
    const [new_warehouse, setNewWarehouse] = React.useState(false);
    const handleClose = () => {
        setNewWarehouse(false)
        setIntroduce('');
        setName('');
        setCreationType(0)
    }
    const [create_type, setCreateType] = React.useState(0);
    const opne_new_warehouse = (e) => {
        setName('');
        setIntroduce('');
        setWebUrl('')
        setArtificialValue('')
        setNewWarehouse(true)
        if (!_.isNil(e)) {
            setCreateType(e)
        }
    }
    const [subordinate, setSubordinate] = React.useState(0);
    const subordinate_handleChange = (e) => {
        setSubordinate(e.target.value)
    }
    const [introduce, setIntroduce] = React.useState('');
    const introduce_Change = (event) => {
        setIntroduce(event.target.value);
    }
    const [name, setName] = React.useState('');
    const [parentId, setParentId] = React.useState('');
    const [subset_name, setSubsetName] = React.useState('');
    const name_Change = (event) => {
        setName(event.target.value);
    }
    const creation_subordinate = () => {
        if (cutpage === 0) {
            if (create_type === 0) {
                if (name !== '') {
                    dispatch(create_dataset({ name: name, type: 'user' }, (v) => setIsLoading(v)))
                } else {
                    message.error('库名不能为空');
                }
            } else {
                if (name !== '') {
                    dispatch(create_dataset({ name: name, type: 'org', org_id: org_id }, (v) => setIsLoading(v)))
                } else {
                    message.error('库名不能为空');
                }
            }

        } else {
            // dispatch(create_dataset({ name: name, parentId: parentId }))
            if (creation_type === 0) {
                if (conllection_file === null || name === '') {
                    message.error('库名与文件不能为空');
                } else {
                    console.log(conllection_file)
                    const formData = new FormData();
                    formData.append('file', conllection_file[0]);
                    formData.append('name', name);
                    formData.append('intro', introduce);
                    formData.append('datasetId', parentId);
                    formData.append('socket_id', socket_id);
                    dispatch(fg_upload(formData, parentId), (v) => setIsLoading(v))
                    message.success('您的知识库正在创建中，几分钟后方可查看');
                }
            } else if (creation_type === 1) {
                if (web_url !== '') {
                    let name_ = name
                    if (name === '') {
                        name_ = time()
                    }
                    let url_lists=web_url.split(/[\r\n]+/)
                    // url_lists.push(web_url)
                    dispatch(url_content({ datasetId: parentId, url_list: url_lists, name: name_, socket_id: socket_id }, (v) => setIsLoading(v)))
                    message.success('您的知识库正在创建中，几分钟后方可查看');
                } else {
                    message.error('网页链接不能为空');
                }
            } else {
                if (artificial_value !== '' && artificial_value?.length < 1000) {
                    let name_ = name
                    if (name === '') {
                        name_ = time()
                    }
                    dispatch(manual_content({ datasetId: parentId, name: name_, intro: introduce, content: artificial_value, socket_id: socket_id }, (v) => setIsLoading(v)))
                } else {
                    message.error('录入内容不能为空且不能超过1000字');
                }
            }
        }
        setNewWarehouse(false)
    }
    const [open_drawer, setOpenDrawer] = React.useState(false);
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpenDrawer(open)
    };
    const [detaile_name, setDetailName] = React.useState('');
    const [collectionId, setCollectionId] = React.useState('');
    const open_details = (e, name) => {
        console.log(e)
        setOpenDrawer(true)
        setCutpage(2)
        setDetailName(name)
        setCollectionId(e)
        dispatch(get_collection_data({ collectionId: e }, (v) => setIsLoading(v)))
    }
    const [conllection_file, setConllectionFile] = React.useState([]);
    const props = {
        name: 'file',
        multiple: true,
        maxCount: 1,
        onRemove: (file) => {
            setConllectionFile([]);
            setName('')
        },
        beforeUpload: (file) => {
            setConllectionFile([file]);
            setName(file.name)
            return false;
        },
        conllection_file,
    };
    const subset_lists = () => {
        setCutpage(1)
        setCollectionId('')
        dispatch(get_collection_list({ datasetId: parentId }, (v) => setIsLoading(v)))
    }
    const detaile_lists = () => {
        setCutpage(2)
        dispatch(get_collection_data({ collectionId: collectionId }, (v) => setIsLoading(v)))
    }
    const [creation_type, setCreationType] = React.useState(0);
    const [web_url, setWebUrl] = React.useState('');
    const creation_cut = (e) => {
        setCreationType(e)
    }
    const url_Change = (event) => {
        setWebUrl(event.target.value)
    }
    const [artificial_value, setArtificialValue] = React.useState('');
    const artificial_Change = (event) => {
        setArtificialValue(event.target.value)
    }
    const options = (item, data, type) => (
        <Menu>
            <Menu.Item key="update" onClick={() => handleUpdate(data, type)}>
                修改
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(item, type)}>
                删除
            </Menu.Item>
            {/* 添加更多选项 */}
        </Menu>
    );
    const handleDelete = (deletedId, type) => {
        console.log(deletedId)
        setDeleteId(deletedId)
        setDeleteConfirm(true)
        setCreationType(type)
    }
    const [updates_data, setUpdatesData] = React.useState(null);
    const handleUpdate = (item, type) => {
        console.log(item)
        setUpdatesData(item)
        setUpdateName(item.name)
        setUpdateIntroduce(item.introduce)
        setUpdate(true)
        setCreationType(type)
    }
    const [is_update, setUpdate] = React.useState(false);
    const [update_name, setUpdateName] = React.useState('');
    const [update_introduce, setUpdateIntroduce] = React.useState('');
    const update_handleClose = () => {
        setUpdate(false)
    }
    const update_datas = () => {
        if (update_name !== '') {
            if (cutpage === 0) {
                if (creation_type === 0) {
                    dispatch(upload_dataset({ id: updates_data._id, name: update_name, intro: update_introduce, type: 'user' }, (v) => setIsLoading(v)))
                } else {
                    dispatch(upload_dataset({ id: updates_data._id, name: update_name, intro: update_introduce, type: 'org', org_id: org_id }, (v) => setIsLoading(v)))
                }
                // upload_dataset
            } else {
                dispatch(upload_collection({ id: updates_data._id, name: update_name, intro: update_introduce, datasetId: parentId }, (v) => setIsLoading(v)))
            }
            setUpdate(false)
        } else {
            message.error('标题不能为空')
        }

    }
    const [delete_confirm, setDeleteConfirm] = React.useState(false);
    const [delete_id, setDeleteId] = React.useState(null);
    const delete_handleClose = () => {
        setDeleteConfirm(false)
        setDeleteId(null)
    }
    const confirm = () => {
        if (cutpage === 0) {
            if (creation_type === 0) {
                dispatch(delete_dataset(delete_id, 'user', '', (v) => setIsLoading(v)))
            } else {
                dispatch(delete_dataset(delete_id, 'org', org_id, (v) => setIsLoading(v)))
            }

        } else if (cutpage === 1) {
            dispatch(delete_collection(delete_id, parentId, (v) => setIsLoading(v)))
        } else {
            dispatch(delete_collection_data(delete_id, collectionId, (v) => setIsLoading(v)))
        }
        setDeleteConfirm(false)
    }
    // const onChange = (id, checked) => {
    //     console.log(id, checked)
    //     let dataset_id_list = []
    //     dataset_id_list.push(id)
    //     dispatch(update_dataset({ dataset_id_list: dataset_id_list, is_enabled: checked, type: 'user' }))
    // }
    const all_onChange = (e) => {
        let dataset_id_list = []
        for (let i = 0; i < dataset_lists?.length; i++) {
            dataset_id_list.push(dataset_lists[i]._id)
        }
        dispatch(update_dataset({ dataset_id_list: dataset_id_list, is_enabled: e.target.checked, type: 'user' }, (v) => setIsLoading(v)))
    }
    const company_all_onChange = (e) => {
        let dataset_id_list = []
        for (let i = 0; i < company_dataset_lists?.length; i++) {
            dataset_id_list.push(company_dataset_lists[i]._id)
        }
        dispatch(update_dataset({ dataset_id_list: dataset_id_list, is_enabled: e.target.checked, type: 'org', org_id: org_id }, (v) => setIsLoading(v)))
    }
    // const company_onChange = (id, checked) => {
    //     let dataset_id_list = []
    //     dataset_id_list.push(id)
    //     dispatch(update_dataset({ dataset_id_list: dataset_id_list, is_enabled: checked, type: 'org', org_id: org_id }))
    // }
    const time = () => {
        let now = new Date()
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    }
    const [is_collection, setIsCollection] = React.useState(false);
    const open_collection = () => {
        setIsCollection(true)
    }
    const collection_handleClose = () => {
        setIsCollection(false)
    }
    const [collection_value, setCollectionValue] = React.useState('');
    const collection_Change = (event) => {
        setCollectionValue(event.target.value)
    }
    const creation_collection = () => {
        if (collection_value !== '') {
            dispatch(manual_create_content({ collectionId: collectionId, content: collection_value, socket_id: socket_id }, (v) => setIsLoading(v)))
            setIsCollection(false)
        } else {
            message.error('数据不能为空')
        }

    }
    const [update_collection_data, setUpdateCollectionData] = React.useState(null);
    const [is_update_collection, setIsUpdateCollection] = React.useState(false);
    const [update_collection_value, setUpdateCollectionValue] = React.useState('');
    const collection_particulars = (e) => {
        setUpdateCollectionData(e)
        setIsUpdateCollection(true)
        setUpdateCollectionValue(e.q)
    }
    const update_collection_handleClose = () => {
        setIsUpdateCollection(false)
    }
    const update_collection_Change = (event) => {
        setUpdateCollectionValue(event.target.value)
    }
    const update_collection = () => {
        if (update_collection_value !== '') {
            update_collection_data.q = update_collection_value
            update_collection_data.id = update_collection_data._id
            dispatch(update_create_collection_data(update_collection_data), (v) => setIsLoading(v))
            setIsUpdateCollection(false)
        }
    }
    const copy_id = (e) => {
        copy(e)
        message.success('复制成功')
    }
    const socket_id = useSelector((state) => state.user.socket_id);
    // const [socket_id, setSocketId] = React.useState(null);
    socket.on(who_are_you, (msg) => {
        console.log(msg)
        // setSocketId(msg.socket_id)
        dispatch(send_socket_id(msg.socket_id))
    })
    const [messageApi, contextHolder] = message.useMessage();
    const key = 'updatable';
    socket.on(RECEIVE_ALERT_STATUS, (msg) => {
        // console.log(msg)
        if (msg.alert_type === 'SUCCESS') {
            messageApi.open({ key, type: 'success', content: msg.content })
            // message.success()
        }
    })
    const ceshi_socket = () => {
        console.log('123333')
        socket.emit(TEST_SOCKET_MESSAGE, { type: "RECEIVE_ALERT_STATUS", alert_type: "SUCCESS", content: "已成功创建1" })
    }


    return (
        <div>
            {contextHolder}
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 54px)' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', padding: '8px 0px', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minWidth: 220 }}>
                            <TabbleLists type='1'></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(100% - 220px)', minHeight: '90vh' }}>
                    {
                        cutpage === 0 ? <div>
                            <div style={{ border: user_type>=170?'1px solid rgba(110,109,109,0.2)':'', minHeight: '30vh', padding: '20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: 20, color: base_setting?.primary_color, fontWeight: 'bold', paddingRight: 10 }}>个人知识库</div>
                                        <Checkbox onChange={all_onChange} checked={user_is_enabled}></Checkbox>
                                    </div>
                                    <div style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '8px 15px', display: 'flex', alignItems: 'center', borderRadius: 10, cursor: 'pointer' }} onClick={() => opne_new_warehouse(0)}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <AddIcon></AddIcon>
                                        </div>
                                        <div>新建文件夹</div>
                                    </div>
                                </div>
                                {
                                    dataset_lists?.length > 0 ? <div style={{ paddingTop: '2%', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                        {
                                            dataset_lists?.map((item, index) => {
                                                return <div key={index} style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '10px', borderRadius: 10, width: '20%', minWidth: 170, marginRight: '5%', marginBottom: '5%', cursor: 'pointer' }} onClick={() => set_subset(item._id, item.name, 0)}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative' }}>
                                                        <div style={{ display: 'flex', width: 'calc(95% - 24px)' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={storeroom} alt='' style={{ width: 20, height: 20 }} />
                                                            </div>
                                                            <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center', fontSize: 15, fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}>{item.name}</div>
                                                        </div>
                                                        <div onClick={(e) => e.stopPropagation()}>
                                                            <Dropdown overlay={options(item._id, item, 0)} onClick={(e) => e.stopPropagation()}>
                                                                <MoreHorizIcon />
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: 12, color: 'rgba(110,109,109)', padding: '10PX 0' }}>这个知识库还没有介绍~</div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }} onClick={(e) => e.stopPropagation()}>
                                                        <div style={{ display: 'flex' }} onClick={() => copy_id(item._id)}>
                                                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                                                                <img src={id_card} alt='' style={{ width: 15, height: 15 }} />
                                                            </div>
                                                            <div style={{ color: '#707070', fontSize: 12, width: 90, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item._id}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => copy_id(item._id)}>
                                                            <div style={{ color: base_setting?.primary_color, fontSize: 11, width: 35 }}>拷贝id</div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={copy_i} alt='' style={{ width: 25, height: 25 }} />
                                                            </div>
                                                        </div>
                                                        {/* <Checkbox style={{ color: '#4844f3' }} onChange={(event) => onChange(item._id, event.target.checked)} checked={item.is_enabled} onClick={(e) => e.stopPropagation()}></Checkbox> */}
                                                    </div>
                                                </div>
                                            })
                                        }


                                    </div> : <div style={{ textAlign: 'center' }}>您还没有知识库</div>
                                }
                            </div>
                            {
                                user_type>=170?<div style={{ border: '1px solid rgba(110,109,109,0.2)', minHeight: '30vh', padding: '20px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: 20, color: base_setting?.primary_color, fontWeight: 'bold', paddingRight: 10 }}>公司知识库</div>
                                        <Checkbox onChange={company_all_onChange} checked={company_is_enabled}></Checkbox>
                                    </div>
                                    {
                                        user_type >=170 ? <div style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '8px 15px', display: 'flex', alignItems: 'center', borderRadius: 10, cursor: 'pointer' }} onClick={() => opne_new_warehouse(1)}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <AddIcon></AddIcon>
                                            </div>
                                            <div>新建文件夹</div>
                                        </div> : ''
                                    }

                                </div>
                                {
                                    company_dataset_lists?.length > 0 ? <div style={{ paddingTop: '2%', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                        {
                                            company_dataset_lists?.map((item, index) => {
                                                return <div key={index} style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '10px', borderRadius: 10, width: '20%', minWidth: 170, marginRight: '5%', marginBottom: '5%', cursor: 'pointer' }} onClick={() => set_subset(item._id, item.name, 1)}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', position: 'relative' }}>
                                                        <div style={{ display: 'flex', width: 'calc(95% - 24px)' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={storeroom} alt='' style={{ width: 20, height: 20 }} />
                                                            </div>
                                                            <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center', fontSize: 15, fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.name}</div>
                                                        </div>
                                                        {
                                                            user_type >= 170 ? <div onClick={(e) => e.stopPropagation()}>
                                                                <Dropdown overlay={options(item._id, item, 1)} onClick={(e) => e.stopPropagation()}>
                                                                    <MoreHorizIcon />
                                                                </Dropdown>
                                                            </div> : ''
                                                        }

                                                    </div>
                                                    <div style={{ fontSize: 12, color: 'rgba(110,109,109)', padding: '10PX 0' }}>这个知识库还没有介绍~</div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }} onClick={(e) => e.stopPropagation()}>
                                                        <div style={{ display: 'flex' }} onClick={() => copy_id(item._id)}>
                                                            <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                                                                <img src={id_card} alt='' style={{ width: 15, height: 15 }} />
                                                            </div>
                                                            <div style={{ color: '#707070', fontSize: 12, width: 90, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item._id}</div>
                                                        </div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => copy_id(item._id)}>
                                                            <div style={{ color: base_setting?.primary_color, fontSize: 11, width: 35 }}>拷贝id</div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={copy_i} alt='' style={{ width: 25, height: 25 }} />
                                                            </div>
                                                        </div>
                                                        {/* <Checkbox onChange={(event) => company_onChange(item._id, event.target.checked)} checked={item.is_enabled} onClick={(e) => e.stopPropagation()}></Checkbox> */}
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div> : <div style={{ textAlign: 'center' }}>您还没有知识库</div>
                                }
                            </div>:''
                            }
                            
                        </div> : ''
                    }
                    {
                        cutpage !== 0 ? <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                            <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                                <div onClick={root_directory} style={{ cursor: 'pointer' }}>知识库</div>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div>
                                <div onClick={subset_lists} style={{ cursor: 'pointer' }}>{subset_name}</div>
                                {
                                    cutpage === 2 ? <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div> : ''
                                }
                                {
                                    cutpage === 2 ? <div onClick={detaile_lists} style={{ cursor: 'pointer' }}>{detaile_name}</div> : ""
                                }
                            </div>
                            {
                                cutpage === 1 ? <div style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '8px 15px', display: 'flex', alignItems: 'center', borderRadius: 10, cursor: 'pointer' }} onClick={opne_new_warehouse}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AddIcon></AddIcon>
                                    </div>
                                    <div>新建</div>
                                </div> : ''
                            }
                            {
                                cutpage === 2 ? <div style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '8px 15px', display: 'flex', alignItems: 'center', borderRadius: 10, cursor: 'pointer' }} onClick={open_collection}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AddIcon></AddIcon>
                                    </div>
                                    <div>新建</div>
                                </div> : ''
                            }
                        </div> : ''
                    }
                    {
                        cutpage === 1 ? <div style={{ padding: '20px' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                {
                                    collection_lists?.length > 0 ? collection_lists.map((item, index) => {
                                        return item.name !== '手动录入' ? <div key={index} onClick={() => open_details(item._id, item.name, 2)} style={{ border: '1px solid rgba(110,109,109,0.2)', padding: '10px', borderRadius: 10, width: '20%', minWidth: 170, marginRight: '5%', marginBottom: '5%', cursor: 'pointer' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <div style={{ display: 'flex', width: 'calc(95% - 24px)' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={subset} alt='' style={{ width: 20, height: 20 }} />
                                                    </div>
                                                    <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center', fontSize: 15, fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '80%' }}>{item.name}</div>
                                                </div>
                                                <div onClick={(e) => e.stopPropagation()}>
                                                    <Dropdown overlay={options(item._id, item)} onClick={(e) => e.stopPropagation()}>
                                                        <MoreHorizIcon />
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div style={{ fontSize: 12, color: 'rgba(110,109,109)', padding: '10px 0' }}>这个知识库还没有介绍~</div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: 10 }} onClick={(e) => e.stopPropagation()}>
                                                <div style={{ display: 'flex' }} onClick={() => copy_id(parentId)}>
                                                    <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                                                        <img src={id_card} alt='' style={{ width: 15, height: 15 }} />
                                                    </div>
                                                    <div style={{ color: '#707070', fontSize: 12, width: 60, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{item._id}</div>
                                                </div>
                                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => copy_id(parentId)}>
                                                    <div style={{ color: base_setting?.primary_color, fontSize: 11, width: 65 }}>拷贝母文件id</div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <img src={copy_i} alt='' style={{ width: 25, height: 25 }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : ''
                                    }) : <div style={{ textAlign: 'center' }}>您还没有知识库</div>
                                }
                            </div>
                        </div> : ''
                    }
                    {
                        cutpage === 2 ? <div style={{ padding: '5%', display: 'flex', flexWrap: 'wrap', paddingTop: 0, }}>
                            {
                                collection_data?.map((item, index) => {
                                    return (
                                        <div onClick={() => collection_particulars(item)} key={index} style={{ cursor: 'pointer', padding: 10, background: 'rgba(221,221,221,0.4)', width: '20%', marginRight: 20, marginBottom: 20, borderRadius: 5, height: 150 }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', padding: 2, borderRadius: 3 }}>#{index + 1}</div>
                                            </div>
                                            <div style={{ height: '65%', margin: '5px 0' }}>
                                                <text style={{ height: '100%', overflow: 'hidden', display: 'flex', fontSize: 14 }}>{item.q}</text>
                                            </div>
                                            <div style={{ textAlign: 'right' }} onClick={(e) => e.stopPropagation()} >
                                                <DeleteForeverIcon onClick={() => handleDelete(item._id)} style={{ boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', fontSize: 20, cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div> : ''
                    }
                    <Spin spinning={is_loading} fullscreen />
                </div>
            </div>
            <Dialog onClose={handleClose} open={new_warehouse} maxWidth='lg' style={{ padding: '5%', width: '100%', height: 800 }}>
                <DialogTitle style={{ textAlign: 'center', padding: 10, fontWeight: 'bold', fontSize: 22 }}>新建知识库</DialogTitle>
                <div style={{ padding: '30px 40px', paddingTop: 0, width: '55vw' }}>
                    <div>
                        {
                            cutpage === 1 ? <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', paddingBottom: 20, justifyContent: 'space-between' }}>
                                    <div style={{ padding: '10px', border: creation_type === 0 ? `1px solid ${base_setting?.primary_color}` : '1px solid rgba(0,0,0,0.2)', borderRadius: 8, width: '30%', textAlign: 'center', color: creation_type == 0 ? MODEL_COLOR : '', cursor: 'pointer' }} onClick={() => creation_cut(0)}>文件上传并自动分段</div>
                                    <div style={{ padding: '10px', border: creation_type === 1 ? `1px solid ${base_setting?.primary_color}` : '1px solid rgba(0,0,0,0.2)', borderRadius: 8, width: '30%', textAlign: 'center', color: creation_type == 1 ? MODEL_COLOR : '', cursor: 'pointer' }} onClick={() => creation_cut(1)}>网页链接读取</div>
                                    <div style={{ padding: '10px', border: creation_type === 2 ? `1px solid ${base_setting?.primary_color}` : '1px solid rgba(0,0,0,0.2)', borderRadius: 8, width: '30%', textAlign: 'center', color: creation_type == 2 ? MODEL_COLOR : '', cursor: 'pointer' }} onClick={() => creation_cut(2)}>手动录入</div>
                                </div>
                                {
                                    (() => {
                                        if (creation_type === 0) {
                                            return (
                                                <div>
                                                    <div style={{ width: '100%', height: 200, paddingBottom: 20 }}>
                                                        <Dragger {...props} >
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-hint">
                                                                拖拽文件上传或点击上传文件
                                                            </p>
                                                            <p className="ant-upload-hint">支持文件：.pdf,.txt,.pptx,.xlsx,.docx,.csv,.html,.json,.md</p>
                                                        </Dragger>
                                                    </div>
                                                </div>
                                            )
                                        } else if (creation_type === 1) {
                                            return (
                                                <div style={{ width: '100%', height: 200, paddingBottom: 20 }}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="网页链接读取;可上传多个链接，但需回车隔开,最多上传50个链接"
                                                        multiline
                                                        variant="outlined"
                                                        value={web_url}
                                                        rows={8}
                                                        size="small"
                                                        onChange={url_Change}
                                                        style={{ width: '100%', fontSize: '12px !important' }}
                                                    />
                                                </div>
                                            )
                                        } else if (creation_type === 2) {
                                            return (
                                                <div style={{ width: '100%', height: 200, paddingBottom: 20 }}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="手动录入"
                                                        multiline
                                                        variant="outlined"
                                                        value={artificial_value}
                                                        rows={8}
                                                        size="small"
                                                        onChange={artificial_Change}
                                                        style={{ width: '100%', fontSize: 15 }}
                                                    />
                                                </div>
                                            )
                                        }
                                    })()
                                }
                            </div> : ''
                        }
                        <div style={{ width: '100%' }}>
                            <Divider style={{ margin: '20px 0' }}></Divider>
                            <div style={{ width: '100%' }}>
                                <TextField
                                    id="outlined-basic"
                                    label="库名"
                                    variant="outlined"
                                    value={name}
                                    size="small"
                                    onChange={name_Change}
                                    style={{ width: '100%', fontSize: 15 }}
                                />
                            </div>
                            <div style={{ padding: '10px 0' }}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="介绍"
                                    multiline
                                    rows={3}
                                    value={introduce}
                                    onChange={introduce_Change}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: 10 }} onClick={creation_subordinate}>
                        <Button variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color }}>创建</Button>
                    </div>
                </div>
            </Dialog>
            <Dialog onClose={update_handleClose} open={is_update} maxWidth='lg' style={{ padding: '5%', width: '100%', height: 800 }}>
                <DialogTitle style={{ textAlign: 'center', padding: 10, fontWeight: 'bold', fontSize: 22 }}>修改数据</DialogTitle>
                <div style={{ padding: '30px 40px', paddingTop: 0, width: '55vw' }}>
                    <div style={{ width: '100%' }}>
                        <Divider style={{ margin: '20px 0' }}></Divider>
                        <div style={{ width: '100%' }}>
                            <TextField
                                id="outlined-basic"
                                label="库名"
                                variant="outlined"
                                value={update_name}
                                size="small"
                                onChange={(event) => setUpdateName(event.target.value)}
                                style={{ width: '100%', fontSize: 15 }}
                            />
                        </div>
                        <div style={{ padding: '10px 0' }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="介绍"
                                multiline
                                rows={3}
                                value={update_introduce}
                                onChange={(event) => setUpdateIntroduce(event.target.value)}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: 10 }} onClick={update_datas}>
                        <Button variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color }}>确认修改</Button>
                    </div>
                </div>
            </Dialog>
            <Dialog onClose={collection_handleClose} open={is_collection} maxWidth='lg' style={{ padding: '5%', width: '100%', height: 800 }}>
                <DialogTitle style={{ textAlign: 'center', padding: 10, fontWeight: 'bold', fontSize: 22 }}>创建新数据</DialogTitle>
                <div style={{ padding: '30px 40px', paddingTop: 0, width: '55vw' }}>
                    <div>
                        <div style={{ padding: '10px 0' }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="该内容为知识点的描述，也可以是用户的问题，最多3000字"
                                multiline
                                rows={8}
                                value={collection_value}
                                onChange={collection_Change}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: 10 }} onClick={creation_collection}>
                        <Button variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color }}>确认创建</Button>
                    </div>
                </div>
            </Dialog>
            <Dialog onClose={update_collection_handleClose} open={is_update_collection} maxWidth='lg' style={{ padding: '5%', width: '100%', height: 800 }}>
                <DialogTitle style={{ textAlign: 'center', padding: 10, fontWeight: 'bold', fontSize: 22 }}>修改数据</DialogTitle>
                <div style={{ padding: '30px 40px', paddingTop: 0, width: '55vw' }}>
                    <div>
                        <div style={{ padding: '10px 0' }}>
                            <TextField
                                id="outlined-multiline-static"
                                label=""
                                multiline
                                rows={8}
                                value={update_collection_value}
                                onChange={update_collection_Change}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div style={{ paddingTop: 10 }} onClick={update_collection}>
                        <Button variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color }}>确认修改</Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={delete_confirm}
                onClose={delete_handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"删除?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确认删除该知识库吗
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={delete_handleClose}>取消</Button>
                    <Button onClick={confirm} autoFocus>
                        删除
                    </Button>
                </DialogActions>
            </Dialog>
            <RecordingDrawer ></RecordingDrawer>
        </div>
    )
}

export default KnowledgeBases