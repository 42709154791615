import {SETSUGGEST,PRICES,WEBQRCODE,CHANGE_PAY_PRODUCT,GETDISCOUNT,USEDDISCOUNT,LLM_PRICE_LISTS,LLM_QR_CODE,LLM_MEMBERSHIP_INFO} from '../actions/types';

const initialState = {
    is_suggest:false,
    error_value:'',
    shui_price:{},
    code_url:'',
    qr_code_uid:'',
    pay_product:'Shui引擎',
    discount:null,
    discount_message:'领取中',
    discount_value:'',
    llm_price_lists:[],
    llm_qr_code:'',
    membership_counts:null,
    membership_months:null,
    exceed_vip:0
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SETSUGGEST:
            return {...state, is_suggest:action.is_suggest,error_value:action.error_value};
        case PRICES:
            return {...state,shui_price:action.shui_price};
        case WEBQRCODE:
            return {...state,code_url:action.code_url,qr_code_uid:action.qr_code_uid};
        case CHANGE_PAY_PRODUCT:
            return {...state,pay_product:action.pay_product};
        case GETDISCOUNT:
            return {...state,discount:action.discount,discount_message:action.message,discount_value:action.discount_value}
        case USEDDISCOUNT:
            return {...state,discount:null,discount_message:'',discount_value:''}
        // llm
        case LLM_PRICE_LISTS:
            return {...state,llm_price_lists:action.data}
        case LLM_QR_CODE:
            return {...state,llm_qr_code:action.data}
        case LLM_MEMBERSHIP_INFO:
            let exceed_vip=0
            if(action.data.membership_months!=null){
                let targetTime =new Date(action.data.membership_months.expire_time)
                if(new Date()<targetTime){
                    exceed_vip=1
                }
                console.log(new Date()<targetTime)
            }
            return {...state,membership_counts:action.data.membership_counts,membership_months:action.data.membership_months,exceed_vip:exceed_vip}
        default:
            return state;
}
}