import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import RecordingDrawer from '../examples/recording_drawer'
import { get_outsider_settings, cut_tab_action, get_crm_customer_lists, filter_crm_customer } from '../../../actions/user'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '50%',
        boxShadow: "0 1px 12px #D6D6D6 !important",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        // backgroundColor: "#0084e9 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#0084e9 !important',
            color: '#fff'
        },
    },
    clearButton: {
        color: '#0084e9 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));

function CrmUser(e) {
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const agent_menus = useSelector((state) => state.user.agent_menus);
    const voice_lists = useSelector((state) => state.user.voice_lists);
    const base_setting = useSelector((state) => state.user.base_setting);
    const crm_lists = useSelector((state) => state.user.crm_lists);
    const [crm_user_lists,setCrmUserLists]=React.useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(get_outsider_settings())
        dispatch(get_crm_customer_lists())
    }, [])
    useEffect(()=>{
        setCrmUserLists(crm_lists)
    },[crm_lists])
    const MaxContent = (props) => {
        const e = props.e
        console.log(e)
        if (e?.length > 10) {
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{e}</div>
                <div>
                    <Tooltip title={e}>
                        <HelpIcon style={{ color: 'rgba(215, 215, 215)', fontSize: 20 }}>
                        </HelpIcon>
                    </Tooltip>
                </div>
            </div>
        } else {
            return <div style={{ width: 100 }}>{e}</div>
        }
    }
    const cut_tab = (event) => {
        console.log(event)
        dispatch(cut_tab_action(event))
    }
    const [searchValue, setSearchValue] = React.useState('');
    const searchChange = (e) => {
        setSearchValue(e.target.value)
    }
    const Enter_searchChange=(e)=>{
        console.log(e)
        let key=e.keyCode
        if(key==13){
            console.log(123+'回车')
            dispatch(filter_crm_customer({ search: searchValue },(v)=>setCrmUserLists(v)))
        }
    }
    const clearAll = () => {
        setSearchValue('')
        dispatch(filter_crm_customer({ search: searchValue },(v)=>setCrmUserLists(v)))
    }
    const handleSearch = () => {
        dispatch(filter_crm_customer({ search: searchValue },(v)=>setCrmUserLists(v)))
    }
    return (
        <div >
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                            <TabbleLists></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '3% 5%', height: 'calc(84vh - 62px)', overflowY: 'auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10, paddingTop: 20 }}>
                        <div style={{ fontWeight: 'bold' }}>CRM客户列表</div>
                        <Link to='/largemodel/create_crm' style={{ textAlign: 'right' }}>
                            <Button variant="contained" color='success' style={{ background: "#46b14b" }}>创建CRM客户</Button>
                        </Link>
                    </div>
                    <div style={{ paddingBottom: 10, padding: 30, paddingTop: 20, paddingLeft: 0 }}>
                        <Paper component="form" className={classes.root}>
                            <IconButton className={classes.iconButton} aria-label="menu">
                                <SearchIcon style={{ fontSize: 32 }} />
                            </IconButton>

                            <InputBase
                                className={classes.input}
                                placeholder=""
                                value={searchValue}
                                onKeyDown={Enter_searchChange}
                                onChange={searchChange}
                            />
                            <InputBase
                                className={classes.input}
                                placeholder="隐藏框，防止按回车刷新页面"
                                value={searchValue}
                                onKeyDown={Enter_searchChange}
                                onChange={searchChange}
                                style={{display:'none'}}
                            />
                            <Button className={classes.clearButton} onClick={clearAll} >清空搜索</Button>
                            <Button className={classes.searchClickButton}
                                onClick={handleSearch} disableElevation style={{ background: base_setting?.primary_color }}>
                                搜索
                            </Button>
                        </Paper>
                    </div>
                    <div>
                        <TableContainer>
                            <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>名称</TableCell>
                                        <TableCell align="left" style={{ minWidth: 60 }}>公司名</TableCell>
                                        <TableCell align="left" style={{ minWidth: 60 }}>电话</TableCell>
                                        <TableCell align="left" style={{ minWidth: 60 }}>内部笔记</TableCell>
                                        <TableCell align="left" style={{ minWidth: 60 }}>介绍</TableCell>
                                        <TableCell align="left" style={{ minWidth: 60 }}>重要性</TableCell>
                                        <TableCell align="center">操作</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {crm_user_lists?.map((row) => (
                                        <TableRow
                                            hover
                                            key={row._id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <MaxContent e={row.name} />
                                            </TableCell>
                                            <TableCell align="left"><MaxContent e={row.company_info} /></TableCell>
                                            <TableCell align="left"><MaxContent e={row.phone} /></TableCell>
                                            <TableCell align="left"><MaxContent e={row.internal_notes} /></TableCell>
                                            <TableCell align="left"><MaxContent e={row.brief} /></TableCell>
                                            <TableCell align="left"><MaxContent e={row.importance_level} /></TableCell>
                                            <TableCell align="center">
                                                <Link to={{ pathname: `/largemodel/update_crm`, state: { id: row._id } }} >
                                                    <Button style={{ color: base_setting?.primary_color }}>查看/修改</Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <RecordingDrawer></RecordingDrawer>
        </div>
    )
}
export default CrmUser