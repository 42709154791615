import {ALL_USER_LOCATION,TRACK_CLOSE_DRAWER,OPEN_TRACK_DRAWER,BEHAVIOR_DATA,HAVE_COLLECT,MY_COLLECTED_USER,USER_LINKED_LISTS,ADMIN_LOADING,SNACK_OPEN,RELEVANCY} from '../actions/types';
import { isUndefined } from 'micro-dash';
import { act } from 'react-dom/test-utils';
const _ = require('micro-dash');


const initialState = {
    all_user_location:[],
    track_open:false,
    behavior_data:{
        behaviors:[
            
        ],
        behaviors_length:1,
        total_duration:0,
        total_visit_times:0,
        update_time:0
    },
    track_data:null,
    have_collect_lists:[],
    my_collected_lists:[],
    count:0,
    all_user_pool:[],
    ranking:[],
    my_behavior_data:{
        behavior:[
            
        ],
        total_duration:0,
        total_visit_times:0,
        update_time:0
    },
    my_ranking:[],
    salesman_lists:[],
    staff_lists:[],
    loading_open:false,
    snack_open:false,
    is_relevancy:true,
};

 // eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type) {
        case ALL_USER_LOCATION:

            if(action.page_type==='map'){
                state.all_user_location=[]
                return {...state,all_user_location:action.data}
            }else{
                for(let i=0;i<action.data.length;i++){
                    if(!_.isNil(action.data[i].phone_number)){
                        action.data[i].phone_number = action.data[i].phone_number.replace(action.data[i].phone_number.substring(3,7), "****");
                    }
                }
                return {...state,all_user_pool:action.data}
            }
        case TRACK_CLOSE_DRAWER:
            return {...state,track_open:false}
        case OPEN_TRACK_DRAWER:
            return {...state,track_open:true,track_data:action.track}
        case BEHAVIOR_DATA:
            console.log(action.data)
            let page_durations=Object.keys(action.data.page_durations)
            let page_times=Object.keys(action.data.page_times)
            let ranking=[
                {
                    ranking:1,
                    page_duration:page_durations[0],
                    page_times:page_times[0]
                },
                {
                    ranking:2,
                    page_duration:page_durations[1],
                    page_times:page_times[1]
                },
                {
                    ranking:3,
                    page_duration:page_durations[2],
                    page_times:page_times[2]
                }
            ]
            action.data.update_time=action.data.update_time.split('T')[0]
            if(action.page_type==='map'){
                return {...state,behavior_data:action.data,ranking:ranking}
            }else{
                return {...state,my_behavior_data:action.data,my_ranking:ranking}
            }
        case HAVE_COLLECT:
            let have_collect=[...state.have_collect_lists]
            have_collect.push(action.data)
            return {...state,have_collect_lists:have_collect}
        case MY_COLLECTED_USER:
            return {...state,my_collected_lists:action.data,count:action.count}
        case USER_LINKED_LISTS:
            let salesman=[]
            let staff=[]
            // (0, '销售员'),
            // (1, '店长'),
            // (2, '员工'),
            for(let i=0;i<action.lists.length;i++){
                if(action.lists[i].role===0){
                    salesman.push(action.lists[i])
                }else if(action.lists[i].role===2){
                    staff.push(action.lists[i])
                }
            }
            return {...state,salesman_lists:salesman,staff_lists:staff}
        case RELEVANCY:
            return {...state,is_relevancy:action.mess,snack_open:true}
        case SNACK_OPEN:
            return {...state,snack_open:false}
        case ADMIN_LOADING:
            return {...state,loading_open:action.loading_open}
        default:
            return state
    }
}