import React, {useEffect,useCallback} from "react";
import {useDispatch,useSelector} from "react-redux";
import {WECHAT_LOGIN_URL} from '../../../configs/constants'
import {Link} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Countdown from 'react-countdown';
import {useHistory} from 'react-router-dom';
import {shui_notification_list,shui_notification_mark_read} from '../../../actions/inform'
import Footer from "../../footer/Footer";
import HeadHavigation from "../../headHavigation/headHavigation";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        maxWidth: '100%',
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
        minHeight:'75vh'
    },
    lists:{
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        '-webkit-transition': 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '4px',
        boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
        padding:'20px',
        marginBottom:'26px',
        position:'relative'
    }
}));

function InformLists(){
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(shui_notification_list())
    },[])
    const shui_inform_lists=useSelector((state)=>state.inform.shui_inform_lists)
    console.log(shui_inform_lists)
    return (    
        <div>
            <HeadHavigation provenance={0} />
            <div className={classes.root}>
                {
                    (()=>{
                       if(shui_inform_lists.length>0){
                           return (
                            shui_inform_lists.map((item,index)=>
                                <Link to={{pathname:`/inform_details`,state:{index:item}}}>
                                    <div className={classes.lists} onClick={() => dispatch(shui_notification_mark_read({notification_id:item.node.id}))}>
                                        <div style={{display:'flex',justifyContent:'space-between'}}>
                                            <div style={{fontWeight:'bold',whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',width:'80%'}}>{item.node.title}</div>
                                            <div style={{width:'20%',textAlign:'right'}}>{item.node.create_time.split('T')[0]}</div>
                                        </div>
                                        <div style={{whiteSpace:'nowrap',textOverflow:'ellipsis',overflow:'hidden',display:'-webkit-box',WebkitLineClamp:'2',WebkitBoxOrient:'vertical'}}>{item.node.content}</div>
                                        {
                                            !item.node.is_read?<div style={{width:12,height:12,borderRadius:'50%',backgroundColor:'#3D1198',position:'absolute',top:0,right:0}}></div>:''
                                        }
                                    </div>
                                </Link>
                            )
                           )
                       }else{
                           return <div style={{textAlign:'center'}}>暂无通知</div>
                       }
                    })()
                }
            </div>
            <div style={{padding:'0 12%'}}>
                <Footer />
            </div>
        </div>
    )
}
export default InformLists;