import {TAX_LISTS, TAX_INFO,TAX_CLOSE_DRAWER} from '../actions/types';

const initialState = {
    tax_accountant_lists:[],
    attorney_lists:[],
    tax_drawer_open:false
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case TAX_LISTS:
            let tax_lists = []
            let attorney_lists = []
            for (let i = 0; i < action.tax_agent_list.length; i++) {
                if (action.tax_agent_list[i].is_equity_lawyer == true && action.tax_agent_list[i].is_examination == true) {
                attorney_lists.push(action.tax_agent_list[i])
                } else if (action.tax_agent_list[i].is_equity_lawyer != true && action.tax_agent_list[i].is_examination == true) {
                tax_lists.push(action.tax_agent_list[i])
                }
            }
            return {...state, tax_accountant_lists:tax_lists,attorney_lists:attorney_lists};
        case TAX_INFO:
            return {...state,tax_accountant_info:action.data,tax_drawer_open:true};
        case TAX_CLOSE_DRAWER:
            return {...state,tax_drawer_open:false}
        default:
            return state;
    }
}