import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import PreviewItem from './PreviewItem';
import { useDispatch, useSelector } from 'react-redux';
import { Link,Route,Switch } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import HeadHavigation from "../../headHavigation/headHavigation";
import Footer from "../../footer/Footer";
import DrawerContent from './DrawerContent';
import {CLOUD_URL} from '../../../configs/constants'
import { home_data,close_drawer,scene_open_drawer } from '../../../actions/search';


const _ = require('micro-dash');




function Scene(){
    const dispatch = useDispatch();
    const swiper_company_data=[
        {
            img_url:'videos/pictures/检查亏损.png',
            video_url:'videos/videos/检查亏损.mp4'
          },
          {
            img_url:'videos/pictures/固定资产筹划.png',
            video_url:'videos/videos/固定资产筹划.mp4'
          },
          {
            img_url:'videos/pictures/设立分公司及子公司.png',
            video_url:'videos/videos/设立分公司及子公司.mp4'
          }
    ]
    const swiper_personage_data=[
        {
            img_url:'videos/pictures/薪金.jpeg',
            video_url:'videos/videos/薪金.mp4'
        },
        {
            img_url:'videos/pictures/不动产租赁.png',
            video_url:'videos/videos/不动产租赁.mp4'
        },
        {
            img_url:'videos/pictures/个人入股情况.png',
            video_url:'videos/videos/个人入股情况.mp4'
        }
    ]
    const swiper_industry_data=[
        {
            img_url:'videos/pictures/科技行业税收筹划.png',
            video_url:'videos/videos/科技行业税收筹划.mp4'
        },
        {
            img_url:'videos/pictures/农林牧渔业税收筹划.png',
            video_url:'videos/videos/农林牧渔业税收筹划.mp4'
        },
        {
            img_url:'videos/pictures/销售型 企业税收筹划.png',
            video_url:'videos/videos/销售型 企业税收筹划.mp4'
        }
    ]
    const company_category_data=useSelector((state) => state.search.company_category_data);
    const individual_category_data=useSelector((state) => state.search.individual_category_data);
    const business_type_data=useSelector((state) => state.search.business_type_data);
    const drawer_open = useSelector((state) => state.search.drawer_open);
    const detailed_info = useSelector((state) => state.search.hit);
    const all_category_data=useSelector((state) => state.search.all_category_data);
    const [video_url, setVideo] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const play_video=(e)=>{
        setVideo(e);
        setOpen(!open)
    }
    useEffect(() => {
        dispatch(home_data())
    }, []);
    return (
        <div>
            <HeadHavigation provenance={0} />
            <div style={{padding:'0 12%',marginTop:39}}>
                <div>
                    <div style={{fontWeight:'bold',padding:'3% 0',fontSize:18,paddingBottom:0}}>公司筹划情景</div>
                    <div style={{display:'flex',width:'90%',textAlign:'center'}}>
                        {
                            swiper_company_data.map(
                                (item,index)=>
                                    <div style={{width:'25%',margin:'5% 2%',cursor: "pointer"}} onClick={()=>play_video(item.video_url)}>
                                        <img src={CLOUD_URL+item.img_url} alt="" style={{width:'100%',height:'100%'}} />
                                    </div>
                            )
                        }
                        <div style={{width:'25%',margin:'5% 2%',display:'flex',alignItems: 'center',justifyContent:'center',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',background:'rgb(61, 17, 152)'}}>
                            <Link to='/tax/video_lists'>
                                <div style={{cursor: "pointer",textAlign:'center'}}>
                                    <PlaylistAddCheckCircleIcon style={{color:'#fff',width:'40px',height:'40px',textAlign:'center'}}/>
                                    <div style={{textAlign:'center',fontSize:14,color:'#fff'}}>点击查看全部视频</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                        {
                            company_category_data.map(
                                (item,index)=>
                                    all_category_data[item.title].length>1?
                                    <Link to={'/scene/lists?name='+item.title} style={{width:'8%',backgroundColor:'#EDE7F8',margin:10,padding:'3%',textAlign:'center',borderRadius:8,cursor: "pointer"}}>
                                        <div >
                                            <div>
                                                <img src={CLOUD_URL+item.img_url} alt="" style={{width:50,height:50}} />
                                            </div>
                                            <div>{item.title}</div>
                                        </div>
                                    </Link>:
                                    <div style={{width:'8%',backgroundColor:'#EDE7F8',margin:10,padding:'3%',textAlign:'center',borderRadius:8,cursor: "pointer"}} onClick={()=>dispatch(scene_open_drawer(all_category_data[item.title][0].id))}>
                                        <div>
                                            <img src={CLOUD_URL+item.img_url} alt="" style={{width:50,height:50}} />
                                        </div>
                                        <div>{item.title}</div>
                                    </div>
                            )
                        }
                    </div>
                </div>
                <div>
                    <div style={{fontWeight:'bold',padding:'3% 0',fontSize:18,paddingBottom:0}}>个人筹划情景</div>
                    <div style={{display:'flex',width:'90%',textAlign:'center'}}>
                        {
                            swiper_personage_data.map(
                                (item,index)=>
                                    <div style={{width:'25%',margin:'5% 2%',cursor: "pointer"}} onClick={()=>play_video(item.video_url)}>
                                        <img src={CLOUD_URL+item.img_url} alt="" style={{width:'100%',height:'100%'}} />
                                    </div>
                            )
                        }
                        <div style={{width:'25%',margin:'5% 2%',display:'flex',alignItems: 'center',justifyContent:'center',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',background:'rgb(61, 17, 152)'}}>
                            <Link to='/tax/video_lists'>
                                <div style={{cursor: "pointer",textAlign:'center'}}>
                                    <PlaylistAddCheckCircleIcon style={{color:'#fff',width:'80px',height:'80px',textAlign:'center'}}/>
                                    <div style={{textAlign:'center',fontSize:14,color:'#fff'}}>点击查看全部视频</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                        {
                            individual_category_data.map(
                                (item,index)=>
                                    all_category_data[item.title].length>1?
                                    <Link to={'/scene/lists?name='+item.title} style={{width:'8%',backgroundColor:'#EDE7F8',margin:10,padding:'3%',textAlign:'center',borderRadius:8,cursor: "pointer"}}>
                                        <div >
                                            <div>
                                                <img src={CLOUD_URL+item.img_url} alt="" style={{width:50,height:50}} />
                                            </div>
                                            <div>{item.title}</div>
                                        </div>
                                    </Link>:
                                    <div style={{width:'8%',backgroundColor:'#EDE7F8',margin:10,padding:'3%',textAlign:'center',borderRadius:8,cursor: "pointer"}} onClick={()=>dispatch(scene_open_drawer(all_category_data[item.title][0].id))}>
                                        <div>
                                            <img src={CLOUD_URL+item.img_url} alt="" style={{width:50,height:50}} />
                                        </div>
                                        <div>{item.title}</div>
                                    </div>
                            )
                        }
                    </div>
                </div>
                <div>
                    <div style={{fontWeight:'bold',padding:'3% 0',fontSize:18,paddingBottom:0}}>行业筹划情景</div>
                    <div style={{display:'flex',width:'90%',textAlign:'center'}}>
                        {
                            swiper_industry_data.map(
                                (item,index)=>
                                    <div style={{width:'25%',margin:'5% 2%',cursor: "pointer"}} onClick={()=>play_video(item.video_url)}>
                                        <img src={CLOUD_URL+item.img_url} alt="" style={{width:'100%',height:'100%'}} />
                                    </div>
                            )
                        }
                        <div style={{width:'25%',margin:'5% 2%',display:'flex',alignItems: 'center',justifyContent:'center',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.16)',background:'rgb(61, 17, 152)'}}>
                            <Link to='/tax/video_lists'>
                                <div style={{cursor: "pointer",textAlign:'center'}}>
                                    <PlaylistAddCheckCircleIcon style={{color:'#fff',width:'80px',height:'80px',textAlign:'center'}}/>
                                    <div style={{textAlign:'center',fontSize:14,color:'#fff'}}>点击查看全部视频</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={{display:'flex',flexWrap:'wrap'}}>
                        {
                            business_type_data.map(
                                (item,index)=>
                                all_category_data[item.title].length>1?
                                    <Link to={'/scene/lists?name='+item.title} style={{width:'8%',backgroundColor:'#EDE7F8',margin:10,padding:'3%',textAlign:'center',borderRadius:8,cursor: "pointer"}}>
                                        <div >
                                            <div>
                                                <img src={CLOUD_URL+item.img_url} alt="" style={{width:50,height:50}} />
                                            </div>
                                            <div>{item.title}</div>
                                        </div>
                                    </Link>:
                                    <div style={{width:'8%',backgroundColor:'#EDE7F8',margin:10,padding:'3%',textAlign:'center',borderRadius:8,cursor: "pointer"}} onClick={()=>dispatch(scene_open_drawer(all_category_data[item.title][0].id))}>
                                        <div>
                                            <img src={CLOUD_URL+item.img_url} alt="" style={{width:50,height:50}} />
                                        </div>
                                        <div>{item.title}</div>
                                    </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <hr/>
            <div style={{padding:'20px 12%'}}>
                <Footer />
            </div>
            <Drawer
                anchor={'right'}
                open={drawer_open}
                onClose={() => dispatch(close_drawer())}
            >
                <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                    <DrawerContent
                        hit={detailed_info}
                    />
                </div>
            </Drawer>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <div>
                    {
                        open?<video src={CLOUD_URL+video_url} autoPlay></video>:''
                    }
                    {/* <video src={CLOUD_URL+video_url} autoPlay></video> */}
                </div>
            </Backdrop>
        </div>
    )
}

export default Scene;