import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client'
import axios from 'axios';
import { useParams, Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import './chat.css'
import shui_engine from '../../../static/logo/logo_2.jpg'
import shui_engine3 from '../../../static/logo/logo3.jpg'
import send_button from '../../../static/image/send.png'
import copy_icon from '../../../static/image/copy_icon.png'
import redact_icon from '../../../static/image/redact_icon.png'
import home from '../../../static/image/home.png'
import accessory from '../../../static/image/accessory.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import user_img from '../../../static/image/user_img.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import qiyeweixin from '../../../static/logo/qiyeweixin.png'
import file_text from '../../../static/image/file-text.png'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import SmsIcon from '@mui/icons-material/Sms';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AddIcon from '@mui/icons-material/Add';
import copy from 'copy-to-clipboard'
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Countdown from 'react-countdown';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpIcon from '@mui/icons-material/Help';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import Chip from '@mui/material/Chip';
// import { marked } from 'marked';
import Markdown from 'react-markdown'
import { message, Spin, Dropdown } from 'antd';
import MicNoneIcon from '@mui/icons-material/MicNone';
import removeMarkdown from "markdown-to-text";
import ai_video from '../../../static/image/ai_video.png'
import agent_video from '../../../static/image/agent_video.png'
import overview_img from '../../../static/image/overview_img.png'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TabbleLists from '../../headHavigation/tabble_lists';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import { MODEL_COLOR, MIDDLE_EARTH_URL, SOCKET_EARTH_URL, socket } from '../../../configs/constants'
import { send_mobile, quick_tax_plan_api } from '../../../actions/dataset'
import {
    mobile_login, new_login, get_crm_user_info, get_prompt_no_auth,
    get_prompt_with_auth, create_user_prompt, delete_user_prompt,
    agent_list, send_socket_id, get_history_detail_file_names,
    optimize_prompt_words, get_user_fileLists, delete_voice_record
} from '../../../actions/user'
import RecordingDrawer from '../examples/recording_drawer'
import { consume_question } from '../../../actions/mine'
import { loading } from '../../../actions/plan';

const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        backgroundColor: "#4844f3 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            // backgroundColor: '#4844f3 !important',
            color: '#fff'
        },
    },
    clearButton: {
        // color: '#4844f3 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'rgb(72, 68, 243)',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : 'rgb(72, 68, 243)',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

let url = SOCKET_EARTH_URL;

const WHO_ARE_YOU = "WHO_ARE_YOU",
    AGENT_MESSAGE = "AGENT_MESSAGE",
    CLIENT_MESSAGE = "CLIENT_MESSAGE",
    LOGIN = "login";

const CLIENT_RECEIVE_MESSAGE = "CLIENT_RECEIVE_MESSAGE",
    AGENT_RECEIVE_MESSAGE = "AGENT_RECEIVE_MESSAGE",
    ERR_MESSAGE = "ERR_MESSAGE",
    AGENT_RECEIVE_MESSAGE_IN_PROGRESS = "AGENT_RECEIVE_MESSAGE_IN_PROGRESS",
    AGENT_RECEIVE_MESSAGE_ENDS = "AGENT_RECEIVE_MESSAGE_ENDS",
    AGENT_GET_NEW_HISTORY_BLOCK_ID = "AGENT_GET_NEW_HISTORY_BLOCK_ID",
    ADD_CHAT_HISTORY = "ADD_CHAT_HISTORY",
    AGENT_RECEIVE_ATTACHMENTS = "AGENT_RECEIVE_ATTACHMENTS",
    AGENT_RECEIVE_PROGRESSING_STATUS = "AGENT_RECEIVE_PROGRESSING_STATUS",
    AGENT_RECEIVE_PROGRESS_FINISH_STATUS = 'AGENT_RECEIVE_PROGRESS_FINISH_STATUS',
    who_are_you = 'who_are_you',
    AGENT_RECEIVE_CREATED_DETAIL_ID = 'AGENT_RECEIVE_CREATED_DETAIL_ID',
    TEST_STATUS_CHANGE = 'TEST_STATUS_CHANGE',
    UPLOADED_FILE_PARSING_FAILED = 'UPLOADED_FILE_PARSING_FAILED',
    AGENT_RECEIVE_UPLOADED_FILE = 'AGENT_RECEIVE_UPLOADED_FILE';

const _ = require('micro-dash');

function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}

function LargeModel() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const uploadFileInput = React.useRef();
    const token = useSelector((state) => state.user.token);
    const user_id = useSelector((state) => state.user.user);
    const user_type = useSelector((state) => state.user.user_type);
    const [files_name, setFilesName] = React.useState(null);
    const [files_suffix, setFilesSuffix] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [message_value, setMessageValue] = React.useState('');
    const [close_hint, setHint] = React.useState(true);
    const [chat_width, setChatwidth] = React.useState('74%');
    const [history_lists, setHistoryLists] = React.useState([]);
    const [cut_id, setCutId] = React.useState(null);
    const [generate_type, setGenerateType] = React.useState('');
    // const [socket_id, setSocketId] = React.useState(null);
    const socket_id = useSelector((state) => state.user.socket_id);
    const base_setting = useSelector((state) => state.user.base_setting);

    const searchParams = new URLSearchParams(window.location.search);
    const [agent_id, setAgentId] = React.useState(searchParams.get('agent_id'))
    const [agent_name, setAgentName] = React.useState(searchParams.get('agent_name'))
    const page_agent_id = searchParams.get('agent_id');
    const page_agent_name = searchParams.get('agent_name');
    console.log(agent_id, agent_name)
    useEffect(() => {
        if (!_.isNil(page_agent_name)) {
            setAgentName(page_agent_name)
            setAgentId(page_agent_id)
        } else {
            setAgentId('')
            setAgentName('')
        }
    }, [page_agent_name])

    const [msgListObj, setMsgListObj] = React.useState({
        streaming: false,
        list: [],
        block_id: null,
        end_export: true,
        cur_block: {
            content: '',
            accessory_lists: [],
            dispose_type: ''
        },
        last_message_time: null
    })
    const [optimize_prompt, setOptimizePrompt] = React.useState([])
    const [is_optimize, setIsOptimize] = React.useState(false)

    const send_to_agents = () => {
        console.log(message_value?.length)
        console.log('agent_name', !_.isNil(agent_name))
        if (_.isNil(token)) {
            message.error('请先登陆')
            setLogin(true)
        } else {
            console.log(is_file)
            setMsgListObj((prev) => {
                return { ...prev, end_export: false }
            })
            setIsGuidance(true)
            if (!is_file) {
                if (agent_name == '录音听潮') {
                    let mess_value = message_value
                    if (message_value == '') {
                        mess_value = '总结内容'
                    }
                    socket.emit(AGENT_MESSAGE, { message: `@录音听潮?record_id=${agent_id}@  ${mess_value}`, user_id: user_id, token: token, history_block_id: msgListObj.block_id, temperature: creativity, agent_id: '' })
                    setIsOptimize(false)
                    setOptimizePrompt([])
                    // if(message_value.length<300){
                    //     dispatch(optimize_prompt_words({content:message_value},(v)=>setOptimizePrompt(v)))
                    // }
                    if (!_.isNil(token)) {
                        get_history_list()
                    }
                    setSelectedFile(null)
                    setFilesName('')
                    setMessageValue('')
                    setFilesSuffix('')
                    setTextareaHeight(120)
                } else if (agent_name != '录音听潮' && agent_name != '' && !_.isNil(agent_name)) {
                    socket.emit(AGENT_MESSAGE, { message: `@${agent_name}@  ${message_value}`, user_id: user_id, token: token, history_block_id: msgListObj.block_id, temperature: creativity, agent_id: agent_id })
                    setIsOptimize(false)
                    setOptimizePrompt([])
                    if (!_.isNil(token)) {
                        get_history_list()
                    }
                    setSelectedFile(null)
                    setFilesName('')
                    setMessageValue('')
                    setFilesSuffix('')
                    setTextareaHeight(120)
                } else {
                    if (selectedFile != null && !selectedFile) {
                        if (message_value?.length !== 0 && message_value != '') {
                            socket.emit(AGENT_MESSAGE, { message: message_value, user_id: user_id, token: token, history_block_id: msgListObj.block_id, temperature: creativity, agent_id: agent_id })
                            setIsOptimize(false)
                            setOptimizePrompt([])
                            if (message_value?.length < 300) {
                                dispatch(optimize_prompt_words({ content: message_value }, (v) => setOptimizePrompt(v)))
                            }
                            setMessageValue('')
                            get_history_list()
                            setSelectedFile(null)
                            setFilesName('')
                            setFilesSuffix('')
                            setIsFile(true)
                            setTextareaHeight(120)
                        } else {
                            message.error('内容不能为空')
                        }
                    } else {
                        if (message_value?.length !== 0 && message_value != '') {
                            setMessageValue('')
                            socket.emit(AGENT_MESSAGE, { message: message_value, user_id: user_id, token: token, history_block_id: msgListObj.block_id, temperature: creativity, agent_id: agent_id })
                            setIsOptimize(false)
                            setOptimizePrompt([])
                            if (message_value?.length < 300) {
                                dispatch(optimize_prompt_words({ content: message_value }, (v) => setOptimizePrompt(v)))
                            }
                            if (!_.isNil(token)) {
                                get_history_list()
                            }
                            setSelectedFile(null)
                            setFilesName('')
                            setFilesSuffix('')
                            setTextareaHeight(120)
                        } else {
                            message.error('内容不能为空')
                        }
                    }
                }
            } else {
                message.error('内容正在生成，请勿多次发送')
            }

        }
    }
    const delete_file = () => {
        setSelectedFile(null)
        setFilesName('')
        setFilesSuffix('')
        const formData_delete = new FormData();
        formData_delete.append('detail_id', msgListObj.block_id);
        formData_delete.append('filename', files_name);
        axios.post(`${MIDDLE_EARTH_URL}/llm/upload_file`, formData_delete, {
            headers: {
                'Contennt-type': 'multipart/form-data',
                Authorization: 'token ' + token
            }
        }).then(res => {
            console.log(res)
        })
    }
    const handleUploadButtonClick = () => {
        if (_.isNil(token)) {
            message.error('请先登陆')
            setLogin(true)
        } else {
            uploadFileInput.current.click();
        }
    }
    const [is_file, setIsFile] = React.useState(false);
    const [file_loading, setFileLoading] = React.useState(false);
    const handleFileChange = (event) => {
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            setGenerateType('上传中...')
            let files = event.target.files
            const filesArray = Array.from(files).map((file) => ({
                name: file.name,
                size: file.size,
                preview: URL.createObjectURL(file),
                file,
            }))
            setSelectedFile(event.target.files[0])
            let name = ''
            for (let i = 0; i < files.length; i++) {
                name += files[i].name + ','
            }
            if (name.length > 20) {
                name = name.substring(0, 20) + '...'
            }
            setFilesName(name)
            // setFilesSuffix(files[0].type.split('.')[files[0].type.split('.')?.length - 1])

            const formData = new FormData();
            // formData.append('files', event.target.files[0], event.target.files[0].name);
            filesArray.forEach((file) => {
                formData.append('files', file.file)
            })
            formData.append('socket_id', socket_id)
            if (msgListObj.block_id != null) {
                formData.append('detail_id', msgListObj.block_id)
            }
            const config = {
                headers: {
                    'Contennt-type': 'multipart/form-data',
                    Authorization: 'token ' + token
                }
            }
            setIsFile(true)
            const instannce = axios.create({
                timeout: 300000
            })
            uploadFileInput.current.value = ''
            instannce.post(`${MIDDLE_EARTH_URL}/llm/multiple_upload_files`, formData, config).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setMsgListObj((prev) => {
                        return { ...prev, block_id: res.data.date.detail_id }
                    })
                    get_history_list()
                    // dispatch(get_history_detail_file_names({ id: res.data.date.detail_id }))
                    // setGenerateType('完成上传')
                } else {
                    setSelectedFile(null)
                    setFilesName('')
                    setFilesSuffix('')
                }
                setIsFile(false)
            }).catch(error => {
                console.log(error)
                let err = JSON.stringify(error?.message)
                setSelectedFile(null)
                setFilesName('')
                setFilesSuffix('')
                // setState({ ...state, open: true });
                // setErrMsg('文件上传失败')
                message.error('文件上传失败')
                setIsFile(false)
                amdent_lists()
            })
        }
    }
    const amdent_lists = () => {
        setPromptList(prev => {
            setStatusTitle('运行状态')
            let tmp = JSON.parse(JSON.stringify(prev))
            // tmp.message[tmp.message?.length - 1].loading = false
            if (_.isNil(tmp) || tmp?.length === 0) {

            }
            else {
                tmp.message[tmp.message?.length - 1].loading = false
            }
            tmp.message.push({ content: '文件上传失败', loading: false })
            return tmp
        })
    }
    const get_history_list = () => {
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_history_list`, {}).then(res => {
            if (res.data.history_list?.length > 0) {
                let lists = res.data.history_list.reverse()
                for (let i = 0; i < lists?.length; i++) {
                    lists[i].type = false
                    lists[i].time = formatTime(new Date(lists[i].time))

                }
                setHistoryLists(lists)
            }
        })
    }
    useEffect(() => {
        if (_.isNil(token)) {
            // return <Redirect to={'/'} />;
            dispatch(get_prompt_no_auth())
            setLogin(true)
        } else {
            get_history_list()
            // dispatch(get_crm_user_info())
            dispatch(get_prompt_with_auth())
        }
    }, [token])
    useEffect(() => {
        if (socket === null) {
            console.log('socket1')
            // setSocket(io(url));
        }
        if (socket) {
            console.log('socket')
            socket.emit(who_are_you, {})
            socket.on(CLIENT_RECEIVE_MESSAGE, (msg) => {
                initiate()
                setGenerateType('正在生成')
                console.log(JSON.stringify(msg));
                let msg_value = msg.content
                msg.content = msg_value
                console.log(msg_value)
                setMsgListObj((prev) => {
                    return { ...prev, end_export: false, last_message_time: (new Date()).toISOString(), list: [...prev.list, { ...msg, type: "client", is_loading: true }] }
                })
            })
            socket.on(who_are_you, (msg) => {
                console.log(msg)
                dispatch(send_socket_id(msg.socket_id))
            })
            socket.on(ERR_MESSAGE, (err) => {
                message.error("请先登录");
            });
            socket.on(AGENT_RECEIVE_MESSAGE_IN_PROGRESS, (msg) => {
                if (is_optimize) {
                    setIsOptimize(false)
                    setOptimizePrompt([])
                }
                // console.log(JSON.stringify(msg));
                setMsgListObj(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    // console.log(tmp)
                    // msg.content = msg.content.replace(/\n/g, "<br/>")
                    tmp.cur_block.content = tmp.cur_block.content + msg.content
                    // tmp.list[prev.list?.length - 1].is_loading = false
                    tmp.last_message_time = (new Date()).toISOString();
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_MESSAGE_ENDS, (msg) => {
                console.log("你好", JSON.stringify(msg));
                setGenerateType('')
                // dispatch(consume_question({ consume_counts: 1 }))
                setMsgListObj(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp.list.push({ content: tmp.cur_block.content, type: 'agent', accessory_lists: tmp.cur_block.accessory_lists, dispose_type: '完成' })
                    // tmp.list[prev.list?.length - 1].is_loading = false
                    tmp.end_export = true
                    tmp.cur_block.content = '';
                    tmp.cur_block.accessory_lists = [];
                    tmp.cur_block.dispose_type = '';
                    tmp.last_message_time = null;
                    return tmp
                })
                setIsOptimize(true)
            })

            socket.on(LOGIN, (msg) => {
                console.log(JSON.stringify(msg));
                message.error(msg['content']);
            });
            socket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}, ${JSON.stringify(err)}`);
            });
            socket.on(AGENT_GET_NEW_HISTORY_BLOCK_ID, (msg) => {
                console.log(JSON.stringify(msg));
                // setBlockId(msg.content)
                setMsgListObj(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp.block_id = msg.content
                    get_history_list()
                    setCutId(0)
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_ATTACHMENTS, (msg) => {
                console.log('ATTACHMENTS', msg)
                setMsgListObj(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    if (tmp.end_export === true) {
                        tmp.list[prev.list?.length - 1].accessory_lists = msg.data;
                    }
                    else {
                        tmp.cur_block.accessory_lists = msg.data
                    }
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_PROGRESSING_STATUS, (msg) => {
                console.log('STATUS', msg)
                setGenerateType(msg.content)
                setPromptList(prev => {
                    setStatusTitle('运行状态')
                    let tmp = JSON.parse(JSON.stringify(prev))
                    if (tmp.should_refresh) {
                        tmp.message = []
                        tmp.message.push({ content: msg.content, loading: true })
                        tmp.should_refresh = false
                    } else {
                        tmp.message[tmp.message?.length - 1].loading = false
                        tmp.message.push({ content: msg.content, loading: true })
                    }
                    return tmp
                })
                setFileLoading(false)
                setMsgListObj(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp.cur_block.dispose_type = msg.content
                    tmp.last_message_time = (new Date()).toISOString();
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_PROGRESS_FINISH_STATUS, (msg) => {
                console.log(msg)
                setFileLoading(true)
                setGenerateType(msg.content)
                setPromptList(prev => {
                    console.log(prev)
                    let tmp = [...prev.message]
                    if (tmp?.length - 1 > 0) {
                        tmp[tmp?.length - 1].loading = false
                    } else {
                        if (_.isNil(tmp) || tmp?.length === 0) {

                        }
                        else {
                            tmp[0].loading = false
                        }
                    }
                    tmp.push({ content: msg.content, loading: false })
                    return { message: tmp, should_refresh: true }
                })


                setMsgListObj((prev) => {
                    dispatch(get_history_detail_file_names({ id: prev.block_id }))
                    return prev
                })
            })
            socket.on(AGENT_RECEIVE_CREATED_DETAIL_ID, (msg) => {
                console.log(msg)
                setMsgListObj((prev) => {
                    return { ...prev, block_id: msg.detail_id }
                })
            })
            socket.on(AGENT_RECEIVE_UPLOADED_FILE, (msg) => {
                console.log('AGENT_RECEIVE_UPLOADED_FILE', msg)
                console.log(msgListObj)
                amend_msgLists(msg)
                // let question_lists = []
                // question_lists.push({ content: `${res?.data?.chat_list?.[i]?.file_name}`, file_name: res?.data?.chat_list?.[i]?.file_name, file_content: res?.data?.chat_list?.[i]?.file_content, type: 'client_file', is_loading: false })
                // setMsgListObj(prev => {
                //     let tmp = JSON.parse(JSON.stringify(prev))
                //     tmp.list = question_lists
                //     return tmp
                // })
            })
            socket.on(UPLOADED_FILE_PARSING_FAILED, (msg) => {
                console.log('上传失败', msg)
                message.error(msg.content)
            })
        }
        // return ()=>{
        //     socket?.disconnect();
        // }
    }, [socket])
    const amend_msgLists = (e) => {
        console.log(e)
        setMsgListObj(prev => {
            let tmp = JSON.parse(JSON.stringify(prev))
            tmp.list.push({ content: `${e.file_name}`, file_name: e.file_name, file_content: e.file_content, file_type: e.filet_ype, type: 'client_file', is_loading: false })
            return tmp
        })
    }
    useEffect(() => {
        if (_.isNil(token)) {
            setLogin(true)
        } else {
            setLogin(false)

        }
    }, [token])
    const initiate = () => {
        const id = setTimeout(() => {
            setMsgListObj(prev => {
                if (prev.last_message_time == null) {
                    return prev;
                }
                let last_msg_time_diff_in_sec = ((new Date()) - (new Date(prev.last_message_time))) / 1000;
                console.log("定时器 " + last_msg_time_diff_in_sec + " seconds" + prev.last_message_time + ". ");
                if (last_msg_time_diff_in_sec > 16) {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    // tmp.list[prev.list?.length - 1].is_loading = false
                    tmp.end_export = true
                    tmp.cur_block.content = '';
                    tmp.cur_block.accessory_lists = [];
                    tmp.cur_block.dispose_type = '';
                    setGenerateType('')
                    return tmp
                }
                else {
                    return prev
                }

            })
            clearTimeout(id);
        }, 20000); // 每隔1秒执行一次
    }
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const [agent_lists, setAgentLists] = React.useState([]);
    const [searchValue, setSearchValue] = React.useState('');
    useEffect(() => {
        setAgentLists(hint_agent)
    }, [hint_agent])
    const text_value = (e) => {
        if (e.key === 'Enter' && e.shiftKey === false) {
            // 执行某些操作
            console.log(123)
        }
        if (e.nativeEvent.data === '@') {
            dispatch(agent_list())
            setChatDialog(true)
        }
        setMessageValue(e.target.value)
        if (e.target.value?.length > 108) {
            setTextareaHeight(200)
        } else {
            setTextareaHeight(120)
        }
    }
    const handleButtonClick = () => {
        dispatch(agent_list())
        setChatDialog(true)
    }
    const [agent_file_lists, setAgentFileLists] = React.useState([]);
    const [expression_value, setExpressionValue] = React.useState('');
    const handleAgent = (e, expression, intr) => {
        console.log(e, expression, intr)
        setExpressionValue(expression)
        let mess_value = message_value.slice(0, -1)
        if (expression == '@文件助手') {
            if (!_.isNil(e)) {
                mess_value = mess_value + expression + e
            } else {
                mess_value = mess_value + expression
            }
        } else {
            if (!_.isNil(e)) {
                mess_value = mess_value + expression + '@ ' + e
            } else {
                mess_value = mess_value + expression + '@'
            }
        }
        // mess_value = mess_value + expression + ' ' + e
        setMessageValue(mess_value)
        setChatDialog(false)
        setIntroduction(intr)
        setStatusTitle('介绍')
        if (expression == '@文件助手') {
            dispatch(get_user_fileLists({}, (v) => setAgentFileLists(v)))
        }

    }
    const send_writing = (e) => {
        setMessageValue(e)
    }
    const close_inspiration = () => {
        setHint(false)
        setChatwidth('100%')
    }
    const open_inspiration = () => {
        setHint(true)
        setChatwidth('74%')
    }
    const history_lists_detail = (e, index) => {
        // if(msgListObj[index].block_id){}
        console.log(e)
        setCutId(index)
        setSelectedFile(null)
        setFilesName('')
        setFilesSuffix('')
        setIsGuidance(true)
        setGenerateType('')
        setStatusTitle('运行状态')
        setMsgListObj(prev => {
            let tmp = JSON.parse(JSON.stringify(prev))
            tmp.block_id = e
            tmp.list = []
            return tmp
        })
        if (!_.isNil(e)) {
            axios.post(`${MIDDLE_EARTH_URL}/llm/get_history_detail`, { id: e }, {
                headers: {
                    'content-type': 'application/json', // 默认值
                    'Authorization': 'token ' + token
                }
            }).then((res) => {
                console.log(res)
                dispatch(get_history_detail_file_names({ id: e }))
                setMsgListObj(prev => {
                    return { ...prev, list: [], block_id: e }
                })
                if (res.data.chat_list?.length > 0) {
                    let question_lists = []
                    for (let i = 0; i < res.data.chat_list?.length; i++) {
                        if (res?.data.chat_list?.[i]?.type === "qa") {
                            question_lists.push({ content: res.data.chat_list[i].question, type: 'client', is_loading: false })
                            question_lists.push({ content: res.data.chat_list[i].answer, type: 'agent', is_loading: false, dispose_type: '完成' })
                        } else if (res?.data.chat_list?.[i]?.type === "file_upload") {
                            question_lists.push({ content: `${res?.data?.chat_list?.[i]?.file_name}`, file_name: res?.data?.chat_list?.[i]?.file_name, file_content: res?.data?.chat_list?.[i]?.file_content, type: 'client_file', is_loading: false })
                        }

                    }
                    setMsgListObj(prev => {
                        let tmp = JSON.parse(JSON.stringify(prev))
                        tmp.block_id = e
                        tmp.list = question_lists
                        return tmp
                    })
                }
            })
        }
    }
    const messagesEnd = useRef(null);
    useEffect(() => {
        // 滚动到底部
        messagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    }, [msgListObj]);
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) { // 检查是否是回车键（Shift+Enter用于换行）
            event.preventDefault(); // 阻止默认的换行行为
            send_to_agents(); // 调用发送消息的函数
        }
    };
    const add_dialog_box = () => {
        if (_.isNil(token)) {
            message.error('请先登陆后操作')
            setLogin(true)
        } else {
            setIsGuidance(false)
            let current_time = new Date()
            const year = current_time.getFullYear();
            const month = ("0" + (current_time.getMonth() + 1)).slice(-2); // 添加前导零，例如从1变为01
            const day = ("0" + current_time.getDate()).slice(-2);
            history_lists.unshift({ preview: '', time: year + '-' + month + '-' + day })
            setHistoryLists([...history_lists])
            setCutId(0)
            setSelectedFile(null)
            setFilesName('')
            setFilesSuffix('')
            setIsOptimize(false)
            setOptimizePrompt([])
            setPromptList({
                message: [],
                should_refresh: true
            })
            setStatusTitle('提示词模板')
            setMsgListObj((prev) => {
                return { streaming: false, list: [], block_id: null, end_export: true, cur_block: { content: '', accessory_lists: [], dispose_type: '', last_message_time: null } }
            })
        }
    }
    const formatTime = date => {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hour = date.getHours()
        const minute = date.getMinutes()
        const second = date.getSeconds()

        return year + '-' + month + '-' + day + ' ' + hour + ':' + minute
    }

    const [err_msg, setErrMsg] = React.useState('');
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setState({ ...state, open: false });
        setErrMsg('')
    };
    const [login, setLogin] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [authCode, setAuthCode] = React.useState('');
    const [verificationCode, setVerificationCode] = React.useState(false);
    const phone_Change = (e) => {
        setPhone(e.target.value)
    }
    const loginClose = () => {
        setLogin(false)
        setAuthCode('')
    }
    const [verift_time, setVeriftTime] = React.useState(0);
    const verify = () => {
        if (phone?.length > 10) {
            setVerificationCode(true)
            setVeriftTime(Date.now() + 60000)
            dispatch(send_mobile({ auth_key: 'bdjabwandjwandkjnwakjdnakjndkjasd', phone_number: phone }))
        } else {
            message.error('手机号不能为空');
        }
        // setVerificationCode(true)
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        return <span style={{ color: '#43179D', fontWeight: 'bold', fontSize: 15, paddingTop: 8 }}>{seconds}</span>;
    }
    const [isCheckingValue, setIsCheckingValue] = React.useState('');
    const login_affirm = () => {
        if (authCode !== '' && phone !== '') {
            setIsCheckingValue('')
            dispatch(mobile_login({ sms_code: authCode, phone_number: phone }, (v) => setIsCheckingValue(v)))
            setLogin(false)
        } else {
            message.error('验证码和手机号不能为空');
        }
    }
    useEffect(() => {
        if (isCheckingValue !== '') {
            message.error(isCheckingValue)
        }
    }, [isCheckingValue])
    const login_phone = () => {
        message.error('请先登陆')
        setLogin(true)
    }
    const [login_mode, setLoginMode] = React.useState(0);
    const [account_number, setAccountNumber] = React.useState('');
    const [user_password, setUserPassword] = React.useState('');
    const login_user = () => {
        // dispatch(news_create())//创建用户
        // dispatch(create_tax_agent())//创建事务所
        if (account_number === '') {
            message.error('账号不能为空')
        } else if (user_password === '') {
            message.error('密码不能为空')
        } else {
            setIsCheckingValue('')
            let data = {
                username: account_number,
                password: user_password
            }
            // const formData = new FormData();
            // formData.append('username', account_number);
            // formData.append('password', user_password);
            dispatch(new_login(data, (v) => setIsCheckingValue(v)))
        }
    }
    const htmlToText = (htmlString) => {
        // 创建一个新的DOM解析器
        const parser = new DOMParser();
        // 使用DOMParser解析HTML字符串
        const doc = parser.parseFromString(htmlString, 'text/html');
        // 输出纯文本内容
        return doc.body.textContent || "";
    }
    const onCopy = (e) => {
        console.log(htmlToText(e))
        let copy_value = removeMarkdown(e)
        copy(copy_value)
        message.success('复制成功')
    }
    const onCopy_file = (e) => {
        copy(e)
        socket.emit(TEST_STATUS_CHANGE, {})
        message.success('复制成功')
        let mess = message_value + e
        setMessageValue(mess)
        setPromptList((prev) => {
            return { ...prev, message: [] }
        })
    }
    const onUse_file = (e) => {
        let mess = `@文件助手?file_name=${e}@`
        setMessageValue(mess)
    }
    const [add_theme, setAddTheme] = React.useState(false);
    const [theme_title, setThemeTitle] = React.useState('');
    const [theme_content, setThemeContent] = React.useState('');
    const [cut_prompt, setCutPrompt] = React.useState(0);
    const general_prompt = useSelector((state) => state.user.general_prompt)
    const user_prompt = useSelector((state) => state.user.user_prompt)
    useEffect(() => {
        if (user_prompt?.length > 0) {
            setCutPrompt(1)
        }
    }, [user_prompt])
    const add_redact = (e) => {
        let value = getText(e)
        setAddTheme(true)
        setThemeContent(value)
        setThemeTitle(value.slice(0, 8))
    }
    const themeClose = () => {
        setAddTheme(false)
    }
    const add_theme_affirm = () => {
        if (theme_title != '' && theme_title?.length < 10 && theme_content?.length > 10) {
            dispatch(create_user_prompt({ title: theme_title, content: theme_content }))
            setAddTheme(false)
        } else {
            message.error('标题不能超过10个字，内容不能小于10个字')
        }
    }
    const [creativity, setCreativity] = React.useState(0);
    const valuetext = (e) => {
        console.log(e.target.value)
        setCreativity(e.target.value)
    }

    const [chat_dialog, setChatDialog] = React.useState(false);
    const chatClose = () => {
        setChatDialog(false)
    }
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    const [prompt_dialog, setPromptDialog] = React.useState(false);
    const [prompt_id, setPromptId] = React.useState(null);
    const delete_prompt = (e) => {
        setPromptDialog(true)
        setPromptId(e)
    }
    const promptClose = () => {
        setPromptDialog(false)
    }
    const promptAffirm = () => {
        dispatch(delete_user_prompt({ prompt_id: prompt_id }))
        setPromptDialog(false)
    }
    const [is_guidance, setIsGuidance] = React.useState(false);
    const VideoComponent = ({ videoSrc, posterSrc }) => {
        return (
            <video
                src={videoSrc}
                poster={posterSrc}
                controls // 添加控制条
            />
        );
    };
    const searchChange = (e) => {
        setSearchValue(e.target.value)
        let a = hint_agent.filter(v => v?.name?.includes(e.target.value) || v?.introduction?.includes(e.target.value))
        setAgentLists(a)
    }
    const search_button = () => {
        console.log(searchValue)
        let a = hint_agent.filter(v => v?.name?.includes(searchValue) || v?.introduction?.includes(searchValue))
        setAgentLists(a)
        console.log(a)
    }
    const clearAll = () => {
        setSearchValue('')
        setAgentLists(hint_agent)
    }
    const [textarea_height, setTextareaHeight] = React.useState(100);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const rightopen = Boolean(anchorEl);
    const righthandleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const righthandleClose = () => {
        setAnchorEl(null);
    };
    const rightStatus = [
        '提示词模板', '运行状态', '介绍'
    ]
    const [status_title, setStatusTitle] = React.useState('提示词模板');
    const [introduction, setIntroduction] = React.useState('');
    const [prompt_list, setPromptList] = React.useState({
        message: [],
        should_refresh: true
    });
    const rightcut = (e) => {
        setStatusTitle(e)
        setAnchorEl(null);
        if (e === '运行状态' && msgListObj.block_id != null) {
            dispatch(get_history_detail_file_names({ id: msgListObj.block_id }))
        }
    }
    const file_lists = useSelector((state) => state.user.file_lists)
    const [value_test, setValueText] = React.useState('');
    const quick_tax_plan_api_test = () => {
        dispatch(quick_tax_plan_api({ prompt: '税务筹划有几个要点？' }, (v) => setValueText(v)))
    }
    const handleDelete = () => {
        setAgentId(null)
        setAgentName(null)
    }
    const optimize_click = (e) => {
        console.log(e)
        socket.emit(AGENT_MESSAGE, { message: e, user_id: user_id, token: token, history_block_id: msgListObj.block_id, temperature: creativity, agent_id: agent_id })
        setIsOptimize(false)
        setOptimizePrompt([])
        dispatch(optimize_prompt_words({ content: e }, (v) => setOptimizePrompt(v)))
    }
    const [file_title, setFileTitle] = React.useState('');
    const [file_content, setFileContent] = React.useState('');

    const examine_file = (content, name) => {
        console.log(content, name)
        setFileTitle(name)
        setFileContent(content)
        setStatusTitle('查看文件')
    }
    const close_file = () => {
        setFileTitle('')
        setFileContent('')
        setStatusTitle('提示词模板')
    }
    const handleRecording = () => {
        const event = new KeyboardEvent('keydown', {
            key: 'k', // K键
            keyCode: 75, // K键的keyCode
            ctrlKey: true, // Ctrl键被按下
            metaKey: true, // 对于Mac，metaKey代表Command键
            which: 75, // which属性也是keyCode
            code: 'KeyK', // code属性代表物理按键
            bubbles: true, // 事件是否冒泡
            cancelable: true // 事件是否可取消
        });

        // 触发键盘事件
        document.dispatchEvent(event);
    }
    return (
        <div>
            <div>
                {/* <div>{value_test}</div> */}
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 54px)' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', overflow: 'auto', minWidth: 220, paddingTop: 8, maxHeight: 'calc(100vh - 62px)' }}>
                            {
                                _.isNil(token) ? (<div style={{ paddingBottom: 10 }} onClick={login_phone}>
                                    <div style={{ display: 'flex', alignItems: 'center', background: 'rgb(221, 221, 221,0.8)' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={home} alt='' style={{ width: 21, height: 21 }} />
                                            </div>
                                            <div style={{ paddingLeft: 15, color: '#000' }}>智能对话</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={library} alt='' style={{ width: 21, height: 21 }} />
                                            </div>
                                            <div style={{ paddingLeft: 15, color: '#000' }}>知识库</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 1 }}>
                                            <img src={monitoring} alt='' style={{ width: 21, height: 22 }} />
                                        </div>
                                        <div style={{ paddingLeft: 15 }}>定时AI任务</div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 1 }}>
                                            <img src={intelligent} alt='' style={{ width: 21, height: 22 }} />
                                        </div>
                                        <div style={{ paddingLeft: 15 }}>智能体</div>
                                    </div>
                                    {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={people} alt='' style={{ width: 21, height: 21 }} />
                                            </div>
                                            <div style={{ paddingLeft: 15, color: '#000' }}>人员管理</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                        <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={mine_img} alt='' style={{ width: 21, height: 21 }} />
                                            </div>
                                            <div style={{ paddingLeft: 15, color: '#000' }}>会员</div>
                                        </div>
                                    </div> */}
                                </div>) : (<TabbleLists type='0'></TabbleLists>)
                            }

                            <div onClick={add_dialog_box} style={{ background: base_setting?.primary_color, color: '#fff', padding: '10px 10px', margin: '0 8px', fontSize: 18, borderRadius: 10, marginTop: 10, marginBottom: 20, cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                <AddIcon></AddIcon>
                                <div style={{ fontSize: 17 }}>新建任务</div>
                            </div>
                            <div style={{ maxHeight: 'calc(100vh - 490px)', overflow: 'auto', padding: '8px' }}>
                                {
                                    history_lists?.map((item, index) => <div disabled={msgListObj.end_export}
                                        style={{ border: cut_id === index ? '1px solid #4844f3' : '1px solid rgba(0,0,0,0.1)', background: cut_id === index ? 'rgba(67,23,157,0.05)' : '#fff', display: 'flex', cursor: 'pointer', padding: '12px 10px 12px 20px', borderRadius: 10, width: '180px', fontSize: 14, marginTop: 10 }} key={index} onClick={() => msgListObj.end_export ? history_lists_detail(item.id, index) : ''}>
                                        <div style={{ paddingRight: 10, paddingTop: 2 }}>
                                            <SmsIcon style={{ color: base_setting?.primary_color }}></SmsIcon>
                                        </div>
                                        <div style={{ textAlign: 'left', width: 150 }}>
                                            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{getText(item.preview)}</div>
                                            <div style={{ paddingTop: 5, fontSize: 12, color: '#878aab' }}>{item.time}</div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>

                    <div style={{ position: 'relative', width: 'calc(74% - 228px)', height: 'calc(100vh - 74px)' }}>
                        <div className='filter_css'></div>
                        <div className='chat_top'>
                            <div style={{ display: 'flex', alignItems: 'center', minWidth: 27 }}>
                                <div style={{ background: base_setting?.primary_color, borderRadius: '50%', width: 12, height: 12 }}></div>
                                <div style={{ paddingLeft: 10, fontSize: 15 }}>税捷大模型</div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: 13, paddingRight: 5, minWidth: 27 }}>创造力系数</div>
                                        <Tooltip title="创造力系数越低代表回答越严谨，随机性越低，适合财税法律相关问题，创造力系数越高代表随机性越高，较适合生成营销文案之类的需要创造力的问题">
                                            <HelpIcon style={{ color: base_setting?.primary_color, fontSize: 20 }}>

                                            </HelpIcon>
                                        </Tooltip>
                                    </div>
                                    <div style={{ width: 70, paddingLeft: 20, display: 'flex', alignItems: 'center' }}>
                                        <Slider
                                            size="small"
                                            defaultValue={0.7}
                                            value={creativity}
                                            onChange={valuetext}
                                            min={0.01}
                                            max={1}
                                            step={0.1}
                                            aria-label="Small"
                                            marks={[0.01, 1]}
                                            valueLabelDisplay="auto"
                                        />
                                    </div>
                                </div>
                                {/* <div style={{ paddingLeft: 20, fontSize: 13 }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        style={{ fontSize: 13, minWidth: 27 }}
                                        control={<IOSSwitch sx={{ m: 1 }} onChange={networking} checked={networking_value} />}
                                        label="联网"
                                    />
                                </div> */}
                                {/* <div style={{ paddingLeft: 15, paddingRight: 10 }}>
                                    <FormControlLabel
                                        labelPlacement="start"
                                        style={{ fontSize: 13, minWidth: 27 }}
                                        control={<IOSSwitch sx={{ m: 1 }} onChange={repository} checked={repository_value} />}
                                        label="知识库"
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div className="hello">
                            {
                                msgListObj?.list?.length === 0 && !is_guidance ? <div className="guidance">
                                    {/* <div style={{ fontWeight: 'bold', fontSize: 18 }}>引导：</div> */}
                                    <div style={{ paddingLeft: 10 }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 15 }}>详细介绍</div>
                                        <div style={{ display: 'flex', paddingLeft: 10 }}>
                                            <div style={{ fontSize: 13, height: 22 }}>动千山产品总览</div>
                                            <div>
                                                <a href="https://mp.weixin.qq.com/s/lkez_Q2qwNbrlmxZ4MC3rQ" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer", width: '210px', color: base_setting?.primary_color, fontSize: 13 }}>
                                                    （点击打开）
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', paddingLeft: 10 }}>
                                            <div style={{ fontSize: 13, }}>动千山智能体介绍</div>
                                            <div>
                                                <a href="https://mp.weixin.qq.com/s/4SqubDo9lefwSF2Sl-R86A" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer", width: '210px', color: base_setting?.primary_color, fontSize: 13 }}>
                                                    （点击打开）
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', paddingLeft: 10 }}>
                                            <div style={{ fontSize: 13, }}>动千山定时AI任务介绍</div>
                                            <div>
                                                <a href="https://mp.weixin.qq.com/s/EIAVnGQl0Qyd_zzs3cO_bg" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer", width: '210px', color: base_setting?.primary_color, fontSize: 13 }}>
                                                    （点击打开）
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ paddingLeft: 10 }}>
                                        <div style={{ fontWeight: 'bold', fontSize: 15 }}>视频教程</div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ paddingLeft: 10 }}>
                                                <div style={{ fontSize: 13, textAlign: 'center' }}>产品总览</div>
                                                <div>
                                                    <a href='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/videos/web_introduce/overview_video.mp4' target="_blank" rel="noopener noreferrer" style={{ width: 150, height: 100, position: 'relative', display: 'flex', cursor: "pointer" }}>
                                                        <img src={overview_img} alt='' style={{ width: 150, height: 100 }}></img>
                                                        <div style={{ position: 'absolute', left: '40%', top: '40%' }}>
                                                            <PlayCircleFilledIcon style={{ fontSize: 30 }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div style={{ paddingLeft: 20 }}>
                                                <div style={{ fontSize: 13, textAlign: 'center' }}>智能体视频教程</div>
                                                <div>
                                                    <a href='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/videos/web_introduce/agent_video.mp4' target="_blank" rel="noopener noreferrer" style={{ width: 150, height: 100, position: 'relative', display: 'flex', cursor: "pointer" }}>
                                                        <img src={agent_video} alt='' style={{ width: 150, height: 100 }}></img>
                                                        <div style={{ position: 'absolute', left: '40%', top: '40%' }}>
                                                            <PlayCircleFilledIcon style={{ fontSize: 30 }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div style={{ paddingLeft: 20 }}>
                                                <div style={{ fontSize: 13, textAlign: 'center' }}>定时AI任务视频教程</div>
                                                <div>
                                                    <a href='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/videos/web_introduce/ai_video.mp4' target="_blank" rel="noopener noreferrer" style={{ width: 150, height: 100, position: 'relative', display: 'flex', cursor: "pointer" }}>
                                                        <img src={ai_video} alt='' style={{ width: 150, height: 100 }}></img>
                                                        <div style={{ position: 'absolute', left: '40%', top: '40%' }}>
                                                            <PlayCircleFilledIcon style={{ fontSize: 30 }} />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                    msgListObj?.list?.map((msg, index) => <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                                        {
                                            msg.type === 'agent' ? <div style={{ margin: '20px 0' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>
                                                        <img src={base_setting?.logo_url} alt='' style={{ width: 30, height: 30 }} />
                                                    </div>
                                                    <div onClick={() => onCopy(msg.content)} style={{ border: '1px solid rgb(191, 191, 191)', marginLeft: 10, width: 18, height: 18, padding: 1, boxShadow: '0px 1px 4px 0px rgba(218,219,220,0.42)', borderRadius: 5, marginRight: 5, cursor: 'pointer' }}>
                                                        <img src={copy_icon} alt='' style={{ width: 18, height: 18 }} />
                                                    </div>
                                                    <div>
                                                        {
                                                            msg?.accessory_lists?.length > 0 ? <Tooltip title="点击打开链接">
                                                                <a target="_blank" rel="noopener noreferrer" href={'https://' + msg.accessory_lists[0].link} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10, border: '1px solid #4844f3', padding: '2px 5px', borderRadius: 8, color: base_setting?.primary_color, cursor: 'pointer' }}>
                                                                    <div style={{ fontSize: 11 }}>{msg.accessory_lists[0].name}</div>
                                                                    <div style={{ width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img src={accessory} style={{ width: 12, height: 12 }} alt='' />
                                                                    </div>
                                                                </a>
                                                            </Tooltip> : ""
                                                        }

                                                    </div>
                                                </div>
                                                <div className="left" >
                                                    <Markdown>{msg.content}</Markdown>
                                                </div>
                                                {/* <div className="left" dangerouslySetInnerHTML={{ __html: msg.content }}></div> */}
                                            </div> : ''
                                        }
                                        {
                                            msg.type === 'client_file' ?
                                                <div style={{ alignSelf: 'end', marginTop: 5 }}>
                                                    <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: 5 }}>
                                                        <div onClick={() => onCopy(msg.content)} style={{ border: '1px solid #bfbfbf', width: 18, height: 18, padding: 3, boxShadow: '0px 1px 4px 0px rgba(218,219,220,0.42)', borderRadius: 5, marginRight: 5, cursor: 'pointer' }}>
                                                            <img src={copy_icon} alt='' style={{ width: 18, height: 18 }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', borderRadius: 10, alignSelf: 'end', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', width: 350, height: 73, marginBottom: 10 }}>
                                                        <div style={{ width: '20%', background: base_setting?.primary_color, boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', padding: '5px 0' }}>
                                                            <div style={{ textAlign: 'center', paddingTop: 5 }}>
                                                                <img src={file_text} alt='' style={{ width: 35, height: 35 }} />
                                                            </div>
                                                            <div style={{ color: '#fff', fontSize: 14, textAlign: 'center', overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
                                                                {msg.file_name?.slice(msg.file_name.lastIndexOf(".") + 1)}
                                                                {/* 文件 */}
                                                            </div>
                                                        </div>
                                                        <div onClick={() => onCopy(msg.content)} style={{ padding: '0px 8px', fontSize: 14, width: 200, display: '-webkit-box', wordBreak: 'break-all', textOverflow: 'ellipsis', overflow: 'hidden', '-webkit-line-clamp': '2', '-webkit-box-orient': 'vertical', cursor: 'pointer' }}>
                                                            {msg.content}
                                                        </div>
                                                        <div onClick={() => examine_file(msg.file_content, msg.file_name)} style={{ background: base_setting?.primary_color, color: '#fff', height: '100%', display: 'flex', alignItems: 'center', fontSize: 15, justifyContent: 'center', textAlign: 'center', padding: '0 2px', cursor: 'pointer', width: 60 }}>查看</div>
                                                    </div>
                                                </div>
                                                : ''
                                        }
                                        {
                                            msg.type === 'client' ? <div style={{ alignSelf: 'end', marginTop: 5, width: '100%' }}>
                                                <div style={{ display: 'flex', justifyContent: 'end', paddingBottom: 5 }}>
                                                    <div onClick={() => onCopy(msg.content)} style={{ border: '1px solid #bfbfbf', width: 18, height: 18, padding: 3, boxShadow: '0px 1px 4px 0px rgba(218,219,220,0.42)', borderRadius: 5, marginRight: 5, cursor: 'pointer' }}>
                                                        <img src={copy_icon} alt='' style={{ width: 18, height: 18 }} />
                                                    </div>
                                                    <div onClick={() => add_redact(msg.content)} style={{ border: '1px solid #bfbfbf', width: 18, height: 18, padding: 3, boxShadow: '0px 1px 4px 0px rgba(218,219,220,0.42)', borderRadius: 5, cursor: 'pointer' }}>
                                                        <img src={redact_icon} alt='' style={{ width: 18, height: 18 }} />
                                                    </div>
                                                </div>
                                                <div className="right" style={{ background: base_setting?.primary_color }}>
                                                    <Markdown style={{width:200}}>{(msg.content).replace('\n', '\n\n')}</Markdown>
                                                </div>
                                                {/* <div className="right" style={{background:base_setting?.primary_color}} dangerouslySetInnerHTML={{ __html: msg.content }}></div> */}
                                            </div> : ''
                                        }
                                    </div>)

                            }


                            {
                                msgListObj?.cur_block?.content !== "" && <div style={{ margin: '20px 0' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <img src={base_setting?.logo_url} alt='' style={{ width: 30, height: 30 }} />
                                        </div>
                                        {
                                            msgListObj?.cur_block?.dispose_type != '' ? <div style={{ fontSize: 11, marginLeft: 10, border: '1px solid #69b1ff', padding: '2px 5px', borderRadius: 8, color: '#69b1ff', textAlign: 'center' }}>{msgListObj.cur_block.dispose_type}</div> : ''
                                        }
                                        <div>
                                            {
                                                msgListObj?.cur_block?.accessory_lists?.length > 0 ? <Tooltip title="点击打开链接">
                                                    <a target="_blank" rel="noopener noreferrer" href={'https://' + msgListObj.cur_block.accessory_lists[0].link} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10, border: '1px solid #4844f3', padding: '2px 5px', borderRadius: 8, color: '#4844f3', cursor: 'pointer' }}>
                                                        <div style={{ fontSize: 11 }}>{msgListObj.cur_block.accessory_lists[0].name}</div>
                                                        <div style={{ width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <img src={accessory} style={{ width: 12, height: 12 }} alt='' />
                                                        </div>
                                                    </a>
                                                </Tooltip> : ''
                                            }

                                        </div>
                                    </div>
                                    <div id="123" className="left">
                                        <Markdown>{msgListObj.cur_block.content}</Markdown>
                                    </div>
                                    {/* <div id="123" className="left" dangerouslySetInnerHTML={{ __html: msgListObj.cur_block.content }}></div> */}
                                </div>
                            }
                            <div ref={messagesEnd}></div>
                            {
                                is_optimize && optimize_prompt != null && optimize_prompt?.length > 0 ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, alignSelf: 'stretch', paddingTop: 5 }}>
                                    <div style={{ fontSize: 13 }}>您可以继续问我：</div>
                                    {
                                        optimize_prompt.map((item, index) => <div onClick={() => optimize_click(item)} className='subjoin_problem' key={'optimize_prompt' + index}>{item}</div>)
                                    }
                                </div> : ""
                            }
                        </div>

                        <div style={{ marginTop: 10, position: 'absolute', left: '5%', width: 'calc(90% - 40px)', bottom: '5%', borderRadius: 10, background: '#fff', padding: '6px 15px', border: '1px solid rgb(72, 68, 243,0.5)' }}>
                            {/* {
                                generate_type !== '' ? <div style={{ position: 'absolute', width: '100%', bottom: 0, top: '-45px', left: 0, height: 40 }}>
                                    <div className='filter_css' style={{ padding: '2px 0', borderRadius: 8, height: 40, top: 0, width: '100%', backdropFilter: 'blur(15px)' }}></div>
                                    <div className='chat_top' style={{ padding: '2px 0', borderRadius: 8, height: 40, top: 0, width: '100%', boxShadow: 'none', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {
                                                !file_loading && generate_type !== '' ? <div style={{ padding: '0 10px' }}>
                                                    <Spin />
                                                </div> : ''
                                            }

                                            <div style={{ fontSize: 13, padding: '2px 5px', textAlign: 'center', borderRadius: 8 }}>{generate_type}</div>
                                        </div>
                                    </div>
                                </div> : ''
                            } */}
                            <div style={{ position: 'absolute', width: '100%', bottom: 0, top: '-45px', left: 0, height: 40 }}>
                                <div style={{ position: 'absolute', width: '50%', bottom: 0, top: 0, left: 0, height: 40, textAlign: 'left', zIndex: 99, }}>
                                    {
                                        agent_name !== null && agent_id !== '' ? <Chip
                                            // color="primary"
                                            avatar={<Avatar sx={{ bgcolor: base_setting?.primary_color, color: '#fff !important' }}>智</Avatar>}
                                            label={agent_name}
                                            // onClick={amend_system}
                                            onDelete={handleDelete}
                                            style={{ background: '#fff' }}
                                            variant="outlined"
                                        /> : ''
                                    }
                                </div>
                            </div>
                            <div style={{ height: textarea_height, width: '100%', position: 'relative' }}>
                                <textarea name="" id="" value={message_value} onChange={text_value} style={{ height: '100%' }} onKeyPress={handleKeyPress} placeholder="按回车即发送，按@可查看智能体列表" className="chat_input"></textarea>
                                <div style={{ textAlign: 'right', paddingTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'absolute', bottom: 0, right: 0, width: '100%' }}>
                                    <div style={{ marginRight: 10 }}>
                                        {/* {
                                            selectedFile != null ? <div style={{ position: 'relative' }}>
                                                <div style={{ display: 'flex', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', borderRadius: 10, alignItems: 'center' }}>
                                                    <div style={{ fontSize: 20, backgroundColor: '#4844f3', color: '#fff', padding: 10, borderRadius: 10 }}>{files_suffix}</div>
                                                    <div style={{ padding: 10 }} className={files_name.length > 15 ? 'filename' : ''}>{files_name}</div>
                                                </div>
                                                <div onClick={delete_file} style={{ position: 'absolute', right: '-10px', top: '-10px', background: '#fff', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', borderRadius: '50%', padding: '1px 2px', cursor: 'pointer' }}>
                                                    <CancelIcon style={{ fontSize: 15 }}></CancelIcon>
                                                </div>
                                            </div> : ''
                                        } */}
                                    </div >
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ paddingRight: 10, display: 'flex' }}>
                                            <button className="custom-button" onClick={handleRecording} style={{ marginRight: 10 }}><MicNoneIcon style={{ fontSize: 20 }}></MicNoneIcon></button>
                                            <button className="custom-button" onClick={handleUploadButtonClick}><AttachFileIcon style={{ fontSize: 20 }}></AttachFileIcon></button>
                                            <button className="custom-button" onClick={handleButtonClick} style={{ marginLeft: 10, fontSize: 16 }}><div>@</div></button>
                                            <input type="file" multiple accept='.pdf,.txt,.pptx,.xlsx,.docx,.csv,.html,.json,.md,.png,.jpg,.zip,.doc' ref={uploadFileInput} onChange={handleFileChange} />
                                        </div>
                                        <button onClick={!is_file ? send_to_agents : ''} style={{ width: 100, height: 40, borderRadius: 20, background: !is_file ? base_setting?.primary_color : "rgba(221, 221, 221, 0.6)", border: 'none', color: '#fff', cursor: 'pointer', paddingLeft: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>发送</div>
                                                <div style={{ paddingLeft: 2 }}>
                                                    <img src={send_button} alt="" style={{ width: 25, height: 25 }} />
                                                </div>
                                            </div>
                                        </button >
                                    </div >
                                </div >
                            </div>
                        </div >
                        <div style={{ display: 'flex', position: 'absolute', bottom: 0, padding: '10px 20px', width: 'calc(90% - 40px)', left: '5%', fontSize: 13, justifyContent: 'center', color: '#8f8787' }}>
                            <div style={{ cursor: "pointer" }} onClick={() => window.open('https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/agreement/privacypolicy.html', '_blank')}>隐私协议</div>
                            <div style={{ padding: '0 5px' }}>|</div>
                            <div>
                                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer", width: '210px', color: '#8f8787' }}>
                                    京ICP备2021025663号-2
                                </a>
                            </div>
                            <div style={{ padding: '0 5px' }}>|</div>
                            <div style={{ cursor: "pointer" }}>
                                <LightTooltip placement="top-start" title={
                                    <div>
                                        <img src={qiyeweixin} alt='' style={{ width: 80, height: 80 }} />
                                    </div>}>
                                    <div>
                                        联系我们
                                    </div>
                                </LightTooltip>
                            </div>
                        </div>
                    </div>
                    {
                        close_hint ? <div style={{ boxShadow: '0px 0px 2px 0px rgba(51,51,51,0.3)', width:'26%' }}>
                            {
                                status_title == '查看文件' ? <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', padding: '10px 20px', }}>
                                    <div style={{ fontSize: 18, fontWeight: 'bold', width: '80%', overflow: 'hidden', whiteSpace: 'normal', textOverflow: 'ellipsis' }}>
                                        {file_title}
                                    </div>
                                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={close_file}>
                                        <CloseIcon></CloseIcon>
                                    </div>
                                </div> : <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', padding: '10px 20px', }}>
                                    <div style={{ fontSize: 18, fontWeight: 'bold' }}>
                                        {status_title}
                                    </div>
                                    <div style={{ cursor: 'pointer' }}>
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={rightopen ? 'long-menu' : undefined}
                                            aria-expanded={rightopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={righthandleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={rightopen}
                                            onClose={righthandleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 48 * 4.5,
                                                    width: '20ch',
                                                },
                                            }}
                                        >
                                            {rightStatus.map((option) => (
                                                <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => rightcut(option)}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </div>
                                </div>
                            }

                            {
                                status_title == '运行状态' ? <div>
                                    <div style={{ padding: '10px', paddingRight: 20, height: '50vh', overflowY: 'scroll' }}>
                                        {
                                            prompt_list?.message.map((item, index) =>
                                                <div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ width: 5, height: 5, borderRadius: '50%', background: '#1C2025' }}></div>
                                                        <div style={{ textAlign: 'left', marginBottom: 5, fontSize: 12, width: 'calc(100% - 15px)', paddingLeft: 10 }} key={index}>{item.content}</div>
                                                    </div>
                                                    {
                                                        item.loading === true ? <div>
                                                            <Spin size="small"></Spin>
                                                        </div> : ''
                                                    }

                                                </div>
                                            )
                                        }
                                    </div>
                                    <div style={{ width: '100%', height: 2, background: "#ececec" }}></div>
                                    <div style={{ padding: '0 10px' }}>
                                        <div style={{ fontSize: 18, fontWeight: 'bold', paddingLeft: 10, paddingTop: 10 }}>本对话文件列表</div>
                                        <div style={{ height: '22vh', overflowY: 'scroll', padding: '5% 10px' }} className='file_lists'>
                                            {
                                                msgListObj.block_id != null && file_lists?.length > 0 ? file_lists.map((item, index) => <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0', alignItems: 'center' }} key={index}>
                                                    <div style={{ position: 'relative' }}>
                                                        <div style={{ display: 'flex', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', borderRadius: 3, alignItems: 'center' }}>
                                                            <div style={{ fontSize: 15, backgroundColor: base_setting?.primary_color, color: '#fff', padding: 6, borderRadius: 2 }}>{item.file_type}</div>
                                                            <div style={{ padding: '3px 8px' }} className='filename'>{item.file_name}</div>
                                                            <div onClick={() => onCopy_file(item.file_name)} style={{ width: 30, fontSize: 14, backgroundColor: base_setting?.primary_color, color: '#fff', padding: 6, borderRadius: 2, cursor: 'pointer' }}>复制</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : ''
                                            }

                                        </div>
                                    </div>
                                </div> : ''
                            }

                            {
                                status_title == '介绍' ? <div style={{ padding: '0 20px' }}>
                                    <div style={{ height: '80vh', overflowY: 'scroll', padding: '5% 0' }}>
                                        <div style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: introduction }}></div>
                                        {
                                            expression_value == '@文件助手' ? <div style={{ paddingTop: 10 }}>
                                                {
                                                    agent_file_lists.map((item, index) => <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0', alignItems: 'center' }} key={index}>
                                                        <div style={{ position: 'relative' }}>
                                                            <div style={{ display: 'flex', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', borderRadius: 3, alignItems: 'center' }}>
                                                                <div style={{ fontSize: 15, backgroundColor: base_setting?.primary_color, color: '#fff', padding: 6, borderRadius: 2 }}>{item.file_type}</div>
                                                                <div style={{ padding: '3px 8px' }} className='filename'>{item.name}</div>
                                                                <div onClick={() => onUse_file(item.name)} style={{ fontSize: 14, backgroundColor: base_setting?.primary_color, color: '#fff', padding: 6, borderRadius: 2, cursor: 'pointer', minWidth: 30 }}>使用</div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }
                                            </div> : ''
                                        }
                                    </div>

                                </div> : ''
                            }

                            {
                                status_title == '提示词模板' ? <div style={{ padding: '0 20px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div onClick={() => setCutPrompt(0)} style={{ background: cut_prompt == 0 ? base_setting?.primary_color : '#f6f7f9', color: cut_prompt == 0 ? '#fff' : '', padding: '4px 10px', fontSize: 13, marginTop: 10, marginRight: 10, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, cursor: 'pointer' }}>职场人</div>
                                        <div onClick={() => setCutPrompt(1)} style={{ background: cut_prompt == 1 ? base_setting?.primary_color : '#f6f7f9', color: cut_prompt == 1 ? '#fff' : '', padding: '4px 10px', fontSize: 13, marginTop: 10, marginRight: 10, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 5, cursor: 'pointer' }}>个人</div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', paddingTop: 15 }}>
                                        {
                                            cut_prompt == 0 ? general_prompt?.map((item, index) =>
                                                <div key={index} style={{ background: '#f4fafc', padding: '3%', width: '40%', marginRight: '3%', marginBottom: '3%', cursor: 'pointer', borderRadius: 8 }} onClick={() => send_writing(item.content)}>
                                                    <div style={{ fontWeight: '600', paddingBottom: 5, fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{getText(item.title)}</div>
                                                    <div style={{ fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>{getText(item.content)}</div>
                                                </div>) : user_prompt?.length > 0 ? user_prompt.map((item, index) => <div style={{ position: 'relative', width: '40%', padding: '3%', marginRight: '3%', marginBottom: '3%', cursor: 'pointer', borderRadius: 10 }}>
                                                    <div style={{ position: 'absolute', right: '3%', top: 2, fontSize: 12 }} onClick={() => delete_prompt(item._id)}>
                                                        <CancelIcon style={{ fontSize: 15, color: 'gray' }} />
                                                    </div>
                                                    <div key={index} style={{ background: '#f4fafc', padding: '3%' }} onClick={() => send_writing(item.content)}>
                                                        <div style={{ fontWeight: '600', paddingBottom: 5, fontSize: 14, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item.title}</div>
                                                        <div style={{ fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>{item.content}</div>
                                                    </div>
                                                </div>) : <div style={{ textAlign: 'center', fontSize: 12 }}>点击问题上方加号添加</div>
                                        }

                                    </div>
                                </div> : ""
                            }
                            {
                                status_title == '查看文件' ? <div style={{ padding: '0 20px' }}>
                                    <div style={{ height: '80vh', overflowY: 'scroll' }}>
                                        {file_content}
                                    </div>
                                </div> : ''
                            }

                        </div> : <div onClick={open_inspiration} style={{ display: 'flex', alignItems: 'center', boxShadow: '0px 0px 2px 0px rgba(51,51,51,0.3)', height: 60, width: 40, padding: '0 10px 0 0', position: 'absolute', right: 0, top: '50%' }}>
                            <div>
                                <NavigateBeforeIcon></NavigateBeforeIcon>
                            </div>
                            <div>提示</div>
                        </div>
                    }
                </div>
            </div >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {err_msg}!
                </Alert>
            </Snackbar>
            <Dialog onClose={loginClose} open={login} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <div style={{ textAlign: 'center', paddingTop: '5%' }}>
                    <img src={base_setting?.logo_url} alt="" style={{ width: 60, height: 60, borderRadius: '50%' }} />
                </div>
                {
                    login_mode === 0 ? <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>手机登陆/注册</DialogTitle> :
                        <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>账号登陆</DialogTitle>
                }

                <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px' }}>
                    {
                        login_mode === 0 ? <div style={{ paddingHorizontal: '5%' }}>
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label="手机号"
                                    variant="outlined"
                                    value={phone}
                                    onChange={phone_Change}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%', fontSize: 15 }}
                                />
                            </div>
                            <div style={{ display: 'flex', padding: '30px 0' }}>
                                <TextField
                                    id="outlined-basic"
                                    label="验证码"
                                    variant="outlined"
                                    value={authCode}
                                    onChange={(e) => setAuthCode(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '70%', fontSize: 15 }}
                                />
                                {
                                    verificationCode ? <div style={{ borderRadius: 8, height: 56, width: '30%', justifyContent: 'center', display: 'flex', fontSize: 18, alignItems: 'center' }}>
                                        <Countdown date={verift_time}
                                            renderer={renderer}
                                            onComplete={() => setVerificationCode(false)}
                                        />
                                    </div> : <div style={{ display: 'flex', alignItems: 'center', borderRadius: 8, marginLeft: 10 }}>
                                        <Button onClick={verify} variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color, fontSize: 15, height: 52 }}>验证码</Button>
                                    </div>
                                }
                            </div>
                            <div style={{ paddingVertical: '5%' }}>
                                <Button onClick={login_affirm} variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color, fontSize: 18, height: 50, color: '#fff' }}>登陆/注册</Button>
                            </div>
                        </div> : <div style={{ paddingHorizontal: '5%' }}>
                            <div>
                                <TextField
                                    id="outlined-basic"
                                    label="账号"
                                    variant="outlined"
                                    value={account_number}
                                    onChange={(e) => setAccountNumber(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%', fontSize: 15 }}
                                />
                            </div>
                            <div style={{ display: 'flex', padding: '30px 0' }}>
                                <TextField
                                    id="outlined-basic"
                                    label="密码"
                                    variant="outlined"
                                    value={user_password}
                                    type='password'
                                    onChange={(e) => setUserPassword(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%', fontSize: 15 }}
                                />
                            </div>
                            <div style={{ paddingVertical: '5%' }}>
                                <Button onClick={login_user} variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color, fontSize: 18, height: 50, color: '#fff', lineHeight: '30px' }}>登陆</Button>
                            </div>
                        </div>
                    }

                    {
                        login_mode === 0 ? <div style={{ textAlign: 'center', marginTop: '5%', padding: 10, cursor: 'pointer', background: 'rgba(241,238,238,1)', fontSize: 18, height: 30, color: base_setting?.primary_color, lineHeight: '30px', boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.2)' }} onClick={() => setLoginMode(1)}>账号密码登陆</div>
                            :
                            <div style={{ textAlign: 'center', padding: 10, marginTop: '5%', cursor: 'pointer', background: 'rgba(241,238,238,1)', fontSize: 18, height: 30, color: base_setting?.primary_color, lineHeight: '30px', boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.2)' }} onClick={() => setLoginMode(0)}>手机号登陆</div>
                    }
                </div>
            </Dialog>
            <Dialog onClose={themeClose} open={add_theme} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>添加专属提示词</DialogTitle>
                <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px' }}>
                    <div>
                        <TextField
                            id="outlined-basic"
                            label="标题"
                            variant="outlined"
                            value={theme_title}
                            onChange={(e) => setThemeTitle(e.target.value)}
                            inputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: { fontSize: 13 } }}
                            style={{ width: '100%', fontSize: 15 }}
                        />
                    </div>
                    <div style={{ display: 'flex', padding: '30px 0' }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="内容"
                            multiline
                            variant="outlined"
                            value={theme_content}
                            rows={8}
                            size="small"
                            onChange={(e) => setThemeContent(e.target.value)}
                            inputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: { fontSize: 13 } }}
                            style={{ width: '100%', fontSize: 15 }}
                        />
                    </div>
                    <div style={{ paddingVertical: '5%' }}>
                        <Button onClick={add_theme_affirm} variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color, fontSize: 18, height: 50, color: '#fff', lineHeight: '30px' }}>添加</Button>
                    </div>
                </div>
            </Dialog>
            <Dialog onClose={chatClose} open={chat_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <div style={{ position: 'absolute', right: 20, top: 20 }} onClick={chatClose}>
                    <CancelIcon style={{ fontSize: 20 }} />
                </div>
                <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>博槐智能体列表</DialogTitle>
                <div style={{ padding: 50, paddingTop: 0 }}>
                    <div style={{ paddingBottom: 10, padding: 30, paddingTop: 20, paddingLeft: 0 }}>
                        <Paper component="form" className={classes.root}>
                            <IconButton className={classes.iconButton} aria-label="menu">
                                <SearchIcon style={{ fontSize: 32 }} />
                            </IconButton>

                            <InputBase
                                className={classes.input}
                                placeholder=""
                                value={searchValue}
                                onChange={searchChange}
                            />
                            <Button className={classes.clearButton} onClick={clearAll} style={{ color: base_setting?.primary_color }}>清空搜索</Button>
                        </Paper>
                    </div>
                    <TableContainer>
                        <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>名称</TableCell>
                                    <TableCell align="left">耗费提问次数</TableCell>
                                    <TableCell align="left">介绍</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agent_lists?.map((row) => (
                                    <TableRow
                                        hover
                                        key={row.name}
                                        onClick={(e) => handleAgent(row.prompt, row.expression, row.introduction)}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.cost}</TableCell>
                                        <TableCell align="left">{row.introduction}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Dialog>
            <Dialog onClose={promptClose} open={prompt_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确定删除该条提示词吗
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={promptClose}>取消</Button>
                    <Button onClick={promptAffirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <RecordingDrawer></RecordingDrawer>
        </div>
    )
}

export default LargeModel