/**
 * Created on 10/19/21.
 * @author Xihao
 */

import React, { useEffect } from "react";
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({
    root: {
        "&.MuiSkeleton-root": {
            backgroundColor: "#5F3EA3",
        }
    }
}));

export const AppSkeleton = ()=> {
    return (
        <div style={{ width: 300 ,maxWidth:'100%',marginLeft:'auto'}}>
            <Skeleton variant="rectangular" width={300} height={30} style={{marginBottom: 20}}/>
            <Skeleton variant="rectangular" width={300} height={80} style={{marginBottom: 20}}/>

            <Skeleton variant="rectangular" width={300} height={300} style={{marginBottom: 20}}/>
        </div>
    );
};


export const WebSkeletonLight = ()=> {
    const classes = useStyles();

    return (
        <div style={{ maxWidth:'90%', overflow: 'hidden'}}>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginBottom: 20}} className={classes.root}/>
            <Skeleton variant="rectangular" width={'100%'} height={'6vw'} style={{marginBottom: 20}} className={classes.root}/>

            <Skeleton variant="rectangular" width={'100%'} height={'10vw'} style={{marginBottom: 20}} className={classes.root}/>
        </div>
    );
};

export const WebSkeletonDark = ()=> {

    return (
        <div style={{ maxWidth:'90%', overflow: 'hidden'}}>
            <Skeleton variant="rectangular" width={'100%'} height={30} style={{marginBottom: 20}} />
            <Skeleton variant="rectangular" width={'100%'} height={'6vw'} style={{marginBottom: 20}} />

            <Skeleton variant="rectangular" width={'100%'} height={'10vw'} style={{marginBottom: 20}} />
        </div>
    );
};