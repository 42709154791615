/**
 * Created on 6/23/21.
 * @author Xihao
 */
import {
    CHANGE_INDUSTRY_CATEGORY,
    CHANGE_TAX_CATEGORY,
    CLOSE_DRAWER,
    GET_PARENT_RESULT_SUCCESS,
    GET_SEARCH_RESULT_SUCCESS,
    GO_TO_PAGE,
    LOADING_PARENT_RESULTS,
    LOADING_SEARCH_RESULTS,
    OPEN_DRAWER,
    SET_SEARCH_WORDS,
    TOGGLE_CONCEPTS,
    TOGGLE_QA,
    TOGGLE_SE,
    TOGGLE_SOLUTIONS,
    TOGGLE_LAW,
    SHUIINTROSHOW,
    SCENELISTS,
    VIDEO_LISTS
} from '../actions/types';
const _ = require('micro-dash');
let all_category_data = _.isNil(localStorage.getItem('all_category_data'))?'[]':localStorage.getItem('all_category_data');
const initialState = {
    search_result_list: [],
    result_count: 0,
    is_loading_search_results: false,
    hit: {},
    drawer_open: false,
    page_index:1,
    total_page_count: 0,
    search_indexes: {
        concepts: true,
        solutions: true,
        qa: false,
        se: true,
        law:false
    },
    search_industry_category:'全部',
    search_tax_category: '全部',
    search_words: "",
    parent_data: {},
    is_loading_parent: false,
    shui_intro_show:true,
    company_category_data:[],
    individual_category_data:[],
    business_type_data:[],
    all_category_data:JSON.parse(all_category_data),
    video_lists:[]
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type) {
        case LOADING_SEARCH_RESULTS:
            return {...state, is_loading_search_results: true};
        case GET_SEARCH_RESULT_SUCCESS:
            return {...state,
                search_result_list: action.search_result_list,
                result_count: action.result_count,
                page_index: action.page_index,
                total_page_count: Math.ceil(action.result_count/10),
                is_loading_search_results: false,
            };
        case OPEN_DRAWER:
            return {...state, hit: action.hit, drawer_open: true};
        case CLOSE_DRAWER:
            console.log('inside close drawer');
            return {...state, hit: {}, drawer_open: false};
        case GO_TO_PAGE:
            return {...state, page_index: action.page_index};
        // index category
        case TOGGLE_CONCEPTS:
            console.log(`In toggle concepts ${state.search_indexes.concepts}`);
            return {...state, search_indexes:{...state.search_indexes ,concepts: !state.search_indexes.concepts}};
        case TOGGLE_SOLUTIONS:
            return {...state, search_indexes:{...state.search_indexes ,solutions: !state.search_indexes.solutions}};
        case TOGGLE_SE:
            return {...state, search_indexes:{...state.search_indexes ,se: !state.search_indexes.se}};
        case TOGGLE_QA:
            return {...state, search_indexes:{...state.search_indexes ,qa: !state.search_indexes.qa}};
        case TOGGLE_LAW:
            return {...state, search_indexes:{...state.search_indexes ,law: !state.search_indexes.law}};
        case SET_SEARCH_WORDS:
            return {...state, search_words: action.search_words};
        // industry category
        case CHANGE_INDUSTRY_CATEGORY:
            console.log(action.value);
            return {...state, search_industry_category:action.value};
        case CHANGE_TAX_CATEGORY:
            return {...state, search_tax_category:action.value};
        case GET_PARENT_RESULT_SUCCESS:
            return {...state, parent_data:action.value, is_loading_parent: false};
        case LOADING_PARENT_RESULTS:
            return {...state, is_loading_parent: true};
        case SHUIINTROSHOW:
            return {...state,shui_intro_show:action.shui_intro_show}
        case SCENELISTS:
            localStorage.setItem('all_category_data', JSON.stringify(action.all_category_data))
            return {...state,
                company_category_data:action.company_category_data,
                individual_category_data:action.individual_category_data,
                business_type_data:action.business_type_data,
                all_category_data:action.all_category_data
            }
        case VIDEO_LISTS:
            return {...state,video_lists:action.data}
        default:
            return state;
    }
}