/**
 * Created on 6/23/21.
 * @author Xihao
 */

import { Exposure } from "@mui/icons-material";

export const GET_SEARCH_RESULT_SUCCESS = 'get_search_result_success';
export const LOADING_SEARCH_RESULTS = 'loading_search_results';

export const OPEN_DRAWER = 'open_drawer';
export const CLOSE_DRAWER = 'close_drawer';

export const GO_TO_PAGE = 'go_to_page';

export const TOGGLE_CONCEPTS = 'toggle_concepts';
export const TOGGLE_SOLUTIONS = 'toggle_solutions';
export const TOGGLE_QA = 'toggle_qa';
export const TOGGLE_SE = 'toggle_se';
export const TOGGLE_LAW = 'toggle_law';

export const SET_SEARCH_WORDS = 'set_search_words';
export const SHUIINTROSHOW='shui_intro_show'
export const SCENELISTS='scene_lists'
export const MORESCENELISTS='more_scene_lists'
export const VIDEO_LISTS='video_lists'

//tax categories
export const CHANGE_TAX_CATEGORY = 'change_tax_category';

//industry categories
export const CHANGE_INDUSTRY_CATEGORY = 'change_industry_category';

// parent data
export const GET_PARENT_RESULT_SUCCESS = 'get_parent_result_success';
export const LOADING_PARENT_RESULTS = 'loading_parent_results';

//user
export const LOGIN='LOGIN';
export const LOGOUT = "logout";
export const USERINFO='userInfo'
export const wechatWebLogin='wechat_web_login'
export const SHUIUPDATETIME='shui_update_time'
export const CHANGE_SUCCESS='change_success'
export const TAX_AGENT_LISTS='tax_agent_lists'
export const CUT_TAX_AGENT='cut_tax_agent'
export const MY_USER_LISTS='my_user_lists'
export const REDUCE_COLLECT_NUM='reduce_collect_num'

export const OPENHINT='open_hint'
export const ACCOINTINGOPENHINT='accounting_open_hint'
export const ALL_PROMPT='all_prompt'
export const PERSONNEL_LISTS='personnel_lists'
export const HINT_AGENT='hint_agent'
export const TASK_LIST='task_list'
export const SEND_SOCKET_ID='send_socket_id'
export const FILE_LISTS='file_lists'
export const BASE_SETTINGS='base_settings'
export const TAB_ACTION='tab_action'
export const VOICE_LISTS='voice_lists'
export const CRM_LISTS='crm_lists'
export const SPEECH_RECOGNIZER='speech_recognizer'

// Profile
export const GET_PROFILE_INFO_SUCCESS = 'get_profile_info_success';

//chat
export const INITIALIZE_SOCKET = 'initialize_socket';
export const SEND_MESSAGE = 'send_message';
export const SEND_MESSAGE_TO_CREATE_TRANSACTION = 'send_message_to_create_transaction';
export const GET_CHAT_RELATION_LIST = 'get_chat_relation_list';
export const RECEIVE_MESSAGE = 'receive_message';
export const GET_CHAT_HISTORY = 'get_chat_history';
export const NOTIFICATION = 'notification';
export const ALARM = 'alarm';
export const WHO_ARE_YOU = 'who_are_you';
export const SOCKET_LOGIN = 'socket_login';
export const SOCKET_LOGOUT = 'socket_logout';

export const PAYMENTRESULTS='payment_results'

export const SET_CURRENT_CHATTING_USER = 'set_current_chatting_user';
export const ADD_CHATTING_NOW = 'add_chatting_now';
export const REMOVE_CHATTING_NOW = 'remove_chatting_now';

export const INCREMENT_TODO = 'increment_todo';
export const DECREMENT_TODO = 'decrement_todo';
export const GET_USER_ORDER_TODOS = 'get_user_order_todos';

export const GET_UNREAD_MSG_NUMBER = 'get_unread_msg_number';
export const SUBMIT_CHAT_ITEM = 'submit_chat_item';

export const SEND_MESSAGE_TO_ROOM='send_message_to_room'

export const SEND_WEB_NOTIFICATION_TO_USER='send_web_notification_to_user'
export const CLEAR_NEW_INFORM='clear_new_inform'

export const SOCKET_TYPE_LIST = [SEND_MESSAGE, RECEIVE_MESSAGE, NOTIFICATION, ALARM, GET_CHAT_HISTORY,
    WHO_ARE_YOU, GET_CHAT_RELATION_LIST,GET_UNREAD_MSG_NUMBER,INCREMENT_TODO,DECREMENT_TODO,GET_USER_ORDER_TODOS,SEND_MESSAGE_TO_ROOM,PAYMENTRESULTS,SEND_WEB_NOTIFICATION_TO_USER];

//mine
export const SETSUGGEST='set_suggest'
export const PRICES='prices'
export const WEBQRCODE='web_qr_code'
export const MEMBERINFOS='member_infos'
export const CHANGE_PAY_PRODUCT='change_pay_product'
export const GETDISCOUNT='get_discount'
export const USEDDISCOUNT='used_discount'

export const LLM_PRICE_LISTS='llm_price_lists'
export const LLM_QR_CODE='llm_qr_code'
export const LLM_MEMBERSHIP_INFO='llm_membership_info'

//question
export const QUESTIONLISTS='question_list'
export const MYQUESTIONLISTS='my_question_list'
export const QUESTIONLISTSLIKE='question_lists_like'
export const QUESTIONSEARCHLISTSLIKE='question_search_lists_like'
export const QUESTION_OPEN_DRAWER='question_open_drawer'
export const QUESTION_CLOSE_DRAWER='question_close_drawer'
export const QUESTION_DETAILS_LIKE='question_details_like'
export const ANSWER_LIST='answer_list'
export const ANSWER_LIKE='answer_like'
export const RELEASE_SUCCESS='release_success'
export const CONTAIN_KEY='contain_key'
export const SHUI_MORE_QUESTION='shui_more_question'
export const MQ_MORE_QUESTION='mq_more_question'
export const SEARCH_MORE_QUESTION='search_more_question'

//programme
export const PROGTAMMELIST='programme_list'
export const PROGRAMMEDETAILS='programme_details'
export const CREATEPROGRAMME='create_programme'
export const RISK_CLOSE_OPEN='risk_close_open'

//inform
export const SHUI_UNREAD_NUM='shui_unread_num'
export const SHUI_CLEAR_NUM='shui_clear_num'
export const SHUI_INFORM_LIST='shui_inform_list'
export const SHOW_SEARCH='show_search'
export const SEARCH_WORD='search_word'

//plan
export const COMPANY_VALUE='company_value'
export const COMPANY_DATA='company_data'
export const PLAN_CLOSE_DRAWER='plan_close_drawer'
export const PLAN_OPEN_DRAWER='plan_open_drawer'
export const DATA_ERROR='data_error'
export const LOADING='loading'
export const SEARCH_NUM='search_num'

//tax_accountant
export const TAX_LISTS='tax_lists'
export const TAX_INFO='tax_info'
export const TAX_CLOSE_DRAWER='tax_close_drawer'

//admin
export const ALL_USER_LOCATION='all_user_location'
export const TRACK_CLOSE_DRAWER='track_close_drawer'
export const TRACK_DRAWER='track_drawer'
export const OPEN_TRACK_DRAWER='open_track_drawer'
export const BEHAVIOR_DATA='behavior_data'
export const HAVE_COLLECT='have_collect'
export const MY_COLLECTED_USER='my_collected_user'
export const INDISTINCT_CHECKED='indistinct_checked'
export const USER_LINKED_LISTS='user_linked_lists'
export const ADMIN_LOADING='admin_loading'
export const SNACK_OPEN='snack_open'
export const RELEVANCY='relevancy'

//dataset
export const CREATE_DATASET='create_dataset'
export const GET_DATASET='get_dataset'
export const COLLECTION_LISTS='collection_lists'
export const COLLECTION_DATA='collection_data'