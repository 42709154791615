import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { get_outsider_settings, delete_voice_record, get_voice, cut_tab_action, manager_get_voice } from '../../../actions/user'
import { BorderLeft, BorderRight } from '@mui/icons-material';
const _ = require('micro-dash');
const type_lists = [
    {
        label: '客户拜访(公开存入CRM)',
        value: 'CUSTOMER'
    },
    {
        label: '会议记录(公开存入CRM)',
        value: 'MEETING'
    },
    {
        label: '个人（数据私有不公开）',
        value: 'PERSONAL'
    }
]

function EnlightenmentLists(e) {
    const { Dragger } = Upload;
    const user_type = useSelector((state) => state.user.user_type);
    const agent_menus = useSelector((state) => state.user.agent_menus);
    const voice_lists = useSelector((state) => state.user.voice_lists);
    const base_setting = useSelector((state) => state.user.base_setting);
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(1);
    const totalPages = useSelector((state) => state.user.totalPages);
    useEffect(() => {
        dispatch(get_outsider_settings())
        if (user_type >= 170) {
            dispatch(manager_get_voice({ page: page }))
        } else {
            dispatch(get_voice({ page: page }))
        }
    }, [])
    const MaxContent = (props) => {
        const e = props.e
        console.log(e)
        if (e?.length > 10) {
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{e}</div>
                <div>
                    <Tooltip title={e}>
                        <HelpIcon style={{ color: 'rgba(215, 215, 215)', fontSize: 20 }}>
                        </HelpIcon>
                    </Tooltip>
                </div>
            </div>
        } else {
            return <div style={{ width: 100 }}>{e}</div>
        }
    }
    const MaxName = (props) => {
        const e = props.e
        console.log(e)
        if (e?.length > 10) {
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 70, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{e}</div>
            </div>
        } else {
            return <div>{e}</div>
        }
    }
    const cut_tab = (event) => {
        console.log(event)
        dispatch(cut_tab_action(event))
    }
    const handleChangePage = (event, newPage) => {
        console.log(event, newPage)
        if (user_type >= 170) {
            dispatch(manager_get_voice({ page: newPage }))
        } else {
            dispatch(get_voice({ page: newPage }))
        }
    }
    // 创建ref来引用audio元素
    const audioRef = useRef(null);
    // 设置播放状态
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [currentAudioLink, setCurrentAudioLink] = React.useState(''); // 状态追踪当前播放的音频链接
    const [currentAudio, setCurrentAudio] = React.useState(null);

    const handlePlay = (audioLink) => {
        if (currentAudio && isPlaying && currentAudio.src === audioLink) {
            // 如果当前音频正在播放，则暂停
            currentAudio.pause();
            setIsPlaying(false);
        } else {
            // 如果当前音频已经存在，先停止它
            if (currentAudio) {
                currentAudio.pause();
                setCurrentAudio(null); // 重置当前音频对象
            }
            // 创建新的音频对象并播放
            const newAudio = new Audio(audioLink);
            newAudio.play();
            newAudio.onended = () => {
                setIsPlaying(false); // 当音频播放结束时，更新播放状态
                setCurrentAudio(null); // 重置当前音频对象
            };
            setCurrentAudio(newAudio);
            setIsPlaying(true);
        }
    };
    const [open, setOpen] = React.useState(false);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [names, setNames] = React.useState([]);
    const [file_type_lists, setFileTypeLists] = React.useState([]);
    const [data_lists, setDataLists] = React.useState({
        selectedFiles: [],
        names: [],
        file_type_lists: []
    });
    const handleClose = () => {
        setOpen(false)
    }
    const open_file = () => {
        setOpen(true)
    }
    const generateUid = () => {
        return `uid_${new Date().getTime()}`;
    };
    const handleBeforeUpload = (file) => {
        console.log(file);
        const fileWithUid = {
            ...file,
            uid: generateUid(), // 为文件生成一个唯一的uid
            originFileObj: file
        };
        setSelectedFiles(prev => [...prev, fileWithUid]);
        setDataLists(prev => ({
            ...prev,
            selectedFiles: [...prev.selectedFiles, fileWithUid],
            names: [...prev.names, file.name],
            file_type_lists: [...prev.file_type_lists, 'CUSTOMER']
        }));
        return false; // 阻止自动上传
    };
    const [alignment, setAlignment] = React.useState('web');
    return (
        <div >
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                            <TabbleLists></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    <div style={{ padding: '3% 5%', height: 'calc(84vh - 90px)', overflowY: 'auto' }}>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10, paddingTop: 20 }}>
                            <div style={{ fontWeight: 'bold' }}>您的录音列表</div>
                            <Link to='/largemodel/update_enlightenment'>
                                <Button variant="contained" color='success' style={{ background: "#46b14b" }}>上传录音</Button>
                            </Link>
                        </div> */}
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            // onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="web">您的录音列表</ToggleButton>
                            <Link to='/largemodel/update_enlightenment'>
                                <ToggleButton value="android">
                                    上传录音
                                </ToggleButton>
                            </Link>
                            <Link to='/largemodel/register_summarize'>
                                <ToggleButton value="ios">
                                    添加拜访总结
                                </ToggleButton>
                            </Link>
                        </ToggleButtonGroup>
                        <div>
                            <TableContainer>
                                <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>名称</TableCell>
                                            <TableCell align="left" style={{ minWidth: 60 }}>创建时间</TableCell>
                                            <TableCell align="left" style={{ minWidth: 60 }}>持续时间</TableCell>
                                            <TableCell align="left" style={{ minWidth: 60 }}>类型</TableCell>
                                            <TableCell align="center">操作</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {voice_lists?.map((row) => (
                                            <TableRow
                                                hover
                                                key={row._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <MaxName e={row.name} />
                                                        {
                                                            !_.isNil(row?.audio_link) ? <div>
                                                                {/* <audio ref={audioRef} src={row?.audio_link}></audio> */}
                                                                <Button style={{ color: base_setting?.primary_color }} onClick={() => handlePlay(row?.audio_link)}>
                                                                    {isPlaying && currentAudio?.src === row.audio_link ? <PauseCircleOutlineIcon style={{ color: base_setting?.primary_color }} /> : <PlayCircleOutlineIcon style={{ color: base_setting?.primary_color }} />}
                                                                </Button>
                                                            </div> : ''
                                                        }
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left"><MaxContent e={row.upload_time.split('T')[0] + ' ' + row.upload_time.split('T')[1].split('.')[0]} /></TableCell>
                                                <TableCell align="left"><MaxContent e={row.duration} /></TableCell>
                                                <TableCell align="left">
                                                    {
                                                        (() => {
                                                            if (row.type == 'MEETING') {
                                                                return '会议记录(公开存入CRM)'
                                                            } else if (row.type == 'PERSONAL') {
                                                                return '个人（数据私有不公开）'
                                                            } else {
                                                                return '客户拜访(公开存入CRM)'
                                                            }
                                                        })()
                                                    }
                                                </TableCell>
                                                {
                                                    row.enabled ? <TableCell align="center">
                                                        <Link to={{ pathname: `/largemodel/enlightenment_info`, state: { id: row._id } }} >
                                                            <Button style={{ color: base_setting?.primary_color }}>查看</Button>
                                                        </Link>
                                                        {/* @录音听悟?record_id=${record_id}@ 总结内容 */}
                                                        <Link to={`/largemodel?agent_name=录音听潮&agent_id=${row._id}`} onClick={() => cut_tab(0)}>
                                                            <Button style={{ color: base_setting?.primary_color }}>使用</Button>
                                                        </Link>
                                                        {/* {
                                                            !_.isNil(row?.audio_link) ? <div>
                                                                <Button style={{ color: base_setting?.primary_color }} onClick={() => handlePlay(row?.audio_link)}>
                                                                    {isPlaying && currentAudio?.src === row.audio_link ? '暂停' : '播放'}
                                                                </Button>
                                                            </div> : ''
                                                        } */}

                                                    </TableCell> : <TableCell align="center">音频处理中...</TableCell>
                                                }
                                                {/* <TableCell align="center">
                                                    <Link to={{ pathname: `/largemodel/enlightenment_info`, state: { id: row._id } }} >
                                                        <Button style={{ color: base_setting?.primary_color }}>查看</Button>
                                                    </Link>
                                                    <Link to={`/largemodel?agent_name=录音听潮&agent_id=${row._id}`} onClick={() => cut_tab(0)}>
                                                        <Button style={{ color: base_setting?.primary_color }}>使用</Button>
                                                    </Link>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                        <Stack spacing={2}>
                            <Pagination count={totalPages} onChange={handleChangePage} />
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EnlightenmentLists