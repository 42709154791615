import {
    INITIALIZE_SOCKET, SOCKET_TYPE_LIST,
    SET_CURRENT_CHATTING_USER, SOCKET_LOGOUT, SEND_MESSAGE,PAYMENTRESULTS,CHANGE_SUCCESS,CLEAR_NEW_INFORM
    // CLEAR_BOTTOM_TAB_MESSAGE_BADGE, SYSTEM_NOTIFICATION_INTERACTIVE_TOPAY
} from './types';
import {user_member_info,used_discount} from '../actions/mine'
import io from "socket.io-client";
import {MIDDLE_EARTH_URL, KINGSYARD_URL,SOCKET_EARTH_URL} from "../configs/constants";
import axios from "axios";

axios.defaults.timeout = 6000;


// const sockets=io(SOCKET_EARTH_URL)   //建立socket链接
// export function initSocket() {
//     return (dispatch, getState) => {
//         console.log("in initsocket")
//         // console.log(getState().user)
//         SOCKET_TYPE_LIST.map(
//             type => sockets.on(type, (payload) => {
//                     console.log('监听',payload,type)
//                     if(type==='payment_results'){
//                         if(payload.is_Success==='True'){
//                             console.log('')
//                             dispatch(user_member_info())
//                             dispatch(used_discount())
//                         }
//                     }
//                     // console.log(`in sokcet types map, ${type}, ${payload}, ${JSON.stringify( getState().user)}`)
//                     dispatch({type, payload, profile: getState().user});
//                 }
//             )
//         );
//     }
   
// }
export const emit = null;

//action creator
// export function initSocketAction(socket) {
//     return {
//         type: INITIALIZE_SOCKET,
//         socket,
//     }
// }

export function chattingWithUserAction(currentChattingUser){
    return{
        type: SET_CURRENT_CHATTING_USER,
        currentChattingUser
    }
}

export function sendMsgAction(payload){
    return{
        type: SEND_MESSAGE,
        payload
    }
}
export function change_is_Success(){
    return{
        type:CHANGE_SUCCESS,
        is_paySuccess:false
    }
}
export function clear_new_inform(mini_type){
    return {
        type:CLEAR_NEW_INFORM,
        mini_type
    }
}
// export function clearbottomTabMessageBadgeAction(){
//     return{
//         type: CLEAR_BOTTOM_TAB_MESSAGE_BADGE
//     }
// }

// dispatchers
export function setCurrentChattingUser(user) {
    return (dispatch) => {
        dispatch(chattingWithUserAction(user));
    }
}

export function leaveChat() {
    return (dispatch) => {
        dispatch(chattingWithUserAction(null));
    }
}


export function sendMsg({type, content, to_user_id}){
    return (dispatch, getState) => {
        const self_user_id = getState().profile.user.id;
        const date = new Date().toLocaleString();
        const message = {
            type,
            content,
            user_id: self_user_id,
            time: date,
        };
        const payload = {
            message,
            to_user_id,
        };

        dispatch(sendMsgAction(payload));
    }
}


