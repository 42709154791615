import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import PreviewItem from './PreviewItem';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import HeadHavigation from "../../headHavigation/headHavigation";
import DrawerContent from './DrawerContent';
import {CLOUD_URL} from '../../../configs/constants'
import Footer from "../../footer/Footer";
import {video_data} from '../../../actions/search'

const _ = require('micro-dash');
function VideoLists(){
    const dispatch = useDispatch();
    const [video_url, setVideo] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const video_list=useSelector((state) => state.search.video_lists);
    const token = useSelector((state) => state.user.token);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    const play_video=(e)=>{
      console.log(e)
      if(_.isNil(token)){
        if(e.vip_type=='1'){
            alert('请先登录!')
        }else{
          setVideo(e.video_url);
          setOpen(!open)
        }
      }else{
        setVideo(e.video_url);
        setOpen(!open)
      }
    }
    useEffect(()=>{
      dispatch(video_data())
  },[])
    return (
        <div>
            <HeadHavigation provenance={0} />
            <div style={{padding:'0 12%',marginTop:39,paddingBottom:'39px'}}>
                <div style={{display:'flex',width:'100%',flexWrap:'wrap'}}>
                    {
                        video_list.map(
                            (item,index)=>
                                <div style={{width:'20%',margin:'20px',cursor: "pointer"}} onClick={()=>play_video({video_url:item.video_url,vip_type:item.vip_type})}>
                                    <img src={CLOUD_URL+item.img_url} alt="" style={{width:'100%',height:'100%'}} />
                                    <div style={{textAlign:'center',overflow:'hidden',textOverflow:'ellipsis',whiteSpace: 'nowrap',width: '80%',margin: '0 auto'}}>{item.title}</div>
                                </div>
                        )
                    }
                </div>
            </div>
            <hr/>
            <div style={{padding:'20px 12%'}}>
                <Footer />
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <div>
                    {
                        open?<video src={CLOUD_URL+video_url} autoPlay></video>:''
                    }
                    {/* <video src={CLOUD_URL+video_url} autoPlay></video> */}
                </div>
            </Backdrop>
        </div>
    )
}

export default VideoLists;