import {COMPANY_VALUE, COMPANY_DATA,PLAN_CLOSE_DRAWER,PLAN_OPEN_DRAWER,DATA_ERROR,LOADING,SEARCH_NUM} from '../actions/types';

const initialState = {
    company_name:'',
    company_data:'',
    company_title:[],
    error_value:'',
    loading_type:false,
    search_nums:localStorage.getItem('search_nums')||0
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case COMPANY_VALUE:
            return {...state, company_name:action.value};
        case COMPANY_DATA:
            let company_title=[]
            for(let key in action.data){
                company_title.push(key)
            }
            console.log('company_title',company_title)
            return {...state,company_data:action.data,company_title:company_title,loading_type:false};
        case PLAN_CLOSE_DRAWER:
            return {...state, hit: {}, drawer_open: false};
        case PLAN_OPEN_DRAWER:
            return {...state, hit: action.hit, drawer_open: true};
        case DATA_ERROR:
            return{...state,error_value:action.error,loading_type:true}
        case LOADING:
            return {...state,loading_type:true}
        case SEARCH_NUM:
            let search_nums=state.search_nums+1
            localStorage.setItem('search_nums',search_nums)
            return{...state,search_nums:search_nums}
        default:
            return state;
    }
}