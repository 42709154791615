/**
 * Created on 6/24/21.
 * @author Xihao
 */

import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { track_close_drawer, open_track_drawer } from '../../../actions/admin'
import { useDispatch, useSelector } from 'react-redux';
import Close from '@mui/icons-material/Close';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import BackspaceIcon from '@mui/icons-material/Backspace';

import enter_into from '../../../static/image/enter_into.png'
import video_img from '../../../static/image/video.png'
import search from '../../../static/image/search.png'
import scheme from '../../../static/image/scheme.png'
import plan from '../../../static/image/plan.png'
import quit_out from '../../../static/image/quit_out.png'
import pay from '../../../static/image/pay.png'
import pdf_img from '../../../static/image/pdf.png'
import other from '../../../static/image/other.png'

const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingInline: '3.5%',
        width: '92%',
        minHeight: '100%',
        // borderLeft: '1px solid #E6E5EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        background:'rgba(0,0,0,0.02)'
        // borderLeft: '8px solid #3D1198',
    },
    center: {
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    title: {
        fontSize: 19,

    }
}));



const DrawerContent = ({ hit }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const track_data = useSelector((state) => state.admin.track_data)
    const user_behavior = useSelector((state) => state.admin.behavior_data)
    return (
        <div className={classes.root}>
            <div style={{
                display: 'flex',
                flexDirection: 'row', alignItems: 'center', marginBottom: 20,background:'#fff',borderRadius:8,width:'100%'
            }}>
                <IconButton onClick={() => dispatch(track_close_drawer())} style={{ width: 39, height: 39, marginRight: 6 }}>
                    <Close />
                </IconButton>
                <p style={{ fontSize: 16, textAlign: 'center' }}>用户轨迹</p>
                <div style={{ padding: '0 5px' }}>---</div>
                <div style={{ fontSize: 12, paddingTop: 2}}>{track_data[0].time}--{track_data[track_data.length-1].time}</div>
            </div>
            <Divider />
            <div style={{ width: '100%',paddingInline: '3.5%', }}>
                {
                    track_data.map((item, index) => {
                        if (item.type === "enter" || item.type === "enter_program") {
                            return <div key={index}>
                                <div style={{ display: 'flex', background:'#fff',padding:'10px',boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',borderTop:(function(){
                                        if(item.name==='进入小程序'){
                                            return '1px solid #4caf50'
                                        }else if(item.name==='播放视频'){
                                            return '1px solid #cc0066'
                                        }else if(item.name==='搜索'){
                                            return '1px solid #33ffff'
                                        }else if(item.name==='个人筹划'||item.name==='详细企业筹划'||item.name==='快速企业筹划'){
                                            return '1px solid #3333ff'
                                        }else if(item.name==='免费筹划'||item.name==='免费方案列表'){
                                            return '1px solid #cccc33'
                                        }else if(item.name==='生成PDF'||item.name==='查看PDF'||item.name==='下载PDF'){
                                            return '1px solid #ff6666'
                                        }else if(item.name==='支付'){
                                            return '1px solid #6600ff'
                                        }else{
                                            return '1px solid #99ccff'
                                        }
                                    }()) }}>
                                    <div style={{ display: 'flex',alignItems:"center", background: (function(){
                                        if(item.name==='进入小程序'){
                                            return '#4caf50'
                                        }else if(item.name==='播放视频'){
                                            return '#cc0066'
                                        }else if(item.name==='搜索'){
                                            return '#33ffff'
                                        }else if(item.name==='个人筹划'||item.name==='详细企业筹划'||item.name==='快速企业筹划'){
                                            return '#3333ff'
                                        }else if(item.name==='免费筹划'||item.name==='免费方案列表'){
                                            return '#cccc33'
                                        }else if(item.name==='生成PDF'||item.name==='查看PDF'||item.name==='下载PDF'){
                                            return '#ff6666'
                                        }else if(item.name==='支付'){
                                            return '#6600ff'
                                        }else{
                                            return '#99ccff'
                                        }
                                    }()), borderRadius: '50%', marginLeft:10}}>
                                        <div style={{ padding: '2px', borderRadius: '50%',height:30,width:30,display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            {
                                                (function (){
                                                    if(item.name==='进入小程序'){
                                                        return <img src={enter_into} alt='' style={{width:25,height:25}} />
                                                    }else if(item.name==='播放视频'){
                                                        return <img src={video_img} alt='' style={{width:25,height:25}} />
                                                    }else if(item.name==='搜索'){
                                                        return <img src={search} alt='' style={{width:25,height:25}} />
                                                    }else if(item.name==='个人筹划'||item.name==='详细企业筹划'||item.name==='快速企业筹划'){
                                                        return <img src={plan} alt='' style={{width:25,height:25}} />
                                                    }else if(item.name==='免费筹划'||item.name==='免费方案列表'){
                                                        return <img src={scheme} alt='' style={{width:25,height:25}} />
                                                    }else if(item.name==='生成PDF'||item.name==='查看PDF'||item.name==='下载PDF'){
                                                        return <img src={pdf_img} alt='' style={{width:25,height:25}} />
                                                    }else if(item.name==='支付'){
                                                        return <img src={pay} alt='' style={{width:25,height:25}} />
                                                    }else{
                                                        return <img src={other} alt='' style={{width:25,height:25}} />
                                                    }
                                                }())
                                            }
                                            
                                        </div>
                                        {/* <div style={{ fontSize: '12px', lineHeight: '24px', color:item.type === "enter_program"?'#fff':'#3D1198', paddingLeft: 5 }}>进入页面</div> */}
                                    </div>
                                    <div style={{ fontSize: 12, width: '70%', padding: '0 5%' }}>
                                        <div style={{ fontSize: 15, fontWeight: 'bold' }}>{item.name}</div>
                                        <div>标题：{item.content}</div>
                                    </div>
                                    <div style={{ width: 45, fontSize: 13,display:'flex',alignItems:'center' }}>{item.time.split('日')[1]}</div>
                                </div>
                                <div style={{ height: 10 }}></div>
                            </div>
                        } else if (item.type === "exit" || item.type === 'exit_program') {
                            return <div key={index} >
                                <div style={{ display: 'flex', marginBottom: 10, background:'#fff',padding:'10px',borderTop:'1px solid red',boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                                    <div style={{ display: 'flex', background:'red', borderRadius: '50%',alignItems:"center" ,marginLeft:10}}>
                                        <div style={{ padding: '2px',  borderRadius: '50%', height: 30 }}>
                                            <img src={quit_out} alt='' style={{width:'100%',height:'100%'}} />
                                        </div>
                                        {/* <div style={{ fontSize: '12px', lineHeight: '24px', color:item.type === 'exit_program'?'#fff':'#3D1198', paddingLeft: 5 }}>退出页面</div> */}
                                    </div>
                                    <div style={{ fontSize: 12, width: '70%', padding: '0 5%' }}>
                                        <div style={{ fontSize: 15, fontWeight: 'bold' }}>{item.name}</div>
                                        <div>标题：{item.content}</div>
                                    </div>
                                    <div style={{ width: 45, fontSize: 13 ,display:'flex',alignItems:'center'}}>{item.time.split('日')[1]}</div>
                                </div>
                                <div style={{ height: 10 }}></div>
                            </div>
                        }
                    })
                }


            </div>
        </div>
    )
};

export default DrawerContent;