export function TMapGL(): Promise<void> {
    if (window.TMap) return Promise.resolve()
    return new Promise(function(resolve, reject) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://map.qq.com/api/gljs?v=2.exp&libraries=tools&key=OKWBZ-XNM3X-JOP4H-7GIPM-UW4GO-W4BDK'
      script.onerror = () => reject()
      script.onload = () => resolve()
      document.head.appendChild(script)
    })
  }

  export function loadMapScript() {
    return new Promise(function (resolve, reject) {
      window.init = function () {
        resolve(window.qqMap);
      };
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://map.qq.com/api/gljs?v=2.exp&key=OKWBZ-XNM3X-JOP4H-7GIPM-UW4GO-W4BDK";
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }