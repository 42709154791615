import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {useDispatch, useSelector } from 'react-redux';
import io from "socket.io-client";
import {initSocket,emit} from '../../actions/chat'
import img_url from '../../static/image/user_img.jpg'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from "react-router-dom";
import { Button } from 'react-bootstrap';
// import {MIDDLE_EARTH_URL} from '../../../configs/constants';
const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    container: {
        height: 400,
        flex: 1,
        backgroundColor: '#F5FCFF',
    },
    li:{
        listStyle:'none'
    }
}))

function Chat(){
    const [chatMessage, setChatMessage] = React.useState('');
    const [chatMessages, setChatMessages] = React.useState([]);
    const [now_user_id, setNowUserId] = React.useState('');
    const user_info=useSelector((state)=>(_.get(state, ['user', 'user'], {})));
    const user_type=useSelector((state)=>(_.get(state, ['user', 'user_type'], {})));
    const token=useSelector((state)=>(_.get(state, ['user', 'token'], {})));
    const dispatch = useDispatch();
    
    // const socket=io('http://localhost:3005')
   
    // socket.emit('who_are_you', {user_id:user_info.id});
    let history={
        user_id:15,
        // to_user_id:13,
        // from_user_id:user_info.id
    }
    

    useEffect(()=>{
        // dispatch(initSocket());
        if(_.get(user_info, null)!==null){
            console.log(token)
            // emit('socket_login', {user_id:user_info,user_type:user_type});
            emit('get_chat_relation_list',{token:'token '+token})
        }

    }, []);

    // useEffect(()=>{
    //     if(_.get(user_info, ['id'], null)!==null){
    //         emit('socket_login', {user_id:user_info.id});
    //         emit('get_chat_relation_list',{token:user_info.token})
    //     }

    // }, [user_info]);
    const relation_list=useSelector((state)=>(_.get(state, ['chat', 'chatRelationList'], [])));
    const chatHistory=useSelector((state)=>(_.get(state, ['chat', 'chatHistory'], {})));

    console.log(`now user id is ${now_user_id}`);
    console.log(JSON.stringify( _.get(chatHistory, [`${now_user_id}`], []).length))
    
    // 发送消息
    const chat_send_message=()=>{
        if(chatMessage!=''){
            let message={
                to_user_id:now_user_id,
                from_user_id:user_info,
                content:chatMessage,
                user_type:1
            }
            console.log('消息',message)
            emit('send_message', message);
            setChatMessage('')
        }else{

        }
        
    }
    const keyUp = (e) => {
        if (e.keyCode === 13) {
            //调用方法
        }
    };
    console.log(useSelector((state)=>{console.log(state)}))
    const input_message=(e)=>{
        setChatMessage(e.target.value);
    }
    const classs=useStyles()
    return (
        <div style={{background:'rgba(0,0,0,0.1)'}}>
            <Router>
                <div style={{display:'flex'}}>
                    <div style={{width:'20%',textAlign:'center',height:'100vh',background:'#fff'}}>
                        <ul>
                            {
                                relation_list.map((item,index) => 
                                    <li key={index} style={{background:now_user_id==item.other_user_id?'rgba(0,0,0,0.1)':'#fff',padding:'15px'}} onClick={()=>(emit('get_chat_history', {user_id:item.other_user_id,cs_id:user_info}),setChatMessage(''),setNowUserId(item.other_user_id))}>
                                        <div style={{display:'flex',justifyContent:'left',position:'relative'}}>
                                            <div>
                                                {item.avatar!=null?<img src={item.avatar} alt='' style={{width:'50px',height:'50px',borderRadius:'50%'}} />:
                                                <img src={'https://img2.baidu.com/it/u=3422481151,3897337691&fm=26&fmt=auto'} alt='' style={{width:'50px',height:'50px',borderRadius:'50%'}} />}
                                            </div>
                                            <div style={{paddingLeft:'10px',textAlign:'left',width:'calc(100% - 50px)'}}>
                                                <div style={{whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden',width:'80%'}}>{item.other_user_username}</div>
                                                <div style={{fontSize:12,whiteSpace: 'nowrap',textOverflow: 'ellipsis',overflow: 'hidden',width:'80%'}}>{item.content.content}</div>
                                            </div>
                                            {
                                                item.badge.toString()=='true'?<div style={{width:'10px',height:10,background:'red',borderRadius:'50%',position:'absolute',right:'5%',top:'calc(50% - 10px)'}}></div>:''
                                            }
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <div style={{width:'70%',padding:'0 5%'}}>
                        {/* 聊天内容版块 */}
                        <div style={{height:'80vh',overflowY:'auto'}}>
                            {
                                _.get(chatHistory, [`${now_user_id}`], []).length !== 0 ?
                                    _.get(chatHistory, [`${now_user_id}`], []).map((item,index)=>
                                        <div key={index} style={{margin:'10px 0'}}>
                                            
                                            {item.user_id==user_info?
                                                <div style={{display:'flex',justifyContent:'end'}}>
                                                    <div style={{lineHeight:'34px',marginLeft:'10px',borderBottomLeftRadius:'15px',borderBottomRightRadius:'15px',borderTopLeftRadius:'10px',fontSize:'14px',padding:'10px',marginRight:'10px',backgroundColor:'#43179D',color:'#fff'}} >{item.content}</div>
                                                    <div>
                                                        <img src={img_url} alt='' style={{width:'50px',height:'50px',borderRadius:'50%'}} />
                                                    </div>
                                                </div>
                                                :
                                                <div style={{display:'flex',justifyContent:'start'}}>
                                                    <div>
                                                        <img src={img_url} alt='' style={{width:'50px',height:'50px',borderRadius:'50%'}} />
                                                    </div>
                                                    <div style={{lineHeight:'34px',marginLeft:'10px',borderBottomLeftRadius:'15px',borderBottomRightRadius:'15px',borderTopRightRadius:'10px',fontSize:'14px',padding:'10px',marginLeft:'10px',background:'#fff'}}>{item.content}</div>
                                                </div>
                                            }
                                            
                                            
                                        </div>
                                    )
                                :<div style={{fontSize:'28px',color:'rgba(0,0,0,0.2)',textAlign:'center'}}>请选择用户开启聊天</div>
                            }
                        </div>
                        {/* 发送聊天 */}
                        {
                            JSON.stringify(chatHistory)!=='{}'?
                            <div style={{display:'flex',width:'100%',padding:'20px 0',borderTop:'1px solid rgba(0,0,0,0.4)'}}>
                                <div style={{width:'calc(70% - 20px)'}}>
                                    <textarea onChange={input_message} value={chatMessage} style={{width:'100%',height:'80px',padding:10,fontSize:'15px'}} />
                                </div>
                                <div style={{textAlign:'center',margin:'0 auto',width:'20%'}}>
                                    <div onClick={chat_send_message} style={{padding:'20px 10px',fontSize:'12px',background:'#43179D',color:'#fff',borderRadius:'5px',marginTop:'15px',width:'100px'}}>发送</div>
                                    {/* <Button>发送</Button> */}
                                </div>
                            </div>
                            :''
                        }
                        
                    </div>
                </div>
            </Router>
        </div>
    )
}


export default Chat

// export default class RealTimeChat extends React.Component {
//     state = {
//         chatMessage: "",
//         chatMessages: []
//     };

//     componentDidMount() {
//         // this.socket = io(MIDDLE_EARTH_URL);
//         // this.socket.on("chat message", msg => {
//         //     this.setState({ chatMessages: [...this.state.chatMessages, msg]
//         //     });
//         // });
//     }

//     submitChatMessage() {
//         this.socket.emit('chat message', this.state.chatMessage);
//         this.setState({chatMessage: ''});
//     }

//     render() {
//         const chatMessages = this.state.chatMessages.map(chatMessage => (
//             <Text style={{borderWidth: 2, top: 500}}>{chatMessage}</Text>
//         ));

//         return (
//             <View style={styles.container}>
//                 {chatMessages}
//                 <TextInput
//                     style={{height: 40, borderWidth: 2, top: 600}}
//                     autoCorrect={false}
//                     value={this.state.chatMessage}
//                     onSubmitEditing={() => this.submitChatMessage()}
//                     onChangeText={chatMessage => {
//                         this.setState({chatMessage});
//                     }}
//                 />
//             </View>
//         );
//     }
// }

// const styles = StyleSheet.create({
//     container: {
//         height: 400,
//         flex: 1,
//         backgroundColor: '#F5FCFF',
//     },
// });
