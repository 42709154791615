import React from 'react';
import { makeStyles } from '@mui/styles';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { useDispatch, useSelector } from 'react-redux';
import { close_drawer } from '../../../actions/forum'


const _ = require('micro-dash');
const useStyles = makeStyles((theme) => ({
    root: {
        paddingInline: '3.5%',
        width: '92%',
        minHeight: '100%',
        // borderLeft: '1px solid #E6E5EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderLeft: '8px solid #3D1198',
    },
    center: {
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    title: {
        fontSize: 19,

    },
    input: {
        width: '90%',
        border: '1px solid #666',
        paddingLeft: '10px'
    }
}));

export default function DrawerContent({ detailed_info}) {
    console.log('detailed_info', detailed_info)
    const classes = useStyles();
    const dispatch = useDispatch();

    if (detailed_info === null) {
        return (<div></div>)
    }
    return (
        <div className={classes.root}>
            <IconButton onClick={() => dispatch(close_drawer())} style={{ width: 39, height: 39, marginRight: 6 }}>
                <Close />
            </IconButton>
            <div style={{padding:'10px'}}>
                <div style={{ fontSize: '15px' }}>
                    <span>提问时间：</span>
                    <span>{detailed_info.create_time.split(' ')[0]}</span>
                </div>
                <div style={{  padding: '10px 0', lineHeight: '30px' ,paddingLeft:20}}>{detailed_info.content}</div>
            </div>
            <Divider style={{width:'100%'}} />
            {
                JSON.stringify(detailed_info.assessment_info)==='{}'?<div style={{textAlign:'center',width:'100%',padding:'5% 0'}}>评估中，请耐心等待！</div>:<div style={{width:'100%',padding:'10px'}}>
                <div style={{ width: '100%' }}>
                    <div style={{background:'rgba(0, 0, 0, 0.16)',color:'#fff',padding:'8px'}}>评估结果：</div>
                    <div style={{display:'flex',justifyContent: 'space-between',paddingLeft:20}}>
                        <div style={{lineHeight:'40px'}}>评估风险：{detailed_info.assessment_info.risk_level}级</div>
                        <div>
                            <Rating
                                name="simple-controlled"
                                value={detailed_info.assessment_info.risk_level}
                                icon={<LocalPoliceIcon fontSize="inherit" />}
                                emptyIcon={<LocalPoliceIcon fontSize="inherit" />}
                                style={{ paddingTop: '15px' }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{paddingLeft:20}}>
                    <div style={{ display: 'flex', padding: '5px 0', justifyContent: 'space-between' ,lineHeight:'40px'}}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50px', display: 'flex', alignItems: 'center' }}>
                                <img src={detailed_info.assessment_info.user_info.avatar} style={{ width: '50px' }} alt='' />
                            </div>
                            <div>{detailed_info.assessment_info.user_info.nick_name}</div>
                        </div>
                        <div style={{ padding: '5px 0' }}>评估时间：{detailed_info.assessment_info.create_time.split(' ')[0]}</div>
                    </div>
                    <div style={{ display: 'flex', width: '100%' ,padding:'20px 0'}}>{detailed_info.assessment_info.content}</div>
                </div>
            </div>
            }
        </div>
    )
};

// export default DrawerContent;