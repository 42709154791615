import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Chip from '@mui/material/Chip';
import WebAudioSpeechRecognizer from './app/webaudiospeechrecognizer'
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from "../../headHavigation/tabble_lists";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ai_img from '../../../static/image/ai_img.png'
import { MODEL_COLOR, MIDDLE_EARTH_URL, SOCKET_EARTH_URL, socket } from '../../../configs/constants'
import Regex from './testRegex'
import copy from 'copy-to-clipboard'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import HelpIcon from '@mui/icons-material/Help';
import { get_speech_token, send_socket_id, text_content_scribe, get_chunks_from } from '../../../actions/user'

import './app/speechrecognizer'
import './cryptojs'
// import './jquery'
const _ = require('micro-dash');
let { secretId, secretKey, appId } = {
    secretKey: '01ALUgy66wa5Mcgk4CB3nV5hFh1830sd',
    secretId: 'AKIDa9bzbAcx56vgO5tY7ZtJbhYHuV2c2JRU',
    appId: 1305959397,
}
const params = {
    // signCallback: signCallback, // 鉴权函数，若直接使用默认鉴权函数。可不传此参数
    // 用户参数
    secretid: secretId,
    secretkey: secretKey,
    appid: appId,
    // 临时密钥参数，非必填
    // token: config.token,
    // 实时识别接口参数
    engine_model_type: '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
    // 以下为非必填参数，可跟据业务自行修改
    // voice_format : 1,
    // hotword_id : '08003a00000000000000000000000000',
    // needvad: 1,
    // filter_dirty: 1,
    // filter_modal: 2,
    // filter_punc: 0,
    // convert_num_mode : 1,
    // word_info: 2
}
// const webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
let webAudioSpeechRecognizer;
const who_are_you = 'who_are_you',
    AGENT_RECEIVE_PROGRESSING_STATUS_AUDIO_SCRIBE = 'AGENT_RECEIVE_PROGRESSING_STATUS_AUDIO_SCRIBE',
    AGENT_RECEIVE_MESSAGE_IN_PROGRESS_AUDIO_SCRIBE = 'AGENT_RECEIVE_MESSAGE_IN_PROGRESS_AUDIO_SCRIBE',
    AGENT_RECEIVE_MESSAGE_ENDS_AUDIO_SCRIBE = 'AGENT_RECEIVE_MESSAGE_ENDS_AUDIO_SCRIBE';
function RecordingIndex() {
    // let webAudioSpeechRecognizer;
    let isCanStop;
    const dispatch = useDispatch();
    const divRef = useRef(null)
    const base_setting = useSelector((state) => state.user.base_setting);
    // const [webAudioSpeechRecognizer, setWebAudioSpeechRecognizer] = React.useState(null);
    const [recognizeText, setRecognizeText] = React.useState(''); //录音内容 腾讯云
    const [recognizeValue, setRecognizeValue] = React.useState('');//录音解析内容 socket返回
    const [recognizeType, setRecognizeType] = React.useState('');
    const [currentCursorContent, setCurrentCursorContent] = React.useState('');

    const [recognizeTextLists, setRecognizeTextLists] = React.useState({
        lists: [''],
        last_lists: [],
        last_type:0,
        recognize_index: 0
    });//录音内容lists
    const [recognize_index, setRecognizeIndex] = React.useState(0);
    const [forbid_click, setForbidClick] = React.useState(false);
    const [start_end, setStartEnd] = React.useState(0);
    const [add_theme, setAddTheme] = React.useState(false);
    const [channel_value, setChannelValue] = React.useState(''); //导入内容
    const [chunks_value, setChunksValue] = React.useState([]); //导入接口返回内容
    const secretid = useSelector((state) => state.user.secretid);
    const secretkey = useSelector((state) => state.user.secretkey);
    const secrettoken = useSelector((state) => state.user.secrettoken);
    const socket_id = useSelector((state) => state.user.socket_id);
    useEffect(() => {
        dispatch(get_speech_token())
    }, [])
    useEffect(() => {
        if (socket === null) {
            console.log('socket1')
        }
        if (socket) {
            console.log('socket')
            socket.emit(who_are_you, {})
            socket.on(who_are_you, (msg) => {
                console.log(msg)
                dispatch(send_socket_id(msg.socket_id))
            })
            socket.on(AGENT_RECEIVE_PROGRESSING_STATUS_AUDIO_SCRIBE, (msg) => {
                console.log(msg)
                setRecognizeType(msg.content)
                setCurrentCursorContent("")
                setRecognizeTextLists(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    console.log(tmp.lists)
                    if(tmp.last_type==0){
                        tmp.last_lists=[...tmp.lists]
                    }
                    tmp.last_type=1
                    tmp.lists[tmp.recognize_index] = ''
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_MESSAGE_IN_PROGRESS_AUDIO_SCRIBE, (msg) => {
                console.log(msg)
                setRecognizeValue(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp = tmp + msg.content
                    return tmp
                })
                setRecognizeTextLists(prev => {
                    console.log(prev)
                    // let index={...recognize_index}
                    // console.log(index)
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp.lists[tmp.recognize_index] = tmp.lists[tmp.recognize_index] + msg.content
                    return tmp
                })

            })
            socket.on(AGENT_RECEIVE_MESSAGE_ENDS_AUDIO_SCRIBE, (msg) => {
                console.log(msg)
                setRecognizeType('结束')
                setForbidClick(false)
                setRecognizeTextLists(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    // tmp[recognize_index] = tmp[recognize_index] + msg.content
                    if (tmp.lists[tmp.recognize_index].length > 200) {
                        let lists = []
                        console.log(lists)
                        tmp.lists.splice(tmp.recognize_index, 0, ...lists)
                        // setRecognizeIndex(recognize_index + 1)
                        tmp.recognize_index = tmp.recognize_index + 1
                    }
                    tmp.last_type=0
                    return tmp
                })
            })
        }
    }, [socket])
    const splitStringByLength = (str) => {
        const maxLength = 200;
        if (str.length > maxLength) {
            return [str.slice(0, maxLength), str.slice(maxLength)];
        } else {
            return [str];
        }
    }
    useEffect(() => {
        if (!_.isNil(secretid)) {
            let params = {
                // signCallback: signCallback, // 鉴权函数，若直接使用默认鉴权函数。可不传此参数
                // 用户参数
                secretid: secretid,
                secretkey: secretkey,
                appid: appId,
                // 临时密钥参数，非必填
                token: secrettoken,
                // 实时识别接口参数
                engine_model_type: '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
            }
            // setWebAudioSpeechRecognizer(new WebAudioSpeechRecognizer(params))
            // webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
        }
    }, [secretid])
    const start_recording = () => {
        webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
        let resultText = '';
        // console.log(webAudioSpeechRecognizer)
        // webAudioSpeechRecognizer.start();
        // setRecognizeType('建立连接中')
        // 开始识别
        webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
            console.log('开始识别', res);
            // setRecognizeType('开始识别')
        };
        // 一句话开始
        webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
            console.log('一句话开始', res);
            isCanStop = true;
            // setRecognizeType('一句话开始')
        };
        // 识别变化时
        webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
            console.log('识别变化时', res);
            let currentText = `${res.result.voice_text_str}`;
            setCurrentCursorContent(currentText)
            // setRecognizeTextLists(prev=>{
            //     let tmp=JSON.parse(JSON.stringify(prev))
            //     let currentText = ''
            //     // ${tmp.lists[tmp.recognize_index]}
            //     currentText = `${res.result.voice_text_str}`;
            //     console.log(tmp.recognize_index)
            //     console.log('currentText',tmp.lists[tmp.recognize_index])
            //     console.log(res.result.voice_text_str)
            //     tmp.lists[tmp.recognize_index] = currentText


            //     return tmp
            // })
        };
        // 一句话结束
        webAudioSpeechRecognizer.OnSentenceEnd = (res) => {
            console.log('一句话结束', res);
            let lists = [...recognizeTextLists.lists]
            let resultText = lists[recognizeTextLists.recognize_index]
            resultText += res.result.voice_text_str;
            console.log(lists)
            setRecognizeText(resultText)
            dispatch(text_content_scribe({ content: resultText, socket_id: socket_id }))
            setForbidClick(true)
            setRecognizeTextLists(prev => {
                let tmp = JSON.parse(JSON.stringify(prev))
                let currentText = ''
                // ${tmp.lists[tmp.recognize_index]}
                currentText = `${tmp.lists[tmp.recognize_index]}${res.result.voice_text_str}`;
                tmp.lists[tmp.recognize_index] = currentText
                return tmp
            })
            setCurrentCursorContent("")
            // setRecognizeType('一句话结束')
        };
        // 识别结束
        webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
            console.log('识别结束', res);
        };
        // 识别错误
        webAudioSpeechRecognizer.OnError = (res) => {
            console.log('识别失败', res)
            // setRecognizeType('识别失败')
        };
        console.log(webAudioSpeechRecognizer)
        webAudioSpeechRecognizer.start();
    }
    const end_recording = () => {
        // setRecognizeType('结束识别')
        // console.log(webAudioSpeechRecognizer)
        webAudioSpeechRecognizer.stop();
    }
    const recording_click = () => {
        setStartEnd(prev => {
            if (prev == 0) {
                start_recording()
                setRecognizeText('')
                setRecognizeValue('')
                setForbidClick(true)
                setRecognizeTextLists(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp.last_lists = tmp.lists
                    return tmp
                })
                return 1
            } else {
                end_recording()
                return 0
            }
        })
    }
    const cut_active = (e) => {
        console.log(e)
        // setRecognizeIndex(e)
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            setRecognizeTextLists((prev) => {
                return { ...prev, recognize_index: e }
            })
            // setCurrentCursorContent(recognizeTextLists?.lists?.[e])
        }
    }
    const handleKeyDown = (event) => {
        // 检查是否同时按下了Ctrl和l键
        if (event.ctrlKey && event.key === 'l') {
            event.preventDefault(); // 阻止默认行为，比如保存网页
            console.log('Ctrl + l pressed!');
            // 这里可以实现你想要执行的操作
            console.log(start_end)
            recording_click()
        }
    };

    useEffect(() => {
        // 组件挂载后添加事件监听器
        window.addEventListener('keydown', handleKeyDown);

        // 组件卸载前移除事件监听器
        return () => {
            console.log('卸载')
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []); // 空依赖数组表示这个effect只在组件挂载和卸载时运行
    const add_lists = (index) => {
        console.log(index)
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            setRecognizeTextLists((prev) => {
                let tmp = JSON.parse(JSON.stringify(prev))
                tmp.lists.splice(index + 1, 0, ...[''])
                tmp.recognize_index = index + 1
                return tmp
            })
        }

        // setRecognizeIndex(index + 1)
    }
    const copy_alone = (index) => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            copy(recognizeTextLists.lists[index])
            message.success('复制成功')
        }
    }
    const detaile_lists = (index) => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            let lists = [...recognizeTextLists.lists]
            if (lists.length > 1) {
                lists.splice(index, 1)
                setRecognizeTextLists(prev => {
                    return { ...prev, lists: lists }
                })
            } else {
                message.warning('请保留最少一个')
            }
        }
    }
    const ai_lists = (index) => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            let lists = [...recognizeTextLists.lists]
            // setRecognizeIndex(index)
            setRecognizeTextLists((prev) => {
                return { ...prev, recognize_index: index }
            })
            setRecognizeText(lists[index])
            setRecognizeValue('')
            dispatch(text_content_scribe({ content: lists[index], socket_id: socket_id }))
        }

    }
    const revocation_value = () => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            setRecognizeTextLists(prev => {
                let tmp = JSON.parse(JSON.stringify(prev))
                tmp.lists = tmp.last_lists
                return tmp
            })
        }
    }
    const to_channel = () => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            setAddTheme(true)
        }

    }
    const themeClose = () => {
        setAddTheme(false)
    }
    const add_theme_affirm = () => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            if (channel_value != '') {
                dispatch(get_chunks_from({ context: channel_value, chunk_size: 200 }, (v) => setChunksValue(v)))
            } else {
                message.error('导入内容不能为空')
            }
        }

    }
    useEffect(() => {
        if (chunks_value.length > 0) {
            message.success('导入成功')
            setChannelValue('')
            setAddTheme(false)
            // let lists=[...recognizeTextLists]
            // let lists_concat=lists.concat(chunks_value)
            setRecognizeTextLists(prev => {
                let tmp = JSON.parse(JSON.stringify(prev))
                tmp.last_lists=tmp.lists
                tmp.lists = tmp.lists.concat(chunks_value)
                return tmp
            })
            setChunksValue([])
        }
    }, [chunks_value])
    const copy_lists = () => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            let lists = [...recognizeTextLists.lists]
            let content = ''
            for (let i = 0; i < lists.length; i++) {
                content += lists[i]
            }
            copy(content)
            message.success('复制成功')
        }
    }
    const text_onclick = (e) => {
        console.log(e)
        setRecognizeTextLists(prev => {
            let tmp = JSON.parse(JSON.stringify(prev))
            tmp.lists[tmp.recognize_index] = e.target.value
            return tmp
        })
    }
    return (
        <div>
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 54px)' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', padding: '8px 0px', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minWidth: 220 }}>
                            <TabbleLists type='4'></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(67% - 220px)', height: 'calc(94vh - 62px)', overflowY: 'auto', padding: '3% 5%', position: 'relative' }}>
                    <div style={{ paddingBottom: 60 }}>
                        {
                            recognizeTextLists.lists.map((item, index) => <div>
                                <div style={{ paddingBottom: 20 }}>
                                    {
                                        recognizeTextLists.recognize_index == index ? <TextField
                                            id="outlined-multiline-static"
                                            label=""
                                            multiline
                                            variant={recognizeTextLists.recognize_index == index ? "outlined" : 'standard'}
                                            InputProps={{
                                                disableUnderline: recognizeTextLists.recognize_index == index ? false : true
                                            }}
                                            value={item + currentCursorContent}
                                            onClick={() => cut_active(index)}
                                            onChange={text_onclick}
                                            rows={(item + currentCursorContent).length < 200 ? 5 : ''}
                                            size="small"
                                            // onChange={url_Change}
                                            style={{ width: '100%', fontSize: '12px !important' }}
                                        /> : <div onClick={() => cut_active(index)} style={{ width: '100%', minHeight: 100, maxHeight: 200, overflowY: 'auto',background:'rgba(0,0,0,0.025)',padding:'2px 5px',borderRadius:6 }}>{item}</div>
                                    }
                                    {/* //或添加拜访记录 */}
                                    {/* <Divider style={{ padding: '10px 0' }}></Divider> */}
                                    {/* <div style={{ minHeight: 100, padding: 10, borderRadius: 10, background: 'rgba(72, 68, 243,0.1)' }}>{item}</div> */}
                                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <div style={{ display: 'flex', paddingTop: 10 }}>
                                            <div>
                                                <Tooltip title="新增">
                                                    <IconButton onClick={() => add_lists(index)} aria-label="copy" style={{ padding: 3, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 10 }}>
                                                        <AddIcon style={{ fontSize: 18 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div style={{ paddingLeft: 10 }}>
                                                <Tooltip title="复制">
                                                    <IconButton aria-label="copy" onClick={() => copy_alone(index)} style={{ padding: 4, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 10 }}>
                                                        <ContentCopyIcon style={{ fontSize: 17 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div style={{ paddingLeft: 10 }}>
                                                <Tooltip title="删除">
                                                    <IconButton aria-label="delet" onClick={() => detaile_lists(index)} style={{ padding: 2, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 10 }}>
                                                        <DeleteIcon style={{ fontSize: 18 }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                            <div style={{ paddingLeft: 10 }}>
                                                <Tooltip title="大模型处理">
                                                    <div onClick={() => ai_lists(index)} style={{ padding: 2, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 10, width: 18, height: 20, textAlign: 'center', cursor: 'pointer' }}>
                                                        <img src={ai_img} alt="" style={{ width: 15, height: 15 }}></img>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div style={{display:'flex',alignItems:'center'}}>
                                            <div style={{color:recognizeTextLists.recognize_index == index ? (item + currentCursorContent).length>200?'red':'' : item.length>200?'red':''}}>{recognizeTextLists.recognize_index == index ? (item + currentCursorContent).length : item.length}/200</div>
                                            <div>
                                            <Tooltip title="字数在200字内效果和AI修改速度最佳">
                                                <IconButton style={{color:'rgba(0,0,0,0.2)'}}>
                                                    <HelpIcon />
                                                </IconButton>
                                            </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Divider /> */}
                            </div>)
                        }

                    </div>
                    <div style={{ position: 'fixed', bottom: '8%', left: 'calc(50% - 220px)', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', padding: 10, borderRadius: 40, background: '#fff' }}>
                        <div style={{ display: 'flex', }}>
                            {
                                start_end == 0 ? <div onClick={recording_click} style={{ background: base_setting?.primary_color, width: 200, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                    <div style={{ color: '#fff', fontSize: 14, paddingLeft: 10 }}>点击开始/ctrl+L</div>
                                </div> : <div onClick={recording_click} style={{ background: base_setting?.primary_color, width: 200, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                    <IconButton aria-label="delet" style={{ padding: 2, borderRadius: 10 }}>
                                        <Spin indicator={<LoadingOutlined spin style={{ color: '#fff', fontSize: 20 }} />} />
                                    </IconButton>
                                    <div style={{ color: '#fff', fontSize: 14, paddingLeft: 10 }}>点击结束/ctrl+L</div>
                                </div>
                            }
                            <div style={{ paddingLeft: 20 }}>
                                <Tooltip title="撤回">
                                    <IconButton aria-label="revocation" onClick={revocation_value}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="导入">
                                    <IconButton aria-label="revocation" onClick={to_channel}>
                                        <PlaylistAddIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="复制">
                                    <IconButton aria-label="copy" onClick={copy_lists}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(40% - 220px)', height: 'calc(94vh - 62px)', overflowY: 'auto', padding: '2%', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', }}>
                    <div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 20 }}>录音抄录信息</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ paddingRight: 10,width:45 }}>状态:</div>
                        {
                            recognizeType != '' ? <Chip label={recognizeType} color="primary" style={{background:base_setting?.primary_color}} /> : ''
                        }

                    </div>
                    {
                        recognizeValue != '' ? <div>
                            <div style={{ padding: '20px 0' }}>
                                <div style={{ paddingBottom: 10 }}>原先内容：</div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label=""
                                    multiline
                                    variant='standard'
                                    disabled
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    value={recognizeText}
                                    rows={5}
                                    size="small"
                                    // onChange={url_Change}
                                    style={{ width: '100%', fontSize: '12px !important' }}
                                />
                            </div>
                            <Divider></Divider>
                            <div style={{ paddingTop: 10 }}>
                                <div style={{ paddingBottom: 10 }}>AI解析内容：</div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label=""
                                    multiline
                                    variant='standard'
                                    disabled
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    value={recognizeValue}
                                    rows={5}
                                    size="small"
                                    // onChange={url_Change}
                                    style={{ width: '100%', fontSize: '12px !important' }}
                                />
                            </div>
                        </div> : ''
                    }

                </div>
            </div>
            <Dialog onClose={themeClose} open={add_theme} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>导入内容</DialogTitle>
                <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px' }}>
                    <div style={{ display: 'flex', padding: '30px 0' }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="内容"
                            multiline
                            variant="outlined"
                            value={channel_value}
                            rows={8}
                            size="small"
                            onChange={(e) => setChannelValue(e.target.value)}
                            inputProps={{ style: { fontSize: 13 } }}
                            InputLabelProps={{ style: { fontSize: 13 } }}
                            style={{ width: '100%', fontSize: 15 }}
                        />
                    </div>
                    <div style={{ paddingVertical: '5%' }}>
                        <Button onClick={add_theme_affirm} variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color, fontSize: 18, height: 50, color: '#fff', lineHeight: '30px' }}>导入</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
export default RecordingIndex;