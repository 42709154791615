import {
    SHUI_UNREAD_NUM,
    SHUI_CLEAR_NUM,
    SHUI_INFORM_LIST,
} from './types';
import {MIDDLE_EARTH_URL, TAX_NAME_MAP, INDUSTRY_NAME_MAP} from '../configs/constants'
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function shui_unread_num(num){
    return {
        type:SHUI_UNREAD_NUM,
        num
    }
}
export function shui_clear_num(){
    return {
        type:SHUI_CLEAR_NUM
    }
}
export function shui_inform_list(lists){
    return {
        type:SHUI_INFORM_LIST,
        lists
    }
}

// 税-标记已读
export function shui_notification_mark_read({notification_id}){
    console.log('notification_id',notification_id)
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/notification/notification-mark-read`,{
            notification_id
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('shui_notification_mark_read',response)
            }
        )
    }
}
//税-未读数清零
export function shui_clear_read_count(){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/notification/clear-read-count`,{

        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('shui_clear_read_count',response)
                if(response.data.code===200){
                    dispatch(shui_clear_num())
                }
            }
        )
    }
}
//税-通知列表
export function shui_notification_list(cursor){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/notification/notification-list`,{

        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('shui_notification_list',response)
                dispatch(shui_inform_list(response.data.data.notification_page))
            }
        )
    }
}
//税-未读数
export function shui_get_unread_count(){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/notification/get-unread-count`,{

        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('shui_get_unread_count',response)
                dispatch(shui_unread_num(response.data.data.unread_num))
            }
        )
    }
}
