import React, {useEffect,useCallback} from "react";
import {useDispatch,useSelector} from "react-redux";
import {WECHAT_LOGIN_URL} from '../../../configs/constants'
import { Link, useLocation } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Countdown from 'react-countdown';
import Drawer from '@mui/material/Drawer';
import DrawerContent from './DrawerContent';
import ResultList from './ResultList';
import HeadHavigation from "../../headHavigation/headHavigation";
import Footer from "../../footer/Footer";
import {all_scene_lists,close_drawer,home_data} from '../../../actions/search'
const _ = require('micro-dash');

function Scene_lists(){
    const query = new URLSearchParams(useLocation().search);
    const name = query.get("name");
    console.log('name',name)
    const dispatch = useDispatch();
    const all_category_data=useSelector((state) => state.search.all_category_data);
    // const lists_data=all_category_data[name]

    const result_list = useSelector((state) => state.search.search_result_list);
    const drawer_open = useSelector((state) => state.search.drawer_open);
    const detailed_info = useSelector((state) => state.search.hit);
    const data=useSelector((state) => state.search)

    useEffect(() => {
        console.log('data',data)
        if (!_.isNil(name)) {
            if(!_.isNil(all_category_data[name])){
                if(all_category_data[name].length>0){
                    let id_lists=[]
                    for(let i=0;i<all_category_data[name].length;i++){
                        id_lists.push(all_category_data[name][i].id)
                    }
                    dispatch(all_scene_lists(id_lists))
                }
            }
        };   
    }, []);
    return (
        <div>
            <HeadHavigation provenance={0} />
            <div style={{padding:'0 12%',marginTop:39,minHeight:'calc(100vh - 240px)'}}>
                <ResultList
                    search_result_list={result_list}
                />
                <Drawer
                    anchor={'right'}
                    open={drawer_open}
                    onClose={() => dispatch(close_drawer())}
                >
                    <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                        <DrawerContent
                            hit={detailed_info}
                        />
                    </div>
                </Drawer>
            </div>
            <div style={{padding:'20px 12%'}}>
                <Footer />
            </div>
        </div>
        
    )
}
export default Scene_lists;