import React, {useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {useDispatch, useSelector} from 'react-redux';
import {close_drawer, es_content_like, es_title_like} from '../../actions/forum'
import {open_hints} from '../../actions/user';
import {question_likes,answer_list,answer_likes,create_answer} from '../../actions/forum'

import cancel_praise from '../../static/icon/cancel_praise.png'
import top_praise from '../../static/icon/top_praise.png'

const _ = require('micro-dash');
const useStyles = makeStyles((theme) => ({
    root: {
        paddingInline: '3.5%',
        width: '92%',
        minHeight: '100%',
        // borderLeft: '1px solid #E6E5EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderLeft: '8px solid #3D1198',
    },
    center:{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    title: {
        fontSize: 19,

    },
    input:{
        width:'90%',
        border:'1px solid #666',
        paddingLeft:'10px'
    }
}));

export default function DrawerContent({detailed_info,answers_list,from}) {
    // console.log('detailed_info',detailed_info,answers_list)
    const classes = useStyles();
    const dispatch = useDispatch();
    const token=useSelector((state) => state.user.token);
    const [sort_type, setSortType] = React.useState(1);
    const [q_like, setQlike] = React.useState(false);
    const [a_like, setAlike] = React.useState(false);
    const [remarkValue, setremarkValue] = React.useState('');
    const is_contain_key = useSelector((state) => state.forum.contain_key);
    const get_release_success = useSelector((state) => state.forum.release_success)

    useEffect(() => {
    if (get_release_success) {
        setState(true)
    }
}, [dispatch, get_release_success]);

    // 评论
    const handleSetremark=()=>{
        if(remarkValue.length>4&&detailed_info.is_official===false){
            // setState(true)
            setremarkValue('')
            dispatch(create_answer({from:from,question_id:detailed_info.id,answer_content:remarkValue,sort_type:sort_type}))
        }else if (remarkValue.length>4&&detailed_info.is_official===true){
            alert('无法评论官方问答')
        }
        else{
            alert('请输入字数不小于5的评论')
        }
    }
    const _handleKeyDown=(e)=>{
        if (e.key === 'Enter') {
            if(remarkValue.length>4&&detailed_info.is_official===false){
                // setState(true)
                setremarkValue('')
                dispatch(create_answer({from:from,question_id:detailed_info.id,answer_content:remarkValue,sort_type:sort_type}))
            }else if (remarkValue.length>4&&detailed_info.is_official===true){
                alert('无法评论官方问答')
            }
            else{
                e.preventDefault();
                alert('请输入字数不小于5的评论')
            }
        }
    }
    //点赞与取消
    const is_like=()=>{
        console.log('from')
        if (detailed_info.is_official===false){
            dispatch(question_likes({from:from,id:detailed_info.id,like:detailed_info.is_like}))
        }else{
            setQlike(!q_like)
            dispatch(es_title_like({ id: detailed_info.id, like: 0, index: 0 ,counts:detailed_info.number_of_likes}))
        }
    }
    const sort=(e)=>{
        setSortType(e)
        dispatch(answer_list({sort_type:e,id:detailed_info.id,from:from}))
    }
    const answer_like=(e)=>{
        if (typeof(e.is_official)==="undefined"){
            dispatch(answer_likes({id:e.id,sort_type:e.is_like,index:e.index,from:from}))
        }else{
            setAlike(!a_like)
            dispatch(es_content_like({ id: e.id, like: 0, index: e.index ,counts:e.count}))
        }

    }
    const [remind_suggest, setState] = React.useState(false);
    const vertical='top'
    const horizontal='center'
    const remindhandleClose = () => {
        setState(false)
    };
    if(detailed_info===''&&answers_list.length===0){
        return (<div></div>)
    }
    return (
        <div className={classes.root}>
            <IconButton onClick={()=>dispatch(close_drawer())} style={{width: 39, height:39, marginRight: 6}}>
                <Close/>
            </IconButton>
            <div style={{display:'flex',width:'100%',paddingTop:'5%',justifyContent:'space-between'}}>
                <div style={{display:'flex',width:'80%'}}>
                    <div>
                        {detailed_info.user_info.avatar!=null?<img src={detailed_info.user_info.avatar} style={{width:60}} alt=''/>:<img src='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png' style={{width:60}} alt=''/>}

                    </div>
                    {detailed_info.user_info.nick_name!=null?<div style={{lineHeight:'60px',paddingLeft:'10px'}}>{detailed_info.user_info.nick_name}</div>:<div style={{lineHeight:'60px',paddingLeft:'10px'}}>用户0{detailed_info.user_info.user_id}</div>}

                </div>
                <div style={{display:'flex',width:'20%',lineHeight:'80px',cursor: "pointer",justifyContent: 'end'}} onClick={is_like}>
                    <div style={{paddingTop:'8px'}}>
                        {
                            detailed_info.is_like||q_like===true?<img src={top_praise} style={{width:30}} alt=''/>:<img src={cancel_praise} style={{width:30}} alt=''/>
                        }
                    </div>
                    <div style={{paddingLeft:'5%',color:detailed_info.is_like||q_like===true?'#43179D':''}}>
                        <span>赞</span>
                        <span>{q_like===true? detailed_info.number_of_likes+1:detailed_info.number_of_likes}</span>
                    </div>
                </div>
            </div>
            <div style={{fontSize:'15px'}}>
                <span>提问时间：</span>
                <span>{detailed_info.create_time.split(' ')[0]}</span>
            </div>
            <div style={{fontSize:'18px',padding:'10px 0',lineHeight:'30px'}}>{detailed_info.question}</div>
            <div style={{display:'flex',width:'100%',padding:'15px 0',justifyContent:'space-between'}}>
                <div>
                    <span style={{paddingRight:"5px"}}>评论</span>
                    <span>{detailed_info.number_of_answers}</span>
                </div>
                {detailed_info.is_official===true?null:<div >
                    <span style={{cursor: "pointer",color:sort_type===1?'#43179D':''}} onClick={()=>sort(1)}>最新</span>
                    <span style={{padding:'0 5px'}}>|</span>
                    <span style={{cursor: "pointer",color:sort_type===2?'#43179D':''}} onClick={()=>sort(2)}>最热</span>
                </div>}
            </div>
            <div style={{width:'100%',display:'flex',justifyContent:'space-between'}}>
                <InputBase
                    className={classes.input}
                    placeholder="请输入字数不小于5的评论"
                    value={remarkValue}
                    onChange={(event)=>setremarkValue(event.target.value)}
                    onKeyDown={_handleKeyDown}
                />
                {/* <input value='' placeholder='请输入字数不小于5的评论' style={{width:'80%',paddingLeft:10}} /> */}
                <Button variant="contained" style={{background:'#43179D',marginLeft:10}} onClick={handleSetremark}>发布</Button>
            </div>
            <div style={{width:'100%',paddingTop:'5%'}}>
                {
                    (()=>{
                        if(answers_list.length>0){
                            return answers_list.map((item,index)=>
                            <div style={{display:'flex',borderBottom:'1px solid rgba(0,0,0,0.1)',padding:'5px 0'}}>
                                <div style={{width:'50px',display:'flex',alignItems:'flex-start '}}>
                                    <img src={item.user.avatar.file_name} style={{width:'50px'}} alt=''/>
                                </div>
                                <div style={{width:'calc(100% - 50px)',paddingLeft:'10px'}}>
                                    <div>{item.user.nick_name}</div>
                                    <div style={{padding:'5px 0'}}>{item.answer}</div>
                                    <div style={{display:'flex',width:'100%'}}>
                                        <div>{item.create_time.split('T')[0]}</div>
                                        <div style={{display:'flex',marginLeft:'auto',cursor: "pointer"}} onClick={()=>answer_like({id:item.id,is_like:item.is_like,index:index,count:item.number_of_likes,is_official:item.is_official})}>
                                            <div>
                                                {
                                                    item.is_like||a_like===true?<img src={top_praise} style={{width:'30px'}} alt=''/>:<img src={cancel_praise} style={{width:'30px'}} alt=''/>
                                                }

                                            </div>
                                            <div style={{color:item.is_like||a_like===true?'#43179D':"",display:'flex',alignItems:'center',flexDirection: 'row',paddingLeft:5}}>赞{a_like===true?item.number_of_likes+1:item.number_of_likes}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        }else{
                            return <div style={{textAlign:'center'}}>暂无评论</div>
                        }
                    })()

                }
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                severity="info"
                autoHideDuration={3500}
                key={vertical + horizontal}
            >
                {is_contain_key===false?<Alert severity="info"> 评论成功</Alert>:<Alert severity="info"> 您输入的内容包含敏感词汇</Alert>}
            </Snackbar>
        </div>
    )
};

// export default DrawerContent;