import React, {useEffect} from "react";
import kuai_qe_code from '../../../static/image/kuai_engine.jpg'
import CHU from '../../../static/icon/first.png'
import ZHONG from '../../../static/icon/middle.png'
import GAO from '../../../static/icon/tall.png'
import CPA from '../../../static/icon/CPA.png'
import ten_icon from '../../../static/icon/10.png'


function KuaiIntro(e){
    console.log('ShuiDetails',e)
    const source=e.source
    return (
        <div>
            <div>
                <h2 style={{paddingLeft:40}}>Kuai引擎功能</h2>
                <div style={{padding:40,display:'flex'}}>
                    <div style={{width:'50%',paddingRight:'5%'}}>
                        <div style={{ padding: 10 }}>
                            &bull;Kuai引擎为用户提供会计实操搜索知识库，会计考试题库搜索功能。
                        </div>
                        <div style={{ padding: 10 }}>
                            &bull;考试题库包括初级会计职称考试， 中级会计职称考试， 高级会计职称考试以及CPA。
                        </div>
                        <div style={{ padding: 10 }}>
                            &bull;免费使用搜索功能。
                        </div>
                    </div>
                    <div style={{width:'50%'}}>
                        <div style={{display:'flex',justifyContent:'space-evenly'}}>
                            <div style={{textAlign:'center',marginBottom:'30px'}}>
                                <div>
                                    <img src={CHU} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>初级</div>
                            </div>
                            <div style={{textAlign:'center',marginBottom:'30px'}}>
                                <div style={{textAlign:'center'}}>
                                    <img src={ZHONG} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>中级</div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexWrap: 'wrap',paddingTop:40}}>
                            <div style={{width:'33.33%',textAlign:'center',marginBottom:'30px'}}>
                                <div>
                                    <img src={GAO} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>高级</div>
                            </div>
                            <div style={{width:'33.33%',textAlign:'center',marginBottom:'30px'}}>
                                <div style={{textAlign:'center'}}>
                                    <img src={CPA} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>CPA</div>
                            </div>
                            <div style={{width:'33.33%',textAlign:'center',marginBottom:'30px'}}>
                                <div>
                                    <img src={CPA} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>注税</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div>*/}
            {/*    <h2 style={{paddingLeft:40}}>价格</h2>*/}
            {/*    <div style={{display:'flex',padding:40}}>*/}
            {/*        <div style={{width:'50%',display:'flex',paddingRight:'5%'}}>*/}
            {/*            <div style={{padding:'20px',width:'33.33%',textAlign:'center'}}>*/}
            {/*                <div>*/}
            {/*                    {*/}
            {/*                        source===0?<img src={shui_month} alt='' style={{width:50,height:50}} />:<img src={kuai_month} alt='' style={{width:50,height:50}} />*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*                <div style={{paddingTop:20}}>*/}
            {/*                    <div>月度会员</div>*/}
            {/*                    <div>￥1</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div style={{padding:'20px',width:'33.33%',textAlign:'center'}}>*/}
            {/*                <div>*/}
            {/*                    {*/}
            {/*                        source===0?<img src={shui_season} alt='' style={{width:50,height:50}} />:<img src={kuai_season} alt='' style={{width:50,height:50}} />*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*                <div style={{paddingTop:20}}>*/}
            {/*                    <div>季度会员</div>*/}
            {/*                    <div>￥3</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div style={{padding:'20px',width:'33.33%',textAlign:'center'}}>*/}
            {/*                <div>*/}
            {/*                    {*/}
            {/*                       source===0?<img src={shui_year} alt='' style={{width:50,height:50}} />:<img src={kuai_year} alt='' style={{width:50,height:50}} />*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*                <div style={{paddingTop:20}}>*/}
            {/*                    <div>年度会员</div>*/}
            {/*                    <div>￥8</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div style={{width:'50%'}}>*/}
            {/*            <div style={{ padding: 10 }}>*/}
            {/*                &bull;月度会员（￥1）可在有效期内无限次使用小程序与网站所有功能*/}
            {/*            </div>*/}
            {/*            <div style={{ padding: 10 }}>*/}
            {/*                &bull;季度会员（￥3）可在有效期内无限次使用小程序与网站所有功能*/}
            {/*            </div>*/}
            {/*            <div style={{ padding: 10 }}>*/}
            {/*                &bull;年度会员（￥8）可在有效期内无限次使用小程序与网站所有功能*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div>
                <h2 style={{paddingLeft:40}}>产品</h2>
                <div style={{display:'flex',padding:40}}>
                    <div style={{width:'50%'}}>
                        <div style={{padding:'20px 0'}}>&bull;PC端-Kuai引擎网站</div>
                        <div>&bull;移动端-Kuai引擎微信小程序（扫描右侧二维码）</div>
                    </div>
                    <div style={{width:'50%'}}>
                        <img src={kuai_qe_code} style={{width:'150px'}} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default KuaiIntro;