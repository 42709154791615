
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { HexColorPicker } from "react-colorful";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import { upload_company_logo, update_outsider_settings, get_outsider_settings } from '../../../actions/user'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
}));



const _ = require('micro-dash');
function SettingBlock() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const token = useSelector((state) => state.user.token);
    const uploadFileInput = React.useRef();
    const history = useHistory()
    const tab_settings = useSelector((state) => state.user.tab_settings);
    const other_menus = useSelector((state) => state.user.other_menus);
    const agent_menus = useSelector((state) => state.user.agent_menus);
    const base_setting = useSelector((state) => state.user.base_setting);
    const [agent_menus_lists, setAgentMenusLists] = React.useState([]);
    const [other_menus_lists, setOtherMenusLists] = React.useState([]);
    const [agent_detele_dialog, setAgentDeteleDialog] = React.useState(false);
    const [other_detele_dialog, setOtherDeteleDialog] = React.useState(false);
    const [agent_ref_lists, setAgentRefLists] = React.useState([]);
    const [other_ref_lists, setOtherRefLists] = React.useState([]);
    useEffect(() => {
        dispatch(get_outsider_settings())
    }, [])
    useEffect(() => {
        setCompanyName(base_setting?.brand_name)
        setLogoUrl(base_setting?.logo_url)
        setSelectedColor(base_setting?.primary_color)
    }, [base_setting])
    useEffect(() => {
        let arr = []
        for (let i = 0; i < agent_menus?.length; i++) {
            arr.push(React.createRef())
        }
        setAgentRefLists(arr)
        setAgentMenusLists(agent_menus)
    }, [agent_menus])
    useEffect(() => {
        let arr = []
        for (let i = 0; i < agent_menus?.length; i++) {
            arr.push(React.createRef())
        }
        setOtherRefLists(arr)
        setOtherMenusLists(other_menus)
    }, [other_menus])
    useEffect(() => {
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    }, [token])
    const handleUploadButtonClick = () => {
        uploadFileInput.current.click();
    }
    const [logo_url, setLogoUrl] = React.useState('https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/share_img.png');
    const handleFileChange = (event) => {
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            const formData = new FormData();
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(upload_company_logo(formData, (v) => setLogoUrl(v), update_settings_logo))

        }
    }
    const update_settings_logo = (e) => {
        console.log(e)
        let data = {
            settings: {
                logo_url: e,
            }
        }
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const agentUploadButtonClick = (e) => {
        agent_ref_lists[e].current.click();
    }
    const [agent_index, setAgentIndex] = React.useState(0);
    const [agent_url, setAgentUrl] = React.useState('');
    const agenthandleFileChange = (event, index) => {
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            const formData = new FormData();
            setAgentIndex(index)
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(upload_company_logo(formData, (v) => setAgentUrl(v)))
        }
    }
    useEffect(() => {
        if (agent_url != '') {
            let agent_lists = [...agent_menus_lists]
            agent_lists[agent_index].img = agent_url
            setAgentMenusLists(agent_lists)
            let data = {
                settings: {
                    agent_menus: agent_lists
                }
            }
            dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
        }
    }, [agent_url])
    const agent_amend_name = (event, index) => {
        let agent_lists = [...agent_menus_lists]
        agent_lists[index].name = event.target.value
        setAgentMenusLists(agent_lists)
    }
    const agent_company = (index) => {
        let agent_lists = [...agent_menus_lists]
        for (let i = 0; i < agent_lists?.length; i++) {
            if (agent_lists[i].name?.length < 2) {
                message.error('列表名称不能为空')
            }
        }
        let data = {
            settings: {
                agent_menus: agent_lists
            }
        }
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const [other_index, setOtherIndex] = React.useState(0);
    const [other_url, setOtherUrl] = React.useState('');
    const otherhandleUploadButtonClick = (e) => {
        other_ref_lists[e].current.click();
    }
    const otherhandleFileChange = (event, index) => {
        console.log(index)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            const formData = new FormData();
            setOtherIndex(index)
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(upload_company_logo(formData, (v) => setOtherUrl(v)))
        }
    }
    useEffect(() => {
        if (other_url != '') {
            let other_lists = [...other_menus_lists]
            other_lists[other_index].img = other_url
            setOtherMenusLists(other_lists)
            let data = {
                settings: {
                    other_menus: other_lists
                }
            }
            dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
        }
    }, [other_url])
    const other_amend_name = (event, index) => {
        let other_lists = [...other_menus_lists]
        other_lists[index].name = event.target.value
        setOtherMenusLists(other_lists)
    }
    const other_company = (index) => {
        let other_lists = [...other_menus_lists]
        for (let i = 0; i < other_lists?.length; i++) {
            if (other_lists[i].name?.length < 2) {
                message.error('列表名称不能为空')
            }
        }
        let data = {
            settings: {
                other_menus: other_lists
            }
        }
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }

    const [company_name, setCompanyName] = React.useState('')
    const [selectedColor, setSelectedColor] = React.useState('');
    const handleColorChange = (color) => {
        setSelectedColor(color);
    };
    const handleValueChange = (e) => {
        setSelectedColor(e.target.value);
    }
    const amend_name = (e) => {
        setCompanyName(e.target.value)
    }
    const [message_value, setMessageValue] = React.useState(false)
    const company_message = () => {
        if (company_name != '') {
            let data = {
                settings: {
                    brand_name: company_name
                }
            }
            dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
        }
    }
    useEffect(() => {
        if (message_value) {
            message.success('设置成功')
            setMessageValue(false)
        }
    }, [message_value])
    const company_color = () => {
        let data = {
            settings: {
                primary_color: selectedColor
            }
        }
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const agenthandleClose = () => {
        setAgentDeteleDialog(false)
    }
    const [agent_detele_index, setAgentDeteleIndex] = React.useState(0);
    const agent_detele = (e) => {
        setAgentDeteleDialog(true)
        setAgentDeteleIndex(e)
    }
    const agent_detele_amend = () => {
        let agent_lists = [...agent_menus_lists]
        agent_lists.splice(agent_detele_index, 1)
        console.log(agent_lists, agent_detele_index)
        let data = {
            settings: {
                agent_menus: agent_lists
            }
        }
        setAgentDeteleDialog(false)
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const agent_top = (e) => {
        let agent_lists = [...agent_menus_lists]
        agent_lists.forEach((item, index) => {
            if (item === agent_lists[e]) {
                agent_lists.unshift(agent_lists.splice(index, 1)[0])
            }
        })
        let data = {
            settings: {
                agent_menus: agent_lists
            }
        }
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const [other_detele_index, setOtherDeteleIndex] = React.useState(0);
    const other_delete = (e) => {
        setOtherDeteleDialog(true)
        setOtherDeteleIndex(e)
    }
    const otherhandleClose = () => {
        setOtherDeteleDialog(false)
    }
    const other_detele_amend = () => {
        let other_lists = [...other_menus_lists]
        other_lists.splice(other_detele_index, 1)
        console.log(other_lists, other_detele_index)
        let data = {
            settings: {
                other_menus: other_lists
            }
        }
        setOtherDeteleDialog(false)
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const other_top = (e) => {
        let other_lists = [...other_menus_lists]
        other_lists.forEach((item, index) => {
            if (item === other_lists[e]) {
                other_lists.unshift(other_lists.splice(index, 1)[0])
            }
        })
        let data = {
            settings: {
                other_menus: other_lists
            }
        }
        dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
    }
    const [add_other, setAddOther] = React.useState(null);
    const addOtherChange = (event) => {
        setAddOther(event.target.value)
    }
    const other_json = [
        {
            name: '知识库',
            url: '/largemodel/knowledge_base',
            img: '/images/library.png',
            user_level: 1,
            type: 0,
            location: 0
        },
        {
            name: '定时AI任务',
            url: '/largemodel/monitoring',
            img: '/images/monitoring.png',
            user_level: 1,
            type: 0,
            location: 0
        },
        {
            name: '智能体',
            url: '/largemodel/intelligent_agent',
            img: '/images/intelligent.png',
            user_level: 1,
            type: 0,
            location: 0
        },
        {
            name:'听潮',
            url:'/largemodel/enlightenment_lists',
            img:'/images/recording_img.png',
            user_level:1,
            type:0,
            location:0
        }
    ]
    const setting_other = () => {
        console.log(add_other, other_menus_lists)
        if (add_other != null) {
            let is_other = true
            for (let i = 0; i < other_menus_lists?.length; i++) {
                if (other_json[add_other].url == other_menus_lists[i].url) {
                    is_other = false
                    message.error('以添加过该功能列表，请勿重复添加')
                }
            }
            if (is_other) {
                let lists = [...other_menus_lists]
                lists.push(other_json[add_other])
                let data = {
                    settings: {
                        other_menus: lists
                    }
                }
                dispatch(update_outsider_settings(data, (v) => setMessageValue(v)))
            }
        } else {
            message.error('不能添加空列表')
        }

    }
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff',paddingTop: 8, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', overflow: 'auto', minWidth: 220, height: 'calc(100vh - 54px)' }}>
                            <TabbleLists type='6'></TabbleLists>
                        </div>
                        <div style={{ padding: '5%', paddingTop: '2%', width: '100%', height: 'calc(90vh - 54px)', overflowY: 'auto' }}>
                            <div style={{ paddingBottom: 10 }}>
                                <div style={{fontSize:12,paddingBottom:20}}>对内大模型产品设置页面</div>
                                <div style={{ fontWeight: 'bold' }}>公司基本信息</div>
                                <div style={{ paddingTop: 20 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div>
                                            <div style={{ fontSize: 18 }}>公司logo：</div>
                                        </div>
                                        <div onClick={handleUploadButtonClick} style={{ width: 50, height: 50, borderRadius: '50%', border: '1px dashed #666', padding: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            <img src={logo_url} alt='' style={{ width: '100%', height: '100%' }}></img>
                                        </div>
                                        <input type="file" accept='.png,.jpg' ref={uploadFileInput} onChange={handleFileChange} />
                                    </div>
                                    <div style={{ padding: '20px 0', display: 'flex', alignItems: 'center' }}>
                                        <div style={{ fontSize: 18 }}>公司名称：</div>
                                        <div>
                                            <TextField id="outlined-basic" label="名称" variant="outlined" value={company_name} onChange={amend_name} />
                                        </div>
                                        <div style={{ width: 100, marginLeft: 20 }}>
                                            <Button variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color }} onClick={company_message}>确认</Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div style={{ padding: '20px 0' }}>
                                <div style={{ fontWeight: 'bold' }}>主题色设置</div>
                                <div style={{ paddingTop: 20, }}>
                                    {/* <div style={{ paddingRight: 10 }}>
                                        <Button variant="contained" size="large">颜色选择</Button>
                                    </div> */}
                                    <HexColorPicker color={selectedColor} onChange={handleColorChange} />

                                    <div style={{ display: 'flex', paddingTop: 40, alignItems: 'center' }}>
                                        <div style={{ marginRight: 10 }}>
                                            <TextField id="outlined-basic" label="颜色" variant="outlined" value={selectedColor} onChange={handleValueChange} style={{ width: 120 }} />
                                        </div>
                                        <div style={{ backgroundColor: selectedColor, width: 100, borderRadius: 8, height: 50 }}>

                                        </div>
                                        <div style={{ marginLeft: 10 }}>
                                            <Button variant="contained" size="large" style={{ background: base_setting?.primary_color }} onClick={company_color}>确认</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: '20px 0' }}>
                                <div>
                                    <div>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
                                            <div style={{ fontWeight: 'bold' }}>智能体列表</div>
                                            <Link to='/largemodel/agent_share'>
                                                <div style={{ paddingLeft: 5, display: 'flex', alignItems: 'center' }}>
                                                    <AddIcon></AddIcon>
                                                </div>
                                            </Link>
                                        </div>
                                        <div style={{ paddingRight: 10, display: 'flex', width: '100%', flexWrap: 'wrap', alignItems: 'center' }}>
                                            {
                                                agent_menus_lists.map((item, index) => <div style={{ display: 'flex', alignItems: 'center', width: '60%', minWidth: 850, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', padding: '10px 15px', borderRadius: 8, marginRight: '20px', marginBottom: 20 }}>
                                                    <div style={{ paddingRight: 20 }}>
                                                        <div onClick={() => agentUploadButtonClick(index)} style={{ width: 40, height: 40, borderRadius: '50%', border: '1px dashed #666', padding: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                            <img src={item.img} alt='' style={{ width: '100%', height: '100%' }}></img>
                                                        </div>
                                                        <input type="file" accept='.png,.jpg' ref={agent_ref_lists[index]} onChange={(event) => agenthandleFileChange(event, index)} />
                                                    </div>
                                                    <div style={{ padding: '0 20px', }}>
                                                        id:{item.id}
                                                    </div>
                                                    <div>
                                                        <TextField id="outlined-basic" label="名称" variant="outlined" size="small" value={item.name} onChange={(event) => agent_amend_name(event, index)} />
                                                    </div>
                                                    <div style={{ paddingLeft: 20 }}>
                                                        <Button variant="contained" size="large" style={{ background: base_setting?.primary_color }} onClick={() => agent_company(index)}>修改</Button>
                                                    </div>
                                                    <div style={{ paddingLeft: 20 }}>
                                                        <Button variant="contained" size="large" style={{ background: base_setting?.primary_color }} onClick={() => agent_detele(index)}>删除</Button>
                                                    </div>
                                                    <div style={{ paddingLeft: 20 }}>
                                                        <Button variant="contained" size="large" style={{ background: base_setting?.primary_color }} onClick={() => agent_top(index)}>置顶</Button>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                        <div style={{ padding: '15px 0' }}>
                                            <Link to='/largemodel/agent_share'>
                                                <Button variant="contained" size="large" style={{ background: base_setting?.primary_color }}>添加</Button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ fontWeight: 'bold', padding: '20px 0' }}>主功能列表</div>
                                        <div style={{ paddingRight: 10, display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                                            {
                                                other_menus_lists.map((item, index) => <div key={'other' + index} style={{ display: 'flex', alignItems: 'center', width: '40%', minWidth: 600, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', padding: '10px 15px', borderRadius: 8, marginRight: '5%', marginBottom: 20 }}>
                                                    <div style={{ paddingRight: 20 }}>
                                                        <div onClick={item.type == 0 ? () => otherhandleUploadButtonClick(index) : ''} style={{ width: 40, height: 40, borderRadius: '50%', border: '1px dashed #666', padding: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                            <img src={item.img} alt='' style={{ width: '100%', height: '100%' }}></img>
                                                        </div>
                                                        <input type="file" accept='.png,.jpg' ref={other_ref_lists[index]} onChange={(event) => otherhandleFileChange(event, index)} />
                                                    </div>
                                                    <div>
                                                        <TextField id="outlined-basic" label="名称" variant="outlined" disabled={item.type == 0 ? false : true} size="small" value={item.name} onChange={(event) => other_amend_name(event, index)} />
                                                    </div>
                                                    {
                                                        item.type == 0 ? <div style={{ display: 'flex' }}>
                                                            <div style={{ paddingLeft: 20 }}>
                                                                <Button variant="contained" size="large" onClick={() => other_company(index)} style={{ background: base_setting?.primary_color }}>修改</Button>
                                                            </div>
                                                            <div style={{ paddingLeft: 20 }}>
                                                                <Button variant="contained" size="large" onClick={() => other_delete(index)} style={{ background: base_setting?.primary_color }}>删除</Button>
                                                            </div>
                                                            <div style={{ paddingLeft: 20 }}>
                                                                <Button variant="contained" size="large" style={{ background: base_setting?.primary_color }} onClick={() => other_top(index)}>置顶</Button>
                                                            </div>
                                                        </div> : ''
                                                    }
                                                </div>)
                                            }
                                        </div>
                                        <div style={{ padding: '15px 0', display: 'flex', alignItems: 'center' }}>
                                            <FormControl style={{ width: 200, paddingRight: 20 }}>
                                                <InputLabel id="demo-simple-select-label">{add_other}</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={add_other}
                                                    label="添加其他列表"
                                                    onChange={addOtherChange}
                                                >
                                                    {
                                                        other_json.map(((item, index) => <MenuItem value={index}>{item.name}</MenuItem>))
                                                    }
                                                    {/* <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem> */}
                                                </Select>
                                            </FormControl>
                                            <div>
                                                <Button variant="contained" size="large" onClick={setting_other} style={{ background: base_setting?.primary_color }}>添加</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Dialog
                open={agent_detele_dialog}
                onClose={agenthandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    删除智能体列表
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确认要删除id为<span style={{ color: 'red' }}>{agent_menus_lists[agent_detele_index]?.id}</span>的智能体列表吗
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={agenthandleClose}>取消</Button>
                    <Button onClick={agent_detele_amend} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={other_detele_dialog}
                onClose={otherhandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    删除智能体列表
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确认要删除名称为<span style={{ color: 'red' }}>{other_menus_lists[other_detele_index]?.name}</span>的其他体列表吗
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={otherhandleClose}>取消</Button>
                    <Button onClick={other_detele_amend} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SettingBlock