import {
    ALARM, RECEIVE_MESSAGE, SEND_MESSAGE,
    SET_CURRENT_CHATTING_USER, GET_CHAT_HISTORY, WHO_ARE_YOU, GET_CHAT_RELATION_LIST,
    INCREMENT_TODO, DECREMENT_TODO,GET_USER_ORDER_TODOS,GET_UNREAD_MSG_NUMBER,SEND_MESSAGE_TO_ROOM,PAYMENTRESULTS,CHANGE_SUCCESS,SEND_WEB_NOTIFICATION_TO_USER,
    CLEAR_NEW_INFORM
} from '../actions/types';
import {emit} from '../actions/chat'
const _ = require('micro-dash');

const initialState = {
    socket: null,
    currentChattingUser: null,
    receivedChat: {},
    chatHistory: {},
    chatPreview: {},
    chatRelationList: [],
    onlineUsersSet: new Set(),

    bottomTabMessageBadgeForBuyer: 0,
    bottomTabMessageBadgeForSeller: 0,

    bottomTabOrderBadgeForBuyer: 0,
    bottomTabOrderBadgeForSeller: 0,

    chatMessage: "",
    chatMessages: [],
    is_paySuccess:false,
    shui_new_inform:0,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    let to_user_id, from_user_id, user_type = 'buyer', bottomTabMessageBadgeState={},
        bottomTabOrderBadgeForBuyer = 0, bottomTabOrderBadgeForSeller = 0;
    switch (action.type) {
        case ALARM:
            alert(action.payload);
            return state;
        case SEND_MESSAGE:
            console.log(`SEND_MESSAGE is ${JSON.stringify(action.payload)}`);
            to_user_id = action.payload.to_user_id;
            console.log(action)

            let newChatRelationList=state.chatRelationList.map(
                item => {
                    let new_item = item;
                    if(parseInt(item.other_user_id) === parseInt(to_user_id)){
                        new_item.badge='false'
                    }
                    return new_item;
                }
            )
            // console.log('chatRelationList',newChatRelationList)

            if(to_user_id in state.chatHistory){
                return {...state, receivedChat: action.payload,
                    chatHistory: {...state.chatHistory, [to_user_id]: [...state.chatHistory[to_user_id], action.payload.message]},
                    now_user_id:to_user_id,
                    chatRelationList: newChatRelationList
                }
            }
            else{
                state.chatHistory[to_user_id] = [];
                state.chatHistory[to_user_id].push(action.payload.message);
                return {...state, receivedChat: action.payload,
                    chatHistory: {...state.chatHistory, [to_user_id]: [action.payload.message]},
                    chatRelationList: newChatRelationList
                }
            }
        case SEND_MESSAGE_TO_ROOM: 
             console.log(`SEND_MESSAGE_TO_ROOM is ${JSON.stringify(action.payload)}`)
             from_user_id = action.payload.from_user_id;
             let now_user_id=state.now_user_id
             console.log('now_user_id',now_user_id)
              //update chatRelationList
            // console.log(`in RECEIVE_MESSAGE, ${state.currentChattingUser}, ${state.currentChattingUser}`);
            let chatRelationList = state.chatRelationList;
            if(_.isNil(state.currentChattingUser) || parseInt(from_user_id) !== parseInt(state.currentChattingUser)){
                chatRelationList = state.chatRelationList.map(
                    item => {
                        if(parseInt(item.other_user_id) === parseInt(from_user_id)){
                            if(now_user_id==from_user_id){
                                return {...item, content: action.payload.message, badge: 'false'}
                            }else{
                                return {...item, content: action.payload.message, badge: 'true'}
                            }
                            
                        }
                        else{
                            return item;
                        }
                    }
                );
            }
            // update chatHistory
            if(from_user_id in state.chatHistory){
                return {...state,
                    chatRelationList: chatRelationList,
                    receivedChat: action.payload,
                    chatHistory: {...state.chatHistory, [from_user_id]: [...state.chatHistory[from_user_id], action.payload.message]}}
            }
            else{
                // state.chatHistory[from_user_id] = [];
                // state.chatHistory[from_user_id].push(action.payload.message);
                return {...state,
                    chatRelationList: chatRelationList,
                    receivedChat: action.payload,
                    // chatHistory: {...state.chatHistory, [from_user_id]: [action.payload.message]}
                }
            }

        case RECEIVE_MESSAGE:
            // console.log(`RECEIVE_MESSAGE is ${JSON.stringify(action.payload)}`);
            from_user_id = action.payload.from_user_id;

            //update chatRelationList
            console.log(`in RECEIVE_MESSAGE, ${state.currentChattingUser}, ${state.currentChattingUser}`);
            return null;
        case GET_CHAT_HISTORY:
            console.log(`GET_CHAT_HISTORY is ${JSON.stringify(action.payload.history)}`);
            to_user_id = action.payload.user_id;
            let history = action.payload.history;

            return {
                ...state, receivedChat: action.payload,
                chatHistory: {...state.chatHistory, [to_user_id]: [...history]}
            };
        case WHO_ARE_YOU:
            let profile = JSON.parse(action.profile.user);
            const user_id = _.get(profile, ['id'], null);
            const user_type= _.get(profile, ['user_type'], null);
            // console.log(`in WHO ARE YOU, user id is ${user_id}, ${JSON.stringify(action.profile)}`);
            // console.log(action)
            emit(WHO_ARE_YOU, {user_id:user_id,user_type:user_type});
            return state;
        case SET_CURRENT_CHATTING_USER:
            return {...state, currentChattingUser: action.currentChattingUser};
        case GET_CHAT_RELATION_LIST:
            console.log(`in GET_CHAT_RELATION_LIST ${JSON.stringify(action.payload.chatRelationList)}`);
            return {...state, chatRelationList: action.payload.chatRelationList};
        case GET_USER_ORDER_TODOS:
            bottomTabOrderBadgeForSeller = parseInt(_.get(action.payload, ['seller_todo_total_number'], 0));
            bottomTabOrderBadgeForBuyer = parseInt(_.get(action.payload, ['buyer_todo_total_number'], 0));
            console.log(`In GET_USER_ORDER_TODOS, bottomTabOrderBadgeForSeller is ${bottomTabOrderBadgeForSeller}, 
            bottomTabOrderBadgeForBuyer is ${bottomTabOrderBadgeForBuyer}`);
            return {...state, bottomTabOrderBadgeForSeller, bottomTabOrderBadgeForBuyer};
        case INCREMENT_TODO:
            console.log(`In chat reducer, INCREMENT_TODO`);
            user_type = _.get(action.payload, ['user_type'], 'buyer');
            if( user_type === 'buyer'){
                return {...state, bottomTabOrderBadgeForBuyer: state.bottomTabOrderBadgeForBuyer+1};
            }
            else{
                return {...state, bottomTabOrderBadgeForSeller: state.bottomTabOrderBadgeForSeller+1};
            }
        case DECREMENT_TODO:
            console.log(`In chat reducer, DECREMENT_TODO`);
            user_type = _.get(action.payload, ['user_type'], 'buyer');
            let count = parseInt(_.get(action.payload, ['count'], 0));
            if( user_type === 'buyer'){
                if(state.bottomTabOrderBadgeForBuyer-count<0){
                    console.log(`In DECREMENT_TODO, ${state.bottomTabOrderBadgeForBuyer},  ${count}`)
                }
                return {...state, bottomTabOrderBadgeForBuyer: state.bottomTabOrderBadgeForBuyer-count};
            }
            else{
                if(state.bottomTabOrderBadgeForSeller-count<0){
                    console.log(`In DECREMENT_TODO, ${state.bottomTabOrderBadgeForSeller},  ${count}`)
                }
                return {...state, bottomTabOrderBadgeForSeller: state.bottomTabOrderBadgeForSeller-count};
            }
        case PAYMENTRESULTS:
            console.log('PAYMENTRESULTS',state,action)
            return {...state,is_paySuccess:action.payload.is_Success}
        case CHANGE_SUCCESS: 
            return {...state,is_paySuccess:action.is_paySuccess}
        case SEND_WEB_NOTIFICATION_TO_USER:
            // console.log('SEND_WEB_NOTIFICATION_TO_USER',action.payload)
            if(action.payload.mini_type===1){
                return {...state,shui_new_inform:state.shui_new_inform+1}
            }
        case CLEAR_NEW_INFORM:
            if(action.mini_type===1){
                return {...state,shui_new_inform:0}
            }
        default:
            return state;
    }
}


const getBottomTabMessageBadgeState = (action, state) => {
    let bottomTabMessageBadgeState={};
    let user_type = _.get(action.profile, ['topLevelUserType'], 'buyer');

    switch (action.type) {
        case GET_UNREAD_MSG_NUMBER:
                bottomTabMessageBadgeState={
                    bottomTabMessageBadgeForBuyer: parseInt(_.get(action.payload, ['buyer_unread_number'], 0)),
                    bottomTabMessageBadgeForSeller: parseInt(_.get(action.payload, ['seller_unread_number'], 0))
                };

            return bottomTabMessageBadgeState;
        case RECEIVE_MESSAGE:
            if( user_type === 'buyer'){
                bottomTabMessageBadgeState={
                    bottomTabMessageBadgeForBuyer: state.bottomTabMessageBadgeForBuyer + 1
                }
            }
            else{
                bottomTabMessageBadgeState={
                    bottomTabMessageBadgeForSeller: state.bottomTabMessageBadgeForSeller + 1
                }
            }
            return bottomTabMessageBadgeState;
        default:
            return state;
    }

};