import React, { useEffect, useRef, useLayoutEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';

import collect from '../../../static/icon/collect.png'
import collect_false from '../../../static/icon/collect_false.png'
import { admin_user } from '../../../actions/admin'

const _ = require('micro-dash');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D1198',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(name, phone, site, time, collect) {
    return { name, phone, site, time, collect };
}

function UserPool() {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(1);
    const [count,setCount]= React.useState(1);
    const all_user_pool = useSelector((state) => state.admin.all_user_pool)
    const [page_message,setMessage]=React.useState([]);
    const membership_info = useSelector((state) => state.user.membership_info);
    useEffect(() => {
        dispatch(admin_user())
    }, [])
    useEffect(() => {
        setCount(Math.ceil(all_user_pool.length/10))
        setMessage(all_user_pool.slice(0,10))
    }, [dispatch,all_user_pool])

    const handleChange = (event, value) => {
        // console.log(event, value)
        setPage(value)
        setMessage(all_user_pool.slice((value-1)*10,value*10))
    }
    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">姓名</StyledTableCell>
                            <StyledTableCell align="center">电话</StyledTableCell>
                            <StyledTableCell align="center">地址</StyledTableCell>
                            {/* <StyledTableCell align="center">时间</StyledTableCell> */}
                            <StyledTableCell align="center">收藏</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {page_message.map((row) => (
                            <StyledTableRow key={row.nick_name}>
                                <StyledTableCell component="th" scope="row" align="center">
                                    {row.nick_name}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.phone_number}</StyledTableCell>
                                <StyledTableCell align="center" style={{filter: 'blur(2px)'}}>{row.location}</StyledTableCell>
                                {/* <StyledTableCell align="center">{row.time}</StyledTableCell> */}
                                <StyledTableCell align="center">
                                    <div>
                                    {
                                        row.is_collect?
                                        <img src={collect} style={{ width: '24px', height: '24px', borderRadius: '50%' }} alt="" />:
                                        <img src={collect_false} style={{ width: '24px', height: '24px',  borderRadius: '50%' }} alt="" />
                                    }
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <div style={{ width: '100%', height: '100%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
                    <Pagination count={count} color="primary"
                        page={page}
                        onChange={handleChange}
                        variant="outlined" shape="rounded" />
                </div>
            </TableContainer>
        </div>
    )
}
export default UserPool;