/**
 * Created on 6/24/21.
 * @author Xihao
 */

import React from 'react';
import { makeStyles } from '@mui/styles';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import {close_drawer, get_by_id,search_by_id_cross_indexes} from '../../../actions/search';
import {useDispatch, useSelector} from 'react-redux';
import Close from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        paddingInline: '3.5%',
        width: '92%',
        minHeight: '100%',
        // borderLeft: '1px solid #E6E5EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderLeft: '8px solid #3D1198',
    },
    center:{
        height: window.innerHeight,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    title: {
        fontSize: 19,

    }
}));



const DrawerContent = ({hit}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showParent, setShowParent] = React.useState(0);

    const parent_data = useSelector((state) => state.search.parent_data);
    const is_loading_parent = useSelector((state) => state.search.is_loading_parent);

    let es_index = _.get(hit, ['_index'], null);
    const has_parent = _.get(hit, ['_source', 'parent_title'], null) !== null;
    const tax_law=_.get(hit,['_source','tax_law_titles'],[]).length>0
    const tax_law_const=_.get(hit,['_source','tax_law_titles'],[])
    let tax_law_ids=_.get(hit,['_source','tax_law_ids'],[])
    let parent_title = _.get(hit, ['_source', 'parent_title'], '');
    let parent_id = _.get(hit, ['_source', 'parent_id'], 0);

    let tax_planning=_.get(parent_data,['_source','tax_planning_titles'],[]).length>0
    let tax_planning_titles=_.get(parent_data,['_source','tax_planning_titles'],[])
    let tax_planning_ids=_.get(parent_data,['_source','tax_planning_ids'],[])

    let tax_law_parent=_.get(parent_data,['_source','tax_law_titles'],[]).length>0
    let tax_law_titles_parent=_.get(parent_data,['_source','tax_law_titles'],[])
    let tax_law_ids_parent=_.get(parent_data,['_source','tax_law_ids'],[])
    
    let tax_planning_hit=_.get(hit,['_source','tax_planning_titles'],[]).length>0
    let tax_planning_titles_hit=_.get(hit,['_source','tax_planning_titles'],[])
    let tax_planning_ids_hit=_.get(hit,['_source','tax_planning_ids'],[])

    let is_law_url=_.get(hit,['_source','law_url'],'')!==''
    let law_url=_.get(hit,['_source','law_url'],'')

    function TypographyLoading() {
        return (
            <div style={{padding: '10px 20px 10px 20px'}}>

                <Skeleton variant="rect" width={'70%'} animation="wave" height={30} style={{marginBottom: 30}}/>
                <Skeleton variant="rect" width={'100%'} height={120} animation="wave" style={{marginBottom: 10}}/>
                <Skeleton variant="rect" width={'100%'} animation="wave" style={{marginBottom: 10}}/>
                <Skeleton variant="rect" width={'100%'} animation="wave" style={{marginBottom: 20}}/>
                <Skeleton variant="rect" width={'100%'} height={100} animation="wave" style={{marginBottom: 10}}/>
                <Skeleton variant="rect" width={'100%'} animation="wave" style={{marginBottom: 10}}/>

            </div>
        );
    }

    if(_.isEmpty(hit) || _.isNil(hit) || (showParent===1 && is_loading_parent)){
        return(
            <TypographyLoading/>
        )
    }
    if(showParent===0){
        return (
            <div className={classes.root}>
                <div style={{display: 'flex',
                    flexDirection: 'row', alignItems: 'center'}}>
                    <IconButton onClick={()=>dispatch(close_drawer())} style={{width: 39, height:39, marginRight: 6}}>
                        <Close/>
                    </IconButton>
                    {
                        has_parent?
                            <p style={{fontSize: 20}}>{`${parent_title}---${_.get(hit, ['_source', 'title'], '')}`}</p>
                            :
                            <p style={{fontSize: 20}}>{_.get(hit, ['_source', 'title'], '')}</p>

                    }
                </div>
                <Divider/>
                {
                    es_index === "tax_law" && tax_planning_hit &&
                        <div style={{ width:'100%'}}>
                            {/* <div style={{display:'flex'}}> */}
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{backgroundColor:'#43179D',color:'#fff',borderRadius:'8px'}}
                                >
                                <Typography>相关税务筹划</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    {
                                        tax_planning_titles_hit.map(
                                            (item,index)=><Button
                                            onClick={()=>{
                                                setShowParent(1);
                                                dispatch(search_by_id_cross_indexes({id:tax_planning_ids_hit[index]}));
                                            }}
                                            color="primary"
                                            style={{fontSize: 16,width:'100%',border:'1px solid #43179D',color: '#43179D',padding:'5px 8px',margin:'4px',borderRadius: '8px',whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                                        >
                                            {item}
                                        </Button>
                                        )
                                    }
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* </div> */}
                        </div>
                }
                {
                     es_index !== "tax_law"  && tax_law&&
                        <div style={{ width:'100%'}}>
                            {/* <div style={{display:'flex'}}> */}
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{backgroundColor:'#43179D',color:'#fff',borderRadius:'8px'}}
                                >
                                <Typography>法务相关税法条款</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    {
                                        tax_law_const.map(
                                            (item,index)=><Button
                                            onClick={()=>{
                                                setShowParent(1);
                                                dispatch(search_by_id_cross_indexes({id:tax_law_ids[index]}));
                                            }}
                                            color="primary"
                                            style={{fontSize: 16,width:'100%',border:'1px solid #43179D',color: '#43179D',padding:'5px 8px',margin:'4px',borderRadius: '8px',whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                                        >
                                            {item}
                                        </Button>
                                        )
                                    }
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* </div> */}
                        </div>
                }
                {
                    is_law_url&&
                    <div style={{color:'rgb(67, 23, 157)',marginTop:'25px',paddingLeft:'10px'}}>
                        {/* <span>法规链接：</span> */}
                        <span style={{cursor: "pointer",borderBottom:'1px solid #6666'}}  onClick={() => { window.open(law_url) }}>点击查看法规链接</span>
                    </div>
                }
                <p style={{fontSize: 16, wordWrap: 'normal', lineHeight: "170%", padding: 10}} dangerouslySetInnerHTML={{ __html: _.get(hit, ['_source', 'content'], '')}}/>
                <Divider style={{marginBottom: 2, marginTop: 10}}/>

                {
                    has_parent &&
                        <div style={{display: 'flex',
                            flexDirection: 'row', alignItems: 'center'}}>
                            <p style={{marginRight: 10, fontSize: 15}}>点击查看母概念:</p>
                            <Button
                                onClick={()=>{
                                    setShowParent(1);
                                    dispatch(get_by_id({id:parent_id}));
                                }}
                                color="primary"
                                style={{fontSize: 16}}
                            >
                                {parent_title}
                            </Button>
                        </div>

                }
            </div>
        )
    }
    else{

        return (
            <div className={classes.root}>
                <div style={{display: 'flex',
                    flexDirection: 'row', alignItems: 'center'}}>
                    <IconButton onClick={()=>dispatch(close_drawer())} style={{width: 39, height:39, marginRight: 6}}>
                        <Close/>
                    </IconButton>
                    <p style={{fontSize: 20}}>{_.get(parent_data, ['_source', 'title'], '')}</p>
                </div>
                <Divider/>

                {
                    tax_planning&&
                        <div style={{ width:'100%'}}>
                            {/* <div style={{display:'flex'}}> */}
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{backgroundColor:'#43179D',color:'#fff',borderRadius:'8px'}}
                                >
                                <Typography>相关税务筹划</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                    {
                                        tax_planning_titles.map(
                                            (item,index)=><Button
                                            onClick={()=>{
                                                setShowParent(0);
                                                dispatch(search_by_id_cross_indexes({id:tax_planning_ids[index]}));
                                            }}
                                            color="primary"
                                            style={{fontSize: 16,width:'100%',border:'1px solid #43179D',color: '#43179D',padding:'5px 8px',margin:'4px',borderRadius: '8px',whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                                        >
                                            {item}
                                        </Button>
                                        )
                                    }
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            {/* </div> */}
                        </div>

                }
                {
                    tax_law_parent&&
                    <div style={{ width:'100%'}}>
                        {/* <div style={{display:'flex'}}> */}
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{backgroundColor:'#43179D',color:'#fff',borderRadius:'8px'}}
                            >
                            <Typography>法务相关税法条款</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                {
                                    tax_law_titles_parent.map(
                                        (item,index)=><Button
                                        onClick={()=>{
                                            setShowParent(0);
                                            dispatch(search_by_id_cross_indexes({id:tax_law_ids_parent[index]}));
                                        }}
                                        color="primary"
                                        style={{fontSize: 16,width:'100%',border:'1px solid #43179D',color: '#43179D',padding:'5px 8px',margin:'4px',borderRadius: '8px',whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                                    >
                                        {item}
                                    </Button>
                                    )
                                }
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        {/* </div> */}
                    </div>
                }

                <p style={{fontSize: 16, wordWrap: 'normal', lineHeight: "170%", padding: 10}} dangerouslySetInnerHTML={{ __html: _.get(parent_data, ['_source', 'content'], '')}}/>
                <Divider style={{marginBottom: 2, marginTop: 10}}/>
                {/*<Button onClick={()=>setShowParent(0)} color="primary">*/}
                    {/*{_.get(parent_data, ['_source', 'title'], '')}*/}
                {/*</Button>*/}
                
                <IconButton
                    onClick={()=>{
                        setShowParent(0);
                    }}
                    style={{width: 39, height:39, marginRight: 6}}>
                    <KeyboardBackspaceIcon/>
                </IconButton>

            </div>
        )
    }

};

export default DrawerContent;