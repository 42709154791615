import React, {useEffect} from "react";
import Icon from '@mui/material/Icon';
import shui_month from '../../static/icon/shui_month.png'
import shui_season from '../../static/icon/shui_season.png'
import shui_year from '../../static/icon/shui_year.png'
import shui_qe_code from '../../static/image/bh_xcx_2.jpg'
import added_value_tax from '../../static/icon/added_value_tax.png'
import tax_planning from '../../static/icon/tax_planning.png'


function ShuiIntro(){
    return (
        <div>
            <div>
                <h2 style={{paddingLeft:40}}>税引擎功能</h2>
                <div style={{padding:40,display:'flex'}}>
                    <div style={{width:'50%',paddingRight:'5%'}}>
                        <div style={{ padding: 10 }}>
                            &bull;税引擎智能提供各行业税务筹划方案，并提供税务筹划以及相关税法知识库搜索
                        </div>
                        <div style={{ padding: 10 }}>
                            &bull;以往动辄几万元的税务筹划方案只用十几块就可以无限次使用
                        </div>
                        <div style={{ padding: 10 }}>
                            &bull;5分钟免费试用，用完后需充值会员方可继续使用
                        </div>
                    </div>
                    <div style={{width:'50%'}}>
                        <div style={{display:'flex',justifyContent:'space-evenly'}}>
                            <div style={{textAlign:'center',marginBottom:'30px'}}>
                                <div>
                                    <Icon style={{fontSize: 32, color:'#fff',background:'rgb(61, 17, 152)',borderRadius:4,padding:'0 9px',fontWeight:'bold',paddingBottom:18}}>个</Icon>
                                    {/* <Individual_income_tax /> */}
                                    {/* <img src={individual_income_tax} alt='' style={{width:50,height:50}} /> */}
                                </div>
                                <div>个人所得税</div>
                            </div>
                            <div style={{textAlign:'center',marginBottom:'30px'}}>
                                <div style={{textAlign:'center'}}>
                                    <Icon style={{fontSize: 32, color:'#fff',background:'rgb(61, 17, 152)',borderRadius:4,padding:'0 9px',fontWeight:'bold',paddingBottom:18}}>企</Icon>
                                    {/* <img src={corporate_income_tax} alt='' style={{width:50,height:50}} /> */}
                                </div>
                                <div>企业所得税</div>
                            </div>
                        </div>
                        <div style={{display:'flex',flexWrap: 'wrap',paddingTop:40}}>
                            <div style={{width:'33.33%',textAlign:'center',marginBottom:'30px'}}>
                                <div>
                                    <img src={added_value_tax} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>增值税</div>
                            </div>
                            <div style={{width:'33.33%',textAlign:'center',marginBottom:'30px'}}>
                                <div style={{textAlign:'center'}}>
                                    <Icon style={{fontSize: 32, color:'#fff',background:'rgb(61, 17, 152)',borderRadius:4,padding:'0 9px',fontWeight:'bold',paddingBottom:18}}>房</Icon>
                                    {/* <img src={building_taxes} alt='' style={{width:50,height:50}} /> */}
                                </div>
                                <div>房产税</div>
                            </div>
                            <div style={{width:'33.33%',textAlign:'center',marginBottom:'30px'}}>
                                <div>
                                    <img src={tax_planning} alt='' style={{width:50,height:50}} />
                                </div>
                                <div>税务筹划</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 style={{paddingLeft:40}}>价格</h2>
                <div style={{display:'flex',padding:40}}>
                    <div style={{width:'50%',display:'flex',paddingRight:'5%'}}>
                        <div style={{padding:'20px',width:'33.33%',textAlign:'center'}}>
                            <div>
                                <img src={shui_month} alt='' style={{width:50,height:50}} />
                            </div>
                            <div style={{paddingTop:20}}>
                                <div>月度会员</div>
                                <div>￥28</div>
                            </div>
                        </div>
                        <div style={{padding:'20px',width:'33.33%',textAlign:'center'}}>
                            <div>
                                <img src={shui_season} alt='' style={{width:50,height:50}} />
                            </div>
                            <div style={{paddingTop:20}}>
                                <div>季度会员</div>
                                <div>￥66</div>
                            </div>
                        </div>
                        <div style={{padding:'20px',width:'33.33%',textAlign:'center'}}>
                            <div>
                                <img src={shui_year} alt='' style={{width:50,height:50}} />
                            </div>
                            <div style={{paddingTop:20}}>
                                <div>年度会员</div>
                                <div>￥166</div>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'50%'}}>
                        <div style={{ padding: 10 }}>
                            &bull;月度会员（￥28）可在有效期内无限次使用小程序与网站所有功能
                        </div>
                        <div style={{ padding: 10 }}>
                            &bull;季度会员（￥66）可在有效期内无限次使用小程序与网站所有功能
                        </div>
                        <div style={{ padding: 10 }}>
                            &bull;年度会员（￥166）可在有效期内无限次使用小程序与网站所有功能
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <h2 style={{paddingLeft:40}}>产品</h2>
                <div style={{display:'flex',padding:40}}>
                    <div style={{width:'50%'}}>
                        <div style={{padding:'20px 0'}}>&bull;PC端-税引擎网站</div>
                        <div>&bull;移动端-税引擎微信小程序（扫描右侧二维码）</div>
                    </div>
                    <div style={{width:'50%'}}>
                        <img src={shui_qe_code} style={{width:'150px'}} alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShuiIntro;