import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import './general.css'
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import shui_engine from '../../../static/logo/logo_2.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import user_img from '../../../static/image/user_img.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import knowledge_base_icon from '../../../static/image/knowledge_base_icon.png'
import knowledge_base_icons from '../../../static/image/knowledge_base_icons.png'
import mess_icon from '../../../static/image/mess_icon.png'
import mess_icons from '../../../static/image/mess_icons.png'
import API_icon from '../../../static/image/API_icon.png'
import API_icons from '../../../static/image/API_icons.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import ResultLists from './result_lists';
import Markdown from 'react-markdown'
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import { v4 as uuidv4 } from 'uuid';
import { get_user_agent_list, create_agent, delete_user_agent, agent_list, test_api_configs } from '../../../actions/user'
import { test_agent_run, get_file_content } from '../../../actions/dataset'
import { message, Spin } from 'antd';
import RecordingDrawer from '../examples/recording_drawer'

const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        backgroundColor: "#4844f3 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#4844f3 !important',
        },
    },
    clearButton: {
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    },
    disabled_button: {
        background: 'rgba(221, 221, 221, 1) !important',
        color: '#fff !important'
    }
}));


const options = ['get', 'post', 'delete', 'put', 'patch'];
const _ = require('micro-dash');
function CreateAgent() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const token = useSelector((state) => state.user.token);
    const [name, setName] = React.useState('');
    const [introduction, setIntroduction] = React.useState('');
    const [prompt_value, setPromptValue] = React.useState('');
    const [vector_db, setVectorDb] = React.useState(['']);
    const base_setting=useSelector((state) => state.user.base_setting);
    const history = useHistory();
    const name_Change = (e) => {
        setName(e.target.value)
    }
    const introduction_Change = (e) => {
        setIntroduction(e.target.value)
    }
    const [prompt_dialog, setPromptDialog] = React.useState(false);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const [searchValue, setSearchValue] = React.useState('');
    const prompt_Change = (e) => {
        if (e.nativeEvent.data === '@') {
            dispatch(agent_list())
            setPromptDialog(true)
        }
        setPromptValue(e.target.value)
    }
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])
    const promptClose = () => {
        setPromptDialog(false)
    }
    const handleAgent = (e, expression, intr) => {
        console.log('123',e)
        let mess_value = prompt_value.slice(0, -1)
        if(expression=='@文件助手'){
            if(!_.isNil(e)){
                mess_value = mess_value + expression + e
            }else{
                mess_value = mess_value + expression
            }
        }else{
            if(!_.isNil(e)){
                mess_value = mess_value + expression + '@ ' + e
            }else{
                mess_value = mess_value + expression+'@'
            }
        }
        // mess_value = prompt_value + e
        setPromptValue(mess_value)
        setPromptDialog(false)
    }
    const vector_Change = (e, index) => {
        console.log(e, index)
        let db_value = [...vector_db]
        db_value[index] = e
        console.log(db_value)
        setVectorDb(db_value)
    }
    const add_input = () => {
        console.log(111)
        setVectorDb(prev => {
            let tmp = JSON.parse(JSON.stringify(prev))
            tmp.push('')
            return tmp
        })

    }
    const delete_lists = (e) => {
        let vector_lists = [...vector_db]
        vector_lists.splice(e, 1)
        setVectorDb(vector_lists)
    }
    const create_agent_list = () => {
        console.log(vector_db)
        if (cut_type === 0) {
            if (name !== '') {
                let vector_db_lists = Array.from(new Set(vector_db))
                dispatch(create_agent({ name: name, introduction: introduction, prompt: prompt_value, vector_db_list: vector_db_lists, agent_content_type: 'vector_db', cost: 3 }, (v) => setIsCreate(v), (v) => setErrorValue(v)))
            }
        } else if (cut_type === 1) {
            if (name !== '') {
                let lists = []
                if(test_lists?.[0]?.prompt == ''){
                    message.error('多步骤智能体不能为空')
                }else{
                    for (let i = 0; i < test_lists?.length; i++) {
                        if (test_lists[i].prompt == '') {
                            message.error('提示词列表不能为空')
                        } else {
                            lists.push(test_lists[i].prompt)
                        }
                    }
                    dispatch(create_agent({ name: name, introduction: introduction, prompt_list: lists, agent_content_type: 'prompt_list', cost: 3, history: file_history }, (v) => setIsCreate(v), (v) => setErrorValue(v)))
                }
            }else{
                message.error('名称不能为空')
            }
        } else if (cut_type === 2) {
            if (name !== '') {
                if (query_checked === true) {
                    dispatch(create_agent({
                        name: name,
                        introduction: introduction,
                        prompt: prompt_value,
                        vector_db_list: null,
                        agent_content_type: 'api',
                        cost: 3,
                        api: {
                            user_question: prompt_value,
                            url: query_value,
                            name: name + '_api',
                            method: options[selectedIndex],
                            headers: JSON.parse(headers_value),
                            body_example: JSON.parse(body_value),
                            body_description: body_params,
                            query_params_description: params_value
                        }
                    }, (v) => setIsCreate(v), (v) => setErrorValue(v)))
                } else {
                    dispatch(create_agent({
                        name: name,
                        introduction: introduction,
                        prompt: prompt_value,
                        vector_db_list: null,
                        agent_content_type: 'api',
                        cost: 3,
                        api: {
                            user_question: prompt_value,
                            url: url_value,
                            name: name + '_api',
                            method: options[selectedIndex],
                            headers: JSON.parse(headers_value),
                            body_example: JSON.parse(body_value),
                            body_description: body_params,
                            query_params_description: params_value
                        }
                    }, (v) => setIsCreate(v), (v) => setErrorValue(v)))
                }

            } else {
                message.error('名称不能为空')
            }
        }

    }
    const [is_create, setIsCreate] = React.useState('');
    
    useEffect(() => {
        if (is_create === true) {
            history.push('/largemodel/intelligent_agent');
        }
    }, [is_create])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const [cut_type, setCutType] = React.useState(0);
    const [headers_value, setHeadersValue] = React.useState('');
    const [url_value, setUrlValue] = React.useState('');
    const [body_checked, setBodyChecked] = React.useState(false);
    const bodyChange = (e) => {
        setBodyChecked(e.target.checked)
    }
    const [body_value, setBodyValue] = React.useState('');
    const [query_checked, setQueryChecked] = React.useState(false);
    const queryChange = (e) => {
        setQueryChecked(e.target.checked)
    }
    const [query_value, setQueryValue] = React.useState('');
    const [params_value, setParamsValue] = React.useState('');
    const [body_params, setBodyParams] = React.useState('');
    const test_try = (data) => {
        try {
            return Object.prototype.toString.call(JSON.parse(data)) === '[object Object]'
        }
        catch (e) {
            return false
        }
    }
    const [error_value, setErrorValue] = React.useState('');
    const [test_success, setTestSuccess] = React.useState(false);
    const [is_loading, setIsLoading] = React.useState(false);
    const test_create_agent = () => {
        console.log(test_try(headers_value))
        if (headers_value === '' || !test_try(headers_value)) {
            message.error('headers格式错误')
        } else if (url_value === '') {
            message.error('url不能为空')
        } else if (body_checked === true) {
            if (body_value === '' || !test_try(body_value)) {
                message.error('body格式错误')
            } else if (body_params === '') {
                message.error('body参数解析不能为空')
            } else {
                if (query_checked === true) {
                    if (query_value === '') {
                        message.error('query Params不能为空')
                    } else if (params_value === '') {
                        message.error('query Params参数解析不能为空')
                    } else {
                        dispatch(test_api_configs({
                            user_question: prompt_value,
                            url: query_value,
                            method: options[selectedIndex],
                            headers: JSON.parse(headers_value),
                            body_example: JSON.parse(body_value),
                            body_description: body_params,
                            query_params_description: params_value
                        }, (v) => setErrorValue(v), (v) => setTestSuccess(v), (v) => setIsLoading(v)))
                    }
                } else {
                    dispatch(test_api_configs({
                        user_question: prompt_value,
                        url: url_value,
                        method: options[selectedIndex],
                        headers: JSON.parse(headers_value),
                        body_example: JSON.parse(body_value),
                        body_description: body_params,
                        // query_params_description:params_value
                    }, (v) => setErrorValue(v), (v) => setTestSuccess(v), (v) => setIsLoading(v)))
                }
            }
        } else if (query_checked === true) {
            if (query_value === '') {
                message.error('query Params不能为空')
            } else if (params_value === '') {
                message.error('query Params参数解析不能为空')
            } else {
                dispatch(test_api_configs({
                    user_question: prompt_value,
                    url: query_value,
                    method: options[selectedIndex],
                    headers: JSON.parse(headers_value),
                    // body_example:body_value,
                    // body_description:body_params,
                    query_params_description: params_value
                }, (v) => setErrorValue(v), (v) => setTestSuccess(v), (v) => setIsLoading(v)))
            }
        } else {
            dispatch(test_api_configs({
                user_question: prompt_value,
                url: url_value,
                method: options[selectedIndex],
                headers: JSON.parse(headers_value),
            }, (v) => setErrorValue(v), (v) => setTestSuccess(v), (v) => setIsLoading(v)))
        }
        // dispatch(test_api_configs())
    }
    useEffect(() => {
        console.log(error_value)
        if (error_value !== '') {
            message.error(error_value)
            setErrorValue('')
        }
    }, [error_value])
    useEffect(() => {
        console.log(test_success)
        if (test_success === true) {
            message.success('测试通过')
            setTestSuccess('')
        }
    }, [test_success])
    const [test_lists, setTextLists] = React.useState([{ prompt: '', id: 'lists' + uuidv4() }]);
    const add_result_lists = (e) => {
        let lists = [...test_lists]
        lists.push({ prompt: '', id: 'lists' + uuidv4() })
        setTextLists(lists)
    }
    const propmpt_value = (v, index) => {
        console.log(v, index)
        let lists = [...test_lists]
        console.log(lists)
        for (let i = 0; i < lists?.length; i++) {
            if (lists[i].id == index) {
                lists[i].prompt = v
            }
        }
        setTextLists(lists)
    }
    const delete_propmpt = (index) => {
        console.log(index)
        let lists = [...test_lists]
        for (let i = 0; i < lists?.length; i++) {
            if (lists[i].id == index) {
                console.log(lists[i].prompt, i)
                let a = lists.splice(i, 1)
                console.log(a)
            }
        }
        console.log(lists)
        setTextLists([...lists])
    }
    const [system_work, setSystemWork] = React.useState(null)
    const work_Change = (e) => {
        setSystemWork(e.target.value)
    }
    const [summarize_work, setSummarizeWork] = React.useState('请总结运行的结果')
    const summarize_Change = (e) => {
        setSummarizeWork(e.target.value)
    }
    const [test_agent_value, setTestAgentValue] = React.useState('')
    const lists_test = () => {
        console.log(file_history)
        if (test_lists?.length == 0) {
            message.error('提示词列表不能为空')
        } else {
            let lists = []
            for (let i = 0; i < test_lists?.length; i++) {
                if (test_lists[i].prompt == '') {
                    message.error('提示词列表不能为空')
                } else {
                    lists.push(test_lists[i].prompt)
                }
            }
            dispatch(test_agent_run({ agent_content_type: 'prompt_list', prompt_list: lists, history: [], system_prompt: system_work, prompt_list_summary: summarize_work }, (v) => setTestAgentValue(v)))
        }
    }
    const [test_agent_dialog, setTestAgentDialog] = React.useState(false)
    useEffect(() => {
        if (test_agent_value != '') {
            setTestAgentDialog(true)
        }
    }, [test_agent_value])
    const testClose = () => {
        setTestAgentDialog(false)
    }
    const uploadFileInput = React.useRef();
    const file_content = () => {
        uploadFileInput.current.click();
    }

    const [file_history, setFileHistory] = React.useState([]);
    const [file_lists, setFileLists] = React.useState([]);
    const handleFileChange = (event) => {
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            let files = event.target.files[0]
            const formData = new FormData();
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(get_file_content(formData, files.name, file_history, (v) => setFileHistory(v),file_lists,files.type.split('.')[files.type.split('.')?.length - 1],(v)=>setFileLists(v)))
        }
    }
    const [file_dialog,setFileDialog]=React.useState('');
    const [is_file_dialog,setIsFileDialog]=React.useState(false);
    const file_check = (e) => {
        // console.log(e)
        setFileDialog(e)
        setIsFileDialog(true)
    }
    const fileClose=()=>{
        setIsFileDialog(false)
    }
    const delete_file=(e)=>{
        console.log(e)
        let file_history_lists=[...file_history]
        let file_lists_copy=[...file_lists]
        // console.log(file_history_lists,file_lists_copy)
        for(let i=0;i<file_history_lists?.length;i++){
            if(file_history_lists[i].id==e){
                file_history_lists.splice(i,2)
            }
        }
        for(let g=0;g<file_lists_copy?.length;g++){
            if(file_lists_copy[g].id==e){
                file_lists_copy.splice(g,1)
            }
        }
        // console.log(file_history_lists,file_lists_copy)
        setFileHistory(file_history_lists)
        setFileLists(file_lists_copy)
    }
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 90px)', overflow: 'auto', minWidth:220, padding: '8px 0' }}>
                            <TabbleLists type='3'></TabbleLists>
                        </div>
                        <div style={{ padding: '3% 5%', width: '100%' }}>
                            <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                                <Link to='/largemodel/intelligent_agent' style={{ cursor: 'pointer' }}>智能体列表</Link>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div>
                                <div style={{ cursor: 'pointer' }}>创建智能体</div>
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', padding: '0 5%' }}>
                                <div onClick={() => setCutType(0)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: cut_type === 0 ? base_setting?.primary_color : '', color: cut_type === 0 ? '#fff' : '', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                                            {
                                                cut_type === 0 ? <img src={knowledge_base_icons} alt='' style={{ width: 30, height: 30 }} /> : <img src={knowledge_base_icon} alt='' style={{ width: 30, height: 30 }} />
                                            }

                                        </div>
                                        <div>知识库</div>
                                    </div>
                                    <div style={{ fontSize: 12, paddingTop: 5 }}>填写向量数据库</div>
                                </div>
                                <div onClick={() => setCutType(1)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: cut_type === 1 ? base_setting?.primary_color : '', color: cut_type === 1 ? '#fff' : '', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                                            {
                                                cut_type === 1 ? <img src={mess_icons} alt='' style={{ width: 30, height: 30 }} /> : <img src={mess_icon} alt='' style={{ width: 30, height: 30 }} />
                                            }

                                        </div>
                                        <div>多智能体</div>
                                    </div>
                                    <div style={{ fontSize: 12, paddingTop: 5 }}>可填写多智能体</div>
                                </div>
                                <div onClick={() => setCutType(2)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: cut_type === 2 ? base_setting?.primary_color : '', color: cut_type === 2 ? '#fff' : '', textAlign: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', paddingRight: 5 }}>
                                            {
                                                cut_type === 2 ? <img src={API_icons} alt='' style={{ width: 30, height: 30 }} /> : <img src={API_icon} alt='' style={{ width: 30, height: 30 }} />
                                            }

                                        </div>
                                        <div>API</div>
                                    </div>
                                    <div style={{ fontSize: 12, paddingTop: 5 }}>api接口</div>
                                </div>
                            </div>
                            <div style={{ fontSize: 11, color: '#2d2d2d', paddingLeft: '5%', paddingTop: 10 }}>
                                *如您不会使用或者您有其他需求可联系我们客服获取教程或定制功能
                            </div>
                            <div style={{ minWidth: '500px', padding: '5%', width: '70%', paddingBottom: 10 }}>
                                <div style={{display:'flex'}}>
                                    <div style={{ fontSize: 15, fontWeight: 'bold', paddingBottom: 20 }}>基础信息</div>
                                    <div style={{color:'red',paddingLeft:5,fontSize:18}}>*</div>
                                </div>
                                <div style={{ width: '90%', padding: '10px 0' }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="名称"
                                        variant="outlined"
                                        focused
                                        color=''
                                        value={name}
                                        onChange={name_Change}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%', fontSize: 15, borderColor: 'rgba(0, 0, 0, 0.6) !import' }}
                                    />
                                </div>
                                <div style={{ width: '90%', padding: '10px 0' }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="介绍"
                                        focused
                                        variant="outlined"
                                        value={introduction}
                                        onChange={introduction_Change}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%', fontSize: 15 }}
                                    />
                                </div>

                                {/* <div>系统提示词</div>
                                <div>上传文件</div>
                                <div>添加新步骤</div>
                                <div>总结答案</div>
                                <div>整体测试</div> */}
                                {
                                    cut_type != 1 ? <div style={{ width: '90%', padding: '10px 0' }}>
                                        <TextField
                                            id="filled-multiline-static"
                                            multiline
                                            rows={4}
                                            label="提示词，可通过@调用可用智能体"
                                            focused
                                            variant="outlined"
                                            value={prompt_value}
                                            onChange={prompt_Change}
                                            inputProps={{ style: { fontSize: 13 } }}
                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                            style={{ width: '100%', fontSize: 15 }}
                                        />
                                    </div> : ''
                                }
                            </div>
                            {
                                cut_type == 1 ? <div style={{ width: '90%', padding: '10px 5%' }}>
                                    <div style={{display:'flex'}}>
                                        <div style={{ fontSize: 15, fontWeight: 'bold', paddingBottom: 20 }}>文件上传（选填）</div>
                                        {/* <div style={{color:'red',paddingLeft:5,fontSize:18}}>*</div> */}
                                    </div>
                                    <div style={{ paddingBottom: 20 }}>
                                        <Button variant="contained" style={{ width: '20%', height: 50, background: base_setting?.primary_color}} onClick={file_content}>上传文件</Button>
                                        <input type="file" accept='.pdf,.txt,.pptx,.xlsx,.docx,.csv,.html,.json,.md,.png,.jpg' ref={uploadFileInput} onChange={handleFileChange} />
                                    </div>
                                    <div>
                                        {
                                            file_lists.map((item, index) => <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0', alignItems: 'center', paddingBottom: 10 }} key={'file_lists' + index}>
                                                <div style={{ position: 'relative' }}>
                                                    <div style={{ display: 'flex', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', borderRadius: 3, alignItems: 'center' }}>
                                                        <div style={{ fontSize: 15, backgroundColor: base_setting?.primary_color, color: '#fff', padding: 6, borderRadius: 2 }}>{item.type}</div>
                                                        <div style={{ padding: '3px 8px' }} className='filename'>{item.name}</div>
                                                        <div onClick={() => file_check(item.content)} style={{ fontSize: 14, backgroundColor: base_setting?.primary_color, color: '#fff', padding: 6, borderRadius: 2, cursor: 'pointer' }}>点击查看</div>
                                                    </div>
                                                    <div onClick={()=>delete_file(item.id)} style={{ position: 'absolute', right: '-10px', top: '-10px', background: '#fff', boxShadow: '0px 0px 5px 0px rgba(51,51,51,0.3)', borderRadius: '50%', padding: '1px 1px', cursor: 'pointer' ,width:15,height:15,display:'flex',alignItems:'center'}}>
                                                        <CancelIcon style={{ fontSize: 15 }}></CancelIcon>
                                                    </div>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                    <div style={{ width: '70%', padding: '10px 0' }}>
                                        <TextField
                                            id="outlined-basic"
                                            label="系统提示词"
                                            focused
                                            variant="outlined"
                                            value={system_work}
                                            onChange={work_Change}
                                            inputProps={{ style: { fontSize: 13 } }}
                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                            style={{ width: '100%', fontSize: 15 }}
                                        />
                                    </div>
                                    <div style={{display:'flex',padding: '20px 0'}}>
                                        <div style={{ fontSize: 15, fontWeight: 'bold',  }}>多步骤智能体调用</div>
                                        <div style={{color:'red',paddingLeft:5,fontSize:18}}>*</div>
                                    </div>
                                    {
                                        test_lists.map((item, index) => <div key={index} style={{ paddingBottom: 20, width: '100%', borderLeft: '3px solid #b0b0b0', paddingLeft: 10 }}>
                                            <ResultLists message_value={item.prompt} propmpt_value={propmpt_value} delete_propmpt={delete_propmpt} id={item.id} index={index}></ResultLists>
                                        </div>)
                                    }
                                    <div onClick={add_result_lists} style={{ marginTop: 10, marginBottom: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', width: 100, justifyContent: 'left' }}>
                                        <div style={{ fontSize: 13, color: 'green', paddingRight: 10 }}>添加</div>
                                        <AddCircleIcon style={{ fontSize: 30, color: '#b0b0b0' }} />
                                    </div>
                                </div> : ''
                            }

                            <div style={{ minWidth: '500px', padding: '5%', width: '70%', paddingTop: 10 }}>

                                {
                                    cut_type == 1 ? <div>
                                        <div style={{display:'flex',padding: '20px 0'}}>
                                            <div style={{ fontSize: 15, fontWeight: 'bold',  }}>总结提示词</div>
                                            <div style={{color:'red',paddingLeft:5,fontSize:18,paddingRight:5}}>*</div>
                                            <Tooltip title='总结上述列表运行结果'>
                                                <HelpIcon style={{ color: 'rgba(215, 215, 215)', fontSize: 20 }}>
                                                </HelpIcon>
                                            </Tooltip>
                                        </div>
                                        <div style={{ width: '90%', padding: '10px 0' }}>
                                            <TextField
                                                id="outlined-basic"
                                                label="总结提示词"
                                                focused
                                                variant="outlined"
                                                value={summarize_work}
                                                onChange={summarize_Change}
                                                inputProps={{ style: { fontSize: 13 } }}
                                                InputLabelProps={{ style: { fontSize: 13 } }}
                                                style={{ width: '100%', fontSize: 15 }}
                                            />
                                        </div>
                                        <div style={{ textAlign: 'center', width: '90%', paddingTop: 20 }}>
                                            <Button variant="contained" style={{ width: '100%', height: 50, background: base_setting?.primary_color }} onClick={lists_test}>测试智能体</Button>
                                        </div>
                                    </div> : ''
                                }

                                {
                                    cut_type === 2 ? <div>
                                        调用方法：
                                        <div style={{ width: '90%', padding: '10px 0' }}>
                                            <ButtonGroup
                                                variant="contained"
                                                ref={anchorRef}
                                                aria-label="Button group with a nested menu"
                                            >
                                                <Button style={{ background: base_setting?.primary_color, width: 100, borderColor: '#fff' }} onClick={handleClick}>{options[selectedIndex]}</Button>
                                                <Button
                                                    size="small"
                                                    aria-controls={open ? 'split-button-menu' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-label="select merge strategy"
                                                    aria-haspopup="menu"
                                                    style={{ background: base_setting?.primary_color}}
                                                    onClick={handleToggle}
                                                >
                                                    <ArrowDropDownIcon />
                                                </Button>
                                            </ButtonGroup>
                                            <Popper
                                                sx={{
                                                    zIndex: 1,
                                                }}
                                                open={open}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === 'bottom' ? 'center top' : 'center bottom',
                                                            width: 120,
                                                            background: '#fff',
                                                            zIndex: 99
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose}>
                                                                <MenuList id="split-button-menu" autoFocusItem>
                                                                    {options.map((option, index) => (
                                                                        <MenuItem
                                                                            key={option}
                                                                            selected={index === selectedIndex}
                                                                            style={{color:base_setting?.primary_color}}
                                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                                        >
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>

                                        </div>

                                        <div>
                                            <div>headers请求头</div>
                                            <div style={{ width: '90%', paddingTop: 10 }}>
                                                <TextField
                                                    id="filled-multiline-static"
                                                    multiline
                                                    rows={4}
                                                    label="请求头"
                                                    focused
                                                    variant="outlined"
                                                    value={headers_value}
                                                    onChange={(e) => setHeadersValue(e.target.value)}
                                                    inputProps={{ style: { fontSize: 13 } }}
                                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                                    style={{ width: '100%', fontSize: 15 }}
                                                />
                                            </div>
                                            <div style={{ fontSize: 11, color: '#2d2d2d', paddingBottom: 10 }}>
                                                *属性键必须加双引号，如:"xx":"xx"
                                            </div>
                                        </div>
                                        <div style={{ width: '90%', paddingTop: 10 }}>
                                            <TextField
                                                id="outlined-basic"
                                                label="url"
                                                variant="outlined"
                                                focused
                                                value={url_value}
                                                onChange={(e) => setUrlValue(e.target.value)}
                                                inputProps={{ style: { fontSize: 13 } }}
                                                InputLabelProps={{ style: { fontSize: 13 } }}
                                                style={{ width: '100%', fontSize: 15 }}
                                            />
                                        </div>
                                        {
                                            query_checked === true ? <div style={{ fontSize: 11, color: '#2d2d2d', paddingBottom: 10 }}>
                                                *query Params打开后该url废弃使用query Params url
                                            </div> : ''
                                        }
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>是否包含body</div>
                                                <div style={{ paddingLeft: 10 }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        否
                                                        <Switch
                                                            checked={body_checked}
                                                            onChange={bodyChange}
                                                            style={{color:base_setting?.primary_color}}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        是
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                body_checked === true ? <div>
                                                    <div style={{ width: '90%', paddingTop: 10 }}>
                                                        <TextField
                                                            id="filled-multiline-static"
                                                            multiline
                                                            rows={4}
                                                            label="body"
                                                            variant="outlined"
                                                            focused
                                                            value={body_value}
                                                            onChange={(e) => setBodyValue(e.target.value)}
                                                            inputProps={{ style: { fontSize: 13 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />
                                                    </div>
                                                    <div style={{ fontSize: 11, color: '#2d2d2d', paddingBottom: 10 }}>
                                                        *属性键必须加双引号，如:"xx":"xx"
                                                    </div>
                                                    <div style={{ width: '90%', paddingTop: 10 }}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="body参数解析"
                                                            focused
                                                            variant="outlined"
                                                            value={body_params}
                                                            onChange={(e) => setBodyParams(e.target.value)}
                                                            inputProps={{ style: { fontSize: 13 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />
                                                    </div>
                                                    <div style={{ fontSize: 11, color: '#2d2d2d', paddingBottom: 10 }}>
                                                        *例：extract_prompt是提取提示词，url是提取链接
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>是否包含query Params</div>
                                                <div style={{ paddingLeft: 10 }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        否
                                                        <Switch
                                                            checked={query_checked}
                                                            onChange={(e) => queryChange(e)}
                                                            style={{color:base_setting?.primary_color}}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                        是
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                query_checked === true ? <div>
                                                    <div style={{ width: '90%', paddingTop: 10 }}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="query Params"
                                                            variant="outlined"
                                                            focused
                                                            value={query_value}
                                                            onChange={(e) => setQueryValue(e.target.value)}
                                                            inputProps={{ style: { fontSize: 13 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />
                                                    </div>
                                                    <div style={{ fontSize: 11, color: '#2d2d2d', paddingBottom: 10 }}>
                                                        *例：https://xxxx?user_name=浏览量&post_id=123455
                                                    </div>
                                                    <div style={{ width: '90%', paddingTop: 10 }}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="query Params参数解析"
                                                            focused
                                                            variant="outlined"
                                                            value={params_value}
                                                            onChange={(e) => setParamsValue(e.target.value)}
                                                            inputProps={{ style: { fontSize: 13 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />
                                                    </div>
                                                    <div style={{ fontSize: 11, color: '#2d2d2d', paddingBottom: 10 }}>
                                                        *例：user_name是用户名，post_id是内容id
                                                    </div>
                                                </div> : ''
                                            }
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ textAlign: 'center', width: '40%', }}>
                                                <Button variant="contained" style={{ width: '100%', height: 50, background: base_setting?.primary_color }} onClick={test_create_agent}>测试</Button>
                                            </div>
                                            {
                                                is_loading === true ? <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                    <Spin />
                                                </div> : ''
                                            }

                                            <div style={{ textAlign: 'center', width: '40%', paddingLeft: '5%' }}>
                                                <Button variant="contained" disabled={!test_success} className={test_success !== true ? classes.disabled_button : ''} style={{ width: '100%', height: 50, background: base_setting?.primary_color, color: '#fff' }} onClick={create_agent_list}>创建</Button>
                                            </div>
                                        </div>
                                    </div> : ""
                                }
                                {
                                    cut_type === 0 ? <div>
                                        <div style={{ width: '90%', padding: '10px 0', display: 'flex' }}>
                                            <div style={{ width: 'calc(100% - 60px)', marginRight: 10 }}>
                                                <div style={{ fontSize: 14, fontWeight: 'bold', paddingBottom: 10 }}>使用知识库列表</div>
                                                <div style={{ borderLeft: '3px solid #b0b0b0' }}>
                                                    {
                                                        vector_db.map((item, index) => <div style={{ marginTop: 10, display: 'flex', alignItems: 'center' }} key={index}>
                                                            <div style={{ padding: '0 10px', fontWeight: '500' }}>{index + 1}</div>
                                                            <TextField
                                                                id="outlined-basic"
                                                                label="知识库id"
                                                                variant="outlined"
                                                                value={item}
                                                                onChange={(e) => vector_Change(e.target.value, index)}
                                                                inputProps={{ style: { fontSize: 13 } }}
                                                                InputLabelProps={{ style: { fontSize: 13 } }}
                                                                style={{ width: '100%' }}
                                                            />
                                                            <div style={{ paddingLeft: 10, cursor: 'pointer' }} onClick={() => delete_lists(index)}>
                                                                <CancelIcon style={{ color: '#b0b0b0' }} />
                                                            </div>
                                                        </div>)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={add_input} style={{ margin: '10px auto', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ fontSize: 13, color: 'green', paddingRight: 10 }}>添加</div>
                                            <AddCircleIcon style={{ fontSize: 30, color: '#b0b0b0' }} />
                                        </div>
                                    </div> : ''
                                }
                                {
                                    cut_type != 2 ? <div style={{ textAlign: 'center', width: '90%', paddingTop: 20 }}>
                                        <Button variant="contained" style={{ width: '100%', height: 50, background: base_setting?.primary_color }} onClick={create_agent_list}>创建</Button>
                                    </div> : ''
                                }

                            </div>
                        </div>
                    </div>
                    <Dialog onClose={promptClose} open={prompt_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                        <div style={{ position: 'absolute', right: 20, top: 20 }} onClick={promptClose}>
                            <CancelIcon style={{ fontSize: 20 }} />
                        </div>
                        <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>博槐智能体列表</DialogTitle>
                        <div style={{ padding: 50, paddingTop: 0 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>名称</TableCell>
                                            <TableCell align="left">耗费提问次数</TableCell>
                                            <TableCell align="left">介绍</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {hint_agent?.map((row) => (
                                            <TableRow
                                                hover
                                                key={row.name}
                                                onClick={(e) => handleAgent(row.prompt, row.expression, row.introduction)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.cost}</TableCell>
                                                <TableCell align="left">{row.introduction}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Dialog>
                    <Dialog onClose={testClose} open={test_agent_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                        <div style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer' }} onClick={testClose}>
                            <CancelIcon style={{ fontSize: 20 }} />
                        </div>
                        <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>提取内容测试结果</DialogTitle>
                        <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px', minHeight: '20vh' }}>
                            <div>
                                <div style={{ fontSize: 16, background: '#f2f4f8', padding: 10, borderRadius: 8 }}>
                                    <Markdown>{test_agent_value}</Markdown>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog onClose={fileClose} open={is_file_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                        <div style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer' }} onClick={fileClose}>
                            <CancelIcon style={{ fontSize: 20 }} />
                        </div>
                        <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>文件内容</DialogTitle>
                        <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px', minHeight: '20vh' }}>
                            <div>
                                <div style={{ fontSize: 16, background: '#f2f4f8', padding: 10, borderRadius: 8 }}>
                                    <Markdown>{file_dialog}</Markdown>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div >
            <RecordingDrawer></RecordingDrawer>
        </div>
    )
}

export default CreateAgent