import React from "react";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {login} from '../../../actions/user';
import { Redirect } from "react-router-dom";
import {initSocket,emit} from '../../../actions/chat'


const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: '100%',
        maxWidth: '100%',
        height: '100vh',

    },

    title: {
        flexGrow: 1,
    },
}));


function Login() {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const token = useSelector((state) => state.user.token);
    console.log(useSelector((state) => state))

    const dispatch = useDispatch();

    const submit = () => {
        dispatch(login(username, password));
    };

    const testfn = (e) => {
        setUsername(e.target.value);
    };
    

    const classes = useStyles();
    
    if(!_.isNil(token)){
        //聊天登录
        // const user_info=useSelector((state)=>JSON.parse(state.user.user));
        // emit('socket_login', {user_id:user_info.id});
        return <Redirect to={'/'} />;
    }

    

    return (
        <Container className={classes.root}>
            <div style={{display: 'flex',flexDirection: 'column',}}>
                <TextField required id="1" label="Username" value={username} onChange={(event)=>testfn(event)}/>
                <TextField required id="2" label="Password" value={password}  onChange={(event)=>setPassword(event.target.value)}/>
                <Button style={{marginTop: '15px'}} variant="contained" color="primary" onClick={()=>submit()}>Login</Button>

            </div>

        </Container>
    );
}

export default Login;