import {CREATE_DATASET,GET_DATASET,COLLECTION_LISTS,COLLECTION_DATA} from './types';
import {MIDDLE_EARTH_URL, TAX_NAME_MAP, INDUSTRY_NAME_MAP} from '../configs/constants'
import fetchJsonp from 'fetch-jsonp'
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function create_datasets(data){
    return {
        type:CREATE_DATASET,
        data
    }
}
export function get_dataset_lists(data,get_type){
    return {
        type:GET_DATASET,
        data,
        get_type
    }
}
export function collection_lists(data){
    return {
        type:COLLECTION_LISTS,
        data
    }
}
export function collection_data(data){
    return {
        type:COLLECTION_DATA,
        data
    }
}

export function create_dataset(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_create_dataset/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            if(data.type==='user'){
                dispatch(get_dataset({type:'user'},(v)=>setIsLoading(v)))
            }else{
                dispatch(get_dataset({type:'org',org_id:data.org_id},(v)=>setIsLoading(v)))
            }
        })
    }
}
// 一级
export function get_dataset(data,setIsLoading){
    setIsLoading(true)
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_get_dataset/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setIsLoading(false)
            dispatch(get_dataset_lists(res.data.dataset_list,data.type),(v)=>setIsLoading(v))
        }).catch(err=>{
            setIsLoading(false)
        })
    }
}
// 一级删除
export function delete_dataset(data,type,org_id,setIsLoading){
    return (dispatch,getState)=>{
        if(type==='user'){
            $http.delete(`${MIDDLE_EARTH_URL}/llm/fg_delete_dataset/?datasetId=`+data,{},{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(res=>{
                console.log(res)
                dispatch(get_dataset({type:'user'},(v)=>setIsLoading(v)))
            })
        }else{
            $http.delete(`${MIDDLE_EARTH_URL}/llm/fg_delete_dataset/?datasetId=`+data+'&type=org&org_id='+org_id,{},{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(res=>{
                console.log(res)
                dispatch(get_dataset({type:'org',org_id:data.org_id},(v)=>setIsLoading(v)))
            })
        }
    }
}
// 一级更新
export function upload_dataset(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_update_dataset/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            if(data.type==='user'){
                dispatch(get_dataset({type:'user'},(v)=>setIsLoading(v)))
            }else{
                dispatch(get_dataset({type:'org',org_id:data.org_id},(v)=>setIsLoading(v)))
            }
        })
    }
}
// 二级删除
export function delete_collection(data,id,setIsLoading){
    console.log(data)
    return (dispatch,getState)=>{
        $http.delete(`${MIDDLE_EARTH_URL}/llm/fg_delete_collection/?collectionId=`+data,{},{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_list({ datasetId: id },(v)=>setIsLoading(v)))
        })
    }
}
// 三级删除
export function delete_collection_data(data,id,setIsLoading){
    return (dispatch,getState)=>{
        $http.delete(`${MIDDLE_EARTH_URL}/llm/fg_delete_collection_data/?dataId=`+data,{},{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_data({collectionId:id},(v)=>setIsLoading(v)))
        })
    }
}
// 二级
export function get_collection_list(data,setIsLoading){
    setIsLoading(true)
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_get_collection_list_by_dataset_id/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setIsLoading(false)
            dispatch(collection_lists(res.data.result.data))
        }).catch(err=>{
            setIsLoading(false)
        })
    }
}
// 二级链接创建
export function url_content(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_upload_url_content/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_list({datasetId:data.datasetId},(v)=>setIsLoading(v)))
        })
    }
}
// 三级
export function get_collection_data(data,setIsLoading){
    setIsLoading(true)
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_get_collection_data_list_by_collection_id/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setIsLoading(false)
            dispatch(collection_data(res.data.result.data))
        }).catch(err=>{
            setIsLoading(false)
        })
    }
}
// 上传
export function fg_upload(formData,id,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_upload_and_create_collection/`,formData,{
            headers:{
                'content-type': 'multipart/form-data', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_list({datasetId:id},(v)=>setIsLoading(v)))
            
        })
    }
}

// 二级手动录入
export function manual_content(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_upload_manual_content/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_list({datasetId:data.datasetId},(v)=>setIsLoading(v)))
            // dispatch(collection_data(res.data.result.data))
        })
    }
}
// 二级更新
export function upload_collection(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_update_collection/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_list({datasetId:data.datasetId},(v)=>setIsLoading(v)))
            // dispatch(collection_data(res.data.result.data))
        })
    }
}
// 一级勾选
export function update_dataset(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_check_or_uncheck_dataset_list/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            if(data.type==='user'){
                dispatch(get_dataset({type:'user'},(v)=>setIsLoading(v)))
            }else{
                dispatch(get_dataset({type:'org',org_id:data.org_id},(v)=>setIsLoading(v)))
            } 
        })
    }
}
// 发送验证码
export function send_mobile(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/user/send-mobile-login-code/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
        })
    }
}

// 三级数据创建
export function manual_create_content(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_manual_create_content_in_collection_data/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_data({collectionId:data.collectionId},(v)=>setIsLoading(v)))
        })
    }
}

// 三级数据修改
export function update_create_collection_data(data,setIsLoading){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/fg_update_collection_data/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_collection_data({collectionId:data.collectionId},(v)=>setIsLoading(v)))
        })
    }
}

export function quick_tax_plan_api(data,setValueText){
    setValueText('')
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 300000
        })
        fetch(`${MIDDLE_EARTH_URL}/llm/test_stream_api/`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                // Authorization:'token 89ff9bce39228449d2727b6ce44b789df44c90ed'
            },
            responseType: 'stream',
            body:JSON.stringify(data)
        }).then(async response=>{
            const reader = response.body.getReader();
            const textDecoder = new TextDecoder();
            let result = true;
            let output = ''
            while (result) {
                const { done, value } = await reader.read();
                if (done) {
                    console.log('Stream ended');
                    result = false;
                    break;
                }
                const chunkText = textDecoder.decode(value);
                console.log(chunkText)
                output += chunkText;
                setValueText(output)
            }     
        })
    }
}

export function test_agent_run(data,setTestAgentValue){
    setTestAgentValue('')
    return (dispatch,getState)=>{
        fetch(`${MIDDLE_EARTH_URL}/llm/test_agent_run/`,{
            method:'POST',
            headers:{
                'Content-Type': 'application/json',
                Authorization:'token '+localStorage.getItem('token')
            },
            responseType: 'stream',
            body:JSON.stringify(data)
        }).then(async response=>{
            const reader = response.body.getReader();
            const textDecoder = new TextDecoder();
            let result = true;
            let output = ''
            while (result) {
                const { done, value } = await reader.read();
                if (done) {
                    console.log('Stream ended');
                    result = false;
                    break;
                }
                const chunkText = textDecoder.decode(value);
                console.log(chunkText)
                output += chunkText;
                setTestAgentValue(output)
            }     
        })
    }
}
export function get_file_content(formData,name,file_history,setFileHistory,lists,type,setFileLists){
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 500000
        })
        const config = {
            headers: {
                'Contennt-type': 'multipart/form-data',
                Authorization: 'token ' +localStorage.getItem('token')
            }
        }
        instannce.post(`${MIDDLE_EARTH_URL}/llm/get_file_content`, formData, config).then((res)=>{
            console.log(res)
            let file_lists=[...file_history]
            let id='file' + uuidv4()
            if(res.status==200){
                file_lists.push({role:'user',content:'上传了文件'+name,id:id})
                file_lists.push({role:'assistant',content:res.data.data,id:id})
                setFileHistory(file_lists)
            }
            let list = [...lists]
            list.push({ type: type, name: name ,content:res.data.data,id:id})
            setFileLists(list)
        })
    }
}