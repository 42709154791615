import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import './general.css'
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { v4 as uuidv4 } from 'uuid';
import { get_crm_customer_detail ,update_crm_customer} from '../../../actions/user'
import { message, Spin } from 'antd';
const _ = require('micro-dash');

function UpdateCrm(data) {
    let _id=data.location.state.id
    console.log(_id)
    const dispatch = useDispatch();
    const user_type = useSelector((state) => state.user.user_type);
    const [name, setName] = React.useState('');
    const [job_title, setJobTitle] = React.useState('');
    const [company_info, setCompanyInfo] = React.useState('');
    const [company_size, setCompanySize] = React.useState('');
    const [industry, setIndustry] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [wechat, setWechat] = React.useState('');
    const [internal_notes, setInternalNotes] = React.useState('');
    const [brief, setBrief] = React.useState('');
    const [preference, setPreference] = React.useState('');
    const [importance_level, setImportanceLevel] = React.useState(1);
    const [is_manager_editable_only, setIsManagerEditableOnly] = React.useState(false);
    const [is_update,setIsUpdate]=React.useState(false);
    const [crm_info,setCrmInfo]=React.useState(null);

    const create_user=()=>{
        if(name==''){
            message.error('名称不能为空')
        }else{
            let data={
                name:name,
                job_title:job_title,
                company_info:company_info,
                company_size:company_size,
                industry:industry,
                address:address,
                email:email,
                phone:phone,
                wechat:wechat,
                internal_notes:internal_notes,
                brief:brief,
                preference:preference,
                importance_level:importance_level,
                is_manager_editable_only:is_manager_editable_only
            }
            dispatch(update_crm_customer({data:data},(v)=>setIsUpdate(v)))
        }
    }
    useEffect(()=>{
        if(is_update){
            message.success('修改成功')
            setIsUpdate(false)
        }
    },[is_update])
    useEffect(()=>{
        dispatch(get_crm_customer_detail(_id,(v)=>setCrmInfo(v)))
    },[])
    useEffect(()=>{
        if(!_.isNil(crm_info)){
            setName(crm_info?.name)
            setJobTitle(crm_info?.job_title)
            setCompanyInfo(crm_info?.company_info)
            setCompanySize(crm_info?.company_size)
            setIndustry(crm_info?.industry)
            setAddress(crm_info?.address)
            setEmail(crm_info?.email)
            setPhone(crm_info?.phone)
            setWechat(crm_info?.wechat)
            setInternalNotes(crm_info?.internal_notes)
            setBrief(crm_info?.brief)
            setPreference(crm_info?.preference)
            setImportanceLevel(crm_info?.importance_level)
            setIsManagerEditableOnly(crm_info?.is_manager_editable_only)
        }
    },[crm_info])
    return <div>
        <div>
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                        <TabbleLists type='5'></TabbleLists>
                    </div>
                    <div style={{ padding: '3% 5%', width: '100%', height: 'calc(90vh - 120px)', overflowY: 'auto' }}>
                        <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                            <Link to='/largemodel/crm_user' style={{ cursor: 'pointer' }}>CRM列表</Link>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div>
                            <div style={{ cursor: 'pointer' }}>修改CRM用户</div>
                        </div>
                        <div style={{ padding: '20px 0' }}>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="名称"
                                    variant="outlined"
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="工作头衔"
                                    variant="outlined"
                                    value={job_title}
                                    onChange={(e)=>setJobTitle(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="公司名"
                                    variant="outlined"
                                    value={company_info}
                                    onChange={(e)=>setCompanyInfo(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="公司规模"
                                    variant="outlined"
                                    value={company_size}
                                    onChange={(e)=>setCompanySize(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="行业"
                                    variant="outlined"
                                    value={industry}
                                    onChange={(e)=>setIndustry(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="地址"
                                    variant="outlined"
                                    value={address}
                                    onChange={(e)=>setAddress(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="电话"
                                    variant="outlined"
                                    value={phone}
                                    onChange={(e)=>setPhone(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="微信id"
                                    variant="outlined"
                                    value={wechat}
                                    onChange={(e)=>setWechat(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="内部笔记"
                                    variant="outlined"
                                    value={internal_notes}
                                    multiline
                                    rows={4}
                                    onChange={(e)=>setInternalNotes(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="介绍"
                                    variant="outlined"
                                    value={brief}
                                    multiline
                                    rows={4}
                                    onChange={(e)=>setBrief(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="喜好"
                                    variant="outlined"
                                    value={preference}
                                    onChange={(e)=>setPreference(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '40%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">重要性程度</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={importance_level}
                                        label="Age"
                                        style={{ width: '40%' }}
                                        onChange={(e)=>setImportanceLevel(e.target.value)}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <FormControlLabel control={<Switch defaultChecked onChange={(e)=>setIsManagerEditableOnly(e.target.checked)} disabled={user_type>=170?false:true} checked={is_manager_editable_only} />} label="唯有管理员能修改" />
                            </div>
                            <div style={{ textAlign: 'left', width: '100%', display: 'flex' }}>
                                <Button variant="contained"  style={{ width: '40%', height: 40, }} onClick={create_user}>修改</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </div>
}
export default UpdateCrm