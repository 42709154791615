import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import shui_engine from '../../static/logo/logo_2.jpg'
import home from '../../static/image/home.png'
import library from '../../static/image/library.png'
import monitoring from '../../static/image/monitoring.png'
import people from '../../static/image/people.png'
import user_img from '../../static/image/user_img.png'
import mine_img from '../../static/image/mine_img.png'
import intelligent from '../../static/image/intelligent.png'
import set_img from '../../static/image/set_img.png'
import recording_img from '../../static/image/recording_img.png'
import crm_img from '../../static/image/crm_crm.png'
import adhibition_apps from '../../static/image/adhibition_apps.png'
import enlightenment_img from '../../static/image/enlightenment_img.png'
import {get_outsider_settings,cut_tab_action,get_update_user_info} from '../../actions/user'

const _ = require('micro-dash');


function TabbleLists(e) {
    const user_type = useSelector((state) => state.user.user_type);
    const is_first_login=useSelector((state) => state.user.is_first_login);
    const token = useSelector((state) => state.user.token);
    const org_id=useSelector((state) => state.user.org_id);
    const tab_settings=useSelector((state) => state.user.tab_settings);
    const other_menus=useSelector((state) => state.user.other_menus);
    const dispatch = useDispatch();
    const tab_action=useSelector((state) => state.user.tab_action);
    const location = useLocation();
    const url = location.pathname;
    console.log(url);
    useEffect(()=>{
        if(!_.isNil(token)&&!is_first_login){
            console.log(token)
            dispatch(get_update_user_info())
        }
    },[token,dispatch])
    useEffect(()=>{
        dispatch(get_outsider_settings())
    },[])
    const cut_tab=(event)=>{
        console.log(event)
        dispatch(cut_tab_action(event))
    }
    const tab_cut_index=()=>{
        let tab=[
            '/largemodel'
        ]
        for(let i=0;i<other_menus.length;i++){
            tab.push(other_menus[i].url)
        }
        tab.push('/largemodel/recording_index')
        if(user_type>=100){
            tab.push('/largemodel/crm_user')
        }
        tab.push('/largemodel/agent_list')
        if(user_type>=190){
            tab.push('/largemodel/people')
        }
        tab.push('/largemodel/mine')
        if(user_type >= 190 &&_.isNil(org_id)){
            tab.push('/largemodel/setting_block')
        }
        for(let k=0;k<tab.length;k++){
            if(tab[k]==url){
                dispatch(cut_tab_action(k))
            }else if(url=='/largemodel/create_monitoring'){
                if(tab[k]=='/largemodel/monitoring'){
                    dispatch(cut_tab_action(k))
                }
            }else if(url=='/largemodel/create_agent'){
                if(tab[k]=='/largemodel/intelligent_agent'){
                    dispatch(cut_tab_action(k))
                }
            }else if(url=='/largemodel/update_enlightenment'||url=='/largemodel/register_summarize'||url=='/largemodel/enlightenment_info'){
                if(tab[k]=='/largemodel/enlightenment_lists'){
                    dispatch(cut_tab_action(k))
                }
            }else if(url=='/largemodel/create_crm'||url=='/largemodel/update_crm'){
                if(tab[k]=='/largemodel/crm_user'){
                    dispatch(cut_tab_action(k))
                }
            }else if(url=='/largemodel/user_information'){
                if(tab[k]=='/largemodel/mine'){
                    dispatch(cut_tab_action(k))
                }
            }
        }
    }
    useEffect(()=>{
        tab_cut_index()
    },[other_menus,url])
    return (
        <div style={{ paddingBottom: 10 }}>
            <Link to='/largemodel' onClick={()=>cut_tab(0)} style={{ display: 'flex', alignItems: 'center',background: tab_action==0?'rgb(221, 221, 221,0.6)':'#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={home} alt='' style={{ width: 21, height: 21 }} />
                    </div>
                    <div style={{ paddingLeft: 15, color: '#000' }}>智能对话</div>
                </div>
            </Link>
            {
                other_menus.map((item,index)=><Link key={'tab'+index} to={item.url} onClick={()=>cut_tab(index+1)} style={{ display: 'flex', alignItems: 'center',background: tab_action==index+1?'rgb(221, 221, 221,0.6)':'#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={item.img} alt='' style={{ width: 21, height: 21 }} />
                    </div>
                    <div style={{ paddingLeft: 15, color: '#000' }}>{item.name}</div>
                </div>
            </Link>)
            }
            <Link to='/largemodel/recording_index' onClick={()=>cut_tab(other_menus?.length+1)} style={{ display: 'flex', alignItems: 'center',background: tab_action==other_menus?.length+1?'rgb(221, 221, 221,0.6)':'#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={enlightenment_img} alt='' style={{ width: 21, height: 21 }} />
                    </div>
                    <div style={{ paddingLeft: 15, color: '#000' }}>AI抄录员</div>
                </div>
            </Link>
            {
                user_type >= 100?<Link to='/largemodel/crm_user' onClick={()=>cut_tab(other_menus?.length+2)} style={{ display: 'flex', alignItems: 'center',background: tab_action==other_menus?.length+2?'rgb(221, 221, 221,0.6)':'#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={crm_img} alt='' style={{ width: 21, height: 21 }} />
                    </div>
                    <div style={{ paddingLeft: 15, color: '#000' }}>CRM</div>
                </div>
            </Link>:''
            }
            <Link to='/largemodel/agent_list' onClick={()=>cut_tab(user_type >= 100?other_menus?.length+3:other_menus?.length+2)} style={{ display: 'flex', alignItems: 'center',background: tab_action==(user_type >= 100?other_menus?.length+3:other_menus?.length+2)?'rgb(221, 221, 221,0.6)':'#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={adhibition_apps} alt='' style={{ width: 21, height: 21 }} />
                    </div>
                    <div style={{ paddingLeft: 15, color: '#000' }}>应用列表</div>
                </div>
            </Link>
            {
                user_type >= 190? <Link to='/largemodel/people' onClick={()=>cut_tab(other_menus?.length+4)} style={{ display: 'flex', alignItems: 'center' ,background: tab_action==other_menus?.length+4?'rgb(221, 221, 221,0.6)':'#fff'}}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={people} alt='' style={{ width: 21, height: 21 }} />
                        </div>
                        <div style={{ paddingLeft: 15, color: '#000' }}>人员管理</div>
                    </div>
                </Link> : ('')
            } 
            <Link to='/largemodel/mine' onClick={()=>cut_tab(user_type<100?other_menus?.length+3:user_type>100&&user_type<190?other_menus?.length+4:other_menus?.length+5)} style={{ display: 'flex', alignItems: 'center', borderRadius: 5 ,background: tab_action==(user_type<100?other_menus?.length+3:user_type>100&&user_type<190?other_menus?.length+4:other_menus?.length+5)?'rgb(221, 221, 221,0.6)':'#fff'}}>
                <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={mine_img} alt='' style={{ width: 21, height: 21 }} />
                    </div>
                    <div style={{ paddingLeft: 15, color: '#000' }}>会员</div>
                </div>
            </Link>
            {
                user_type >= 190 &&_.isNil(org_id)? <Link to='/largemodel/setting_block' onClick={()=>cut_tab(other_menus?.length+6)} style={{ display: 'flex', alignItems: 'center' ,background: tab_action==other_menus?.length+6?'rgb(221, 221, 221,0.6)':'#fff'}}>
                    <div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={set_img} alt='' style={{ width: 21, height: 21 }} />
                        </div>
                        <div style={{ paddingLeft: 15, color: '#000' }}>设置</div>
                    </div>
                </Link> : ('')
            }
        </div>
    )
}
export default TabbleLists