import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import QrCodeIcon from '@mui/icons-material/QrCode';
import AndroidIcon from '@mui/icons-material/Android';
import Dialog from '@mui/material/Dialog';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import styled_components from "styled-components";
import CircleIcon from '@mui/icons-material/Circle';

import {WECHAT_LOGIN_URL,HOME_TITLE,MODEL_COLOR} from '../../../configs/constants'
import {company_name,loading,search_num} from '../../../actions/plan'
import { wechat_web_login } from '../../../actions/user';

import { AllTop_img, AppLoad_image, WebLoad_image } from './onLoad_image';
import bh_xcx_2 from '../../../static/image/bh_xcx_2.jpg'
import kuai_engine from '../../../static/image/kuai_engine.jpg'

import accountant_icon from '../../../static/icon/accountant_icon.png'
import tax_icon from '../../../static/icon/tax_icon.png'

import logo from '../../../static/logo/kuai_logo.jpg';
import white_logo from '../../../static/logo/logo_1.png';
import shui_engine_logo from '../../../static/logo/shui_engine.png';
import tax_shui_bai from '../../../static/logo/shui_bai.jpg'
import left_arrows from '../../../static/image/left_arrows.png'

const _ = require('micro-dash');
makeStyles((theme) => ({
    slickUl: {
        '& li': {
            width: '30px !important',
            height: '15px !important',
            paddingTop: '5px !important',

        },
        '& .slick-active': {
            margin: '0 8px !important',
            '& div': {
                background: '#fff !important',
                padding: '6px 15px !important',
                margin: '0 5px !important'
            }
        }
    }
}));

function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        accessibility: false,
        arrows: false,
        // autoplay: true,
        pauseOnDotsHover: true,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <Slider {...settings}>
            <div>
                <Link to='/initial/intro'>
                    <div style={{ color: '#fff' }}>
                        <h1 style={{ fontSize: 36 }}>动千山智能体</h1>
                        <div style={{ fontSize: 19, minWidth: '400px' }}>
                        动千山智能体是一款集智能问答、知识库、AI定时任务、智能录音与CRM功能于一体的强大产品。它旨在帮助企业内部人员降低成本、提高效率，同时也支持对企业外部的目标客户提供生成式人工智能服务，以传递企业的知识、价值和文化。
                        </div>
                    </div>
                </Link>
            </div>
        </Slider>
    );
}




const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px  !important",
        margin: 0,
        maxWidth: '100%',
        overflowX: 'hidden'
    },
    searchClickButton:{
        backgroundColor:"rgb(0,0,0,0.35) !important",
        color: 'rgba(255,255,255,0.7) !important',
        fontSize: '14px !important',
        paddingInline: '15px !important',
        padding:'8px 0 !important',
        marginInline: '4px !important',
        borderRadius:'16px !important',
        '&:hover': {
            backgroundColor: 'rgb(0,0,0,0.35) !important',
        },
    },
    input:{
        // width: ${(p) => (p.isOpen ? "500px" : "287px")};
        height:'20px',
        borderRadius:'25px',
        transition: 'width 1s ease',
        '-webkit-transition':' width 1s',
        '&::placeholder' :{
            color: 'rgba(255,255,255,0.7)',
            opacity: 1, /* Firefox */
        },
        outline:'none',
        border:'none',
        padding:'10px',
        color:'#fff',
        background:'rgb(0,0,0,0.35)',
        paddingLeft:'35px'
    }
}));

const to_tax_planning = () => {
    // console.log(12)
    // const anchorElement = document.getElementById('tax_planning'); //找到滚动到的元素
    // if (anchorElement) {
    //     anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' }); // 让页面滚动到元素所在位置
    // }
}
const to_tax_accountant = () => {
    const anchorElement = document.getElementById('tax_accountant'); //找到滚动到的元素
    if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' }); // 让页面滚动到元素所在位置
    }
}

function Initial() {
    console.log('location',window.location.host)
    const dispatch = useDispatch();
    const host_name=window.location.host
    const host_type=host_name.indexOf("huainor.com")
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [qr_type,setQrtypoe]=React.useState('')
    const token = useSelector((state) => state.user.token);
    const query = new URLSearchParams(useLocation().search);
    const code = query.get("code");
    const login_state = query.get("state")
    const [needRedirect, setNeedRedirect] = React.useState(false);
    const [isCheckingCode, setIsCheckingCode] = React.useState(null);
    const search_nums = useSelector((state) => state.plan.search_nums);

    useEffect(() => {
        console.log(`code is ${code}, ischecking: ${isCheckingCode}, token: ${token}, ${_.isNil(isCheckingCode)}, ${_.isNil(token)}`)
        document.title = HOME_TITLE;

        if (!_.isNil(code)) {
            setIsCheckingCode(true);
            if (login_state == 'development') {

            } else {
                dispatch(wechat_web_login(code, (v) => setIsCheckingCode(v)));
            }
        };
        console.log(`code is ${code}, ischecking: ${isCheckingCode}, token: ${token}, ${_.isNil(isCheckingCode)}, ${_.isNil(token)}`)

        if (_.isNil(isCheckingCode)) {
            if (_.isNil(token)) {
                setNeedRedirect(true)
            }
        }

    }, []);

    useEffect(() => {
        if (isCheckingCode === false) {
            if (_.isNil(token)) {
                setNeedRedirect(true)

            }
        }

    }, [isCheckingCode]);


    const SimpleDialog=(props)=> {
        const { onClose, selectedValue, open } = props;
    
        const handleClose = () => {
            onClose(selectedValue);
        };
    
        return (
            <Dialog onClose={handleClose} open={open}>
                <div style={{textAlign:'center',paddingTop:'10px',fontSize:'20px',fontWeight:'bold'}}>动千山智能体</div>
                <img src={selectedValue} style={{ width: 300 }} alt='' />
                <div style={{textAlign:'center',paddingBottom:10}}>仅支持安卓app下载</div>
            </Dialog>
        );
    }
    const SampleNextArrow=(props)=> {
        const { className, style, onClick } = props;
        return (
          <div
            // className={className}
            style={{ ...style, display: "block",right:'5%',top:'50%',position:'absolute',zIndex:99,cursor: 'pointer'}}
            onClick={onClick}
          ><ArrowForwardIosIcon style={{fontSize:40,color:'#666'}} /></div>
        );
      }
      const SamplePrevArrow=(props)=> {
        // console.log(props)
        const { className, style, onClick } = props;
        return (
          <div
            // className={className}
            style={{ ...style, display: "block",left:'5%',top:'50%',position:'absolute',zIndex:99,cursor: 'pointer' }}
            onClick={onClick}
          ><ArrowBackIosIcon style={{fontSize:40,color:'#666'}} /></div>
        );
      }
    const Shuilider=()=> {
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            accessibility: false,
            // arrows: false,
            autoplay: false,
            pauseOnDotsHover: true,
            adaptiveHeight: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };
            const [add_theme, setAddTheme] = React.useState(false);
    const themeClose=()=>{
        setAddTheme(false)
    }
    const app_download=()=>{
        setAddTheme(true)
    }
        return (
            <Slider {...settings}>
                <div >
                    <h2 style={{ fontSize: 30, textAlign: 'center', margin: '60px 0', cursor: 'pointer' }} id='tax_planning' onClick={() => handleClickOpen(1)}>动千山智能体<QrCodeIcon style={{ marginLeft: 10, cursor: 'pointer' }} /></h2>
                    <div style={{ display: 'flex', padding: 20, paddingBottom: 70,justifyContent:'center' }}>
                        <AppLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/dongqiansan_phone.gif'} />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '12%' }}>
                            <img src={shui_engine_logo} style={{ width: 50, height: 50, marginTop: -150 }} />
                        </div>
                        <div style={{ width: '25.5%' }}>
                        <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>动千山智能体简介</h4>
                            <div style={{ color: '#536682', padding: '30px 0', width: 350, fontSize: 18 }}>动千山智能体是一款集智能问答、知识库、AI定时任务、多智能体、智能录音与CRM功能于一体的强大产品。它旨在帮助企业内部人员降低成本、提高效率，同时也支持对企业外部的目标客户提供生成式人工智能服务，以传递企业的知识、价值和文化。</div>
                            <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>多样化的访问形式</h4>
                            <div style={{ color: '#536682', padding: '30px 0', width: 350, fontSize: 18 }}>动千山智能体的形式多样，包括微信小程序、网站和APP等。这些形式可以满足不同用户的需求，让用户随时随地获取所需的信息和服务。</div>
                            <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>企业价值提升</h4>
                            <div style={{ color: '#536682', padding: '30px 0', width: 350, fontSize: 18 }}>动千山智能体的用处非常广泛，它可以帮助企业提高效率，减少重复性工作，提高生产力。同时，它还可以帮助企业传递知识、价值和文化，提升品牌形象和知名度。无论是内部管理还是外部服务，动千山智能体都能够为企业带来巨大的价值。<QrCodeIcon onClick={() => handleClickOpen(1)}  style={{ cursor: 'pointer' }} /></div>
                        </div>
                    </div>
                </div>
            </Slider>
        );
    }

    const handleClickOpen = (e) => {
        // if (e === 1) {
        //     setSelectedValue(bh_xcx_2);
        // } else {
        //     setSelectedValue('https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/android_download_code.png');
        // }
        setSelectedValue('https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/android_download_code.png');
        setOpen(true);
        setQrtypoe(e)
    };

    const handleClose = () => {
        setOpen(false);

    };
    const [isOpen, setisOpen] = React.useState(false);
    const onSearchFocus= () => {
        setisOpen(true);
    };
    const  onSearchBlur= () => {
        // setisOpen(false);
    }
    //获得公司名
    const [value, setValue] = React.useState('');
    const plan_value=(e)=>{
        // console.log(e.target.value)
        setValue(e.target.value)
    }
    const search_company=()=>{
        if(_.isNil(token)){
            if(search_nums>0){
                alert('请先登录!')
            }else{
                // dispatch(loading())
                dispatch(company_name(value))
                dispatch(search_num())
            }
        }else{
            dispatch(company_name(value))
        }
    }
    const [add_theme, setAddTheme] = React.useState(false);
    const themeClose=()=>{
        setAddTheme(false)
    }
    const app_download=()=>{
        setAddTheme(true)
    }
    return (
        <div className={classes.root}>
            <div>
                <div style={{ backgroundColor: '#4844f3', color: '#fff', width: '100%', height: 500, position: 'relative', display: 'flex', justifyContent: 'space-between', overflowX: 'clip' }}>
                    <div style={{ zIndex: 99, width: '55%', minWidth: '762px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', padding: '20px', fontSize: 20 }}>
                                <div>
                                    <img src={white_logo} style={{ width: '26px', height: '26px', background: '#fff', borderRadius: '50%' }} alt="" />
                                </div>
                                <div style={{ paddingLeft: 8 }}>博槐互联网科技</div>
                            </div>
                            <Link to='/largemodel' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '15%', fontSize: 15, lineHeight: '60px', cursor: "pointer", padding: '5px 8px', color: '#fff' }}>
                                <div >动千山智能体</div>
                            </Link>
                            <div onClick={app_download} style={{color:'#fff',cursor:'pointer',fontSize: 15,lineHeight: '60px',padding: '5px 8px',paddingLeft:40,}}>APP下载</div>
                        </div>
                        <div style={{ padding: '40px 50px', width: '500px' }}>
                            <SimpleSlider />
                        </div>
                        <div style={{ display: 'flex', padding:"0 50px", paddingTop: 0 }}>
                            <Link to='/largemodel' onClick={to_tax_planning} style={{ borderRadius: 25, cursor: "pointer", padding: '8px 0', background: '#fff', color: '#000000', width: 287, height: 33, textAlign: 'center', lineHeight: '40px', fontSize: 18, display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
                                <img src={tax_icon} style={{ width: 27 }} />
                                <div style={{ marginLeft: 10 }}>动千山智能体</div>
                            </Link>
                        </div>
                        <Link to='/largemodel' style={{margin:'30px 50px'}}>
                            <p style={{position:'relative',marginLeft:50,marginTop:30}}>
                                <SearchIcon style={{ fontSize: 25,position:'absolute',left:8,top:8,zIndex:99 ,color:'rgba(255,255,255,0.7)'}}/>
                                <input 
                                    className={classes.input}  
                                    placeholder="动千山智能体"
                                    style={{width:isOpen?'70%':'287px'}}
                                    onChange={plan_value}
                                />
                            </p>
                        </Link>
                    </div>
                    <div style={{ marginTop: -130, marginRight: -150, zIndex: 1, width: '45%' }}>
                        <AllTop_img img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/gif/all_iphone.png'}></AllTop_img>
                    </div>
                </div>
                <div style={{ padding: '40px 50px 50px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: 287, textAlign: 'center' }}>
                            <p style={{ fontSize: 17, width: 287 }}>动千山安卓app</p>
                            <div style={{paddingTop:10}}>
                                <img src="https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/android_download_code.png" style={{ width: 160 }} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Shuilider/>
                    <div>
                        <div style={{backgroundColor: 'rgb(72, 68, 243,0.95)',color:'#fff',paddingTop:20}}>
                            <h2 style={{ fontSize: 30, textAlign: 'center', margin: '60px 0', cursor: 'pointer' }} id='tax_planning' onClick={() => handleClickOpen(1)}>听潮CRM录音功能<QrCodeIcon style={{ marginLeft: 10, cursor: 'pointer' }} /></h2>
                            <div style={{ display: 'flex', padding: 20, paddingBottom: 70,justifyContent:'center' }}>
                            <div style={{ width: '25.5%' }}>
                                    <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>语音识别与录音</h4>
                                    <div style={{ color: '#fff', paddingTop: 30, width: 350, fontSize: 18,fontWeight:'bold' }}>智能录音功能可以精确分离和识别不同人的声音，并将其与相应的员工和客户姓名匹配</div>
                                    <div style={{ color: '#fff', paddingTop: 30, width: 350, fontSize: 18 }}>1.支持最长十小时的录音，可上传电话录音、会议录音等音频文件。</div>
                                    {/* <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>多样化的访问形式</h4> */}
                                    <div style={{ color: '#fff', paddingTop: 30, width: 350, fontSize: 18 }}>2.支持近三十种方言的识别。</div>
                                    {/* <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>企业价值提升</h4> */}
                                    <div style={{ color: '#fff', paddingTop: 30, width: 350, fontSize: 18 }}>3.自动识别并区分不同人的声音，并按照员工姓名和客户姓名标注发言。<QrCodeIcon onClick={() => handleClickOpen(1)}  style={{ cursor: 'pointer' }} /></div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '12%' }}>
                                    <img src={shui_engine_logo} style={{ width: 50, height: 50, marginTop: -150 }} alt="" />
                                </div>
                                <AppLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/enlightenment_phone.gif'} />
                            </div>
                        </div>
                        <div>
                            <h2 style={{ fontSize: 30, textAlign: 'center',paddingTop:20 }}>
                                <div style={{ textAlign: 'center', fontSize: 30, marginBottom: '60px' }}>听潮CRM网页查询功能</div>
                            </h2>
                            <div style={{ display: 'flex' }}>
                                <WebLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/enlightenment_gif.gif'} />
                                <div style={{ width: '30%',  }}>
                                    
                                    <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>CRM功能</h4>
                                    <div style={{ padding: '20px 0', width: 300, fontSize: 18, textAlign: 'left',color: '#536682' }}>
                                        {/* <CircleIcon style={{color:'#666',fontSize:14,paddingRight:10}}/> */}
                                        听潮CRM功能允许用户在动千山网页端使用问答的方式快速获得企业的客户和员工数据。以往类似这样的任务首先需要技术人员查到相关数据，然后运营人员再进行数据汇总与总结。现在只需一句话便可以得到曾经可能要花几个小时才能得到的答案。
                                    </div>
                                    {/* <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>手动粘贴创建</h4>
                                    <div style={{ padding: '10px 0', width: 300, fontSize: 18, textAlign: 'left',color: '#536682' }}>
                                        用户可以将会议记录等内容复制粘贴到知识库中，方便整理和保存。
                                    </div>
                                    <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>网页链接提取式创建</h4>
                                    <div style={{ padding: '10px 0', width: 300, fontSize: 18, textAlign: 'left',color: '#536682' }}>
                                        用户可以上传网页链接，系统将自动提取网页内容并保存到知识库中，实现快速的信息整合。
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div style={{backgroundColor: 'rgb(72, 68, 243,0.95)',color:'#fff',marginTop:30}}>
                            <h2 style={{ fontSize: 30, textAlign: 'center', marginBottom: 60,paddingTop:20 }} id='tax_accountant'>
                                智能对话
                            </h2>
                            <div style={{ display: 'flex', padding: 20, paddingBottom: 70,justifyContent:'center' }}>
                                <div style={{ width: '30%', textAlign: 'center' }}>
                                    <div style={{padding: '20px 0', width: 300, fontSize: 18, margin: '0 auto', textAlign: 'left'}}>
                                        用户可通过平台提交税务咨询，动千山智能体通利用其海量信息资源和先进的自然语言处理技术，提供专业、准确的即时解答，有效解决税务疑问，带来便捷的服务体验。
                                    </div>
                                </div>
                                <WebLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/chat_gif.gif'} />
                            </div>
                        </div>
                        <div>
                            <h2 style={{ fontSize: 30, textAlign: 'center',paddingTop:20 }}>
                                <div style={{ textAlign: 'center', fontSize: 30, marginBottom: '60px' }}>知识库</div>
                            </h2>
                            <div style={{ display: 'flex' }}>
                                <WebLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/knowledgeBase_gif.gif'} />
                                <div style={{ width: '30%',  }}>
                                    <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>文件上传创建</h4>
                                    <div style={{ padding: '20px 0', width: 300, fontSize: 18, textAlign: 'left',color: '#536682' }}>
                                        {/* <CircleIcon style={{color:'#666',fontSize:14,paddingRight:10}}/> */}
                                        用户可以直接上传税务书籍等文件，以便于管理和查阅。
                                    </div>
                                    <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>手动粘贴创建</h4>
                                    <div style={{ padding: '10px 0', width: 300, fontSize: 18, textAlign: 'left',color: '#536682' }}>
                                        {/* <CircleIcon style={{color:'#666',fontSize:14,paddingRight:10}}/> */}
                                        用户可以将会议记录等内容复制粘贴到知识库中，方便整理和保存。
                                    </div>
                                    <h4 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>网页链接提取式创建</h4>
                                    <div style={{ padding: '10px 0', width: 300, fontSize: 18, textAlign: 'left',color: '#536682' }}>
                                        {/* <CircleIcon style={{color:'#666',fontSize:14,paddingRight:10}}/> */}
                                        用户可以上传网页链接，系统将自动提取网页内容并保存到知识库中，实现快速的信息整合。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: 20, margin: '30px 0', paddingBottom: 90, marginBottom: 0, backgroundColor: 'rgb(72, 68, 243,0.95)' }}>
                            <h2>
                                <div style={{ textAlign: 'center', fontSize: 30, marginBottom: 60,color: '#fff', }}>定时AI任务</div>
                            </h2>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '30%', color: '#fff',  paddingLeft: '15%' }}>
                                    <h3 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>定时监控网页</h3>
                                    <div style={{ padding: '30px 0', width: 300, fontSize: 18,  textAlign: 'left' }}>您可让系统定时监测网站内容的变化，并设置检测变化规则，当网页内容按规则变化时会第一时间通知您。</div>
                                    <h3 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>定时执行智能体</h3>
                                    <div style={{ padding: '30px 0', width: 300, fontSize: 18,  textAlign: 'left' }}>您可定时执行智能体任务并以报告的形式发送给目标邮箱。</div>
                                    
                                </div>
                                <WebLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/monitoring_gif.gif'} />
                            </div>
                        </div>
                        <div style={{ padding: 20, margin: '30px 0', paddingBottom: 90, marginBottom: 0 }}>
                            <h2>
                                <div style={{ textAlign: 'center', fontSize: 30, marginBottom: 60 }}>多智能体</div>
                            </h2>
                            <div style={{ display: 'flex' }}>
                                <WebLoad_image img_url={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/gif/knowledgeBase_gif.gif'} />
                                <div style={{ width: '30%', textAlign: 'left',}}>
                                    <h3 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>智能体</h3>
                                    <div style={{ padding: '30px 0', width: 300, fontSize: 18,  textAlign: 'left' }}>通过上传税务书籍到向量数据库，您可以创建一个税务智能体。在对话中使用“@税务智能体”指令，即可获得税务问题的专业回答。</div>
                                    <h3 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>多智能体拼接</h3>
                                    <div style={{ padding: '30px 0', width: 300, fontSize: 18,  textAlign: 'left' }}>此功能让您能够灵活组合不同智能体，比如先用一个智能体提取网页数据，再用另一个智能体生成Excel报表，实现自动化处理。</div>
                                    <h3 style={{ fontWeight: 'bold', fontSize: 23, margin: 0 }}>API接口</h3>
                                    <div style={{ padding: '30px 0', width: 300, fontSize: 18,  textAlign: 'left' }}>API调用简化了与现有系统的集成，加强了企业内部系统的协同和信息流通。</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#35363A', padding: '10px 20px' }}>
                    <div style={{ lineHeight: '70px' }}>
                        <div style={{ textAlign: 'left', color: '#fff', fontSize: 12, display: 'flex' }}>
                            <a href="https://beian.miit.gov.cn/" style={{ marginRight: 10, cursor: "pointer", width: '210px',color: '#fff' }}>
                                {
                                    host_type==-1?'京ICP备2021025663号-2':'京ICP备2021025663号-1'
                                }
                            </a>
                            <div style={{ width: '210px' }}>博槐（北京）互联网科技有限责任公司</div>
                        </div>
                        <div style={{ textAlign: 'left', color: '#fff', fontSize: 12, display: 'flex' }}>
                            <div style={{ marginRight: 10, width: '210px' }}>电话：18910186883</div>
                            <div style={{ width: '210px' }}>邮箱：zhuxihao@huainor.com</div>
                        </div>
                    </div>
                    <div style={{ paddingTop: '30px' }}>
                        <div>
                            <img src={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/web_content/web_content.png?time=' + parseInt((new Date().getTime() / 1000).toString())} style={{ width: 100 }} alt='' />
                        </div>
                        <div style={{ color: '#fff', fontSize: 12, textAlign: 'center' }}>加入我们</div>
                    </div>
                </div>
                <SimpleDialog
                    selectedValue={selectedValue}
                    open={open}
                    onClose={handleClose}
                />
                <Dialog onClose={themeClose} open={add_theme} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                    <div style={{ padding: 20}}>
                        <div>
                            <img src="https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/android_download_code.png" style={{ width: 160 }} alt='' />
                        </div>
                        <div style={{textAlign:'center'}}>*仅支持安卓app下载</div>
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

export default Initial;