import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DirectionsIcon from '@mui/icons-material/Directions';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import PreviewItem from './PreviewItem';
import DrawerContent from './DrawerContent';
import Footer from "../../footer/Footer";
import HeadHavigation from "../../headHavigation/headHavigation";

import {plan_close_drawer,company_name,data_error,loading,search_num} from '../../../actions/plan'
const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        width: '100%',
        maxWidth: '100%',
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
    },
    search_paper:{
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 55,
    },
    input: {
        // marginLeft: theme.spacing(1),
         flex: 1,
         fontSize: 18,
     },
     iconButton: {
         padding: 10,
 
     },
     divider: {
         height: 28,
         margin: 4,
     },
     searchClickButton:{
         backgroundColor:"#3D1198 !important",
         color: 'white !important',
         fontSize: '17px !important',
         paddingInline: '36px !important',
         marginInline: '6px !important',
         '&:hover': {
             backgroundColor: '#3D1198 !important',
         },
     },
     clearButton:{
         color: '#3D1198 !important',
         fontSize: 14,
         paddingBottom: 2,
         marginRight: 6,
         '&:hover': {
             backgroundColor: 'white',
             color: 'grey !important',
         },
     },
     loading_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height:  window.innerHeight/2,
    },
}));

function SpeedinessPlan() {
    const dispatch = useDispatch();
    const company_data = useSelector((state) => state.plan.company_data);
    const company_title = useSelector((state) => state.plan.company_title);
    const drawer_open = useSelector((state) => state.plan.drawer_open);
    const detailed_info=useSelector((state)=>state.plan.hit)
    const search_value=useSelector((state)=>state.plan.company_name)
    const [searchValue, setSearchValue] = React.useState(search_value);
    const [multiWords, setMultiWords] = React.useState([]);
    const token=useSelector((state) => state.user.token);
    const tax_vip=useSelector((state) => state.user.member_type_info);
    const loading_type=useSelector((state) => state.plan.loading_type);
    const search_nums = useSelector((state) => state.plan.search_nums);
    
    console.log('error',search_value,searchValue)
    const get_search_words = () => {
        return [...multiWords, searchValue].join(' ')
    };

    const handleSearch = () => {
        if(_.isNil(token)){
            if(search_nums>0){
                alert('请先登录!')
            }else{
                get_search_words();
                // dispatch(loading())
                dispatch(company_name(searchValue))
                dispatch(search_num())
            }
        }else{
            get_search_words();
            // dispatch(loading())
            dispatch(company_name(searchValue))
        }
        
    };
    useEffect(()=>{
        if(search_value!=''){
            setSearchValue(search_value)
        }
    },[dispatch,search_value])

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if(_.isNil(token)){
                if(search_nums>0){
                    alert('未登录仅能搜索一次!')
                }else{
                    // dispatch(loading())
                    dispatch(company_name(searchValue))
                    dispatch(search_num())
                }
            }else{
                // dispatch(loading())
                dispatch(company_name(searchValue))
            }
        }
        else if(e.keyCode === 32){
            // if(searchValue.length>15){
            //     alert("搜索语句不能超过15个字");
            //     return;
            // }
            if(!multiWords.includes(searchValue) && searchValue.length>0){
                setMultiWords(multiWords=>[...multiWords, searchValue]);
            }
            e.preventDefault();
            setSearchValue('');
        }
    };
    const handleDelete = (item) => {
        console.log(item);
        setMultiWords(multiWords.filter(v=>v!==item));
        console.log(multiWords);
    };

    const clearAll = () => {
        setMultiWords([]);
        setSearchValue("");
    };

    const hasSearchContent = () => {
        return multiWords.length>0 || searchValue.length>0;
    };

    const addToMultiWords = () => {
        // if(searchValue.length>15){
        //     alert("搜索语句不能超过15个字");
        //     return;
        // }
        if(!multiWords.includes(searchValue) && searchValue.length>0){
            setMultiWords(multiWords=>[...multiWords, searchValue]);
        }
        setSearchValue('');
    };
    const error=useSelector((state) => state.plan.error_value);
    const [remind_suggest, setState] = React.useState(false);
    const vertical='top'
    const horizontal='center'

    const remindhandleClose = () => {
        dispatch(data_error(''))
        setState(false)
    };
    useEffect(() => {
        if(error!==''){
            setState(true)
        }
    }, [dispatch,error]);




    const classes = useStyles();
    console.log('company_title',company_data)
    return (
        <div>
            <HeadHavigation provenance={0} />
            <Container className={classes.root}>
                <div>
                    <Paper component="form" className={classes.search_paper}>
                        <IconButton className={classes.iconButton} aria-label="menu" onClick={addToMultiWords}>
                            {
                                hasSearchContent()?
                                    <AddIcon style={{ fontSize: 32 }} />
                                    :
                                    <SearchIcon style={{ fontSize: 32 }}/>

                            }
                        </IconButton>
                        {
                            multiWords.length>0 &&
                                multiWords.map(
                                    (item, index) => <Chip key={index}
                                                        label={item}
                                                        onDelete={()=>handleDelete(item)}
                                                        color="primary"
                                                        style={{backgroundColor: '#3D1198', marginRight: 2}}
                                    />

                                )
                        }
                        <InputBase
                            className={classes.input}
                            placeholder="输入公司名获得快速筹划方案"
                            inputProps={{ 'aria-label': '输入公司全名获得快速筹划方案' }}
                            value={searchValue}
                            onChange={(event)=>setSearchValue(event.target.value)}
                            onKeyDown={_handleKeyDown}
                        />
                        <Button className={classes.clearButton} onClick={clearAll} >清空搜索</Button>
                        <Button className={classes.searchClickButton}
                        onClick={handleSearch} disableElevation>
                            搜索
                        </Button> 
                    </Paper>
                    <div style={{display:'flex',justifyContent: 'space-between'}}>
                        <div style={{marginTop: 8, marginBottom: 2, color: '#B5B4B9', fontSize: 14}}>按空格键添加搜索条件，按回车键直接进行搜索</div>
                        <div style={{display:'flex'}}>
                            <div style={{fontSize:14,color: '#B5B4B9',paddingRight:'5px',marginTop:8,marginBottom:2}}>快速智能筹划功能介绍</div>
                            <Tooltip title="1、搜索框内输入公司全称方可进行搜索。
                                            2、智能筹划方案为系统智能算法计算出的方案，人工筹划请在微信小程序上搜索“博槐税引擎”，并在小程序内进行人工咨询">
                                <IconButton style={{background:'rgb(61, 17, 152,0.2)',padding:4,marginTop:5}}>
                                    <QuestionMarkIcon style={{color:'#3D1198',fontSize:'16px'}} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    {/* <p style={{marginTop: 8, marginBottom: 2, color: '#B5B4B9', fontSize: 14}}>
                        按空格键添加搜索条件，按回车键直接进行搜索
                    </p>
                    <Tooltip title="Delete">
                        <IconButton style={{background:'rgb(61, 17, 152,0.2)'}}>
                            <PriorityHighIcon style={{color:'#3D1198'}} />
                        </IconButton>
                    </Tooltip> */}
                </div>
                {
                    loading_type?<div className={classes.loading_container}>
                    <CircularProgress />
                </div>:<div>
                {
                    company_title.length>0?company_title.map((item,index)=>
                        <div key={index}>
                            <div style={{display:'flex'}}>
                                <div style={{fontWeight:'bold',fontSize:'50px'}}>·</div>
                                <div style={{padding:'15px 0',fontSize:'18px',fontWeight:'bold',lineHeight:'40px'}}>{item}</div>
                            </div>
                            
                            <div>
                                {
                                    company_data[item].length>0? 
                                    company_data[item].map(
                                            (item1, index1) => <PreviewItem
                                                key={`result-preview-${index1}`}
                                                title={item1._source.title}
                                                content={item1._source.content}
                                                tags={item1._source.tags}
                                                tax_category={item1._source.tax_category}
                                                industry_category={item1._source.industry_category}
                                                search_index={item1._index}
                                                hit={JSON.stringify(item1)}
                                                total_title={item}
                                                index_key={index}
                                        />
                                    ):<div style={{textAlign:'center'}}>搜索结果为空</div>
                                }
                            </div>
                        </div>
                    ):<div style={{textAlign:'center',height:'70vh'}}>暂无内容</div>
                    
                }
                </div>
                    
                }
                
                <Drawer
                    anchor={'right'}
                    open={drawer_open}
                    onClose={() => dispatch(plan_close_drawer())}
                >
                    <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                        <DrawerContent
                            hit={detailed_info}
                        />
                    </div>
                </Drawer>
                <div>
                    <Footer />
                </div>
            </Container>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                severity="info"
                autoHideDuration={4000}
                key={vertical + horizontal}
            >
                <Alert severity="info"> {error}</Alert>
            </Snackbar>
        </div>
    )
}
export default SpeedinessPlan;