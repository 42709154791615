import React, {useEffect,useCallback} from "react";
import {useDispatch,useSelector} from "react-redux";
import {Link} from "react-router-dom";
import { makeStyles } from '@mui/styles';

import {question_list,lists_like} from '../../actions/forum'

import Footer from "../footer/Footer";
import HeadHavigation from "../headHavigation/headHavigation";

import cancel_praise from '../../static/icon/cancel_praise.png'
import top_praise from '../../static/icon/top_praise.png'

const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
        minHeight:'calc(100vh - 240px)'
    },
    textContent:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        padding:'10px 0'
    }
}));
function QuestionDetails(option){
    console.log(option)
    const from=_.isNil(option.location.state)?0:option.location.state.from
    const dispatch = useDispatch();
    useEffect(()=>{
        // dispatch(question_list({from:from}))
    },[])



    const classes = useStyles();
    return (
        <div>
            <HeadHavigation provenance={from} />
            <div className={classes.root}>
                <div>
                    
                </div>
            </div>
            <hr/>
            <div>
                <Footer />
            </div>
        </div>
    )
}
export default QuestionDetails;