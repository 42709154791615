import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
    shui_question_list,
    lists_like,
    close_drawer,
    question_details,
    create_question,
    release_success,
    getshui_by_ids, search_shui, search_more_question, es_lists_like, question_lists, es_title_like
} from '../../actions/forum'

import Footer from "../footer/Footer";
import HeadHavigation from "../headHavigation/headHavigation";
import DrawerContent from "./DrawerContent"

import cancel_praise from '../../static/icon/cancel_praise.png'
import top_praise from '../../static/icon/top_praise.png'
import SearchBarQA from "../web/tax/SearchBarQA";

const _ = require('micro-dash');
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: '#666',
    height: 80,
    lineHeight: '120px',
}));
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
        minHeight: 'calc(100vh - 240px)'
    },
    textTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        margin: '10px 0',
        fontWeight: 'bold',
    },
    textContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        margin: '10px 0'
    }
}));
function ShuiQuestion() {
    const dispatch = useDispatch();
    const [sort_type, setSortType] = React.useState(1);
    useEffect(() => {
        if (show_search===false) {
            dispatch(shui_question_list({ sort_type: sort_type }))
        }}, [])
    const question_list = useSelector((state) => state.forum.question_lists);
    const drawer_open = useSelector((state) => state.forum.drawer_open);
    const detailed_info = useSelector((state) => state.forum.question_details);
    const answers_list = useSelector((state) => state.forum.answers_list);
    const get_release_success = useSelector((state) => state.forum.release_success)
    const is_contain_key = useSelector((state) => state.forum.contain_key)
    const is_more = useSelector((state) => state.forum.shui_is_more)
    const searches_is_more = useSelector((state) => state.forum.search_is_more)
    const show_search = useSelector((state) => state.forum.show_search_list)
    const search_word = useSelector((state) => state.forum.search_word)
    const sort = (e) => {
        setSortType(e)
        if (show_search===false) {
            dispatch(shui_question_list({ sort_type: e }))
        } else {
            dispatch(question_lists([]))
            dispatch(search_shui({ sort_type: e ,words:search_word}))
        }
    }
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [question_content, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    // 发布
    const issue_question = () => {
        if (question_content.length > 4) {
            setOpen(false)
            dispatch(create_question({ from: 0, question_content: question_content, sort_type: sort_type }))
        } else {
            alert('字数请符合规范')
        }
    }
    useEffect(() => {
        if (get_release_success) {
            setState(true)
        }
    }, [dispatch, get_release_success]);


    const [remind_suggest, setState] = React.useState(false);
    const vertical = 'top'
    const horizontal = 'center'
    const remindhandleClose = () => {
        setState(false)
        setValue('')
        dispatch(release_success(false))
    };
    const fetchMoreData = () => {
        if (question_list.length > 0) {
            setTimeout(() => {
                dispatch(shui_question_list({ sort_type: sort_type, after: question_list[question_list.length - 1].cursor, next: true }))
            }, 1500);
        }
    }
    const fetchMoreDataSearch = () => {
        if (question_list.length > 0) {
            setTimeout(() => {
                console.log(search_word)
                dispatch(search_shui({ page_index:question_list.length/10+1, words:search_word}))
            }, 1500);
        }
    }
    const classes = useStyles();
    return (
        <div>
            <HeadHavigation provenance={0} />
            <div className={classes.root}>
            <SearchBarQA/>
                <div style={{ marginBottom: 30 }}>
                    <Box
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Item elevation={3} style={{ cursor: "pointer", width: '15%' }} onClick={handleClickOpen}>
                            <AddIcon style={{ fontSize: 40 }} />
                        </Item>
                        <div style={{ marginLeft: 'auto', paddingRight: '10px', flexDirection: 'row', alignItems: 'end', display: 'flex' }}>
                            <span style={{ color: sort_type === 1 ? '#43179D' : '', cursor: "pointer" }} onClick={() => sort(1)}>最新 </span>
                            <span style={{ padding: '0 8px' }}>|</span>
                            <span style={{ color: sort_type === 2 ? '#43179D' : '', cursor: "pointer" }} onClick={() => sort(2)}> 最热</span>
                        </div>
                    </Box>
                </div>
                {
                    (() => {
                        if (show_search===false && question_list.length > 0) {
                            return <InfiniteScroll
                                dataLength={question_list.length}
                                next={fetchMoreData}
                                hasMore={is_more}
                                loader={<Box sx={{ display: 'flex' }}>
                                    <CircularProgress style={{ margin: '0 auto' }} />
                                </Box>}
                            >
                                {
                                    question_list.map((item, index) =>
                                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: '1% 3%', position: 'relative', marginBottom: '2%' }}>
                                            {/* <Link to={{pathname:`/question/details`,state:{from:0}}}> */}
                                            <div style={{ cursor: "pointer" }} onClick={() => dispatch(question_details({ from: 0, id: item.node.id }))}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={classes.textContent}>{item.node.question}</div>
                                                    <div style={{ margin: '10px 0' }}>{item.node.create_time.split('T')[0]}</div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                    {item.node.user.avatar.file_name!=null?<img src={item.node.user.avatar.file_name} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />:<img src='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png' alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />}
                                                    </div>
                                                    {item.node.user.nick_name!=null?<div style={{ lineHeight: '40px', paddingLeft: 10 }}>{item.node.user.nick_name}</div>:<div style={{ lineHeight: '40px', paddingLeft: 10 }}>用户0{item.node.user.id}</div>}
                                                </div>
                                            </div>
                                            {/* </Link> */}
                                            <div style={{ position: 'absolute', bottom: '8%', right: '2%', display: 'flex' }} onClick={() => dispatch(lists_like({ id: item.node.id, like: item.node.likes, index: index }))}>
                                                <div>
                                                    {
                                                        item.node.likes === 0 ? <img alt="" src={cancel_praise} style={{ width: 30, height: 30 }} /> : <img alt="" src={top_praise} style={{ width: 30, height: 30 }} />
                                                    }
                                                </div>
                                                <div style={{ lineHeight: '30px', color: item.node.likes === 0 ? '' : '#43179D' }}>
                                                    <span>{item.node.number_of_likes}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </InfiniteScroll>
                        }else if(show_search===true&&question_list.length > 0){
                            return <InfiniteScroll
                                dataLength={question_list.length}
                                next={fetchMoreDataSearch}
                                hasMore={searches_is_more}
                                loader={<Box sx={{ display: 'flex' }}>
                                    <CircularProgress style={{ margin: '0 auto' }} />
                                </Box>}
                            >
                                {
                                    question_list.map((item, index) =>
                                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: '1% 3%', position: 'relative', marginBottom: '2%' }}>
                                            {/* <Link to={{pathname:`/question/details`,state:{from:0}}}> */}
                                            <div style={{ cursor: "pointer" }} onClick={() => item._source.is_office==="true"?dispatch(getshui_by_ids(item._id)):dispatch(question_details({ from: 0, id: item._source.id }))}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={classes.textTitle}>{item._source.title}</div>
                                                    <div style={{ margin: '10px 0' }}>{item._source.question_date}</div>
                                                </div>
                                                <div className={classes.textContent}>{item._source.content}</div>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                    {item._source.questioner_logo!=null?<img src={item._source.questioner_logo} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />:<img src='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png' alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />}
                                                    </div>
                                                    {item._source.questioner!=null?<div style={{ lineHeight: '40px', paddingLeft: 10 }}>{item._source.questioner}</div>:<div style={{ lineHeight: '40px', paddingLeft: 10 }}>用户0{item.node.user.id}</div>}
                                                </div>
                                            </div>
                                            {/* </Link> */}
                                            <div style={{ position: 'absolute', bottom: '8%', right: '2%', display: 'flex' }} >
                                                <div style={{ lineHeight: '30px', color: '' }}>
                                                    <span>评论数</span>
                                                    <span style={{marginRight:'24px'}}>{item._source.comment_num}</span>
                                                </div>
                                                <div onClick={() => dispatch(es_title_like({ id: item._id, like:item._source.likes, index: index ,counts:item._source.title_likes}))}>
                                                    {
                                                        item._source.likes === 0 ? <img alt="" src={cancel_praise} style={{ width: 30, height: 30 }} /> : <img alt="" src={top_praise} style={{ width: 30, height: 30 }} />
                                                    }
                                                </div>
                                                <div style={{ lineHeight: '30px', color: item._source.likes === 0 ? '' : '#43179D' }} >
                                                    <span>{item._source.title_likes}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </InfiniteScroll>
                        }
                        else {
                            console.log(show_search)
                            return <div style={{ textAlign: 'center' }}>暂无提问</div>
                        }
                    })()
                }

            </div>
            <Drawer
                anchor={'right'}
                open={drawer_open}
                onClose={() => dispatch(close_drawer())}
            >
                <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                    <DrawerContent
                        detailed_info={detailed_info}
                        answers_list={answers_list}
                        from={0}
                    />
                </div>
            </Drawer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>发布</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="outlined-multiline-static"
                        label="请输入字数不小于5的问题"
                        multiline
                        rows={4}
                        fullWidth
                        style={{ width: window.innerWidth * 1 / 4, height: '40%' }}
                        value={question_content}
                        onChange={handleChange}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={issue_question}>发布</Button>
                </DialogActions>
            </Dialog>
            <hr style={{ opacity: '0.4' }} />
            <div style={{ padding: '10px 12%' }}>
                <Footer />
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                severity="info"
                autoHideDuration={3500}
                key={vertical + horizontal}
            >
                {is_contain_key===false?<Alert severity="info"> 发布成功</Alert>:<Alert severity="info"> 您输入的内容包含敏感词汇</Alert>}
            </Snackbar>
        </div>
    )
}
export default ShuiQuestion;