import {
    QUESTIONLISTS,
    QUESTIONLISTSLIKE,
    QUESTIONSEARCHLISTSLIKE,
    QUESTION_OPEN_DRAWER,
    QUESTION_CLOSE_DRAWER,
    QUESTION_DETAILS_LIKE,
    ANSWER_LIST,
    ANSWER_LIKE,
    RELEASE_SUCCESS,
    CONTAIN_KEY,
    SHUI_MORE_QUESTION,
    PROGTAMMELIST,
    PROGRAMMEDETAILS,
    CREATEPROGRAMME,
    RISK_CLOSE_OPEN,
    SHOW_SEARCH,
    SEARCH_WORD,
    SEARCH_MORE_QUESTION, MYQUESTIONLISTS, MQ_MORE_QUESTION
} from './types';
import {MIDDLE_EARTH_URL, TAX_NAME_MAP, INDUSTRY_NAME_MAP} from '../configs/constants'
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})


export function question_lists(content,next){
    return {
        type:QUESTIONLISTS,
        content,
        next
    }
}
export function my_question_lists(content,next){
    return {
        type:MYQUESTIONLISTS,
        content,
        next
    }
}
export function question_lists_like(index,like){
    return {
        type:QUESTIONLISTSLIKE,
        index,
        like
    }
}
export function question_search_lists_like(index,like){
    return {
        type:QUESTIONSEARCHLISTSLIKE,
        index,
        like
    }
}

export function open_drawer(question_details,answers_list) {
    return {
        type: QUESTION_OPEN_DRAWER,
        question_details,
        answers_list
    }
}

export function close_drawer() {
    return {
        type: QUESTION_CLOSE_DRAWER,
    }
}
export function question_details_like(like){
    return {
        type:QUESTION_DETAILS_LIKE,
        like
    }
}
export function answer_lists(answers_list){
    return {
        type:ANSWER_LIST,
        answers_list
    }
}
export function answer_like(index,sort_type){
    return {
        type:ANSWER_LIKE,
        index,
        sort_type,
    }
}
export function release_success(success){
    return {
        type:RELEASE_SUCCESS,
        success
    }
}
export function contain_key(key) {
    return {
        type: CONTAIN_KEY,
        key
    }
}
export function shui_more_question(is_more){
    return {
        type:SHUI_MORE_QUESTION,
        is_more
    }
}
export function mq_more_question(is_more){
    return {
        type:MQ_MORE_QUESTION,
        is_more
    }
}
export function search_more_question(is_more){
    return {
        type:SEARCH_MORE_QUESTION,
        is_more
    }
}
export function programme_list(programme_list){
    return {
        type:PROGTAMMELIST,
        programme_list
    }
}
export function programme_details(programme_details){
    return {
        type:PROGRAMMEDETAILS,
        programme_details
    }
}
export function create_programme(is_programme){
    return {
        type:CREATEPROGRAMME,
        is_programme
    }
}
export function risk_close_open(){
    return {
        type:RISK_CLOSE_OPEN
    }
}
export function show_search(is_show){
    return{
        type:SHOW_SEARCH,
        is_show
    }
}
export function search_word(word){
    return{
        type:SEARCH_WORD,
        word
    }
}

//问题列表
export function shui_question_list({sort_type,after,next,user_id}){
    console.log('sort_type',sort_type,after,user_id)
    return (dispatch,getState)=>{
        if (_.isNil(user_id)) {
            axios.post(`${MIDDLE_EARTH_URL}/qa/shui-question-list/`,{
            sort_type,
            after,
            user_id
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('question_list',response)
                if(response.data.data.shui_questions_page.edges.length>0){
                    for(let i=0;i<response.data.data.shui_questions_page.edges.length;i++){
                        response.data.data.shui_questions_page.edges[i].node.likes=0
                    }
                    dispatch(question_lists(response.data.data.shui_questions_page.edges,next))
                }else{
                    dispatch(shui_more_question(false))
                }

            }
        )
        }else {
            axios.post(`${MIDDLE_EARTH_URL}/qa/shui-question-list/`,{
            sort_type,
            after,
            user_id
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('question_list',response)
                if(response.data.data.shui_questions_page.edges.length>0){
                    for(let i=0;i<response.data.data.shui_questions_page.edges.length;i++){
                        response.data.data.shui_questions_page.edges[i].node.likes=0
                    }
                    dispatch(my_question_lists(response.data.data.shui_questions_page.edges,next))
                }else{
                    dispatch(mq_more_question(false))
                }

            }
        )
        }

    }
}

//问题列表点赞
export function lists_like({id,like,index}){
    // console.log('id',id,like)
    return (dispatch,getState)=>{
        if(like===0){
            axios.post(`${MIDDLE_EARTH_URL}/qa/shui-question-likes`,{
                question_id:id
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    dispatch(question_lists_like(index,like))
                }
            )
        }else{
            dispatch(question_lists_like(index,like))
        }
    }
}

//详细问题
export function question_details({from,id}){
    return (dispatch,getState)=>{
        if(from===0){
            $http.get(`${MIDDLE_EARTH_URL}/qa/get-shui-question/?question_id=${id}`,{
                
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log('question_details',response)
                    dispatch(open_drawer(response.data.data,''))
                    axios.post(`${MIDDLE_EARTH_URL}/qa/shui-answer-list`,{
                        question_id:id
                    },{
                        headers:{
                            'content-type': 'application/json', // 默认值
                            'Authorization':'token '+localStorage.getItem('token')
                        } 
                    }).then(
                        res=>{
                            console.log('answer-list',res)
                            dispatch(open_drawer(response.data.data,res.data.data.shui_answers_list))
                        }
                    )
                }
            )
        }
    }
}
export function answer_list({from,id,sort_type}){
    // console.log('from,id',from,id)
    return (dispatch,getState)=>{
        if(from===0){
            axios.post(`${MIDDLE_EARTH_URL}/qa/shui-answer-list`,{
                question_id:id,
                sort_type:sort_type
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                } 
            }).then(
                res=>{
                    console.log('answer-list',res)
                    dispatch(answer_lists(res.data.data.shui_answers_list))
                }
            )
        }
    }
}
export function question_likes({from,id,like}){
    return (dispatch,getState)=>{
        if(from===0){
            axios.post(`${MIDDLE_EARTH_URL}/qa/shui-question-likes`,{
                question_id:id,
                is_cancel:like
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    dispatch(question_details_like(like))
                }
            )
        }
    }
}
export function answer_likes({id,sort_type,index,from}){
    // console.log('id',id,sort_type)
    return (dispatch,getState)=>{
        if(from===0){
            axios.post(`${MIDDLE_EARTH_URL}/qa/shui-answer-likes`,{
                answer_id:id,
                is_cancel:sort_type
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    dispatch(answer_like(index,sort_type))
                }
            )
        }
    }
}
export function create_answer({from,question_id,answer_content,sort_type}){
    return (dispatch,getState)=>{
        if(from===0){
            axios.post(`${MIDDLE_EARTH_URL}/qa/create-shui-answer`,{
                question_id:question_id,
                answer_content:answer_content
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    if(response.data.code===200){
                        dispatch(contain_key(false))
                        dispatch(release_success(true))
                    }else {
                        dispatch(contain_key(true))
                        dispatch(release_success(true))
                    }
                    dispatch(answer_list({from,id:question_id,sort_type}))
                }
            )
        }
    }
}
export function create_question({from,question_content,sort_type}){
    return (dispatch,getState)=>{
        if(from===0){
            axios.post(`${MIDDLE_EARTH_URL}/qa/create-shui-question`,{
                question_content
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(
                response=>{
                    console.log(response)
                    if(response.data.code===200){
                        dispatch(release_success(true))
                        dispatch(contain_key(false))
                    }else {
                        dispatch(contain_key(true))
                        dispatch(release_success(true))
                    }
                    // dispatch(release_success(true))
                    dispatch(shui_question_list({sort_type:sort_type}))
                }
            )
        }
    }
}

//风险评估列表
export function risk_lists(){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/risk-assessment/programme-list/`,{
                
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('risk_lists',response)
                dispatch(programme_list(response.data.data.programme_list))
            }
        )
    }
}

//风险评估详情
export function risk_details({id}){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/risk-assessment/get-programme?programme_id=${id}`,{
                
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('risk_details',response)
                dispatch(programme_details(response.data.data))
            }
        )
    }
}

//创建风险评估
export function create_risk({content}){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/risk-assessment/create-programme`,{
            content
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                console.log('create_risk',response)
                dispatch(create_programme(true))
                dispatch(risk_lists())
            }
        )
    }
}

const get_search_query = (words, state) => {
    return {
        "bool": {
            "must":get_filter_query(words, state)
        }
    }
};

export function search_shui({sort_type,page_index=1, words=""}) {
    console.log(`Inside search for ${page_index}, ${words}`);
    return (dispatch, getState) => {
        const index_url = "questions+answers+shuifasuibianwen"
        console.log(`index url is ${index_url}`);
        axios.post(`${MIDDLE_EARTH_URL}/es/search/`,
            {
                sort: sort_type,
                size: 10,
                from: parseInt((page_index-1)*10),
                query: get_search_query(words, getState()),
                index_url
            })
            .then(
                response => {
                    console.log(response);
                    if (response.data.hits.length > 0) {
                        dispatch(search_more_question(true))
                        dispatch(show_search(true))
                        dispatch(search_word(words))
                        dispatch(question_lists(response.data.hits, page_index));
                    } else {
                        dispatch(search_more_question(false))
                    }
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search`);
                if( error.response ){
                    console.log(error.response.data); // => the response payload
                }
            })
    };
}

const get_filter_query = (words, state)=> {
    let filter_query = [];
    // normal multi-word search
    if(!(_.isNil(words) || words.length===0)){
        words.trim().split(" ").map(
            word => filter_query.push({
                "multi_match" : {
                    "type": "phrase",
                    "query": word,
                    "lenient": true,
                    "fields": [ "title", "tags", "content", "parent_title" ]
                }
            })
        )
    }

    return filter_query;
};

export function getshui_by_ids(id){
    return (dispatch, getState) => {
        console.log(id)
        axios.post(`${MIDDLE_EARTH_URL}/es/getshui_by_ids/`,{
            id:[id]
        })
        .then(
            response => {
                // dispatch(open_drawer(response.data.data))
                let question;
                let answer;
                question = process_es_Q(response.data[0])
                answer = process_es_A(response.data[0])
                dispatch(open_drawer(question, answer))
            })
        .catch((error) => {
            console.log(`ERROR is: ${error}  in home_data`);
        })
    };
}

const process_es_Q = (words) => {
    return {
        "id": words._id,
        "question": words._source.title,
        "create_time": words._source.question_date,
        "update_time": words._source.question_date,
        "user_info": {
            "nick_name": "博槐官方",
            "user_id": 220,
            "avatar": "https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png"
        },
        "number_of_likes": words._source.title_likes,
        "number_of_answers": 1,
        "is_like": false,
        "is_official": true,
    }
};

const process_es_A = (words) => {
    return [{
        "id": words._id,
        "answer": words._source.content,
        "create_time": "2022-01-06T17:01:43.174424",
        "update_time": "2022-01-06T17:01:43.174424",
        "is_like": false,
        "is_official": true,
        "number_of_likes": words._source.content_likes,
        "user": {
            "id": "220",
            "nick_name": "博槐官方",
            "avatar": {
                "file_name": "https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png"
            }
    }
}]
};

//问题列表点赞
export function es_title_like({id,like,index,counts}){
    return (dispatch,getState)=>{
        if(like===0){
            axios.post(`${MIDDLE_EARTH_URL}/es/update_q_by_id`,{
                id:parseInt(id),
                count:counts+1,
            }).then(
                response=>{
                    console.log(response)
                    dispatch(question_search_lists_like(index,like))
                }
            )
        }else{
            dispatch(question_search_lists_like(index,like))
        }
    }
}

export function es_content_like({id,like,index,counts}){
    return (dispatch,getState)=>{
        if(like===0){
            axios.post(`${MIDDLE_EARTH_URL}/es/update_a_by_id`,{
                id:parseInt(id),
                count:counts+1,
            }).then(
                response=>{
                    console.log(response)
                    dispatch(question_lists_like(index,like))
                }
            )
        }else{
            dispatch(question_lists_like(index,like))
        }
    }
}