import {
    TAX_LISTS,
    TAX_INFO,
    TAX_CLOSE_DRAWER
} from './types';
import {MIDDLE_EARTH_URL} from '../configs/constants'
import axios from 'axios';

const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})

export function tax_lists(tax_agent_list){
    return {
        type:TAX_LISTS,
        tax_agent_list
    }
}
export function tax_info(data){
    return {
        type:TAX_INFO,
        data
    }
}
export function tax_close_drawer(){
    return {
        type: TAX_CLOSE_DRAWER,

    }
}
 
// dispatch
//税务师列表
export function agent_lists() {
    return (dispatch, getState) => {
        
        axios.post(`${MIDDLE_EARTH_URL}/user/get-tax-agent-list`,
            {},{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            })
            .then(
                response => {
                    console.log('agent_lists',response);
                    dispatch(tax_lists(response.data.data.tax_agent_list))
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search_all`);
                if( error.response ){
                    console.log(error.response.data);
                }
            })
    };
}
// 税务师详情
export function agent_info(id) {
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/user/get-tax-agent-info?user_id=`+id,
            {},{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            })
            .then(
                response => {
                    console.log('agent_info',response);  
                    dispatch(tax_info(response.data.data))
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search_all`);
                if( error.response ){
                    console.log(error.response.data);
                }
            })
    };
}