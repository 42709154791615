import React, {useEffect,useCallback} from "react";
import {useDispatch,useSelector} from "react-redux";
import {WECHAT_LOGIN_URL} from '../../configs/constants'
import {Link} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Countdown from 'react-countdown';
import {update_user_member_type,open_hints} from '../../actions/user'
import {shui_get_unread_count,shui_clear_read_count} from '../../actions/inform'
import {clear_new_inform,emit, initSocket} from '../../actions/chat'
import {useHistory} from 'react-router-dom';
import Dialogs from '../web/tax/Dialog'
import {change_pay_product} from "../../actions/mine"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Divider from '@mui/material/Divider';

import headHavigation from '../web/mine/index'
import logo_shui from '../../static/logo/shui_engine.png'
import left_arrows from '../../static/image/left_arrows.png'
import inform from '../../static/icon/inform.png'

const _ = require('micro-dash');
const Completionist = () => <span>您的试用时长已到期</span>;
const useStyles = makeStyles((theme) => ({
    lists:{

    },
    answers_list:{
        '&:hover+': {
            '& lists':{
                display:'block !important'
            }
        }
    }
}));

function Head(e){
    // console.log('hea',e)
    const classes = useStyles();
    const provenance=e.provenance
    const token = useSelector((state) => state.user.token);
    const user_type = useSelector((state) => state.user.user_type);
    const avatar = useSelector((state) => state.user.avatar);
    const nick_name = useSelector((state) => state.user.nick_name);
    const user_id = useSelector((state) => state.user.user);
    const member_type_info=useSelector((state)=>state.user.member_type_info)
    const [new_remaining_time, setNewTime] = React.useState(parseInt((new Date().getTime() / 1000).toString()));
    const dispatch = useDispatch();
    const tax_agent_list=useSelector((state) => state.user.tax_agent_list);
    useEffect(()=>{
        dispatch(shui_get_unread_count())
    },[])
    // useEffect(() => {
    //     if (!_.isNil(token)) {
    //         dispatch(initSocket());
    //         emit('socket_login', { user_id: user_id, user_type: user_type, terminal_type: 2 });
    //     }
    // }, [token])

    const shui_unread_num=useSelector((state)=>state.inform.shui_unread_num)

    const shui_new_inform=useSelector((state)=>state.chat.shui_new_inform)

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if(completed){
            return <Completionist />;
        }else{
            return <span>{minutes>9?minutes:`0${minutes}`}:{seconds>9?seconds:`0${seconds}`}</span>;
        }
    };
    const update_now_time=()=>{
        let now_time=parseInt((new Date().getTime() / 1000).toString());
        let expenditure_time= now_time - new_remaining_time
        dispatch(update_user_member_type(expenditure_time))
        setNewTime(now_time)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const more_answers = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose  = () => {
        setAnchorEl(null);
    };

    const history = useHistory();
    const gotoPayment = useCallback(() => {
        dispatch(change_pay_product('税引擎'))
        history.push('/mine/payment');
    }, [history]);

    const [remind_suggest, setState] = React.useState(false);
    const vertical='top'
    const horizontal='center'

    const remindhandleClose = () => {
        setState(false)
    };
    useEffect(() => {
        if(shui_new_inform>0){
            setState(true)
        }
    }, [dispatch,shui_new_inform]);

    const open_inform_lists=()=>{
        if(shui_new_inform>0){
            dispatch(shui_clear_read_count());
            dispatch(clear_new_inform(1))
            history.push('/inform_lists');
        }
    }

    return (
        <div style={{backgroundColor:'#3D1198',display:'flex',justifyContent:'space-between',padding:'8px 30px',boxShadow:'0 15px 9px #d6d6d6'}}>
            <div style={{display:'flex',minWidth:710}}>
                <Link to='/'>
                    <div>
                        <img src={logo_shui} style={{width:30,height:30,borderRadius:'50%'}} alt='' />
                    </div>
                </Link>
                <Link to='/'><div style={{color:'#fff',paddingLeft:10,lineHeight:'32px'}}>税引擎</div></Link>
                <Link to='/tax'><div style={{color:'#fff',paddingLeft:50,lineHeight:'32px'}}>知识库</div></Link>
                <Link to='/largemodel'><div style={{color:'#fff',paddingLeft:50,lineHeight:'32px'}}>博槐税务大模型</div></Link>
                <Link to='/tax/intro'><div style={{color:'#fff',paddingLeft:50,lineHeight:'32px'}}>产品介绍</div></Link>
                <div style={{color:'#fff',paddingLeft:50,paddingRight:25,lineHeight:'32px',cursor: "pointer"}} onClick={more_answers} aria-describedby={id}>筹划学习<KeyboardArrowDownIcon style={{verticalAlign:'text-top'}}/></div>
                {/* <Divider orientation="vertical"  color='#D0D0D0' flexItem /> */}
                {/* {
                    (()=>{
                        if(_.isNil(token)){
                            return <div style={{color:'#fff',paddingLeft:25,lineHeight:'32px',cursor: "pointer"}} onClick={()=>dispatch(open_hints(true))}>税务问答</div>
                        }else{
                            return <Link to='/question'><div style={{color:'#fff',paddingLeft:25,lineHeight:'32px'}}>税务问答</div></Link>
                        }
                    })()
                }
                {
                    (()=>{
                        if(_.isNil(token)){
                            return <div style={{color:'#fff',paddingLeft:50,lineHeight:'32px',cursor: "pointer"}} onClick={()=>dispatch(open_hints(true))}>风险评估</div>
                        }else{
                            return <Link to='/riskAssessment'><div style={{color:'#fff',paddingLeft:50,lineHeight:'32px'}}>风险评估</div></Link>

                        }
                    })()
                } */}
                <div>
                    <Popover
                        onClose={handleClose}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                    >
                        <div style={{width:'100px',color:'#fff',background:'rgb(61, 17, 152)'}}>
                            <Link to='/tax/scene' >
                                <div style={{padding:'7px',color:'#fff',fontSize:'14px',textAlign:'center'}}>筹划情景</div>
                            </Link>
                            <div style={{width:'100%',height:'1px',background:'rgba(0,0,0,0.4)'}}></div>
                            <Link to='/tax/video_lists'>
                                <div style={{padding:'7px',color:'#fff',fontSize:'14px',textAlign:'center'}}>筹划视频</div>
                            </Link>
                        </div>
                    </Popover>
                </div>
            </div>
            {/* {
                _.isNil(token)?<div style={{ textAlign: 'center', display: 'flex', fontSize: 15,cursor: "pointer" }} onClick={() => window.location.href = WECHAT_LOGIN_URL}>
                <div style={{ color: '#fff',lineHeight:'32px' }}>登录</div>
                    <div>
                        <img src={left_arrows} alt="" style={{ width: 20, height: 20, paddingLeft: 8,paddingTop:'6px' }} />
                    </div>
                </div>:''
            } */}
            {/* {
                (()=>{
                    if(_.isNil(token)){
                        return <div style={{ textAlign: 'center', display: 'flex', fontSize: 15,cursor: "pointer" }} onClick={() => window.location.href = WECHAT_LOGIN_URL}>
                                    <div style={{ color: '#fff',lineHeight:'32px' }}>登录</div>
                                    <div>
                                        <img src={left_arrows} alt="" style={{ width: 20, height: 20, paddingLeft: 8,paddingTop:'6px' }} />
                                    </div>
                                </div>
                    }else{
                        return  <div style={{display:'flex'}}> */}
                                    {/* {
                                        (()=>{
                                            if(member_type_info.member_type!==1){
                                                return (
                                                    <Tooltip title="如您的免费时长已用完，请点击成为VIP后继续使用" arrow>
                                                        <div style={{color:'#fff',fontSize:'14px',lineHeight:'34px',marginRight:80}}>
                                                            {member_type_info.remaining_time>0?'试用时长:':''}
                                                            {member_type_info.remaining_time>0&&
                                                            <Countdown style={{color:'#fff'}}
                                                                onTick={(t)=>{
                                                                    if(t.seconds===0){
                                                                        console.log(t)
                                                                        update_now_time()
                                                                    }
                                                                }}
                                                                renderer={renderer}
                                                                onComplete={(time)=>{
                                                                    if(time.minutes===0&&time.seconds===0){
                                                                        update_now_time()
                                                                    console.log('time',time)
                                                                }}}
                                                                date={Date.now() + (member_type_info.remaining_time)*1000}>
                                                            </Countdown>}
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }
                                        })()
                                    } */}
                                    {/* {
                                        (()=>{
                                            if((member_type_info.member_type!==1)){
                                                return <div style={{fontSize:'14px',color:'#fff',borderRadius:'8px',padding:'3px 5px',height:'20px',textAlign:'center',marginRight:'80px',cursor: "pointer",lineHeight:'27px',width:60}} onClick={gotoPayment}>成为VIP</div>
                                            }else if((member_type_info.member_type===1)){
                                                return <div style={{fontSize:'14px',color:'#fff',borderRadius:'8px',padding:'3px 5px',height:'20px',textAlign:'center',marginRight:'80px',cursor: "pointer",lineHeight:'27px',width:60}} onClick={gotoPayment}>续费</div>
                                            }
                                        })()
                                    } */}
                                    {/* <div style={{display:'flex'}}>
                                        <Link to={parseInt(user_type)===2&&tax_agent_list!=null?'/tax_admin':'/mine'}>  
                                            <div style={{display:'flex', flexDirection: 'row', alignItems: 'center',width:100}}>
                                                <div style={{paddingRight:'10px'}}>
                                                    {
                                                        avatar===''?<AccountCircleIcon sx={{ fontSize: 30}} color="disabled"  />:<img src={avatar} style={{width:30,height:30,borderRadius:'50%'}} alt='' />
                                                    }
                                                </div>
                                                {
                                                    _.isNil(nick_name) || nick_name === "null"?<div style={{color:'#fff', fontSize: 15}}>{'博槐用户'+user_id}</div>:<div style={{color:'#fff', fontSize: 15,whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{nick_name}</div>
                                                }
                                            </div>
                                        </Link>   */}
                                        {/* {
                                            (()=>{
                                                if(!_.isNil(token)){
                                                    return <Link to='/inform_lists'>  <div onClick={() => (dispatch(shui_clear_read_count()),dispatch(clear_new_inform(1)))} style={{marginLeft:'8px',position:'relative'}}><img src={inform} alt="" style={{width:30,height:30}} />
                                                    {
                                                        (()=>{
                                                            if(shui_unread_num>0||shui_new_inform>0){
                                                                return <div style={{width:'10px',height:'10px',borderRadius:'50%',background:'red',position:'absolute',top:'0',right:"0"}}></div>
                                                            }
                                                        })()
                                                    }
                                                    </div></Link>
                                                }
                                            })()
                                        } */}
                                    {/* </div> 
                                </div>
                    }
                })()
            } */}
            <Dialogs />
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                onClick={open_inform_lists}
                severity="info"
                autoHideDuration={4000}
                key={vertical + horizontal}
            >
                <Alert severity="info"> 您有新的消息！</Alert>
            </Snackbar>
        </div>
    )
}

export default Head;