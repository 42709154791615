import React, { useEffect, useCallback ,useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { shui_question_list, lists_like, close_drawer, question_details} from '../../../actions/forum'

import DrawerContent from "../../question/DrawerContent"

const _ = require('micro-dash');
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: '#666',
    height: 80,
    lineHeight: '120px',
}));
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#3D1198',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#3D1198',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#3D1198',
        },
        '&:hover fieldset': {
        borderColor: '#3D1198',
        },
        '&.Mui-focused fieldset': {
        borderColor: '#3D1198',
        },
    },
});
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        minHeight: '85vh'
    },
    textContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        margin: '10px 0'
    }
}));
const product = [
    {
      value: 0,
      label: '税引擎',
    }
  ];
function ShuiQuestion() {
    const dispatch = useDispatch();
    const user_id = useSelector((state) => state.user.user);
    useEffect(() => {
        dispatch(shui_question_list({ sort_type: 1 ,user_id:user_id}))
    }, [])

    const question_lists = useSelector((state) => state.forum.my_question_lists);
    const shui_is_more=useSelector((state) => state.forum.my_shui_is_more);
    const drawer_open = useSelector((state) => state.forum.drawer_open);
    const detailed_info = useSelector((state) => state.forum.question_details);
    const answers_list = useSelector((state) => state.forum.answers_list);
    const [remind_suggest, setState] = React.useState(false);
    const product_value = 0
    const lists_conter=useRef()

    const moreData=(e)=>{
        console.log(e)
        if(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight-10){
            console.log('到底')
            if (question_lists.length > 0) {
                if(shui_is_more){
                    setTimeout(() => {
                        dispatch(shui_question_list({ sort_type: 1, after: question_lists[question_lists.length - 1].cursor, next: true ,user_id:user_id}))
                    }, 1500);
                }else{
                    setState(true)
                }
            }
        }
    }
    
    const vertical = 'top'
    const horizontal = 'center'
    const remindhandleClose = () => {
        setState(false)
    };
    const classes = useStyles();
    return (
        <div>
            <div className={classes.root}>
                <div style={{ marginBottom: 30 }}>
                    <Box sx={{ minWidth: 120,maxWidth:150 }}>
                        <CssTextField
                            id="outlined-select-currency"
                            select
                            label="产品"
                            value={product_value}
                            helperText=""
                            focused
                            style={{width:120}}
                            >
                            {product.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Box>
                </div>
                {
                    (() => {
                        if (question_lists.length > 0) {
                            return <div style={{height:'75vh',overflowY:'auto'}} ref={lists_conter} onScrollCapture={moreData}>
                                {
                                    question_lists.map((item, index) =>
                                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)', padding: '1% 3%', position: 'relative', margin: '2%' }}>
                                            <div style={{ cursor: "pointer" }} onClick={() => dispatch(question_details({ from: product_value, id: item.node.id }))}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={classes.textContent}>{item.node.question}</div>
                                                    <div style={{ margin: '10px 0' }}>{item.node.create_time.split('T')[0]}</div>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div>
                                                    {item.node.user.avatar.file_name!=null?<img src={item.node.user.avatar.file_name} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />:<img src='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png' alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />}
                                                    </div>
                                                    {item.node.user.nick_name!=null?<div style={{ lineHeight: '40px', paddingLeft: 10 }}>{item.node.user.nick_name}</div>:<div style={{ lineHeight: '40px', paddingLeft: 10 }}>用户0{item.node.user.id}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        } else {
                            return <div style={{  }}>暂无提问</div>
                        }
                    })()
                }

            </div>
            <Drawer
                anchor={'right'}
                open={drawer_open}
                onClose={() => dispatch(close_drawer())}
            >
                <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                    <DrawerContent
                        detailed_info={detailed_info}
                        answers_list={answers_list}
                        from={0}
                    />
                </div>
            </Drawer>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                severity="info"
                autoHideDuration={3500}
                key={vertical + horizontal}
            >
                <Alert severity="info"> 已加载全部</Alert>
            </Snackbar>
        </div>
    )
}
export default ShuiQuestion;