import React, { useEffect } from "react";
import {AppSkeleton, WebSkeletonLight, WebSkeletonDark} from './LazySkeleton';
import LazyLoad from 'react-lazyload';
// import { is } from "immer/dist/internal";


export const AppLoad_image = (url)=> {
    const img_url=url.img_url
    const [isLoad, setIsLoad] = React.useState(true);
    if(isLoad){
        return (
            <LazyLoad offset={100} style={{ textAlign: 'right', flexDirection: 'row', justifyContent: 'flex-end',display:'flex'}}>
                <div style={{   }}>
                    <AppSkeleton />
                    <img src={img_url} onLoad={()=>{setIsLoad(false)}}  hidden="hidden" alt='' />
                </div>
            </LazyLoad>
        )
    }else{
        return (
            <LazyLoad offset={100} style={{ textAlign: 'right', flexDirection: 'row', justifyContent: 'flex-end',display:'flex'}}>
                <div style={{ }}>
                    <img src={img_url} style={{ width: 300, maxWidth: '100%' }} alt='' />
                </div>
            </LazyLoad>
        );
    }
};

export const WebLoad_image = (url)=> {
    const Webimg_url=url.img_url
    const [WebisLoad, setWebIsLoad] = React.useState(true);
    if(WebisLoad){
        return (
            <LazyLoad offset={100} style={{paddingRight: 20, width: '75%', textAlign: 'center', paddingLeft: '5%'}}>
                <div style={{  }}>
                    <WebSkeletonLight />
                    <img src={Webimg_url} onLoad={()=>{setWebIsLoad(false)}}  hidden="hidden" alt='' />
                </div>
            </LazyLoad>
        )
    }else{
        return (
            <LazyLoad offset={100} style={{paddingRight: 20, width: '75%', textAlign: 'center', paddingLeft: '5%'}}>
                <div style={{ }}>
                    <img src={Webimg_url} style={{ maxWidth: '90%' }} alt='' />
                </div>
            </LazyLoad>
        )
    }
};
export const AllTop_img=(url)=>{
    const all_top_img=url.img_url
    const [AllisLoad,setAllIsLoad]=React.useState(true)
    if(AllisLoad){
        return (
            // <LazyLoad offset={0} style={{width:'50vw'}}>
                <div>
                    <WebSkeletonLight />
                    <img src={all_top_img} style={{width:'50vw'}} onLoad={()=>{setAllIsLoad(false)}}  hidden="hidden" alt='' />
                </div>
            // </LazyLoad>
        )
    }else{
        return (
            // <LazyLoad offset={0} style={{width:'50vw'}}>
                <div style={{ }}>
                    <img src={all_top_img} style={{width:'50vw'}}  alt='' />
                </div>
            // </LazyLoad>
        )
    }
}