import {
    QUESTIONLISTS,
    QUESTIONLISTSLIKE,
    QUESTION_OPEN_DRAWER,
    QUESTION_CLOSE_DRAWER,
    QUESTION_DETAILS_LIKE,
    ANSWER_LIST,
    ANSWER_LIKE,
    RELEASE_SUCCESS,
    SHUI_MORE_QUESTION,
    PROGTAMMELIST,
    PROGRAMMEDETAILS,
    CREATEPROGRAMME,
    RISK_CLOSE_OPEN,
    SHOW_SEARCH,
    SEARCH_WORD,
    SEARCH_MORE_QUESTION, QUESTIONSEARCHLISTSLIKE, MYQUESTIONLISTS, MQ_MORE_QUESTION, CONTAIN_KEY
} from '../actions/types';

const initialState = {
    my_question_lists:[],
    question_lists:[],
    question_details:'',
    answers_list:[],
    release_success:false,
    contain_key:false,
    shui_is_more:true,
    my_shui_is_more:true,
    search_is_more:true,
    programme_list:[],
    programme_details:null,
    is_programme:false,
    risk_drawer_open:false,
    show_search_list:false,
    search_word:''
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case QUESTIONLISTS:
            if(action.next){
                let shui_question_lists=[...state.question_lists]
                shui_question_lists=shui_question_lists.concat(action.content)

                return {...state, question_lists:shui_question_lists};
            }else{
                return {...state, question_lists:action.content};
            }
        case MYQUESTIONLISTS:
            if(action.next){
                let my_question_lists=[...state.my_question_lists]
                my_question_lists=my_question_lists.concat(action.content)
                return {...state, my_question_lists:my_question_lists};
            }else{
                return {...state, my_question_lists:action.content};
            }
        case QUESTIONLISTSLIKE:
            let question_lists=[...state.question_lists]
            if(action.like===0){
                question_lists[action.index].node.likes=1
                question_lists[action.index].node.number_of_likes=question_lists[action.index].node.number_of_likes+1
            }else{
                question_lists[action.index].node.likes=0
                question_lists[action.index].node.number_of_likes=question_lists[action.index].node.number_of_likes-1
            }
            return {...state,question_lists:question_lists}
        case QUESTIONSEARCHLISTSLIKE:
            let question_lists2=[...state.question_lists]
            if(action.like===0){
                question_lists2[action.index]._source.likes=1
                question_lists2[action.index]._source.title_likes=question_lists2[action.index]._source.title_likes+1
            }else{
                question_lists2[action.index]._source.likes=0
                question_lists2[action.index]._source.title_likes=question_lists2[action.index]._source.title_likes-1
            }
            return {...state,question_lists:question_lists2}
        case QUESTION_OPEN_DRAWER:
            return {...state, question_details: action.question_details,answers_list:action.answers_list, drawer_open: true}
        case ANSWER_LIST:
            return {...state,answers_list:action.answers_list}
        case QUESTION_CLOSE_DRAWER:
            return {...state,drawer_open: false,question_details:'',answers_list:[]}
        case QUESTION_DETAILS_LIKE:
            let question_details={...state.question_details}
            if(action.like){
                question_details.is_like=false
                question_details.number_of_likes=question_details.number_of_likes-1
            }else{
                question_details.is_like=true
                question_details.number_of_likes=question_details.number_of_likes+1
            }
            return {...state,question_details:question_details}
        case ANSWER_LIKE:
            let answers_list=[...state.answers_list]
            if(action.sort_type){
                answers_list[action.index].is_like=false
                answers_list[action.index].number_of_likes=answers_list[action.index].number_of_likes-1
            }else{
                answers_list[action.index].is_like=true
                answers_list[action.index].number_of_likes=answers_list[action.index].number_of_likes+1
            }
            return {...state,answers_list:answers_list}
        case RELEASE_SUCCESS:
            return {...state,release_success:action.success}
        case CONTAIN_KEY:
            return {...state,contain_key:action.contain_key}
        case SHUI_MORE_QUESTION:
            return {...state,shui_is_more:action.is_more}
        case MQ_MORE_QUESTION:
            return {...state,mq_is_more:action.is_more}
        case SEARCH_MORE_QUESTION:
            return {...state,search_is_more:action.is_more}
        case PROGTAMMELIST:
            return {...state,programme_list:action.programme_list}
        case PROGRAMMEDETAILS:
            return {...state,programme_details:action.programme_details,risk_drawer_open:true}
        case CREATEPROGRAMME:
            return {...state,is_programme:action.is_programme}
        case RISK_CLOSE_OPEN:
            return {...state,risk_drawer_open:false,programme_details:null}
        case SHOW_SEARCH:
            return {...state,show_search_list:action.is_show}
        case SEARCH_WORD:
            return {...state,search_word:action.word}
        default:
            return state;
    }
}