import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';

import { risk_lists,risk_details,risk_close_open,create_risk,create_programme} from '../../../actions/forum'

import Footer from "../../footer/Footer";
import HeadHavigation from "../../headHavigation/headHavigation";
import DrawerContent from "./RiskDrawerContent"

import cancel_praise from '../../../static/icon/cancel_praise.png'
import top_praise from '../../../static/icon/top_praise.png'

const _ = require('micro-dash');
const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: '#666',
    height: 80,
    lineHeight: '120px',
}));
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
        minHeight: 'calc(100vh - 240px)'
    },
    textContent: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        // margin: '20px 0',
        padding:'10px'
    }
}));
function RiskAssessment() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(risk_lists())
    }, [])

    const programme_list = useSelector((state) => state.forum.programme_list);
    const drawer_open = useSelector((state) => state.forum.risk_drawer_open);
    const detailed_info = useSelector((state) => state.forum.programme_details);
    const get_release_success = useSelector((state) => state.forum.is_programme)
    // const is_more = useSelector((state) => state.forum.shui_is_more)
    console.log('programme_list',programme_list)
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [question_content, setValue] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    // 发布
    const issue_question = () => {
        if (question_content.length > 20) {
            dispatch(create_risk({ content: question_content}))
        } else {
            alert('字数请符合规范')
        }
    }
    useEffect(() => {
        if (get_release_success) {
            setState(true)
        }
    }, [dispatch, get_release_success]);


    const [remind_suggest, setState] = React.useState(false);
    const vertical = 'top'
    const horizontal = 'center'
    const remindhandleClose = () => {
        setState(false)
        setOpen(false);
        setValue('')
        dispatch(create_programme(false))
    };
    const classes = useStyles();
    return (
        <div>
            <HeadHavigation provenance={0} />
            <div className={classes.root}>
                <div style={{ marginBottom: 30 }}>
                    <Box
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Item elevation={3} style={{ cursor: "pointer", width: '15%' }} onClick={handleClickOpen}>
                            <AddIcon style={{ fontSize: 40 }} />
                        </Item>
                    </Box>
                </div>
                {
                    (() => {
                        if (programme_list.length > 0) {
                            return <div>
                                    {
                                    programme_list.map((item, index) =>
                                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.16)',  position: 'relative', marginBottom: '2%' }}>
                                            {/* <Link to={{pathname:`/question/details`,state:{from:0}}}> */}
                                            <div style={{ cursor: "pointer",border:'1px solid rgb(0, 0, 0,0.2)' }} onClick={() => dispatch(risk_details({ id: item.id }))}>
                                                <div>
                                                    <div style={{background:'rgb(61, 17, 152)',color:'#fff',padding:'8px'}}>提问方案：</div>
                                                    <div className={classes.textContent}>{item.content}</div>
                                                    <div style={{ padding: '10px' }}>{item.create_time.split('T')[0]}</div>
                                                </div>
                                                
                                                {
                                                    _.isNil(item.assessment)?'':<div style={{padding:'10px',border:'1px solid rgb(0,0,0,0.16)',margin:'10px'}}>
                                                            <div style={{padding:'8px'}}>评估：</div>
                                                            <div className={classes.textContent}>{item.assessment.content}</div>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                                {
                                                                    _.isNil(item.assessment)?'':<div style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'0 10px'}}>
                                                                        <div style={{ margin: '10px 0' }}>{item.assessment.create_time.split('T')[0]}</div>
                                                                        <Rating
                                                                            name="simple-controlled"
                                                                            value={item.assessment.risk_level}
                                                                            icon={<LocalPoliceIcon fontSize="inherit" />}
                                                                            emptyIcon={<LocalPoliceIcon fontSize="inherit" />}
                                                                            style={{paddingTop:'8px'}}
                                                                        />
                                                                    </div> 
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            {/* </Link> */}
                                        </div>
                                    )
                                }
                                </div>
                           
                        } else {
                            return <div style={{ textAlign: 'center' }}>您暂未提交任何税务筹划评估，请点击上方发布按钮，提交对已有方案的风险评估</div>
                        }
                    })()
                }

            </div>
            <Drawer
                anchor={'right'}
                open={drawer_open}
                onClose={() => dispatch(risk_close_open())}
            >
                <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                    <DrawerContent
                        detailed_info={detailed_info}
                    />
                </div>
            </Drawer>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>发布</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="outlined-multiline-static"
                        label="请输入字数不小于20的风险评估"
                        multiline
                        rows={4}
                        fullWidth
                        style={{ width: window.innerWidth * 1 / 4, height: '40%' }}
                        value={question_content}
                        onChange={handleChange}

                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={issue_question}>发布</Button>
                </DialogActions>
            </Dialog>
            <hr style={{ opacity: '0.4' }} />
            <div style={{ padding: '10px 12%' }}>
                <Footer />
            </div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                severity="info"
                autoHideDuration={3500}
                key={vertical + horizontal}
            >
                <Alert severity="info"> 发布成功</Alert>
            </Snackbar>
        </div>
    )
}
export default RiskAssessment;