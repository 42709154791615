/**
 * Created on 6/23/21.
 * @author Xihao
 */

import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DirectionsIcon from '@mui/icons-material/Directions';
import {useDispatch,useSelector} from "react-redux";
import {search} from "../../../actions/search";
import Chip from '@mui/material/Chip';

import Dialogs from './Dialog'

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {open_hints} from '../../../actions/user';


const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 55,
    },
    input: {
       // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton:{
        backgroundColor:"#3D1198 !important",
        color: 'white !important',
        fontSize: '17px !important',
        paddingInline: '36px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#3D1198 !important',
        },
    },
    clearButton:{
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));

export default function SearchBar() {
    const [searchValue, setSearchValue] = React.useState('');
    const [multiWords, setMultiWords] = React.useState([]);
    const token=useSelector((state) => state.user.token);
    const tax_vip=useSelector((state) => state.user.member_type_info);
    const classes = useStyles();
    const dispatch = useDispatch();

    const get_search_words = () => {
        return [...multiWords, searchValue].join(' ')
    };

    const handleSearch = () => {
        get_search_words();
        dispatch(search({words:get_search_words()}));
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            dispatch(search({words:get_search_words()}));
            // if(_.isNil(token)){
            //     dispatch(open_hints(true))
            //     e.preventDefault();
            // }else{
            //     console.log(tax_vip)
            //     if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
            //         dispatch(open_hints(true))
            //         e.preventDefault();
            //     }else{
            //         e.preventDefault();
            //         dispatch(search({words:get_search_words()}));
            //     }
            // }
        }
        else if(e.keyCode === 32){
            if(searchValue.length>15){
                alert("搜索语句不能超过15个字");
                return;
            }
            if(!multiWords.includes(searchValue) && searchValue.length>0){
                setMultiWords(multiWords=>[...multiWords, searchValue]);
            }
            e.preventDefault();
            setSearchValue('');
        }
    };
    const handleDelete = (item) => {
        console.log(item);
        setMultiWords(multiWords.filter(v=>v!==item));
        console.log(multiWords);
    };

    const clearAll = () => {
        setMultiWords([]);
        setSearchValue("");
    };

    const hasSearchContent = () => {
        return multiWords.length>0 || searchValue.length>0;
    };

    const addToMultiWords = () => {
        if(searchValue.length>15){
            alert("搜索语句不能超过15个字");
            return;
        }
        if(!multiWords.includes(searchValue) && searchValue.length>0){
            setMultiWords(multiWords=>[...multiWords, searchValue]);
        }
        setSearchValue('');
    };

    return (
        <div>
            <Paper component="form" className={classes.root}>
                <IconButton className={classes.iconButton} aria-label="menu" onClick={addToMultiWords}>
                    {
                        hasSearchContent()?
                            <AddIcon style={{ fontSize: 32 }} />
                            :
                            <SearchIcon style={{ fontSize: 32 }}/>

                    }
                </IconButton>
                {
                    multiWords.length>0 &&
                        multiWords.map(
                            (item, index) => <Chip key={index}
                                                   label={item}
                                                   onDelete={()=>handleDelete(item)}
                                                   color="primary"
                                                   style={{backgroundColor: '#3D1198', marginRight: 2}}
                            />

                        )
                }
                <InputBase
                    className={classes.input}
                    placeholder="税务筹划搜索引擎"
                    inputProps={{ 'aria-label': '税务筹划搜索引擎' }}
                    value={searchValue}
                    onChange={(event)=>setSearchValue(event.target.value)}
                    onKeyDown={_handleKeyDown}
                />
                <Button className={classes.clearButton} onClick={clearAll} >清空搜索</Button>
                <Button className={classes.searchClickButton}
                onClick={handleSearch} disableElevation>
                    搜索
                </Button> 
                {/* {
                    (()=>{
                        if(!_.isNil(token)){
                            if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                                return <Button className={classes.searchClickButton}
                                        onClick={()=>{dispatch(open_hints(true))}} disableElevation>
                                            搜索
                                        </Button>
                            }else{
                                return  <Button className={classes.searchClickButton}
                                        onClick={handleSearch} disableElevation>
                                            搜索
                                        </Button> 
                            }
                        }else{
                            return      <Button className={classes.searchClickButton}
                                        onClick={()=>{dispatch(open_hints(true))}} disableElevation>
                                            搜索
                                        </Button> 
                        }
                    })()
                } */}
            </Paper>
            <p style={{marginTop: 8, marginBottom: 2, color: '#B5B4B9', fontSize: 14}}>
                按空格键添加搜索条件，按回车键直接进行搜索
            </p>

            <Dialogs />
        </div>
    );
}