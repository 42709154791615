import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import { MODEL_COLOR } from '../../../configs/constants'
import shui_engine from '../../../static/logo/logo_2.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import user_img from '../../../static/image/user_img.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Countdown from 'react-countdown';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { get_cron_expression, extract_url, create_scheduler, send_mobile_code, agent_list, test_prompt } from '../../../actions/user'
import { send_mobile } from '../../../actions/dataset'
import { message, Spin } from 'antd';
import RecordingDrawer from '../examples/recording_drawer'

const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        backgroundColor: "#4844f3 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#4844f3 !important',
        },
    },
    clearButton: {
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    },
    disabled_button: {
        background: 'rgba(221, 221, 221, 1) !important',
        color: '#fff !important'
    }
}));



const _ = require('micro-dash');
function CreateMonitoring() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const token = useSelector((state) => state.user.token);
    const user_type = useSelector((state) => state.user.user_type);
    const [time_rule, setTimeRule] = React.useState('');
    const [cron_value, setCronValue] = React.useState('');
    const [url_value, setUrlValue] = React.useState('');
    const [message_value, setMessageValue] = React.useState('');
    const [name, setName] = React.useState('');
    const [compare_value, setCompareValue] = React.useState('是否有不同');
    const [phone_value, setPhoneValue] = React.useState('');
    const [email_value, setEmailValue] = React.useState('');
    const [authCode, setAuthCode] = React.useState('');
    const [verificationCode, setVerificationCode] = React.useState(false);
    const [disabled_value, setDisabledValue] = React.useState(true);
    const base_setting=useSelector((state) => state.user.base_setting);
    const rule_Change = (e) => {
        setTimeRule(e.target.value)
    }
    const [isCheckingValue, setIsCheckingValue] = React.useState('');
    const [detection_loading, setDetectionLoading] = React.useState(false);
    const detection_time = () => {
        if (time_rule !== '') {
            dispatch(get_cron_expression({ question: time_rule }, (v) => setIsCheckingValue(v), (v) => setCronValue(v), (v) => setDetectionLoading(v)))
        } else {
            message.error('规则不能为空')
        }

    }
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])
    useEffect(() => {
        if (isCheckingValue === false) {
            message.error('规则填写错误，请重新填写')
            setIsCheckingValue('')
            setDisabledValue(true)
        } else if (isCheckingValue === true) {
            setDisabledValue(false)
        }
    }, [isCheckingValue])
    const url_Change = (e) => {
        setUrlValue(e.target.value)
    }
    const message_Change = (e) => {
        setMessageValue(e.target.value)
    }
    const name_Change = (e) => {
        setName(e.target.value)
    }
    const phone_Change = (e) => {
        setPhoneValue(e.target.value)
    }
    const email_Change = (e) => {
        setEmailValue(e.target.value)
    }
    const compare_Change = (e) => {
        setCompareValue(e.target.value)
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        return <span style={{ color: '#43179D', fontWeight: 'bold', fontSize: 15, paddingTop: 8 }}>{seconds}</span>;
    }
    const verify = () => {
        if (phone_value?.length > 10 && phone_value.startsWith('1')) {
            setVerificationCode(true)
            dispatch(send_mobile_code({ phone_number: phone_value }, (v) => setVerifyCode(v)))
        } else {
            message.error('手机号不能为空');
        }
        // setVerificationCode(true)
    }
    const [test_value, setTestValue] = React.useState('');
    const [test_loading, setTestLoading] = React.useState(false);
    const [test_error, setTestError] = React.useState('');
    const test_click = () => {
        if (cut_type === 0) {
            if (url_value != '' && message_value != '') {
                console.log(233)
                dispatch(extract_url({ url: url_value, extract_prompt: message_value }, (v) => setTestValue(v), (v) => setTestLoading(v), (v) => setTestDialog(v), (v) => setTestError(v)))
            } else {
                message.error('填写错误，请重新填写')
            }
        } else {
            console.log(222)
            if (agent_value !== '') {
                dispatch(test_prompt({ prompt: agent_value }, (v) => setTestValue(v), (v) => setTestLoading(v), (v) => setTestDialog(v), (v) => setTestError(v)))
            } else {
                message.error('填写错误，请重新填写')
            }
        }
    }

    useEffect(() => {
        if (test_error != '') {
            message.error(test_error)
            setTestError('')
        }
    }, [test_error])
    const [error_value,setErrorValue]= React.useState('');
    useEffect(()=>{
        console.log(error_value)
        if(error_value!==''){
            message.error(error_value)
            setErrorValue('')
        }
    },[error_value])
    const [verify_code, setVerifyCode] = React.useState('');
    const [is_verify, setIsVerify] = React.useState(false);
    const create_phone = () => {
        if (relation_type === 0) {
            if (verify_code === authCode && verify_code != '') {
                message.success('验证成功')
                setIsVerify(true)
            } else {
                message.error('验证错误，请重新填写')
            }
        } else {
            if (email_value != '') {
                setIsVerify(true)
            }
        }

    }
    const [is_create, setIsCreate] = React.useState('');
    const [create_loading, setCreateLoading] = React.useState(false);
    const create_mon = () => {
        if (cut_type === 0) {
            if (relation_type === 0) {
                if (name != '' && compare_value != '' && cron_value != '' && time_rule != '' && message_value != '' && url_value != '' && phone_value != '') {
                    dispatch(create_scheduler({ 
                        name: name, 
                        url: url_value, 
                        compare_prompt: compare_value, 
                        cron: cron_value, 
                        cron_language_expression: time_rule, 
                        extract_prompt: message_value, 
                        notification_content: phone_value, 
                        content: test_value, 
                        notification_type: 'PHONE', 
                        type: 'LINK' 
                    }, (v) => setIsCreate(v), (v) => setCreateLoading(v),(v)=>setErrorValue(v)))
                } else {
                    message.error('请按步骤填写')
                }
            } else {
                if (name != '' && compare_value != '' && cron_value != '' && time_rule != '' && message_value != '' && url_value != '' && email_value != '') {
                    dispatch(create_scheduler({ 
                        name: name, 
                        url: url_value, 
                        compare_prompt: compare_value, 
                        cron: cron_value, 
                        cron_language_expression: time_rule, 
                        extract_prompt: message_value, 
                        notification_content: email_value, 
                        content: test_value, 
                        notification_type: 'EMAIL', 
                        type: 'LINK' 
                    }, (v) => setIsCreate(v), (v) => setCreateLoading(v),(v)=>setErrorValue(v)))
                } else {
                    message.error('请按步骤填写')
                }
            }
        } else {
            if (name != '' && cron_value != '' && time_rule != '' && agent_value != '' && email_value != '') {
                dispatch(create_scheduler({ 
                    name: name, 
                    prompt: agent_value, 
                    cron: cron_value, 
                    cron_language_expression: time_rule, 
                    notification_content: email_value, 
                    content: test_value, 
                    notification_type: 'EMAIL', 
                    type: 'PROMPT' 
                }, (v) => setIsCreate(v), (v) => setCreateLoading(v),(v)=>setErrorValue(v)))
            } else {
                message.error('请按步骤填写1')
            }
        }
    }
    const history = useHistory();
    useEffect(() => {
        if (is_create === true) {
            history.push('/largemodel/monitoring');
        }
    }, [is_create])
    const [test_dialog, setTestDialog] = React.useState(false);
    const testClose = () => {
        setTestDialog(false)
    }
    const [cut_type, setCutType] = React.useState(0);
    const [relation_type, setRelationType] = React.useState(0);
    const cut_click = (e) => {
        setCutType(e)
        if (e === 1) {
            setRelationType(1)
        }
    }
    const relation_click = (e) => {
        if (cut_type === 1) {
            message.error('智能体任务只支持email通知')
        } else {
            setRelationType(e)
        }
    }
    const [agent_value, setAgentValue] = React.useState('');
    const [prompt_dialog, setPromptDialog] = React.useState(false);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const agent_Change = (e) => {
        if (e.nativeEvent.data === '@') {
            dispatch(agent_list())
            setPromptDialog(true)
        }
        setAgentValue(e.target.value)
    }
    const promptClose = () => {
        setPromptDialog(false)
    }
    const handleAgent = (e, expression, intr) => {
        console.log(e)
        // let mess_value = { ...agent_value }
        let mess_value = agent_value.slice(0, -1)
        if(expression=='@文件助手'){
            if(!_.isNil(e)){
                mess_value = mess_value + expression + e
            }else{
                mess_value = mess_value + expression
            }
        }else{
            if(!_.isNil(e)){
                mess_value = mess_value + expression + '@ ' + e
            }else{
                mess_value = mess_value + expression+'@'
            }
        }
        // mess_value = agent_value + e
        setAgentValue(mess_value)
        setPromptDialog(false)
    }
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 90px)', overflow: 'auto', minWidth: 220, padding: '8px 0' }}>
                            <TabbleLists type='2'></TabbleLists>
                        </div>
                        <div style={{ padding: '3% 5%' }}>
                            <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                                <Link to='/largemodel/monitoring' style={{ cursor: 'pointer' }}>定时AI任务列表</Link>
                                <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div>
                                <div style={{ cursor: 'pointer' }}>创建定时AI任务</div>
                            </div>
                            <div>
                                <div style={{ width: '80%', padding: '5%' }}>
                                    <div style={{ fontWeight: 'bold', paddingBottom: 10 }}>
                                        步骤1：创建时间规则
                                    </div>
                                    <div style={{ minWidth: '500px' }}>
                                        <div style={{ width: '90%', padding: '10px 0' }}>
                                            <TextField
                                                id="outlined-basic"
                                                label="创建时间规则"
                                                variant="outlined"
                                                value={time_rule}
                                                onChange={rule_Change}
                                                inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                InputLabelProps={{ style: { fontSize: 13 } }}
                                                style={{ width: '100%', fontSize: 15 }}
                                            />
                                        </div>
                                        <div style={{ fontSize: 11, color: '#2d2d2d' }}>*请描述您的调用时间规则，比如每天十点，每周二上午9点，注意，时间规则最小精确到小时，不能填写分或秒。</div>
                                        <div style={{ fontSize: 11, color: '#2d2d2d', paddingTop: 5 }}>*每按时间规则跑一次监控会消耗3次提问次数，请谨慎创建时间规则。</div>
                                        {
                                            cron_value != '' ? <div style={{ width: '90%', padding: '10px 0' }}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label="cron参数"
                                                    variant="outlined"
                                                    value={cron_value}
                                                    disabled
                                                    inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                                    style={{ width: '100%', fontSize: 15 }}
                                                />
                                            </div> : ''
                                        }
                                    </div>

                                    <div style={{ paddingTop: 20 }}>
                                        <div style={{ display: 'flex' }}>
                                            <Button variant="contained" style={{ width: '60%', height: 40, background: base_setting?.primary_color }} onClick={detection_time}>检测</Button>
                                            {
                                                detection_loading === true ? <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                    <Spin />
                                                </div> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '80%', padding: '5%' }}>
                                    <div style={{ fontWeight: 'bold', paddingBottom: 10 }}>步骤2：选择执行任务</div>
                                    <div style={{ display: 'flex', padding: '20px 0' }}>
                                        <div onClick={() => cut_click(0)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: cut_type === 0 ? base_setting?.primary_color : '', color: cut_type === 0 ? '#fff' : '', textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div>监控网站</div>
                                            </div>
                                            <div style={{ fontSize: 12, paddingTop: 5 }}>定期监控公开网站内容变化</div>
                                        </div>
                                        <div onClick={() => cut_click(1)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: cut_type === 1 ? base_setting?.primary_color : '', color: cut_type === 1 ? '#fff' : '', textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div>执行智能体</div>
                                            </div>
                                            <div style={{ fontSize: 12, paddingTop: 5 }}>执行智能体任务</div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ minWidth: '500px' }}>
                                            {
                                                cut_type === 0 ? <div>
                                                    <div style={{ width: '90%', padding: '10px 0' }}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="提取链接"
                                                            variant="outlined"
                                                            value={url_value}
                                                            onChange={url_Change}
                                                            inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />
                                                    </div>
                                                    <div style={{ width: '90%', padding: '10px 0' }}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="提取内容，举例：提取列表内容"
                                                            variant="outlined"
                                                            value={message_value}
                                                            onChange={message_Change}
                                                            inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />
                                                    </div>
                                                    <div style={{ width: '90%', padding: '10px 0' }}>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="内容对比规则，若返回是则触发通知。举例：是否有不同,是否有新增"
                                                            variant="outlined"
                                                            value={compare_value}
                                                            onChange={compare_Change}
                                                            inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                            InputLabelProps={{ style: { fontSize: 13 } }}
                                                            style={{ width: '100%', fontSize: 15 }}
                                                        />

                                                    </div>
                                                </div> : <div style={{ width: '90%', padding: '10px 0' }}>
                                                    <TextField
                                                        id="filled-multiline-static"
                                                        multiline
                                                        rows={4}
                                                        label="执行智能体"
                                                        focused
                                                        variant="outlined"
                                                        value={agent_value}
                                                        onChange={agent_Change}
                                                        inputProps={{ style: { fontSize: 13 } }}
                                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                                        style={{ width: '100%', fontSize: 15 }}
                                                    />
                                                </div>
                                            }
                                            <div style={{ textAlign: 'left', width: '90%', display: 'flex' }}>
                                                <Button variant="contained" className={disabled_value == true ? classes.disabled_button : ''} disabled={disabled_value} style={{ width: '60%', height: 40, background: base_setting?.primary_color }} onClick={test_click}>测试</Button>
                                                {
                                                    test_loading === true ? <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                        <Spin />
                                                    </div> : ''
                                                }
                                            </div>
                                            {
                                                cut_type===0?<div>
                                                    <div style={{ fontSize: 11, color: '#2d2d2d', paddingTop: 5 }}>*测试仅针对提取链接和提取内容</div>
                                                    <div style={{ fontSize: 11, color: '#2d2d2d', paddingTop: 5 }}>*内容对比规则是监控创建后才会进行对比</div>
                                                </div>:<div style={{ fontSize: 11, color: '#2d2d2d', paddingTop: 5 }}>*输入@可调用已创建智能体</div>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '80%', padding: '5%' }}>
                                    <div style={{ fontWeight: 'bold', paddingBottom: 10 }}>步骤3：选择通知方式</div>
                                    <div style={{ display: 'flex', padding: '20px 0' }}>
                                        <div onClick={() => relation_click(0)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: relation_type === 0 ? base_setting?.primary_color : '', color: relation_type === 0 ? '#fff' : '', textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div>短信通知</div>
                                            </div>
                                            <div style={{ fontSize: 11, paddingTop: 5 }}>短信内容审核严格不能通知具体结果</div>
                                        </div>
                                        <div onClick={() => relation_click(1)} style={{ boxShadow: '0px 1px 4px 0px rgba(218,219,220,1)', padding: 20, borderRadius: 10, width: 150, marginRight: 10, cursor: 'pointer', background: relation_type === 1 ? base_setting?.primary_color : '', color: relation_type === 1 ? '#fff' : '', textAlign: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div>Email通知</div>
                                            </div>
                                            <div style={{ fontSize: 11, paddingTop: 5 }}>Email可详细查看全部运行结果</div>
                                        </div>
                                    </div>
                                    <div style={{ minWidth: '500px' }}>
                                        {
                                            relation_type === 0 ? <div>
                                                <div style={{ width: '90%', padding: '10px 0' }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="手机号"
                                                        variant="outlined"
                                                        value={phone_value}
                                                        onChange={phone_Change}
                                                        disabled={is_verify}
                                                        inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                                        style={{ width: '100%', fontSize: 15 }}
                                                    />
                                                </div>
                                                <div style={{ display: 'flex', padding: '30px 0' }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="验证码"
                                                        variant="outlined"
                                                        value={authCode}
                                                        onChange={(e) => setAuthCode(e.target.value)}
                                                        inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                                        style={{ width: '70%', fontSize: 15 }}
                                                    />
                                                    {
                                                        verificationCode ? <div style={{ borderRadius: 8, height: 43, width: '30%', justifyContent: 'center', display: 'flex', fontSize: 18, alignItems: 'center' }}>
                                                            <Countdown date={Date.now() + 60000}
                                                                renderer={renderer}
                                                                onComplete={() => setVerificationCode(false)}
                                                            />
                                                        </div> : <div style={{ display: 'flex', alignItems: 'center', borderRadius: 8, marginLeft: 10 }}>
                                                            <Button onClick={verify} variant="contained" size="large" style={{ width: '100%', background: base_setting?.primary_color, fontSize: 13, height: 43 }}>验证码</Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div> : <div>
                                                <div style={{ width: '90%', padding: '10px 0' }}>
                                                    <TextField
                                                        id="outlined-basic"
                                                        label="Email"
                                                        variant="outlined"
                                                        value={email_value}
                                                        onChange={email_Change}
                                                        disabled={is_verify}
                                                        inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                                        style={{ width: '100%', fontSize: 15 }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div style={{ textAlign: 'left', width: '90%', display: 'flex' }}>
                                            <Button variant="contained" disabled={disabled_value} className={disabled_value == true ? classes.disabled_button : ''} style={{ width: '60%', height: 40, background: base_setting?.primary_color }} onClick={create_phone}>确认绑定</Button>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '80%', padding: '5%' }}>
                                    <div style={{ fontWeight: 'bold', paddingBottom: 10 }}>步骤4：创建智能监控</div>
                                    <div style={{ minWidth: '500px' }}>
                                        <div style={{ width: '90%', padding: '10px 0' }}>
                                            <TextField
                                                id="outlined-basic"
                                                label="名称"
                                                variant="outlined"
                                                value={name}
                                                onChange={name_Change}
                                                inputProps={{ style: { fontSize: 13, height: 10 } }}
                                                InputLabelProps={{ style: { fontSize: 13 } }}
                                                style={{ width: '100%', fontSize: 15 }}
                                            />
                                        </div>

                                        <div style={{ textAlign: 'left', width: '90%', display: 'flex' }}>
                                            <Button variant="contained" disabled={!is_verify} className={!is_verify == true ? classes.disabled_button : ''} style={{ width: '60%', height: 40, background: base_setting?.primary_color }} onClick={create_mon}>创建</Button>
                                            {
                                                create_loading === true ? <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                    <Spin />
                                                </div> : ''
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog onClose={testClose} open={test_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                    <div style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer' }} onClick={testClose}>
                        <CancelIcon style={{ fontSize: 20 }} />
                    </div>
                    <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>提取内容测试结果</DialogTitle>
                    <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px', minHeight: '20vh' }}>
                        <div>
                            <div style={{ fontSize: 16, background: '#f2f4f8', padding: 10, borderRadius: 8 }} dangerouslySetInnerHTML={{ __html: test_value }}></div>
                        </div>
                    </div>
                </Dialog>
                <Dialog onClose={promptClose} open={prompt_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                    <div style={{ position: 'absolute', right: 20, top: 20 }} onClick={promptClose}>
                        <CancelIcon style={{ fontSize: 20 }} />
                    </div>
                    <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>博槐智能体列表</DialogTitle>
                    <div style={{ padding: 50, paddingTop: 0 }}>
                        <TableContainer>
                            <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>名称</TableCell>
                                        <TableCell align="left">耗费提问次数</TableCell>
                                        <TableCell align="left">介绍</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hint_agent?.map((row) => (
                                        <TableRow
                                            hover
                                            key={row.name}
                                            onClick={(e) => handleAgent(row.prompt, row.expression, row.introduction)}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">{row.cost}</TableCell>
                                            <TableCell align="left">{row.introduction}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Dialog>
            </div >
            <RecordingDrawer></RecordingDrawer>
        </div>
    )
}

export default CreateMonitoring