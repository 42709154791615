/**
 * Created on 6/23/21.
 * @author Xihao
 */
import React from 'react';
import { makeStyles } from '@mui/styles';
import PreviewItem from './PreviewItem';
import {useSelector} from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 10,
        width: '100%',
        minHeight:  window.innerHeight/2,
        marginTop: 20,
        // borderLeft: '1px solid #E6E5EB',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',

    },
    loading_container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height:  window.innerHeight/2,
    },
    title: {
        fontSize: 19,
    }
}));

const ResultList = ({search_result_list}) => {
    const classes = useStyles();
    const result_count = useSelector((state) => state.search.result_count);
    const is_loading = useSelector((state) => state.search.is_loading_search_results);

    if(is_loading){
        return(
            <div className={classes.loading_container}>
                <CircularProgress />
            </div>
        )
    }
    return (
        <div className={classes.root}>
            {
                search_result_list.length>0? 
                    search_result_list.map(
                        (item, index) => <PreviewItem
                            key={`result-preview-${index}`}
                            title={item._source.title}
                            content={item._source.content}
                            tags={item._source.tags}
                            tax_category={item._source.tax_category}
                            industry_category={item._source.industry_category}
                            search_index={item._index}
                            hit={item}
                    />
                ):<div style={{textAlign:'center'}}>搜索结果为空</div>
            }
        </div>
    )
};

export default ResultList;








