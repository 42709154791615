import {
    COMPANY_VALUE,
    COMPANY_DATA,
    PLAN_CLOSE_DRAWER,
    PLAN_OPEN_DRAWER,
    DATA_ERROR,
    LOADING,
    SEARCH_NUM
} from './types';
import {MIDDLE_EARTH_URL} from '../configs/constants'
import axios from 'axios';

const _ = require('micro-dash');

export function company_value(value){
    return {
        type:COMPANY_VALUE,
        value
    }
}
export function company_data(data){
    return {
        type:COMPANY_DATA,
        data
    }
}
export function open_drawer(hit){
    return {
        type: PLAN_OPEN_DRAWER,
        hit
    }
}
export function plan_close_drawer(){
    return {
        type:PLAN_CLOSE_DRAWER
    }
}
export function data_error(error){
    return {
        type:DATA_ERROR,
        error
    }
}
export function loading(){
    return {
        type:LOADING
    }
}
export function search_num(){
    return {
        type:SEARCH_NUM
    }
}

// dispatch
// 调查表
export function company_name(name) {
    return (dispatch, getState) => {
        dispatch(loading())
        axios.post(`${MIDDLE_EARTH_URL}/marketing/get-solutions-for-company-name/`,
            {name:name})
            .then(
                response => {
                    console.log(response);
                    dispatch(company_value(name))
                    dispatch(company_data(response.data.data))
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search_all`);
                if( error.response ){
                    console.log(error.response.data); // => the response payload
                    dispatch(data_error(error.response.data.msg))
                }
            })
    };
}

