import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import shui_engine from '../../../static/logo/logo_2.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import user_img from '../../../static/image/user_img.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { get_user_agent_list, create_agent, delete_user_agent,update_outsider_settings } from '../../../actions/user'
import copy from 'copy-to-clipboard';
import { message } from 'antd';

const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        backgroundColor: "#4844f3 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#4844f3 !important',
        },
    },
    clearButton: {
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));



const _ = require('micro-dash');
function IntelligentAgent() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const token = useSelector((state) => state.user.token);
    const tab_settings=useSelector((state) => state.user.tab_settings);
    const other_menus=useSelector((state) => state.user.other_menus);
    const base_setting=useSelector((state) => state.user.base_setting);
    // const [hint_agent,setHintAgent]=React.useState('');
    const history=useHistory()
    useEffect(() => {
        dispatch(get_user_agent_list())
    }, [])
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])
    const handleAgent = () => { }
    const [agent_dialog,setAgentDialog]=React.useState(false);
    const [agent_id,setAgentId]=React.useState(null);
    const agent_delete=(e)=>{
        setAgentId(e)
        setAgentDialog(true)
    }
    const agentClose=()=>{
        setAgentDialog(false)
    }
    const agentAffirm=()=>{
        dispatch(delete_user_agent({_id:agent_id}))
        setAgentDialog(false)
    }
    const MaxContent = (props) => {
        const e = props.e
        console.log(e)
        if (e?.length > 10) {
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{e}</div>
                <div>
                    <Tooltip title={e}>
                        <HelpIcon style={{ color: 'rgba(215, 215, 215)', fontSize: 20 }}>
                        </HelpIcon>
                    </Tooltip>
                </div>
            </div>
        } else {
            return <div style={{ width: 100 }}>{e}</div>
        }
    }
    const [open, setOpen] = React.useState(true);
    const share_lists=(name,id)=>{
        console.log(name,id)
        console.log(tab_settings,other_menus,base_setting)
        copy('https://dongqianshan.com/largemodel?agent_name='+name+'&agent_id='+id)
        message.success('分享链接复制成功')
        // let agent_lists=base_setting?.agent_menus
        // let data={settings:{
        //     agent_menus:[
        //         {
        //             name:name,
        //             url:'/largemodel?agent_name='+name+'&agent_id='+id,
        //             img:'/images/share_img.png',
        //             user_level:1,
        //             hint:'您没有权限'
        //         }
        //     ],
        //     other_menus:base_setting?.other_menus
        // }}
        // dispatch(update_outsider_settings(data))
    }
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex',width:'100%' }}>
                        <div style={{ background: '#fff',paddingTop: 8, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', overflow: 'auto', minWidth: 220,height:'calc(100vh - 54px)' }}>
                            <TabbleLists type='3'></TabbleLists>
                        </div>
                        <div style={{ padding: '5%',paddingTop: '2%',width:'calc(90% - 220px)',overflowY:'auto',height:'calc(88vh - 54px)'}}>
                        <Collapse in={open}>
                                <Alert
                                    // variant="outlined"
                                    severity="info"
                                    // style={{ borderColor: 'rgb(72, 68, 243)', color: 'rgb(72, 68, 243)' }}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    智能体（Agent）是指基于大型语言模型(LLM)构建的智能代理(Agent)技术。这些智能代理具备决策和调用工具来执行任务的能力，可以进行规划、调用工具去逐步完成给定目标。 智能体以自然语言为交互方式,高自动化地执行和处理专业或繁复的工作任务,从而极大程度释放人员精力。
                                </Alert>
                            </Collapse>
                            <div style={{display:'flex',justifyContent:'space-between',paddingBottom:10,marginTop:20}}>
                                <div style={{fontWeight:'bold'}}>您的智能体列表</div>
                                <Link to='/largemodel/create_agent' style={{ textAlign: 'right' }}>
                                    <Button variant="contained" color='success' style={{background:"#46b14b"}}>创建智能体</Button>
                                </Link>
                            </div>
                            <TableContainer>
                                <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>名称</TableCell>
                                            {/* <TableCell align="left">耗费提问次数</TableCell> */}
                                            <TableCell align="left">介绍</TableCell>
                                            <TableCell align="left">提示</TableCell>
                                            <TableCell align="left">类型</TableCell>
                                            <TableCell align="left">分享</TableCell>
                                            <TableCell align="left">操作</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {hint_agent?.map((row) => (
                                            <TableRow
                                                hover
                                                key={row.name}
                                                onClick={(e) => handleAgent(row.prompt)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                {/* <TableCell align="left">{row.cost}</TableCell> */}
                                                <TableCell align="left"><MaxContent e={row?.introduction} /></TableCell>
                                                <TableCell align="left">
                                                    {
                                                        row.type=='prompt_list'?<Link to={{pathname:`/largemodel/agent_particulars`,state:{lists:row}}}><Button style={{color:base_setting?.primary_color}}>查看详情</Button></Link>:<MaxContent e={row?.prompt} />
                                                    }
                                                    </TableCell>
                                                <TableCell align="left">
                                                    {
                                                        (()=>{
                                                            if(row.type==="api"){
                                                                return <Chip label="api" color="primary" variant="outlined" />
                                                            }else if(row.type==="vector_db"){
                                                                return <Chip label="知识库" color="success" variant="outlined" />
                                                            }else{
                                                                return <Chip label="多智能体" color="secondary" variant="outlined" />
                                                            }
                                                        })()
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Button style={{color:base_setting?.primary_color}} onClick={()=>share_lists(row.name,row._id)}>点击分享</Button>
                                                </TableCell>
                                                <TableCell align="left"><Button style={{color:base_setting?.primary_color}} onClick={()=>agent_delete(row._id)}>删除</Button></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div >
            <Dialog onClose={agentClose} open={agent_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                {/* <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>添加专属提示词</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确定删除该条智能体吗
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={agentClose}>取消</Button>
                    <Button onClick={agentAffirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default IntelligentAgent