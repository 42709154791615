import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory, useLocation, Redirect } from "react-router-dom";

import logo from '../../../static/logo/shui_engine.png'
import member_icon_selected from '../../../static/icon/member.png'
import pay_icon_selected from '../../../static/icon/pay.png'
import my_questions_icon_selected from '../../../static/icon/my_questions.png'
import personnel_management_selected from '../../../static/icon/personnel_management_selected.png'


import member_icon from '../../../static/icon/member_selected.png'
import pay_icon from '../../../static/icon/pay_selected.png'
import my_questions_icon from '../../../static/icon/my_questions_selected.png'
import personnel_management from '../../../static/icon/personnel_management.png'
import icon_sign_out from '../../../static/icon/icon_sign_out.png'

import Admin from './map';
import UserPool from './user_pool';
import UserLists from './user_lists';
import AgentLists from './tax_agent_list';

import { logout } from '../../../actions/user'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { get_user_linked } from '../../../actions/admin'
import {cut_tax_agent,user_customers} from '../../../actions/user'

const _ = require('micro-dash');

function Index() {
    const [lefTab, setlefTab] = React.useState(0);
    const token = useSelector((state) => state.user.token);
    const tax_agent_list = useSelector((state) => state.user.tax_agent_list);
    const avatar = useSelector((state) => state.user.avatar);
    const nick_name = useSelector((state) => state.user.nick_name);
    const user_id = useSelector((state) => state.user.user);
    const membership_info = useSelector((state) => state.user.membership_info);
    const dispatch = useDispatch();
    const location = useLocation();
    console.log(`locayion: ${location.pathname}`)
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [company, setCompany] = React.useState('');
    const [market_id, setMarket] = React.useState('');
    const handleChange = (event) => {
        // dispatch(get_user_linked(company))
        console.log(event.target.value)
        setCompany(event.target.value)
        dispatch(cut_tax_agent(event.target.value))
        dispatch(user_customers(event.target.value))
    }
    console.log(membership_info)
    useEffect(() => {
        // dispatch(web_qr_code({mini_type:1,coupon_uid:null,level:1}))
        switch (location.pathname) {
            case "/tax_admin":
                setlefTab(0);
                break;
            case "/tax_admin/user_pool":
                setlefTab(1);
                break;
            case "/tax_admin/user_lists":
                setlefTab(2);
                break;
            case "/tax_admin/agent_lists":
                setlefTab(3)
                break
            default:
                setlefTab(0);
        }
    }, []);
    useEffect(() => {
        if(tax_agent_list!=null){
            if (tax_agent_list.length > 0) {
                setCompany(tax_agent_list[0].tax_agent_id);
            }
        }
        
    }, [dispatch, tax_agent_list]);
    // useEffect(() => {
    //     console.log('mine_index')
    //     dispatch(user_member_info(1))
    // }, []);

    useEffect(() => {
        console.log(`token is ${token}`)
        if (_.isNil(token)) {
            return <Redirect to={'/'} />;
        }
    }, [token]);

    useEffect(() => {
        console.log(location.pathname)
        switch (location.pathname) {
            case "/tax_admin":
                setlefTab(0);
                break;
            case "/tax_admin/user_pool":
                setlefTab(1);
                break;
            case "/tax_admin/user_lists":
                setlefTab(2);
                break;
            case "/tax_admin/agent_lists":
                setlefTab(3)
                break
            default:
                setlefTab(0);
        }
    }, [location.pathname]);

    const drop_out_login = () => {
        window.localStorage.clear()
        dispatch(logout())
        history.replace('/')
    }
    console.log(`token is ${token}`)
    if (_.isNil(token) || _.isNil(tax_agent_list)) {
        return <Redirect to={'/'} />;
    }
    const handleClose = () => {
        setOpen(false)
    }


    return (
        <div style={{ display: 'flex', }}>
            <div style={{ minHeight: '100vh', width: '10vw', position: 'relative', minWidth: '150px' }}>
                <div style={{ height: 'calc(80% - 1px)'}}>
                    <Link to='/'>
                        <div style={{ display: 'flex', padding: 20, cursor: "pointer", paddingRight: 0, paddingTop: 15, paddingBottom: 8 }}>
                            <div> 
                                <img style={{ width: 30, height: 30 }} src={logo} alt='' />
                            </div>
                            <div style={{ paddingLeft: 10, lineHeight: '30px', fontSize: '15px' }}>博槐CRM</div>
                        </div>
                    </Link>
                    <Link to='/tax_admin'>
                        <div onClick={() => setlefTab(0)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 0 ? '#3D1198' : '' }}>
                            <div>
                                <img src={lefTab === 0 ? member_icon : member_icon_selected} alt='' style={{ width: 20, height: 20, paddingTop: 2 }} />
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 0 ? '#fff' : '' }}>潜在用户</div>
                        </div>
                    </Link>
                    <Link to='/tax_admin/user_pool'>
                        <div onClick={() => setlefTab(1)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 1 ? '#3D1198' : '' }}>
                            <div>
                                <img src={lefTab === 1 ? pay_icon : pay_icon_selected} alt='' style={{ width: 20, height: 20, paddingTop: 2 }} />
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 1 ? '#fff' : '' }}>用户池</div>
                        </div>
                    </Link>
                    <Link to='/tax_admin/user_lists'>
                        <div onClick={() => setlefTab(2)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 2 ? '#3D1198' : '' }}>
                            <div>
                                <img src={lefTab === 2 ? my_questions_icon : my_questions_icon_selected} alt='' style={{ width: 20, height: 20, paddingTop: 2 }} />
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 2 ? '#fff' : '' }}>我的用户</div>
                        </div>
                    </Link>
                    <Link to='/tax_admin/agent_lists'>
                        <div onClick={() => setlefTab(3)} style={{ cursor: "pointer", display: 'flex', padding: '8px 20px', margin: '10px 0', background: lefTab === 3 ? '#3D1198' : '' }}>
                            <div>
                                <img src={lefTab === 3 ? personnel_management : personnel_management_selected} alt='' style={{ width: 20, height: 20, paddingTop: 2 }} />
                            </div>
                            <div style={{ paddingLeft: 10, fontSize: 14, color: lefTab === 3 ? '#fff' : '' }}>人员管理</div>
                        </div>
                    </Link>
                </div>
                {/* <div style={{ width: '100%', textAlign: 'right', height: '20%' }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                            <div>
                                {
                                    avatar === '' ? <AccountCircleIcon sx={{ fontSize: 30, color: 'rgba(0, 0, 0, 0.26)' }} color="disabled" /> : <img style={{ width: 30, height: 30, borderRadius: '50%' }} src={avatar} alt='' />
                                }
                            </div>
                            {
                                _.isNil(nick_name) || nick_name === "null" ? <div style={{ lineHeight: '30px', paddingLeft: '10px', fontSize: 15 }}>{'用户00' + user_id}</div> : <div style={{ lineHeight: '30px', paddingLeft: '10px', fontSize: 13 }}>{nick_name}</div>
                            }
                        </div>
                        <div onClick={drop_out_login} style={{ padding: '8px 10px', background: '#3D1198', color: '#fff', borderRadius: 8, cursor: "pointer", width: '50%', margin: '0 auto', textAlign: 'center', fontSize: '13px' }}>退出登录</div>
                    </div>
                </div> */}
            </div>
            <div style={{ width: '90vw' }}>
                <div>
                    <div style={{ display: 'flex', justifyContent: "space-between", padding: '20px',alignItems:'center' }}>
                        <div>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">公司</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={company}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    {
                                        tax_agent_list != null ?
                                            tax_agent_list.map((item, index) =>
                                                <MenuItem value={item.tax_agent_id} key={index}>{item.full_name}</MenuItem>
                                            ) : ''
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                {
                                    avatar === '' ? <AccountCircleIcon sx={{ fontSize: 30, color: 'rgba(0, 0, 0, 0.26)' }} color="disabled" /> : <img style={{ width: 30, height: 30, borderRadius: '50%' }} src={avatar} alt='' />
                                }
                            </div>
                            {
                                _.isNil(nick_name) || nick_name === "null" ? <div style={{ lineHeight: '30px', paddingLeft: '10px', fontSize: 15 }}>{'用户00' + user_id}</div> : <div style={{ lineHeight: '30px', paddingLeft: '10px', fontSize: 13 }}>{nick_name}</div>
                            }
                            {
                                _.isNil(membership_info)?'':<div style={{fontSize:15,paddingLeft:20}}>
                                    <div>剩余月数：{membership_info.remaining_months}</div>
                                    <div>本月剩余收藏次数：{membership_info.remaining_users}</div>
                                </div>
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', cursor: "pointer", }} onClick={() => setOpen(true)} >
                            <div style={{ fontSize: 15, paddingRight: 10, color: '#3D1198' }}>退出</div>
                            <img src={icon_sign_out} alt="" style={{ width: 20, height: 20 }} />
                        </div>
                        {/* <div onClick={drop_out_login} style={{ padding: '8px 10px', background: '#3D1198', color: '#fff', borderRadius: 8, cursor: "pointer",  textAlign: 'center', fontSize: '13px' }}>退出登录</div> */}
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"退出"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            您确认退出网站吗？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>取消</Button>
                        <Button onClick={drop_out_login} autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
                <div style={{ padding: '40px 50px', background: 'rgba(204,153,204,0.05)', minHeight: 'calc(100vh - 80px)', paddingTop: 30 }}>
                    <Switch>
                        <Route exact path="/tax_admin" component={Admin} />
                        <Route path="/tax_admin/user_pool" component={UserPool} />
                        <Route path="/tax_admin/user_lists" component={UserLists} />
                        <Route path="/tax_admin/agent_lists" component={AgentLists} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default Index;