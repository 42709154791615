
import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import QRCode  from 'qrcode.react';

import {initSocket,emit} from '../../../actions/chat'
import {price_list,web_qr_code,user_member_info, discounts,used_discount} from '../../../actions/mine'
import {change_is_Success} from '../../../actions/chat'

import logo_shui from '../../../static/logo/shui_engine.png'

const _ = require('micro-dash');
// const QRCode = require('qrcode.react');
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#3D1198',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#3D1198',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#3D1198',
        },
        '&:hover fieldset': {
        borderColor: '#3D1198',
        },
        '&.Mui-focused fieldset': {
        borderColor: '#3D1198',
        },
    },
});

const product = [
    {
      value: '1',
      label: '税引擎',
    },
  ];

//   function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
//   }


function Payment(){
    const user_id=useSelector((state)=>state.user.user)
    const user_type=useSelector((state)=>state.user.user_type)
    const [price_value, setPlatform] = React.useState('1');
    const shui_price=useSelector((state) => state.mine.shui_price);
    const qr_code_url=useSelector((state)=>state.mine.code_url)||''
    const is_paySuccess=useSelector((state)=>state.chat.is_paySuccess)
    const product_name=useSelector((state)=>state.mine.pay_product)
    console.log('qr_code_url',qr_code_url)

    const shui_member_type_info=useSelector((state)=>state.user.member_type_info)
    const shui_member_info=useSelector((state)=>state.user.member_info)
    const pay_history=useSelector((state)=>state.user.pay_history)
    const [remind_suggest, setState] = React.useState(false);
    const vertical='top'
    const horizontal='center'
    const product_value = '1'
    // 支付成功与优惠劵提示
    const remindhandleClose = () => {
        if(is_paySuccess){
            dispatch(web_qr_code({mini_type:parseInt(product_value),coupon_uid:'',level:parseInt(price_value)}))
            setState(false);
            dispatch(change_is_Success())
            setSearchValue('')
            dispatch(used_discount())
        }else{
            if(discount===0){
                setState(false);
                dispatch(used_discount())
                setSearchValue('')
                dispatch(web_qr_code({mini_type:parseInt(product_value),coupon_uid:'',level:parseInt(price_value)}))
            }else if(discount===null){
                setState(false);
                dispatch(used_discount())
                setSearchValue('')
                dispatch(web_qr_code({mini_type:parseInt(product_value),coupon_uid:'',level:parseInt(price_value)}))
            }else{
                setState(false);
            }
        }
    };

    const shui_price_list=[
        {
            value: '1',
            label: shui_price[1]/100+'￥/月',
        },
        {
            value: '3',
            label: shui_price[3]/100+'￥/季',
        },
        {
            value:'2',
            label:shui_price[2]/100+'￥/年'
        }
    ]
    const dispatch = useDispatch();
    //价格切换
    const platformChange=(event)=>{
        console.log('event',event)
        handleToggle()
        setPlatform(event.target.value)
        dispatch(web_qr_code({mini_type:parseInt(product_value),coupon_uid:searchValue,level:parseInt(event.target.value)}))
    }
    // 进入页面第一次
    useEffect(() => {
        dispatch(price_list())
        dispatch(user_member_info('1'))
        dispatch(web_qr_code({mini_type:parseInt('1'),coupon_uid:searchValue,level:1}))
        // dispatch(initSocket());
        // emit('socket_login', {user_id:user_id,user_type:user_type,terminal_type:2});
    }, []);

    // 监听二维码链接
    useEffect(() => {
        setOpen(false);
    }, [dispatch,qr_code_url]);

    //刷新链接socket
    // useEffect(()=>{
        
    // },[])

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    // 监听支付成功
    useEffect(() => {
        if(is_paySuccess){
            setState(true);
            dispatch(user_member_info(product_value))
        }
    }, [dispatch,is_paySuccess]);

    const [searchValue, setSearchValue] = React.useState('');
    const discount_value=useSelector((state)=>state.mine.discount_value)
    useEffect(()=>{
        setSearchValue(discount_value)
    },[])

    const [is_promotion, setPromotion] = React.useState(false);
    // 领取优惠劵
    const get_coupons=()=>{
        let regNum = /^[0-9]+$/g;
        console.log()
        if(regNum.test(searchValue)){
            setPromotion(false)
            setState(true);
            dispatch(discounts({mini_type:parseInt(product_value),coupon_uid:searchValue,level:parseInt(price_value)}))
        }else{
            setPromotion(true)
        }
    }
    const discount=useSelector((state)=>state.mine.discount) //优惠折扣
    const discount_message=useSelector((state)=>state.mine.discount_message)  //领取成功失败提示
    return (
        <div>
            <div style={{display:'flex',padding:20}}>
                <div style={{paddingRight:'10%'}}>
                    <div style={{width:'15%',marginBottom:'40px'}}>
                        <Box sx={{ minWidth: 120,maxWidth:150 }}>
                            <CssTextField
                                id="outlined-select-currency"
                                select
                                label="产品"
                                value={product_value}
                                helperText=""
                                focused
                                style={{width:120}}
                                >
                                {product.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                    </MenuItem>
                                ))}
                            </CssTextField>
                        </Box>
                    </div>
                    <div style={{width:'15%'}}>
                        <Box sx={{ minWidth: 120,maxWidth:150 }}>
                            <CssTextField
                                id="outlined-select-currency"
                                select
                                label="价格"
                                value={price_value}
                                onChange={platformChange}
                                helperText=""
                                focused
                                style={{width:120}}
                                >
                                {
                                    (()=>{
                                            return shui_price_list.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                        </MenuItem>
                                                    ))
                                    })()
                                }

                            </CssTextField>
                        </Box>
                    </div>
                </div>
                <div style={{position:'relative'}}>
                    <QRCode value={qr_code_url} style={{width:'150px',height:150}} />
                    {
                        remind_suggest?
                        <div style={{position:'absolute',width:150,height:150,left:0,top:0,background:'rgba(255,255,255,0.95)',display:'flex',justifyContent:'center',alignItems:'center',cursor: "pointer"}}>
                            <RefreshIcon style={{color:'rgb(61, 17, 152)'}}/>
                        </div>:''
                    }
                    {
                        (()=>{
                            return _.isNil(discount)?'':<div style={{textAlign:'center'}}>{discount*10}折优惠&nbsp;&nbsp;&nbsp;{shui_price[parseInt(price_value)]/100*discount}￥</div>
                        })()
                        
                    }
                </div>
                <div style={{boxShadow:'2px 2px 10px #d6d6d6',padding:'25px 40px',marginLeft:'10%',width:'40%',display:'flex', flexDirection: "row", alignItems: 'center',minWidth:'300px'}}>
                    <div style={{ width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={logo_shui}  style={{width:80,height:80}} alt="" />
                    </div>
                    <div style={{ width: '30%', padding: "0 10px", display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <h3>{product_name}</h3>
                    </div>
                    <div style={{width: '50%', paddingLeft: 16}}>
                        <div style={{padding:'12px',fontSize:'18px'}}>
                            <div>
                                <span>会员信息</span>
                            </div>
                        </div>
                        {
                            (()=>{
                                    if(shui_member_type_info.member_type!==1){
                                        return <div style={{padding:'12px',fontSize:'18px'}}>
                                                    <span>您还不是会员</span>
                                                </div>
                                    }else{
                                        return (
                                            <div style={{padding:'12px',fontSize:'18px'}}>
                                                <span>{shui_member_info.expire_date.split(' ')[0]}</span>
                                                <span>到期</span>
                                            </div>
                                        )
                                    }
                            })()
                        }
                    </div>
                </div>
            </div>
            <div style={{marginBottom:'80px',padding:20}}>
                <InputBase
                    placeholder="请输入优惠码"
                    inputProps={{ 'aria-label': '税务筹划搜索引擎' }}
                    value={searchValue}
                    style={{borderBottom:'1px solid rgba(0, 0, 0, 0.12)'}}
                    onChange={(event)=>setSearchValue(event.target.value)}
                />
                <Button onClick={get_coupons} style={{background:'rgb(61, 17, 152)',color:'#fff',marginLeft:20}}  disableElevation>
                    领取
                </Button>
                {
                    is_promotion?<div style={{color:'red',fontSize:14}}>请输入正确优惠码</div>:''
                }
            </div>
            <Divider>{product_name}-支付历史</Divider>
            {
                pay_history.length>0?
                    <div style={{marginTop:'20px'}}>
                        <TableContainer component={Paper} style={{maxHeight:'40vh'}}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="center">价格</TableCell>
                                    <TableCell align="center">时间</TableCell>
                                    <TableCell align="center">会员等级</TableCell>
                                    <TableCell align="center">产品类别</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {pay_history.map((row) => (
                                    <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell align="center" component="th" scope="row">
                                        {row.price/100+'元'}
                                    </TableCell>
                                    <TableCell align="center">{row.create_time.split('T')[0]}</TableCell>
                                    <TableCell align="center">
                                        {
                                            (()=>{
                                                if(row.level===1){
                                                    return '月'
                                                }else if(row.level===2){
                                                    return '年'
                                                }else{
                                                    return '季'
                                                }
                                            })()
                                        }
                                    </TableCell>
                                    <TableCell align="center">{product_name}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>:
                    <div style={{marginTop:'20px',textAlign:'center'}}>暂无支付历史</div>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remind_suggest}
                onClose={remindhandleClose}
                severity="info"
                autoHideDuration={4000}
                key={vertical + horizontal}
            >
                <Alert severity="info"> {is_paySuccess?'支付成功':discount_message}</Alert>
            </Snackbar>
        </div>
    )
}
export default Payment;