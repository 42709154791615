/**
 * Created on 8/25/21.
 * @author Xihao
 */

import {LOGIN,wechatWebLogin,OPENHINT,ACCOINTINGOPENHINT,SHUIUPDATETIME,MEMBERINFOS,LOGOUT,TAX_AGENT_LISTS,
    INDISTINCT_CHECKED,CUT_TAX_AGENT,MY_USER_LISTS,REDUCE_COLLECT_NUM,ALL_PROMPT,PERSONNEL_LISTS,HINT_AGENT,
    TASK_LIST,SEND_SOCKET_ID,FILE_LISTS,BASE_SETTINGS,TAB_ACTION,VOICE_LISTS,CRM_LISTS,SPEECH_RECOGNIZER} from '../actions/types';
import {initSocket,emit} from '../actions/chat'
import { isUndefined } from 'micro-dash';
import { Settings } from '@mui/icons-material';
// import { tab_action } from '../actions/user';
const _ = require('micro-dash');

let distributor_info = _.isNil(localStorage.getItem('distributor_info'))?'{}':localStorage.getItem('distributor_info');
let member_info = _.isNil(localStorage.getItem('member_info'))?'{}':localStorage.getItem('member_info');
let member_type_info = _.isNil(localStorage.getItem('member_type_info'))?'{}':localStorage.getItem('member_type_info');
let tax_agent_list=_.isNil(localStorage.getItem('tax_agent_list'))?null:localStorage.getItem('tax_agent_list');
let tax_agent_id=_.isNil(localStorage.getItem('tax_agent_id'))?null:localStorage.getItem('tax_agent_id');
let membership_info=_.isNil(localStorage.getItem('membership_info'))?null:localStorage.getItem('membership_info');

 const initialState = {
     token: localStorage.getItem('token'),
     user: localStorage.getItem('user_id')||'',
     avatar:localStorage.getItem('avatar')||'',
     distributor_info:null,
     member_info:member_info,
     member_type_info:member_type_info,
     nick_name:localStorage.getItem('nick_name')||'',
     open_hint:false,
     accounting_open_hint:false,
     user_type:localStorage.getItem('user_type')||'',
     quota:localStorage.getItem('quota')||0,
     company_quota:localStorage.getItem('company_quota')||0,
     pay_history:[],
     tax_agent_list:JSON.parse(tax_agent_list),
     indistinct_checked:true,
     collect_lists:[],
     tax_agent_id:tax_agent_id,
     membership_info:membership_info,
     general_prompt:[],
     user_prompt:[],
     manager_lists:[],
     staff_lists:[],
     hint_agent:[],
     task_lists:[],
     socket_id:null,
     file_lists:[],
     tab_settings:[],
     other_menus:[],
     agent_menus:[],
     base_setting:{
        brand_name:'动千山智能体',
        logo_url:"https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/logs/qiandi_logo.jpg",
        primary_color:'#4844f3'
     },
     tab_action:0,
     voice_lists:[],
     is_first_login:false,
     crm_lists:[],
     user_info_particulars:null,
     org_id:null,
     secretid:null,
     secretkey:null,
     secrettoken:null
 }
 
 // eslint-disable-next-line import/no-anonymous-default-export
 export default function(state=initialState, action) {
     switch(action.type) {
         case LOGIN:
            localStorage.setItem('token', action.token)
            localStorage.setItem('user_id',action.user)
            localStorage.setItem('avatar',action.avatar)
            // localStorage.setItem('distributor_info',JSON.stringify(action.distributor_info))
            // localStorage.setItem('member_info',JSON.stringify(action.member_info))
            // localStorage.setItem('member_type_info',JSON.stringify(action.member_type_info))
            localStorage.setItem('nick_name',action.nick_name)
            localStorage.setItem('user_type',action.user_type)
            // emit('socket_login', {user_id:action.user,user_type:action.user_type,terminal_type:2});
             return {
                 ...state,
                 token: action.token,
                 user:action.user,
                 avatar:action.avatar,
                 distributor_info:action.distributor_info,
                 member_info:action.member_info,
                 member_type_info:action.member_type_info,
                 update_time:action.member_type_info.remaining_time,
                 nick_name:action.nick_name,
                 user_type:action.user_type,
                 org_id:action.org_id,
                 is_first_login:true
             };
        case LOGOUT:
             return {
                ...state,
                token: null,
                user:null,
             };
        case wechatWebLogin:
            localStorage.setItem('token', action.token)
            localStorage.setItem('user_id',action.user)
            localStorage.setItem('avatar',action.avatar)
            // localStorage.setItem('distributor_info',JSON.stringify(action.distributor_info))
            // localStorage.setItem('member_info',JSON.stringify(action.member_info))
            // localStorage.setItem('member_type_info',JSON.stringify(action.member_type_info))
            localStorage.setItem('nick_name',action.nick_name)
            localStorage.setItem('user_type',action.user_type)
            localStorage.setItem('is_staff',action.is_staff)
            localStorage.setItem('org_id',action.org_id)
            localStorage.setItem('company_quota',action.company_quota)
            localStorage.setItem('quota',action.quota)
            // emit('socket_login', {user_id:action.user,user_type:action.user_type,terminal_type:2});
             return {
                 ...state,
                 token: action.token,
                 user:action.user,
                 avatar:action.avatar,
                 distributor_info:action.distributor_info,
                 member_info:action.member_info,
                 member_type_info:action.member_type_info,
                 update_time:action.member_type_info.remaining_time,
                 nick_name:action.nick_name,
                 user_type:action.user_type,
                 is_staff:action.is_staff,
                 org_id:action.org_id,
                 is_first_login:true,
                 user_info_particulars:action.user_info_particulars,
                 company_quota:action.company_quota,
                 quota:action.quota
             };
        case TAX_AGENT_LISTS:
            let tax_agent_id=action.data[0].tax_agent_id
            let tax_agent_role=action.data[0].role
            let membership_info=action.data[0].membership_info
            localStorage.setItem('tax_agent_list',JSON.stringify(action.data))
            localStorage.setItem('tax_agent_id',tax_agent_id)
            localStorage.setItem('tax_agent_role',tax_agent_role)
            localStorage.setItem('membership_info',JSON.stringify(membership_info))
            return {...state,tax_agent_list:action.data,tax_agent_id:tax_agent_id,tax_agent_role:tax_agent_role,membership_info:membership_info}
        case OPENHINT:
            return {
                ...state, open_hint: action.open_hint
            }
        case ACCOINTINGOPENHINT:
            return {
                ...state,accounting_open_hint:action.accounting_open_hint
            }
        case SHUIUPDATETIME:
            let member_type_info=state.member_type_info
            let member_type_infos={...member_type_info}
            member_type_infos.remaining_time=action.remaining_times
            member_type_infos.member_type=action.member_type
            localStorage.setItem('member_type_info',JSON.stringify(member_type_infos))
            // if(action.remaining_times===0){
            return {...state,member_type_info:{...state.member_type_info,member_type:action.member_type,remaining_time:action.remaining_times}}
            // }else{
                // return state;
            // }
        case MEMBERINFOS:
            console.log("MEMBERINFOS")
            let data=JSON.parse(action.data)
            console.log(data)
            localStorage.setItem('member_info',JSON.stringify(data.member_info))
            localStorage.setItem('member_type_info',JSON.stringify(data.member_type_info))
            return{
                ...state,
                 member_info:data.member_info,
                 member_type_info:data.member_type_info,
                 pay_history:action.pay_history
            }
        case INDISTINCT_CHECKED:
            return {...state,indistinct_checked:action.checked}
        case CUT_TAX_AGENT:
            let role=''
            let membership_infos=''
            for(let i=0;i<state.tax_agent_list.length;i++){
                if(state.tax_agent_list[i].tax_agent_id===action.data){
                    role=state.tax_agent_list[i].role
                    membership_infos=state.tax_agent_list[i].membership_info
                    break
                }
            }
            localStorage.setItem('tax_agent_id',action.data)
            localStorage.setItem('tax_agent_role',role)
            localStorage.setItem('membership_info',membership_infos)
            return {...state,tax_agent_id:action.data,tax_agent_role:role,membership_info:membership_infos}
        case MY_USER_LISTS:
            return {...state,collect_lists:action.data}
        case REDUCE_COLLECT_NUM:
            let membership=''
            for(let i=0;i<action.data.length;i++){
                if(action.data[i].tax_agent_id===state.tax_agent_id){
                    membership=action.data[i].membership_info
                    break
                }
            }
            localStorage.setItem('membership_info',membership)
            return {membership_info:membership}
        case ALL_PROMPT:
            return {...state,general_prompt:action.general_data,user_prompt:action.user_data}
        case PERSONNEL_LISTS:
            return {...state,manager_lists:action.manager_lists,staff_lists:action.staff_lists}
        case HINT_AGENT:
            return {...state,hint_agent:action.data}
        case TASK_LIST:
            return {...state,task_lists:action.data}
        case SEND_SOCKET_ID:
            return {...state,socket_id:action.data}
        case FILE_LISTS:
            return {...state,file_lists:action.data}
        case BASE_SETTINGS:
            let tab_lists=[]
            for(let i=0;i<action.data.other_menus.length;i++){
                tab_lists.push(action.data.other_menus[i])
            }
            for(let k=0;k<action.data.agent_menus.length;k++){
                tab_lists.push(action.data.agent_menus[k])
            }
            if(action.data?.brand_name==''||_.isNil(action.data?.brand_name)){
                action.data.brand_name='动千山智能体'
            }
            if(action.data?.logo_url==''||_.isNil(action.data?.logo_url)){
                action.data.logo_url='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/logs/qiandi_logo.jpg'
            }
            if(action.data?.primary_color==''){
                action.data.primary_color='#4844f3'
            }
            return {...state,base_setting:action.data,tab_settings:tab_lists,other_menus:action.data.other_menus,agent_menus:action.data.agent_menus}
        case TAB_ACTION:
            return {...state,tab_action:action.data}
        case VOICE_LISTS:
            return {...state,voice_lists:action.data,totalPages:action.totalPages}
        case CRM_LISTS:
            return {...state,crm_lists:action.data}
        case SPEECH_RECOGNIZER:
            return {...state,secretid:action.data.TmpSecretId,secretkey:action.data.TmpSecretKey,secrettoken:action.data.Token}
            default:
             return state;
     }
 };