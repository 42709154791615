import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { agent_lists,agent_info ,tax_close_drawer} from '../../../actions/tax_accountant';
import { Link,Route,Switch } from "react-router-dom";


import Footer from "../../footer/Footer";
import HeadHavigation from "../../headHavigation/headHavigation";
import PreviewItem from "./PreviewItem";
import DrawerContent from './DrawerContent'
import { Redirect, useLocation } from "react-router-dom";
import Slider from "react-slick";


const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        width: '100%',
        maxWidth: '100%',
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    }
}));


function Tax_accountant() {
    const result_list = useSelector((state) => state.tax_accountant.tax_accountant_lists);
    const attorney_lists=useSelector((state) => state.tax_accountant.attorney_lists);
    const tax_drawer_open = useSelector((state) => state.tax_accountant.tax_drawer_open);
    const dispatch = useDispatch();
    const classes = useStyles();
    console.log(result_list)
    useEffect(() => {
        dispatch(agent_lists())
    }, []);
    return (
        <div>
            <HeadHavigation provenance={0} />
            <Container className={classes.root}>
                <div style={{minHeight:'calc(100vh - 209px)'}}>
                    <div>
                        <div style={{padding:'5%',paddingTop:0,fontWeight:'bold',fontSize:18}}>税务师</div>
                        <div style={{display:'flex',flexWrap:'wrap'}}>
                            {
                                result_list.length>0?result_list.map(
                                    (item, index) => <PreviewItem
                                        id={item.user.id}
                                        avatar_url={item.avatar_url}
                                        full_name={item.full_name}
                                        other_information={item.other_information}
                                        tax_agent_type={item.tax_agent_type}
                                        working_age={item.working_age}
                                        official_rating={item.official_rating}
                                    />
                                ):<div style={{margin:'0 auto'}}>暂无</div>
                            }
                        </div>
                    </div>
                    <div>
                        <div style={{padding:'5%',fontWeight:'bold',fontSize:18}}>股权律师</div>
                        <div style={{display:'flex',flexWrap:'wrap'}}>
                            {
                                attorney_lists.length>0?attorney_lists.map(
                                    (item, index) => <PreviewItem
                                        id={item.user.id}
                                        avatar_url={item.avatar_url}
                                        full_name={item.full_name}
                                        other_information={item.other_information}
                                        tax_agent_type={item.tax_agent_type}
                                        working_age={item.working_age}
                                        official_rating={item.official_rating}
                                    />
                                ):<div style={{margin:'0 auto'}}>暂无</div>
                            }
                        </div>
                    </div>
                </div>
                <Drawer
                    anchor={'right'}
                    open={tax_drawer_open}
                    onClose={() => dispatch(tax_close_drawer())}
                >
                    <div style={{ width: window.innerWidth * 1 / 2, height: '100%' }}>
                        <DrawerContent/>
                    </div>
                </Drawer>
                <div>
                    <Footer />
                </div>
            </Container>
        </div>
    );
}

export default Tax_accountant;
