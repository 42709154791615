
import React, { useEffect, useRef, useLayoutEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as mapUtils from '../../../utils/TMap/index'
import collect from '../../../static/icon/collect.png'
import collect_false from '../../../static/icon/collect_false.png'
import { admin_user, collect_user, track_close_drawer, open_track_drawer, get_behavior } from '../../../actions/admin'
import { logout } from '../../../actions/user'
import DrawerContent from './DrawerContent'

const _ = require('micro-dash');
const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: '0 15px 9px #d6d6d6'
    },
    table: {
        // minWidth: 650,
        fontSize: 12
    },
}));

const map_width = '75%'
function UserMap() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const ref = useRef(null)
    const history = useHistory();

    const [mapWidth, setWidth] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const all_user_location = useSelector((state) => state.admin.all_user_location)
    const [geometries, setGeometries] = React.useState([]);
    const track_open = useSelector((state) => state.admin.track_open)
    const user_behavior = useSelector((state) => state.admin.behavior_data)
    const have_collect_lists = useSelector((state) => state.admin.have_collect_lists)
    const ranking = useSelector((state) => state.admin.ranking)
    const mapId = useRef() //  地图实例
    const [createmap, seCreateMap] = React.useState(false);
    const indistinct_checked = useSelector((state) => state.user.indistinct_checked);
    useEffect(() => {
        dispatch(admin_user('map'))
        if (!createmap) {
            mapUtils
                .TMapGL() // 开始加载腾讯地图gl文件
                .then(() => { // 完成加载后，开始渲染地图
                    let center = new window.TMap.LatLng(28.23018, 112.893713)
                    let map = new window.TMap.Map(document.querySelector('#container'), {
                        center,
                        zoom: 12,
                        baseMap: [
                            { type: 'vector' }, //设置矢量底图
                        ],
                    })
                    mapId.current = map //存储当前的map
                })
        }
        seCreateMap(true)
    }, [])

    useEffect(() => {
        if (all_user_location.length > 0 && !_.isNil(mapId.current)) {
            let location = []
            for (let i = 0; i < all_user_location.length; i++) {
                let map_geometries = {
                    position: new window.TMap.LatLng(all_user_location[i].latitude, all_user_location[i].longitude),
                    id: all_user_location[i].user_id
                }
                location.push(map_geometries)
            }
            setGeometries(location)
            let markerCluster = new window.TMap.MarkerCluster({
                id: 'cluster', //图层id
                map: mapId.current,       //设置点聚合显示在哪个map对象中
                enableDefaultStyle: true,   //使用默认样式
                minimumClusterSize: 2,  //最小聚合点数：2个
                geometries: location,
                zoomOnClick: true,  //点击聚合数字放大展开
                gridSize: 60,       //聚合算法的可聚合距离，即距离小于该值的点会聚合至一起，默认为60，以像素为单位
                averageCenter: false, //每个聚和簇的中心是否应该是聚类中所有标记的平均值
                maxZoom: 16 //采用聚合策略的最大缩放级别，若地图缩放级别大于该值，则不进行聚合，标点将全部被展开
            });
            //监听marker点击事件
            markerCluster.on("click", clickHandler)
            for (let i = 0; i < all_user_location.length; i++) {
                if (!_.isNil(all_user_location[i].phone_number)) {
                    all_user_location[i].phone_number = all_user_location[i].phone_number.replace(all_user_location[i].phone_number.substring(3, 7), "****");
                }
            }
        }
    }, [dispatch, all_user_location])

    //监听回调函数（非匿名函数）
    var clickHandler = function (evt) {
        console.log(evt)
        // evt.cluster.geometries[0].id
        dispatch(get_behavior(evt.cluster.geometries[0].id, 'map',5,0))
    }

    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    }, [mapWidth]);


    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%', display: 'flex', paddingBottom: 10, height: '80%' }}>
                <div style={{ width: map_width, height: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginRight: 10, padding: '10px' }}>
                    <div style={{ paddingBottom: '10px' }}>
                        <div style={{ fontSize: '18px', fontWeight: 'bold' }}>用户分布</div>
                    </div>
                    <div ref={ref} id="container" style={{ width: '100%', height: mapWidth * 0.7, borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginRight: 10 }}>

                    </div>
                </div>
                <div style={{ borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(30% - 10px)', background: '#fff', padding: '10px' }}>
                    <div style={{ paddingBottom: '10px' }}>
                        <div style={{ fontSize: '18px', fontWeight: 'bold' }}>推荐用户</div>
                    </div>
                    <div style={{ height: '80%' }}>
                        <div>
                            {
                                all_user_location.slice(0, 10).map((item, index) => {
                                    return <div key={index}  style={{ display: 'flex', justifyContent: 'space-between', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', padding: 8, marginBottom: 10 }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ filter: indistinct_checked?'blur(2px)':'blur(0px)' }}>
                                                <img src={item.avatar} style={{ width: '26px', height: '26px', background: '#fff', borderRadius: '50%' }} alt="" />
                                            </div>
                                            <div style={{ lineHeight: '26px', paddingLeft: 5, filter: indistinct_checked?'blur(2px)':'blur(0px)' }}>
                                                <div style={{ fontSize: '13px', color: 'transparent:text-shadow:0 0 5px rgba(0,0,0.5)' }}>{item.nick_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', display: 'flex', height: '20%' }}>
                <div style={{ width: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', background: '#fff', padding: 10 }}>
                    <div style={{ paddingBottom: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>用户轨迹</div>
                            <div style={{ fontSize: 12 }}>（点击地图中用户标点查看）</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: "wrap", width: '100%' }}>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(33.33% - 36px)', padding: 8, margin: 10 }}>
                            <div style={{ fontSize: 12, textAlign: 'center', paddingBottom: 8 }}>总时间</div>
                            <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>{user_behavior.total_duration}s</div>
                        </div>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(33.33% - 36px)', padding: 8, margin: 10 }}>
                            <div style={{ fontSize: 12, textAlign: 'center', paddingBottom: 8 }}>进入次数</div>
                            <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>{user_behavior.total_visit_times}</div>
                        </div>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(33.33% - 36px)', padding: 8, margin: 10 }}>
                            <div style={{ fontSize: 12, textAlign: 'center', paddingBottom: 8 }}>最近访问</div>
                            <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>{user_behavior.update_time != 0 ? user_behavior.update_time.split('T')[0] : user_behavior.update_time}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', display: 'flex', height: '20%', margin: '10px 0' }}>
                <div style={{ width: map_width, minHeight: 269, borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginRight: 10, background: '#fff', padding: 10 }}>
                    <div style={{ paddingBottom: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>详细轨迹</div>
                            <div style={{ fontSize: 12 }}>（点击地图中用户标点查看）</div>
                        </div>
                    </div>
                    <div style={{ fontSize: 14 ,height:'75%'}}>
                        {
                            user_behavior.behaviors.length>0?
                                user_behavior.behaviors.reverse().slice(0, 5).map((item, index) => {
                                    return <div style={{ cursor: 'pointer', padding: 8 }}>
                                        <div style={{ display: 'flex' }} onClick={() => dispatch(open_track_drawer(item.data))} key={index}>
                                            <div style={{ width: '40%' }}>{item.time}</div>
                                            <div style={{ width: '40%', textAlign: 'center' }}>{item.data[item.data.length-1].duration}s</div>
                                            <div style={{ width: '20%', textAlign: 'right' }}>
                                                <ArrowForwardIcon style={{ color: '#3D1198' }} />
                                            </div>
                                        </div>
                                        <div style={{ padding: '10px 0' }}>
                                            <Divider />
                                        </div>
                                    </div>
                                }):''
                        }

                    </div>
                </div>
                <div style={{ borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(30% - 10px)', background: '#fff', padding: 10 }}>
                    <div style={{ paddingBottom: '15px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>页面排行</div>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left" style={{ fontSize: 13, fontWeight: 'bold' }}>页面停留时间</TableCell>
                                    <TableCell align="left" style={{ fontSize: 13, fontWeight: "bold" }}>页面进入次数</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ranking.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.ranking}
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>{row.page_duration}</TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>{row.page_times}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <Drawer
                anchor={'right'}
                open={track_open}
                onClose={() => dispatch(track_close_drawer())}
            >
                <div style={{ width: window.innerWidth * 1 / 3, height: '100%' }}>
                    <DrawerContent />
                </div>
            </Drawer>
        </div>
    )
}
export default UserMap;
