/**
 * Created on 8/25/21.
 * @author Xihao
 */
import { LOGIN, OPENHINT, SHUIUPDATETIME, wechatWebLogin, LOGOUT, TAX_AGENT_LISTS, INDISTINCT_CHECKED, CUT_TAX_AGENT, 
    MY_USER_LISTS, REDUCE_COLLECT_NUM, ALL_PROMPT, PERSONNEL_LISTS, HINT_AGENT,TASK_LIST ,SEND_SOCKET_ID,FILE_LISTS,
    BASE_SETTINGS,TAB_ACTION,VOICE_LISTS,CRM_LISTS,SPEECH_RECOGNIZER} from './types';
import { MIDDLE_EARTH_URL, KY_URL } from '../configs/constants';
import { initSocket } from './chat';
import axios from 'axios';
const _ = require('micro-dash');
axios.defaults.timeout = 10000;
const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})

const $test_http = axios.create();
$test_http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})

export function open_hints(open_hint) {
    return {
        type: OPENHINT,
        open_hint
    }
}

export function logout() {
    return {
        type: LOGOUT,
    }
}
export function indistinct_show(checked) {
    return {
        type: INDISTINCT_CHECKED,
        checked
    }
}


//1
export function login_success({ token, user, response }) {
    console.log('login_success', response)
    if (response != null) {
        return {
            type: LOGIN,
            token,
            user,
            user_type: response.user_type,
            member_info: response.member_info,
            nick_name: response.nick_name,
            avatar: response.avatar,
            // distributor_info: response.distributor_info,
            member_type_info: response.member_type_info,
            org_id:response.org_id
        }
    } else {
        return {
            type: LOGIN,
            token,
            user,
            user_type: '',
            member_info: '',
            nick_name: '',
            avatar: '',
            // distributor_info: '',
            member_type_info: '',
        }
    }

}
export function web_login({ token, user, response }) {
    return {
        type: wechatWebLogin,
        user_info_particulars:response,
        token,
        user,
        user_type: response.user_type,
        member_info: response.member_info,
        nick_name: response.nick_name,
        avatar: response.avatar,
        // distributor_info: response.distributor_info,
        member_type_info: response.member_type_info,
        is_staff: response.is_staff,
        org_id:response.org_id,
        company_quota:response.company_quota,
        quota:response.quota
    }
}
export function shui_update_time({ remaining_times, member_type }) {
    return {
        type: SHUIUPDATETIME,
        remaining_times,
        member_type
    }
}
export function tax_agent_list(data) {
    return {
        type: TAX_AGENT_LISTS,
        data
    }
}
export function cut_tax_agent(data) {
    return {
        type: CUT_TAX_AGENT,
        data
    }
}
export function my_user_lists(data) {
    return {
        type: MY_USER_LISTS,
        data
    }
}
export function reduce_collect(data) {
    return {
        type: REDUCE_COLLECT_NUM,
        data
    }
}
export function user_prompt(general_data, user_data) {
    return {
        type: ALL_PROMPT,
        general_data,
        user_data
    }
}
export function personnel_lists(manager_lists, staff_lists) {
    return {
        type: PERSONNEL_LISTS,
        manager_lists,
        staff_lists
    }
}
export function hint_agent(data) {
    return {
        type: HINT_AGENT,
        data
    }
}
export function task_list(data){
    return {
        type:TASK_LIST,
        data
    }
}
export function send_socket_id(data){
    return {
        type:SEND_SOCKET_ID,
        data
    }
}
export function file_lists(data){
    return {
        type:FILE_LISTS,
        data
    }
}
export function base_settings(data){
    return {
        type:BASE_SETTINGS,
        data
    }
}
export function cut_tab_action(data){
    return {
        type:TAB_ACTION,
        data
    }
}
export function voice_lists(data,totalPages){
    return {
        type:VOICE_LISTS,
        data,
        totalPages
    }
}
export function crm_lists(data){
    return {
        type:CRM_LISTS,
        data
    }
}
export function recognizer_fede(data){
    return {
        type:SPEECH_RECOGNIZER,
        data
    }
}

//2 dispatch

export function login(username, password) {
    console.log(`inside login`);
    return (dispatch, getState) => {
        // initSocket(dispatch, getState);
        axios.post(`${MIDDLE_EARTH_URL}/user/login/`,
            {
                username,
                password,

            })
            .then(
                response => {
                    // console.log(JSON.stringify(response));
                    dispatch(login_success({ token: response.data.key, user: null, response: null }));
                    // if (!localStorage.getItem('userInfo') | null) {
                    dispatch(get_user_info({ token: response.data.key }))
                    // }
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search_all`);
                if (error.response) {
                    console.log(error.response.data); // => the response payload
                }
            })
    };
}

export function get_user_info(token) {
    // console.log(`inside userInfo`);
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/user/wechat-get-userInfo`)
            .then(
                responses => {
                    console.log('userinfo', responses, token)
                    dispatch(login_success({ token: responses.data.data.token, user: responses.data.data.id, response: responses.data.data }));
                })
            .catch((err) => {
                console.log('userinfo,err', err)
            })
    }
}
export function get_update_user_info(token) {
    // console.log(`inside userInfo`);
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/user/get_crm_user_info`)
            .then(
                response => {
                    console.log('userinfo', response, token)
                    dispatch(web_login({ token: response.data.data.token, user: response.data.data.id, response: response.data.data }));
                })
            .catch((err) => {
                console.log('userinfo,err', err)
            })
    }
}

export function get_crm_user_info() {
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/user/get_crm_user_info`).then(
            response => {
                console.log(response)
                dispatch(tax_agent_list(response.data.result.data.user_tax_agent_list))
            }
        )
    }
}
export function get_crm_user() {
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/user/get_crm_user_info`).then(
            response => {
                console.log(response)
                dispatch(reduce_collect(response.data.result.data.user_tax_agent_list))
            }
        )
    }
}
export function wechat_web_login(code, setIsCheckingCode) {
    return (dispatch, getState) => {
        console.log(code)
        setIsCheckingCode(true)
        axios.post(`${MIDDLE_EARTH_URL}/user/wechat-web-login/`,
            {
                code

            })
            .then(
                response => {
                    console.log(response);
                    dispatch(web_login({ token: response.data.token, user: response.data.id, response: response.data }));
                    // dispatch(get_crm_user_info())
                    setIsCheckingCode(false)
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search_all`);
                if (error.response) {
                    console.log(error.response.data); // => the response payload
                }
                setIsCheckingCode(false)
            })
    }
}
//手机号登陆
export function mobile_login(data, setIsCheckingValue) {
    return (dispatch, getState) => {
        setIsCheckingValue('')
        axios.post(`${MIDDLE_EARTH_URL}/user/mobile-login/`, data, {
            headers: {
                'content-type': 'application/json', // 默认值
                // 'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(response => {
            console.log(response)
            dispatch(web_login({ token: response.data.token, user: response.data.id, response: response.data }));
            // dispatch(get_crm_user_info())
        }).catch((err) => {
            console.log(err.response)
            setIsCheckingValue(err.response.data.msg)
        })
    }
}
export function update_user_member_type(time) {
    return (dispatch, getState) => {
        debugger
        console.log(time)
        axios.post(`${MIDDLE_EARTH_URL}/user/update-user-member-type/`,
            {
                spend_time: time
            },
            {
                headers: {
                    'content-type': 'application/json', // 默认值
                    'Authorization': 'token ' + localStorage.getItem('token')
                }
            }
        ).then(response => {
            console.log(response)
            let remaining_times = 0
            if (response.data.data.remaining_time <= 0) {
                remaining_times = 0
            } else {
                remaining_times = response.data.data.remaining_time
            }
            dispatch(shui_update_time({ remaining_times: remaining_times, member_type: response.data.data.member_type }))
        })
    }
}

export function user_customers(agent_id) {
    return (dispatch, getState) => {
        // $test_http.get(`http://120.48.69.147:3003/user/crm/user-customers?agent_id=`+agent_id,{},{
        $test_http.get(`${MIDDLE_EARTH_URL}/user/crm/user-customers?agent_id=` + agent_id, {}, {
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token jarod1'//+localStorage.getItem('token')
            }
        }).then(res => {
            console.log(res)
            dispatch(my_user_lists(res.data.result.data))

        }).catch(err => {
            console.log(err)
        })
    }
}
// 用户名密码登陆
export function new_login(data, setIsCheckingValue) {
    setIsCheckingValue('')
    return (dispatch, getState) => {
        // ${KY_URL}/api/user/new-login/
        axios.post(`${MIDDLE_EARTH_URL}/user/username-password-login/`, data,
            {
                headers: {
                    'content-type': 'application/json', // 默认值
                    // 'Authorization':'token '+localStorage.getItem('token')
                }
            }
        ).then(response => {
            console.log('sssd', response)
            dispatch(web_login({ token: response.data.data.token, user: response.data.data.id, response: response.data.data }));
            // dispatch(get_crm_user_info())
        }).catch((err) => {
            console.log(err.response)
            setIsCheckingValue(err.response.data.detail)
        })
    }
}
// 用户名密码创建
export function news_create(data) {
    return (dispatch, getState) => {
        const formData = new FormData();
        formData.append('username', 'wmkbohuai1');
        formData.append('password', 'bohuai123');
        formData.append('phone_number', '13548746882');
        axios.post(`${KY_URL}/api/user/create-user-by-superuser/`, formData,
            {
                headers: {
                    'content-type': 'application/form-data', // 默认值
                    'Authorization': 'token superuser'
                }
            }
        ).then(response => {
            console.log('sssd', response)
        }).catch((err) => {
            console.log(err)
        })
    }
}
// 税务所用户名密码创建
export function create_tax_agent(data) {
    return (dispatch, getState) => {
        axios.post(`${KY_URL}/api/user/create-tax-agent-user/`, { username: 'bohuai123', password: 'bohuai123', full_name: '税务所测试', tax_agent_type: 1 },
            {
                headers: {
                    'content-type': 'application/json', // 默认值
                    'Authorization': 'token superuser'
                }
            }
        ).then(response => {
            console.log('sssd1', response)
        }).catch((err) => {
            console.log(err)
        })
    }
}
// 关联用户
export function link_user_agent_phone(data, setIsCheckingValue) {
    return (dispatch, getState) => {
        setIsCheckingValue('')
        axios.post(`${MIDDLE_EARTH_URL}/user/manager-link-user-to-org-by-phone/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(response=>{
            console.log(response)
            setIsCheckingValue('关联成功')
            dispatch(get_employees(data.tax_agent_id))
        }).catch(error=>{
            setIsCheckingValue(error.response.data.detail)
        })
        // axios.post(`${KY_URL}/api/user/link_user_to_tax_agent_by_phone/`, data,
        //     {
        //         headers: {
        //             'content-type': 'application/json', // 默认值
        //             'Authorization': 'token ' + localStorage.getItem('token')
        //         }
        //     }
        // ).then(response => {
        //     console.log('sssd1', response)
        //     setIsCheckingValue('关联成功')
        //     dispatch(get_employees(data.tax_agent_id))
        // }).catch((err) => {
        //     console.log(err)
        //     setIsCheckingValue(err.response.data.detail)
        // })
    }
}
export function get_employees(data) {
    return (dispatch, getState) => {
        console.log(data)
        $http.get(`${MIDDLE_EARTH_URL}/user/get_linked_user_to_org/`,{},{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            let data=res.data.result
            let manager_lists = data.filter(v=>v?.user_type>=170)
            let staff_lists = data.filter(v=>v?.user_type<170)
            dispatch(personnel_lists(manager_lists, staff_lists))
        })
    }
}

export function get_prompt_no_auth() {
    return (dispatch, getState) => {
        axios.get(`${MIDDLE_EARTH_URL}/llm/get_prompt_object_no_auth/`, {}, {
            headers: {
                'content-type': 'application/json', // 默认值
                // 'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res => {
            console.log(res)
            let general_data = []
            for (let key in res.data.result) {
                console.log(key)
                general_data.push(key)
            }
            dispatch(user_prompt(res.data.result[general_data[0]], ''))
        }).catch(err => {
            console.log(err)
        })
    }
}
export function get_prompt_with_auth() {
    return (dispatch, getState) => {
        $test_http.get(`${MIDDLE_EARTH_URL}/llm/get_prompt_object_with_auth/`, {}, {
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res => {
            console.log(res)
            let general_data = []
            for (let key in res.data.result) {
                console.log(key)
                general_data.push(key)
            }
            dispatch(user_prompt(res.data.result[general_data[0]], res.data.result[general_data[1]]))
        }).catch(err => {
            console.log(err)
        })
    }
}
export function create_user_prompt(data) {
    return (dispatch, getState) => {
        axios.post(`${MIDDLE_EARTH_URL}/llm/create_user_prompt/`, data,
            {
                headers: {
                    'content-type': 'application/json', // 默认值
                    'Authorization': 'token ' + localStorage.getItem('token')
                }
            }
        ).then(response => {
            console.log(response)
            dispatch(get_prompt_with_auth())
        }).catch((err) => {
            console.log(err)
        })
    }
}
export function delete_user_prompt(data) {
    return (dispatch, getState) => {
        axios.delete(`${MIDDLE_EARTH_URL}/llm/delete_user_prompt/`, {
            data, headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(response => {
            console.log(response)
            dispatch(get_prompt_with_auth())
        })
    }
}

export function agent_list(data) {
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_agent_list/`, {}).then(res => {
            console.log(res)
            dispatch(hint_agent(res.data.data))
        })
    }
}
export function get_user_agent_list(data) {
    return (dispatch, getState) => {
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_user_agent_list/`, {}).then(res => {
            console.log(res)
            dispatch(hint_agent(res.data.data))
        })
    }
}
export function create_agent(data,setIsCreate,setErrorValue) {
    setIsCreate('')
    setErrorValue('')
    return (dispatch, getState) => {
        axios.post(`${MIDDLE_EARTH_URL}/llm/create_agent/`, data,
            {
                headers: {
                    'content-type': 'application/json', // 默认值
                    'Authorization': 'token ' + localStorage.getItem('token')
                }
            }
        ).then(response => {
            console.log(response)
            dispatch(get_user_agent_list())
            setIsCreate(true)
        }).catch((err) => {
            console.log(err.request.response)
            let error=JSON.parse(err.request.response)
            setErrorValue(error.result)
        })
    }
}
export function delete_user_agent(data) {
    return (dispatch, getState) => {
        axios.delete(`${MIDDLE_EARTH_URL}/llm/delete_user_agent/`, {
            data, headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(response => {
            console.log(response)
            dispatch(get_user_agent_list())
        })
    }
}

export function scheduler_task_lists(data){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_scheduler_task_list_for_user/`).then(res=>{
            console.log(res)
            dispatch(task_list(res.data.task_list))
        })
    }
}
export function get_cron_expression(data,setIsCheckingValue,setCronValue,setDetectionLoading){
    setIsCheckingValue('')
    setCronValue('')
    setDetectionLoading(true)
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/get_cron_expression/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setIsCheckingValue(res.data.result.doable)
            setCronValue(res.data.result.cron)
            setDetectionLoading(false)
        }).catch(error=>{
            let err=JSON.parse(error.request.response)
            setIsCheckingValue(err.result.doable)
            setDetectionLoading(false)
        })
    }
}
export function extract_url(data,setTestValue,setTestLoading,setTestDialog,setTestError){
    setTestValue('')
    setTestLoading(true)
    setTestDialog(false)
    setTestError('')
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 30000
        })
        instannce.post(`${MIDDLE_EARTH_URL}/llm/extract_url_html/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res.result)
            setTestValue(res.data.result)
            setTestLoading(false)
            setTestDialog(true)
        }).catch(error=>{
            let err=JSON.parse(error.request.response)
            setTestError(err.result)
            setTestLoading(false)
        })
    }
}
export function create_scheduler(data,setIsCreate,setCreateLoading,setErrorValue){
    setIsCreate('')
    setCreateLoading(true)
    setErrorValue('')
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 30000
        })
        instannce.post(`${MIDDLE_EARTH_URL}/llm/create_scheduler_task/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(scheduler_task_lists())
            setIsCreate(true)
            setCreateLoading(false)
        }).catch(error=>{
            console.log(error)
            let err=JSON.parse(error.request.response)
            setCreateLoading(false)
            setErrorValue(err.result)
        })
    }
}

export function send_mobile_code(data,setVerifyCode){
    setVerifyCode('')
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/user/send-mobile-verify-code/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setVerifyCode(res.data.code)
        }).catch(error=>{
            console.log(error)
        })
    }
}
export function toggle_scheduler(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/toggle_scheduler_task/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(scheduler_task_lists())
        }).catch(error=>{
            console.log(error)
        })
    }
}
export function delete_scheduler(data) {
    return (dispatch, getState) => {
        axios.delete(`${MIDDLE_EARTH_URL}/llm/delete_scheduler_task/`, {
            data, headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(response => {
            console.log(response)
            dispatch(scheduler_task_lists())
        })
    }
}
export function test_api_configs(data,setErrorValue,setTestSuccess,setIsLoading){
    setErrorValue('')
    setTestSuccess(false)
    setIsLoading(true)
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 15000
        })
        instannce.post(`${MIDDLE_EARTH_URL}/llm/test_api_configs/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setTestSuccess(true)
            setIsLoading(false)
        }).catch(error=>{
            let err=JSON.parse(error.request.response)
            setErrorValue(err.result)
            console.log(err)
            setIsLoading(false)
        })
    }
}
export function test_prompt(data,setTestValue,setTestLoading,setTestDialog,setTestError){
    setTestValue('')
    setTestLoading(true)
    setTestDialog(false)
    setTestError('')
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 30000
        })
        instannce.post(`${MIDDLE_EARTH_URL}/llm/test_prompt/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setTestValue(res.data.result)
            setTestLoading(false)
            setTestDialog(true)
        }).catch(error=>{
            let err=JSON.parse(error.request.response)
            setTestError(err.result)
            setTestLoading(false)
        })
    }
}

export function get_history_detail_file_names(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/get_history_detail_file_names`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            let lists=res.data.file_name_list.reverse()
            dispatch(file_lists(lists))
        })
    }
}
export function get_outsider_settings(data){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_insider_company_settings/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                // 'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            // console.log(res)
            if(res.status==200){
                dispatch(base_settings(res.data.result.base_settings))
            }
        })
    }
}
export function update_outsider_settings(data,setMessageValue){
    return (dispatch,getState)=>{
        setMessageValue(false)
        axios.post(`${MIDDLE_EARTH_URL}/llm/update_insider_company_settings/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(get_outsider_settings())
            setMessageValue(true)
        })
    }
}
export function upload_company_logo(formData,setLogoUrl,update_settings_logo){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/upload_company_logo/`,formData,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            if(res.status==200){
                setLogoUrl(res.data.result)
                if(!_.isNil(update_settings_logo)){
                    update_settings_logo(res.data.result)
                }
            }
        })
    }
}
export function optimize_prompt_words(data,setOptimizePrompt){
    setOptimizePrompt([])
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/optimize-prompt-words/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            setOptimizePrompt(res.data)
            console.log(res)
        })
    }
}
export function get_user_fileLists(data,setAgentFileLists){
    setAgentFileLists([])
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_user_file_list/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setAgentFileLists(res.data.result)
        })
    }
}

export function get_voice(data){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_voice_record_list/?page=${data.page}&pageSize=10`,'',{
            headers: {
                'content-type': 'application/json', // 默认值
            }
        }).then(res=>{
            console.log('get_voice_record_list',res.data.result)
            dispatch(voice_lists(res.data.result,res.data.totalPages))
        }).catch(error=>{
            console.log(error)
        })
    }
}
export function manager_get_voice(data){
    return (dispatch,getState)=>{
        const instannce = axios.create({
            timeout: 50000
        });
        instannce.interceptors.request.use(config => {
            config.headers.Authorization = 'token ' + localStorage.getItem('token')
            return config
        })
        instannce.get(`${MIDDLE_EARTH_URL}/llm/manager_get_voice_record_list/?page=${data.page}&pageSize=10`,'',{
            headers: {
                'content-type': 'application/json', // 默认值
            }
        }).then(res=>{
            console.log('get_voice_record_list',res.data.result)
            dispatch(voice_lists(res.data.result,res.data.totalPages))
        }).catch(error=>{
            console.log(error)
        })
    }
}

export function get_voice_id(data,setInfoData){
    return (dispatch,getState)=>{
        setInfoData(null)
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_voice_record_by_id?_id=${data}`,'',{
            headers: {
                'content-type': 'application/json', // 默认值
            }
        }).then(res=>{
            console.log('get_voice_record_by_id',res.data.result)
            setInfoData(res.data.result)
        }).catch(error=>{
            console.log(error)
        })
    }
}
export function update_voice_record(data,setIsupdate){
    console.log(12333)
    return (dispatch,getState)=>{
        setIsupdate(false)
        axios.post(`${MIDDLE_EARTH_URL}/llm/update_voice_record/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token ' + localStorage.getItem('token')
            }
        }).then(res=>{
            console.log('update_voice_record',res.data)
            setIsupdate(true)
            
        }).catch(error=>{
            console.log(error)
            setIsupdate(false)
        })
    }
}
export function delete_voice_record(data){
    return (dispatch,getState)=>{
        $http.delete(`${MIDDLE_EARTH_URL}/llm/delete_voice_record/`,{
            data,
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
        })
    }
}

export function create_crm_customer(data,type,setIsCreate){
    return (dispatch,getState)=>{   
        if(type=='crm'){
            setIsCreate(false)
        }else{
            setIsCreate('')
        }
        axios.post(`${MIDDLE_EARTH_URL}/crm/create_crm_customer/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            dispatch(get_crm_customer_lists())
            if(type=='crm'){
                setIsCreate(true)
            }else{
                setIsCreate(res.data?.result?._id)
            }
            console.log(res)
        })
    }
}
export function get_crm_customer_lists(data){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/crm/get_crm_customer_list/`,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(crm_lists(res.data.result))
        })
    }
}
export function get_crm_customer_detail(data,setCrmInfo){
    return (dispatch,getState)=>{
        setCrmInfo(null)
        $http.get(`${MIDDLE_EARTH_URL}/crm/get_crm_customer_detail?_id=${data}`,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setCrmInfo(res.data.result)
        })
    }
}
export function update_crm_customer(data,setIsUpdate){
    return (dispatch,getState)=>{
        setIsUpdate(false)
        axios.post(`${MIDDLE_EARTH_URL}/crm/update_crm_customer/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setIsUpdate(true)
        })
    }
}

export function filter_crm_customer(data,setCrmUserLists){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/crm/filter_crm_customers/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            // dispatch(crm_lists(res.data.result))
            setCrmUserLists(res.data.result)
        })
    }
}

export function update_user_basic(data,setIsAmend){
    return (dispatch,getState)=>{
        setIsAmend(false)
        axios.post(`${MIDDLE_EARTH_URL}/user/update-user-basic-info/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            // dispatch(crm_lists(res.data.result))
            setIsAmend(true)
            dispatch(get_update_user_info())
        })
    }
}
export function get_speech_token(data){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/llm/get_speech_recognizer_federation_token/`,data,{

        }).then(res=>{
            console.log(res)
            dispatch(recognizer_fede(res.data.Credentials))
        })
    }
}
export function text_content_scribe(data){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/llm/text_content_scribe/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
        })
    }
}
export function get_chunks_from(data,setChunksValue){
    return (dispatch,getState)=>{
        setChunksValue([])
        axios.post(`${MIDDLE_EARTH_URL}/llm/get_chunks_from_text/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setChunksValue(res.data.chunks)
        })
    }
}
export function multiple_upload(formData,setIsFile){
    return (dispatch,getState)=>{
        setIsFile('')
        const instannce = axios.create({
            timeout: 50000
        })
        instannce.post(`${MIDDLE_EARTH_URL}/llm/multiple_upload_voice_records/`,formData,{
            headers:{
                'content-type': 'multipart/form-data', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            setIsFile(true)
        })
    }
}
export function create_vooice_record_text(data,setIsCreate){
    return (dispatch,getState)=>{
        setIsCreate(null)
        axios.post(`${MIDDLE_EARTH_URL}/llm/create_voice_record_by_text/`,data,{
            headers: {
                'content-type': 'application/json', // 默认值
                'Authorization': 'token '+ localStorage.getItem('token')
            }
        }).then(res=>{
            console.log('recording_save',res.data.result)
            // dispatch(get_voice())
            setIsCreate(true)
        }).catch(error=>{
            console.log(error)
            setIsCreate(false)
        })
    }
}