import {ALL_USER_LOCATION,TRACK_CLOSE_DRAWER,OPEN_TRACK_DRAWER,BEHAVIOR_DATA,HAVE_COLLECT,MY_COLLECTED_USER,USER_LINKED_LISTS,ADMIN_LOADING,SNACK_OPEN,RELEVANCY} from './types';
import {MIDDLE_EARTH_URL, TAX_NAME_MAP, INDUSTRY_NAME_MAP} from '../configs/constants'
import fetchJsonp from 'fetch-jsonp'
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization='token ' + localStorage.getItem('token')
    return config
})


export function all_user_location(data,page_type){
    return {
        type:ALL_USER_LOCATION,
        data,
        page_type
    }
}
export function track_close_drawer(){
    return {
        type:TRACK_CLOSE_DRAWER
    }
}
export function open_track_drawer(track){
    return {
        type:OPEN_TRACK_DRAWER,
        track
    }
}
export function behavior_data(data,page_type){
    return {
        type:BEHAVIOR_DATA,
        data,
        page_type
    }
}
export function have_collect(data){
    return {
        type:HAVE_COLLECT,
        data
    }
}
export function my_collected_user(data,count){
    return {
        type:MY_COLLECTED_USER,
        data,
        count
    }
}
export function user_linked_lists(lists){
    return {
        type:USER_LINKED_LISTS,
        lists
    }
}
export function admin_loading(loading_open){
    return {
        type:ADMIN_LOADING,
        loading_open
    }
}
export function snack_open(){
    return {
        type:SNACK_OPEN,
        
    }
}
export function relevancy(mess){
    return {
        type:RELEVANCY,
        mess
    }
}

// export function cut_office(){
//     return {
//         type:
//     }
// }

export function admin_user(type){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/user/get-all-user-location/`,{
            // $http.get(`${MIDDLE_EARTH_URL}/api/user/get-all-user-location/`,{
                
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(res=>{
                console.log('admin_user',res)
                dispatch(all_user_location(res.data.data,type))
            })
    }
}


// export function collect_user(data){
//     return (dispatch,getState)=>{
//         axios.post(`${MIDDLE_EARTH_URL}/user/collect-user/`,{
//             user_id:data.user_id
//         },{
//             headers:{
//                 'content-type': 'application/json', // 默认值
//                 'Authorization':'token '+localStorage.getItem('token')
//             }
//         }).then(res=>{
//             console.log(res)
//             if(res.data.code==200){
//                 dispatch(have_collect(data))
//                 dispatch(admin_user())
//             }
//         })
//     }
// }

export function collect_user(data,tax_agent_id){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/user/crm/agent-customers/`,{
            user_id:data.user_id,
            agent_id:JSON.parse(tax_agent_id)
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            if(res.data.code==200){
                dispatch(have_collect(data))
                dispatch(admin_user())
            }
        })
    }
}


export function get_behavior(user_id,type,page_size,offset){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/user/get-user-behavior/`,{
            user_id:user_id,
            page_size:page_size, 
            offset:offset
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log('用户行为',res)
            for(let i=0;i<res.data.behaviors.length;i++){
                res.data.behaviors[i].time=format(res.data.behaviors[i].time)
            }
            dispatch(behavior_data(res.data,type))
        })
    }
}

export function my_collrct_user(page){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/user/get-collected-users?limit=10&page=${page}`,{
            
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log('my_collrct_user',res)
            dispatch(my_collected_user(res.data.data,res.data.count))
        })
    }
}
export function get_user_linked(tax_agent_id){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/user/get_users_linked_to_tax_agent?tax_agent_id=`+tax_agent_id,{tax_agent_id:tax_agent_id},{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(user_linked_lists(res.data.result))
        })
    }
}

export function link_user_tax_agent(tax_agent_id,user_id,role){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/user/link_user_to_tax_agent`,{
            tax_agent_id:tax_agent_id,
            user_id:user_id,
            role
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log(res)
            dispatch(relevancy(true))
            dispatch(admin_loading(false))
            dispatch(get_user_linked(tax_agent_id))
        }).catch(err=>{
            console.log(err)
            dispatch(relevancy(false))
            dispatch(admin_loading(false))
        })
    }
}



function add0(m){return m<10?'0'+m:m }
function format(shijianchuo){
    //shijianchuo是整数，否则要parseInt转换
    var time = new Date(shijianchuo);
    var y = time.getFullYear();
    var m = time.getMonth()+1;
    var d = time.getDate();
    var h = time.getHours();
    var mm = time.getMinutes();
    var s = time.getSeconds();
    return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);
}