import React,{useCallback} from 'react';
import {useDispatch,useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';
import {change_pay_product} from "../../../actions/mine"

import logo_shui from '../../../static/logo/shui_engine.png'


function Member(){
    const shui_member_type_info=useSelector((state)=>state.user.member_type_info)
    const shui_member_info=useSelector((state)=>state.user.member_info)
    const dispatch = useDispatch();


    const history = useHistory();
    const gotoShuiPayment = useCallback(() => {
        dispatch(change_pay_product('税引擎'))
        history.push('/mine/payment');
    }, [history]);


    return (
        <div >
            <div style={{display:'flex',boxShadow:'2px 2px 10px #d6d6d6',margin:'40px 0px',padding:'30px 20px'}}>
                <div style={{width:'15%',textAlign:'center'}}>
                    <img src={logo_shui} alt style={{width:80,height:80}} alt="" />
                </div>
                <div style={{width:'40%'}}>
                    <div style={{fontSize:'20px',paddingBottom:5}}>税引擎</div>
                    <div style={{fontSize:'15px'}}>博槐税引擎是税务筹划，税务税法相关知识的线上SaaS平台，致力于提供低价，优质，合理合法的税筹筹划方案以及毫秒级税务知识库。</div>
                </div>
                {
                    (()=>{
                        if(shui_member_type_info.member_type!==1){
                            return <div style={{width:'30%',textAlign:'center',lineHeight:'80px',marginTop:'10px'}}><div style={{color:'red'}}>您还不是会员</div></div>
                        }else{
                            return <div style={{width:'30%',textAlign:'center',lineHeight:'30px',marginTop:'10px'}}>
                                        <div>过期时间</div>
                                        <div>{shui_member_info.expire_date.split(' ')[0]}</div>
                                    </div>
                        }
                    })()
                }
                <div style={{width:'15%'}}>
                    {
                        shui_member_type_info.member_type!==1?
                            <div style={{padding:'5px 0',background:'#3D1198',borderRadius:'8px',color:'#fff',textAlign:'center',maxWidth:'100px',marginTop:'30px',cursor: "pointer"}} onClick={gotoShuiPayment}>成为会员</div>:
                            <div style={{padding:'5px 0',background:'#3D1198',borderRadius:'8px',color:'#fff',textAlign:'center',maxWidth:'100px',marginTop:'30px',cursor: "pointer"}} onClick={gotoShuiPayment}>续费</div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Member;