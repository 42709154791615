import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import Slider from "react-slick";
import QrCodeIcon from '@mui/icons-material/QrCode';
import AndroidIcon from '@mui/icons-material/Android';
import Dialog from '@mui/material/Dialog';

import logo from '../../static/logo/logo3.jpg';
import bh_xcx_2 from '../../static/image/bh_xcx_2.jpg'
import kuai_engine from '../../static/image/kuai_engine.jpg'

import accountant_icon from '../../static/icon/accountant_icon.png'
import tax_icon from '../../static/icon/tax_icon.png'


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px  !important",
        margin: 0,
        maxWidth: '100%',
        overflowX: 'hidden'
    }
}));



function Initial() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState('');

    return (
        <Container className={classes.root}>
            <div style={{padding:'5%'}}>
                <div style={{display:'flex',justifyContent:'center'}}>
                    <img src={logo} alt="" style={{ width: 150, height: 150, borderRadius: '50%' }} />
                </div>
                <div style={{textAlign:"center"}}>请用电脑端浏览器打开该页面。</div>
            </div>
        </Container>
    );
}

export default Initial;