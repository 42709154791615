import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import shui_engine from '../../../static/logo/logo_2.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import user_img from '../../../static/image/user_img.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Chip from '@mui/material/Chip';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { message } from 'antd';
import { scheduler_task_lists, create_agent, delete_user_agent, toggle_scheduler, delete_scheduler } from '../../../actions/user'

const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        backgroundColor: "#4844f3 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#4844f3 !important',
        },
    },
    clearButton: {
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));

const MaxContent = (props) => {
    const e = props.e
    console.log(e)
    if (e?.length > 10) {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{e}</div>
            <div>
                <Tooltip title={e}>
                    <HelpIcon style={{ color: 'rgba(215, 215, 215)', fontSize: 20 }}>
                    </HelpIcon>
                </Tooltip>
            </div>
        </div>
    } else {
        return <div style={{ width: 100 }}>{e}</div>
    }
}

const _ = require('micro-dash');
function Monitoring() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const task_lists = useSelector((state) => state.user.task_lists);
    const company_quota=useSelector((state) => state.user.company_quota);
    const quota=useSelector((state) => state.user.quota);
    const token = useSelector((state) => state.user.token);
    const base_setting=useSelector((state) => state.user.base_setting);
    const history=useHistory()
    // const [hint_agent,setHintAgent]=React.useState('');
    useEffect(() => {
        dispatch(scheduler_task_lists())
    }, [])
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])
    const handleAgent = () => { }
    const [agent_dialog, setAgentDialog] = React.useState(false);
    const [agent_id, setAgentId] = React.useState(null);
    const agent_delete = (e) => {
        setAgentId(e)
        setAgentDialog(true)
    }
    const agentClose = () => {
        setAgentDialog(false)
    }
    const agentAffirm = () => {
        dispatch(delete_scheduler({ task_id: agent_id }))
        setAgentDialog(false)
    }
    const handleChange = (event, id) => {
        dispatch(toggle_scheduler({ task_id: id }))
    };
    const [compare_result, setCompareResult] = React.useState('');
    const [result_dialog, setResultDialog] = React.useState(false);
    const examine = (e) => {
        setCompareResult(e)
        setResultDialog(true)
    }
    const promptClose = () => {
        setResultDialog(false)
    }
    const [open, setOpen] = React.useState(true);
    const [particulars_dialog, setParticularsDialog] = React.useState(false);
    const [particulars_value, setParticularsValue] = React.useState(null);
    const open_particulars = (e) => {
        console.log(e)
        setParticularsValue(e)
        setParticularsDialog(true)
    }
    const particularsClose = () => {
        setParticularsDialog(false)
    }
    const quota_num=()=>{
        message.error('您的提问次数不足，请充值')
    }
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff',padding:'8px 0', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 54px)', overflow: 'auto', minWidth: 220 }}>
                            <TabbleLists type='2'></TabbleLists>
                        </div>
                        <div style={{ padding: '5%', paddingTop: '2%' ,width:'calc(90% - 220px)'}}>
                            <Collapse in={open}>
                                <Alert
                                    // variant="outlined"
                                    severity="info"
                                    // style={{ borderColor: 'rgb(72, 68, 243)', color: 'rgb(72, 68, 243)' }}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            style={{color:base_setting?.primary_color}}
                                            size="small"
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    定时AI任务功能可以定时执行基于大模型智能体的任务也可以定时监控公共网站内容的变化，并以个性化的方式通知客户。
                                    比如：每天早上8点定时检查国税局官网是否有最新的政策变动，或者每周一上午十点定时调用智能体将上周公司产品新增用户数据以及相关财务信息整理成报告，发送到关联邮箱。
                                </Alert>
                            </Collapse>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10, paddingTop: 20 }}>
                                <div style={{ fontWeight: 'bold' }}>您的定时AI任务列表</div>
                                {
                                    company_quota+quota<=0?<div><Button onClick={quota_num} variant="contained" color='success' style={{ background: "#46b14b" }}>创建定时AI任务</Button></div>:<Link to='/largemodel/create_monitoring' style={{ textAlign: 'right' }}>
                                        <Button variant="contained" color='success' style={{ background: "#46b14b" }}>创建定时AI任务</Button>
                                    </Link>
                                }
                                
                            </div>
                            <TableContainer>
                                <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>名称</TableCell>
                                            <TableCell align="left" style={{ minWidth: 60 }}>任务计划</TableCell>
                                            <TableCell align="center">上次触发通知时间</TableCell>
                                            <TableCell align="center">类型/详情</TableCell>
                                            <TableCell align="center">状态</TableCell>
                                            <TableCell align="center">操作</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {task_lists?.map((row) => (
                                            <TableRow
                                                hover
                                                key={row._id}
                                                onClick={(e) => handleAgent(row.prompt)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <MaxContent e={row.name} />
                                                </TableCell>
                                                <TableCell align="left"><MaxContent e={row.cron_language_expression} /></TableCell>
                                                <TableCell align="left">
                                                    {
                                                        row.last_compare_result != null ? <div style={{ textAlign: 'center' }}><Button onClick={() => examine(row.last_compare_result)} style={{color:base_setting?.primary_color}}>查看</Button><div>{row.last_compare_result.last_notification_time}</div></div> : <div style={{ textAlign: 'center' }}>无</div>
                                                    }
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ButtonGroup variant="outlined" aria-label="Basic button group">
                                                        <Button  variant="outlined" style={{color:base_setting?.primary_color}}>
                                                            {
                                                            row.type==="LINK"?'链接':'智能体' 
                                                            }
                                                        </Button>
                                                        <Button variant="outlined" style={{color:base_setting?.primary_color}}  onClick={() => open_particulars(row)}><SearchOutlinedIcon /></Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        关
                                                        <Switch
                                                            checked={row.enabled}
                                                            onChange={(e) => handleChange(e, row._id)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            style={{color:base_setting?.primary_color}}
                                                        />
                                                        开
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button onClick={() => agent_delete(row._id)} style={{color:base_setting?.primary_color}}>删除</Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </div >
            <Dialog onClose={promptClose} open={result_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <div style={{ position: 'absolute', right: 20, top: 20 }} onClick={promptClose}>
                    <CancelIcon style={{ fontSize: 20 }} />
                </div>
                <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>定时AI任务对比</DialogTitle>
                <div style={{ padding: 50, paddingTop: 0 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>上次出发通知时间</TableCell>
                                    <TableCell align="left">前次内容（对比内容）</TableCell>
                                    <TableCell align="left">本次内容</TableCell>
                                    <TableCell align="left">大模型对比结果</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    hover
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={{ width: 200 }}>
                                        {compare_result.last_notification_time}
                                    </TableCell>
                                    <TableCell align="left" style={{ width: 320 }}>{compare_result.compare_content}</TableCell>
                                    <TableCell align="left" style={{ width: 320 }}>{compare_result.current_content}</TableCell>
                                    <TableCell align="left" ><MaxContent e={compare_result.compare_result} /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Dialog>
            <Dialog onClose={particularsClose} open={particulars_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <div style={{ position: 'absolute', right: 20, top: 20 }} onClick={particularsClose}>
                    <CancelIcon style={{ fontSize: 20 }} />
                </div>
                <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>详情</DialogTitle>
                {particulars_value != null ? particulars_value.type === 'LINK' ? <div style={{ padding: 50, paddingTop: 0 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>名称</TableCell>
                                    <TableCell align="left">提取链接</TableCell>
                                    <TableCell align="left">网页对比提示词</TableCell>
                                    <TableCell align="left">网页提取提示词</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    hover
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" style={{ maxWidth: '10%' }}>
                                        {particulars_value.name}
                                    </TableCell>
                                    <TableCell align="left" style={{minWidth:100, maxWidth: '30%', overflow: 'hidden', overflowWrap: 'break-word' }}>{particulars_value.url}</TableCell>
                                    <TableCell align="left" style={{minWidth:100, maxWidth: '20%' }}>{particulars_value.compare_prompt}</TableCell>
                                    <TableCell align="left" style={{minWidth:100, maxWidth: '20%' }}>{particulars_value.extract_prompt}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : <div style={{ padding: 50, paddingTop: 0 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: '50vw' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>名称</TableCell>
                                    <TableCell align="left">提示</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    hover
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {particulars_value.name}
                                    </TableCell>
                                    <TableCell align="left" >{particulars_value.prompt}</TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div> : ''}

            </Dialog>
            <Dialog onClose={agentClose} open={agent_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                {/* <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>添加专属提示词</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        您确定删除该条定时AI任务吗
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={agentClose}>取消</Button>
                    <Button onClick={agentAffirm} autoFocus>
                        确认
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Monitoring