import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import Footer from "../../footer/Footer";
import Container from '@mui/material/Container';
import HeadHavigation from "../../headHavigation/headHavigation";
import ShuiIntro from "./ShuiIntro";

function ShuiDetails(){
    return (
        <div>
            <HeadHavigation provenance={0} />
            <Container>
                <ShuiIntro/>
            </Container>
            <hr/>
            <div style={{padding:'0px 12% 0px 12%'}}>
                <Footer />
            </div>
        </div>
    )
}
export default ShuiDetails;