/**
 * Created on 6/23/21.
 * @author Xihao
 */
import { io } from 'socket.io-client'
export const MIDDLE_EARTH_URL =
    process.env.NODE_ENV === 'production'?
    'https://www.dongqianshan.com:3006'
        // :'http://192.168.31.186:3005'
        // :'http://192.168.31.17:3005'
        // :'http://localhost:3005';
        :'https://www.dongqianshan.com:3006'
        // :'https://bohuaiapi.top:3006'

    // :'http://124.222.237.211:8003/api'

export const KY_URL=
    process.env.NODE_ENV === 'production'?
        'https://www.dongqianshan.com:8020'
        // :"http://localhost:8000";
        :"https://www.dongqianshan.com:8020"

export const SOCKET_EARTH_URL=
    process.env.NODE_ENV === 'production'?
        'wss://www.dongqianshan.com:3006'
        // :'ws://192.168.31.17:3005'
        // :'ws://192.168.31.186:3005'
        :'wss://www.dongqianshan.com:3006'
        // :'wss://bohuaiapi.top:3006'
        // :"ws://localhost:3005";

    
export const TAX_NAME_MAP = {
    qysds: '企业所得税',
    zzs: '增值税',
    grsds: '个人所得税',
    qs: '契税',
    yhs: '印花税',
    fcs: '房产税'
};

export const INDUSTRY_NAME_MAP = {
    agriculture: '农林牧渔',
    tech: '科技',
    real_estate: '房地产',
    manufacturing: '制造业',
};
export const socket = io(SOCKET_EARTH_URL);
export const HOME_SEARCH_COLOR='rgb(61, 17, 152)'
export const MODEL_COLOR='#4844f3'

export const CLOUD_URL='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/'

export const WECHAT_LOGIN_URL = `https://open.weixin.qq.com/connect/qrconnect?appid=wxd9458c81863a7bfa&redirect_uri=https%3A%2F%2Fwww.huainor.com%2F&response_type=code&scope=snsapi_login&state=${process.env.NODE_ENV}#wechat_redirect`;

export const HOME_TITLE = "会计实操搜索与税务筹划平台-博槐互联网科技";

export const TAX_KEYWORDS_ARRAY = ["税务筹划","税筹","合理节税","合理避税","节税","避税",
    "个人所得税","个税","增值税","企业所得税","征税","财税","税收优惠","税收筹划","纳税筹划","税收优化",
    "税务咨询","代扣代缴","预扣预缴","应纳税额","应纳税所得额","关税","消费税","土地增值税","城镇土地使用税",
    "印花税","车辆购置税","城市维护建设税","耕地占用税","契税","车船税","房产税","一般纳税人","小规模纳税人"];
const COMPANY_NAME_ARRAY = ["博槐","博槐互联网科技","博槐互联网"];

// for SEO use cases
export const rand_arr_with_date_seed = (arr)=>{
    let d = (new Date()).getUTCDay()+2; // per day seed
    return [...arr.filter((item, index)=>index%d!==0), ...COMPANY_NAME_ARRAY];
};