import React, { useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import { styled, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import Drawer from '@mui/material/Drawer';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import DrawerContent from './DrawerContent'
import { track_close_drawer, open_track_drawer, get_behavior } from '../../../actions/admin'
import {user_customers} from '../../../actions/user'

import { my_collrct_user } from '../../../actions/admin'

const _ = require('micro-dash');
const useStyles = makeStyles((theme) => ({
    shadow: {
        boxShadow: '0 15px 9px #d6d6d6'
    },
    table: {
        // minWidth: 650,
        fontSize: 12
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3D1198',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


function UserLists() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(1);
    const track_open = useSelector((state) => state.admin.track_open)
    const my_collected_lists = useSelector((state) => state.user.collect_lists)
    const count = useSelector((state) => state.admin.count)
    const user_behavior = useSelector((state) => state.admin.my_behavior_data)
    const ranking = useSelector((state) => state.admin.my_ranking)
    const tax_agent_id=useSelector((state) => state.user.tax_agent_id)
    console.log(user_behavior)
    // useEffect(() => {
    //     dispatch(my_collrct_user(page))
    // }, [])
    useEffect(() => {
        console.log(tax_agent_id)
        if(!_.isNil(tax_agent_id)){
            dispatch(user_customers(tax_agent_id))
            // dispatch(user_customers(152))
        }
    }, [tax_agent_id])
    const handleChange = (event, value) => {
        setPage(value)
        dispatch(my_collrct_user(value))
    }
    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <div style={{ width: '70%', height: '100%' }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">姓名</StyledTableCell>
                                <StyledTableCell align="center">电话</StyledTableCell>
                                <StyledTableCell align="center">地址</StyledTableCell>
                                <StyledTableCell align="center">时间</StyledTableCell>
                                {/* <StyledTableCell align="center">收藏</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {my_collected_lists.map((row) => (
                                <StyledTableRow key={row.name} style={{ cursor: 'pointer' }} onClick={() => dispatch(get_behavior(row.user_id))}>
                                    <StyledTableCell component="th" scope="row" align="center" >
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.phone_number}</StyledTableCell>
                                    <StyledTableCell align="center">{row.address}</StyledTableCell>
                                    <StyledTableCell align="center">{row.create_time.split('T')[0]}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div style={{ width: '100%', height: '100%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
                        <Pagination count={Math.ceil(my_collected_lists.length / 10)} color="primary"
                            page={page}
                            onChange={handleChange}
                            variant="outlined" shape="rounded" />
                    </div>
                </TableContainer>
            </div>
            <div style={{ width: '30%', height: '100%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginLeft: 10, background: '#fff', padding: 10 }}>
                <div>
                    <div style={{ display: 'flex', flexWrap: "wrap", width: '100%' }}>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(33.33% - 36px)', padding: 8, margin: 10 }}>
                            <div style={{ fontSize: 12, textAlign: 'center', paddingBottom: 8 }}>总时间</div>
                            <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>{user_behavior.total_duration}s</div>
                        </div>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(33.33% - 36px)', padding: 8, margin: 10 }}>
                            <div style={{ fontSize: 12, textAlign: 'center', paddingBottom: 8 }}>进入次数</div>
                            <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>{user_behavior.total_visit_times}</div>
                        </div>
                        <div style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', width: 'calc(33.33% - 36px)', padding: 8, margin: 10 }}>
                            <div style={{ fontSize: 12, textAlign: 'center', paddingBottom: 8 }}>最近访问</div>
                            <div style={{ fontSize: 18, textAlign: 'center', fontWeight: 'bold' }}>{user_behavior.update_time != 0 ? user_behavior.update_time.split('T')[0] : user_behavior.update_time}</div>
                        </div>
                    </div>
                    <div>
                        <div style={{ fontSize: '13px', fontWeight: 'bold', padding: '10px 0' }}>排行</div>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"></TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>页面停留时间</TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>页面进入次数</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ranking.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell component="th" scope="row">
                                                {row.ranking}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>{row.page_duration}</TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>{row.page_times}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div>
                        <div style={{ fontSize: '13px', fontWeight: 'bold', padding: '10px 0' }}>详细轨迹</div>
                        {
                            user_behavior.behavior.length>0?
                            user_behavior.behaviors.reverse().slice(0, 5).map((item, index) => {
                                return <div style={{ cursor: 'pointer', padding: 8, fontSize: 14 }}>
                                <div style={{ display: 'flex' }} onClick={() => dispatch(open_track_drawer(item.data))} key={index}>
                                    <div style={{ width: '40%' }}>{item.time.split(' ')[0]} {item.time.split(' ')[1]}</div>
                                    <div style={{ width: '40%', textAlign: 'center' }}>{item.data[item.data.length - 1].duration}s</div>
                                    <div style={{ width: '20%', textAlign: 'right' }}>
                                        <ArrowForwardIcon style={{ color: '#3D1198' }} />
                                    </div>
                                </div>
                                <div style={{ padding: '10px 0' }}>
                                    <Divider />
                                </div>
                            </div>
                            }):''
                        }

                    </div>
                </div>
                <div>

                </div>
            </div>
            <Drawer
                anchor={'right'}
                open={track_open}
                onClose={() => dispatch(track_close_drawer())}
            >
                <div style={{ width: window.innerWidth * 1 / 3, height: '100%' }}>
                    <DrawerContent />
                </div>
            </Drawer>
        </div>

    )
}
export default UserLists;