import React,{useCallback} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {useDispatch,useSelector} from "react-redux";
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom';
import {change_pay_product} from "../../../actions/mine"

import {open_hints} from '../../../actions/user';

import { WECHAT_LOGIN_URL } from '../../../configs/constants'

const _ = require('micro-dash');


function Dialogs(){
    const dispatch = useDispatch();
    const token=useSelector((state) => state.user.token);
    const tax_vip=useSelector((state) => state.user.member_type_info);
    const open_hint=useSelector((state)=>state.user.open_hint)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => {
        dispatch(open_hints(false))
    };
    // console.log('token',token,open_hint)

    const history = useHistory();
    const gotoShuiPayment = useCallback(() => {
        dispatch(change_pay_product('税引擎'))
        dispatch(open_hints(false))
        history.push('/mine/payment');
    }, [history]);

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Open responsive dialog
            </Button> */}
            <Dialog
                fullScreen={fullScreen}
                open={open_hint}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                {
                    (()=>{
                        if(_.isNil(token)){
                            return '登录/注册'
                        }else{
                            if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                                return '试用时长已结束'
                            }
                        }
                    })()
                }
                </DialogTitle>
                <DialogContent>
                {
                    (()=>{
                        if(_.isNil(token)){
                            return <DialogContentText>
                                        登录/注册后才可使用此功能
                                    </DialogContentText>
                        }else{
                            if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                                return <DialogContentText>
                                        您的试用时长已结束，成为会员后才可使用此功能
                                    </DialogContentText>
                            }
                        }
                    })()
                }
                {/* <DialogContentText>
                    Let Google help apps determine location. This means sending anonymous
                    location data to Google, even when no apps are running.
                </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    关闭
                </Button>
                {
                    (()=>{
                        if(_.isNil(token)){
                            return <Button onClick={() => window.location.href = WECHAT_LOGIN_URL} autoFocus>
                                        登录
                                    </Button>
                        }else{
                            if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                                return <Button onClick={gotoShuiPayment} autoFocus>
                                            成为vip
                                        </Button>
                            }
                        }
                    })()
                }
                </DialogActions>
            </Dialog>
        </div>
    )   
}
export default Dialogs;