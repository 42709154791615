import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import RecordingDrawer from '../examples/recording_drawer'
import { create_vooice_record_text} from '../../../actions/user'
import { message } from 'antd';
import { MODEL_COLOR } from '../../../configs/constants'

const _ = require('micro-dash');
const type_lists = [
    {
        label: '客户拜访(公开存入CRM)',
        value: 'CUSTOMER'
    },
    {
        label: '会议记录(公开存入CRM)',
        value: 'MEETING'
    },
    {
        label: '个人（数据私有不公开）',
        value: 'PERSONAL'
    }
]
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: "0 1px 12px #D6D6D6 !important",
        zIndex: 6,
        height: 50,
    },
    input: {
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#0084e9 !important',
            color: '#fff'
        },
    },
    clearButton: {
        color: '#0084e9 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    },
    disabledLabel: {
        position: 'absolute !important' /* 确保label位置正常 */,
        left: '0px !important',
        top: '-23px !important',
        fontSize: 12,
        color: '#1976d2 !important'
    }
}));


function RegisterSummarize() {
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const base_setting = useSelector((state) => state.user.base_setting);
    const dispatch = useDispatch();
    const history = useHistory()    
    const [name_value, setNameValue] = React.useState('');
    const [info_value, setInfoValue] = React.useState([]);
    const [is_create,setIsCreate]= React.useState(false);
    const info_change=(e)=>{
        setInfoValue(e.target.value)
    }
    const name_change=(e)=>{
        setNameValue(e.target.value)
    }
    const update_info=()=>{
        if(info_value!=''){
            dispatch(create_vooice_record_text({ name: name_value, info: info_value }, (v) => setIsCreate(v)))
        }else{
            message.success('内容不能为空')
        }
        
    }
    useEffect(() => {
        console.log('is_create', is_create)
        if (!_.isNil(is_create) && is_create != '') {
            message.success('添加成功')
            history.replace('/largemodel/enlightenment_lists')
        }
    }, [is_create]);
    return (
        <div >
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                            <TabbleLists></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '3% 5%', width: '50%' }}>
                    <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                        <Link to='/largemodel/enlightenment_lists' style={{ cursor: 'pointer' }}>录音列表</Link>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div>
                        <div style={{ cursor: 'pointer' }}>添加拜访总结</div>
                    </div>
                    <div style={{ paddingTop: 20 }}>
                        <div>
                            <TextField
                                id="outlined-basic"
                                value={name_value}
                                label="名字"
                                focused
                                onChange={name_change}
                                style={{ width: '100%' }}
                                variant="outlined"
                            />
                        </div>
                        <div style={{ padding: '20px 0' }}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="介绍"
                                focused
                                multiline
                                rows={4}
                                style={{ width: '100%' }}
                                onChange={info_change}
                                value={info_value}
                            />
                            <div style={{fontSize:12,color:'rgba(0, 0, 0, 0.54)',paddingTop:5}}>*ctrl+k可快速录音加入内容</div>
                        </div>
                    </div>
                    <div style={{textAlign:'center'}}>
                        <Button variant="contained" color='success' onClick={update_info} style={{ background: base_setting?.primary_color, width: '50%' }}>添加</Button>
                    </div>
                </div>
            </div>
            <RecordingDrawer ></RecordingDrawer>
        </div>
    )
}
export default RegisterSummarize