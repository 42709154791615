import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import shui_engine from '../../static/logo/logo_2.jpg'
import user_img from '../../static/image/user_img.png'


function HeadLargemodel(){
    const base_setting=useSelector((state) => state.user.base_setting);
    const [add_theme, setAddTheme] = React.useState(false);
    const themeClose=()=>{
        setAddTheme(false)
    }
    const app_download=()=>{
        setAddTheme(true)
    }
    return (
        <div style={{ width: '95%', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', padding: '5px 3%',paddingLeft:'2%', display: 'flex', justifyContent: 'space-between', background: base_setting?.primary_color }}>
            <div style={{ display: 'flex',alignItems:'center' }}>
                <Link to='/' style={{ paddingRight: 10 }}>
                    <img src={base_setting?.logo_url} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />
                </Link>
                <Link to='/' style={{ display: 'flex', alignItems: 'center', }}>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: 17, color: '#fff', fontWeight: 'bold' }}>{base_setting?.brand_name}</div>
                </Link>
                <div onClick={app_download} style={{color:'#fff',paddingLeft:20,cursor:'pointer',fontSize:14}}>APP下载</div>
            </div>
            <Link to='/largemodel/mine'>
                <img src={user_img} alt="" style={{ width: 40, height: 40, borderRadius: '50%' }} />
            </Link>
            <Dialog onClose={themeClose} open={add_theme} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <div style={{ padding: 20}}>
                    <div>
                        <img src="https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/dongqianshan/android_download_code.png" style={{ width: 160 }} alt='' />
                    </div>
                    <div style={{textAlign:'center',fontSize:15}}>*仅支持安卓app下载</div>
                    <div style={{textAlign:'center',paddingTop:5,fontSize:15}}>*请用浏览器扫码下载</div>
                </div>
            </Dialog>
        </div>
    )
}
export default HeadLargemodel;