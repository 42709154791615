import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import RecordingDrawer from '../examples/recording_drawer'
import { get_outsider_settings, delete_voice_record, get_voice_id, cut_tab_action, update_voice_record, get_crm_customer_lists, filter_crm_customer, create_crm_customer } from '../../../actions/user'
import { message } from 'antd';
import { MODEL_COLOR } from '../../../configs/constants'

const _ = require('micro-dash');
const type_lists = [
    {
        label: '客户拜访(公开存入CRM)',
        value: 'CUSTOMER'
    },
    {
        label: '会议记录(公开存入CRM)',
        value: 'MEETING'
    },
    {
        label: '个人（数据私有不公开）',
        value: 'PERSONAL'
    }
]
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: "0 1px 12px #D6D6D6 !important",
        zIndex: 6,
        height: 50,
    },
    input: {
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#0084e9 !important',
            color: '#fff'
        },
    },
    clearButton: {
        color: '#0084e9 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    },
    disabledLabel: {
        position: 'absolute !important' /* 确保label位置正常 */,
        left: '0px !important',
        top: '-23px !important',
        fontSize: 12,
        color: '#1976d2 !important'
    }
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function EnlightenmentInfo(e) {
    let _id = e.location.state.id
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const agent_menus = useSelector((state) => state.user.agent_menus);
    const base_setting = useSelector((state) => state.user.base_setting);
    const crm_lists = useSelector((state) => state.user.crm_lists);
    const dispatch = useDispatch();
    const history = useHistory()
    const [info_data, setInfoData] = React.useState(null);
    const [crm_user_lists, setCrmUserLists] = React.useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = React.useState('');

    useEffect(() => {
        dispatch(get_outsider_settings())
        dispatch(get_voice_id(_id, (v) => setInfoData(v)))
        dispatch(get_crm_customer_lists())
    }, [])
    useEffect(() => {
        setCrmUserLists(crm_lists)
        console.log(crm_lists, selectedCustomerId)
        if (crm_lists.length > 0 && info_data != null) {
            // 假设info_data.customer_id是正确的默认值
            setSelectedCustomerId(info_data?.customer_id);
        }
        console.log(crm_lists)
    }, [crm_lists, info_data])
    const name_value = (e) => {
        let data = { ...info_data }
        data.name = e.target.value
        setInfoData(data)
    }
    const info_value = (e) => {
        let data = { ...info_data }
        data.info = e.target.value
        setInfoData(data)
    }

    const InfoTypeChange = (event) => {
        let data = { ...info_data }
        data.type = event.target.value
        setInfoData(data)
    };
    const [is_update, setIsupdate] = React.useState(false);
    const update_info = () => {
        if (info_data.file_name != '') {
            console.log(2233)
            let data = { ...info_data }
            console.log(data)
            dispatch(update_voice_record(data, (v) => setIsupdate(v)))
        }
    }
    useEffect(() => {
        if (is_update) {
            message.success('修改成功')
            setIsupdate(false)
        }
    }, [is_update])
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
    }
    const delete_info = () => {
        setOpen(true)
    }
    const affirm = () => {
        dispatch(delete_voice_record({ _id: _id }))
        history.replace('/largemodel/enlightenment_lists')
    }
    const [searchValue, setSearchValue] = React.useState('');
    const searchChange = (e) => {
        setSearchValue(e.target.value)
    }
    const handleSearch = () => {
        dispatch(filter_crm_customer({ search: searchValue }, (v) => setCrmUserLists(v)))
    }
    const [personName, setPersonName] = React.useState('');

    const handleChange = (event) => {
        let data = { ...info_data }
        data.customer_id = event.target.value
        setSelectedCustomerId(event.target.value);
        setInfoData(data)
        // setPersonName(event.target.value)
    };
    const Enter_searchChange = (e) => {
        console.log(e)
        let key = e.keyCode
        if (key == 13) {
            dispatch(filter_crm_customer({ search: searchValue }, (v) => setCrmUserLists(v)))
        }
    }
    const [name, setName] = React.useState('');
    const [job_title, setJobTitle] = React.useState('');
    const [company_info, setCompanyInfo] = React.useState('');
    const [company_size, setCompanySize] = React.useState('');
    const [industry, setIndustry] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [wechat, setWechat] = React.useState('');
    const [internal_notes, setInternalNotes] = React.useState('');
    const [brief, setBrief] = React.useState('');
    const [preference, setPreference] = React.useState('');
    const [importance_level, setImportanceLevel] = React.useState(1);
    const [is_manager_editable_only, setIsManagerEditableOnly] = React.useState(false);
    const [is_create, setIsCreate] = React.useState(false);
    const [create_user_dialog, setCreateUserDialog] = React.useState(false);

    let maxDialogWidth = 'sm'
    const creation_user = () => {
        console.log(personName)
        setCreateUserDialog(true)
    }
    const userhandleClose = () => {
        setCreateUserDialog(false)
    }
    const create_user = () => {
        if (name == '') {
            message.error('名称不能为空')
        } else {
            let data = {
                name: name,
                job_title: job_title,
                company_info: company_info,
                company_size: company_size,
                industry: industry,
                address: address,
                email: email,
                phone: phone,
                wechat: wechat,
                internal_notes: internal_notes,
                brief: brief,
                preference: preference,
                importance_level: importance_level,
                is_manager_editable_only: is_manager_editable_only
            }
            dispatch(create_crm_customer({ data: data }, 'enlightenmentInfo', (v) => setPersonName(v)))
            setCreateUserDialog(false)
        }
    }
    useEffect(() => {
        if (personName != null) {
            let data = { ...info_data }
            data.customer_id = personName
            setInfoData(data)
        }
    }, [personName])
    // 创建ref来引用audio元素
    const audioRef = useRef(null);
    // 设置播放状态
    const [isPlaying, setIsPlaying] = React.useState(false);

    // 处理播放/暂停的函数
    const handlePlayPause = () => {
        if (isPlaying) {
            // 如果正在播放，则暂停
            audioRef.current.pause();
        } else {
            // 如果暂停或未开始播放，则播放
            audioRef.current.play();
        }
        // 切换播放状态
        setIsPlaying(!isPlaying);
    };
    return (
        <div >
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                            <TabbleLists></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '3% 5%', width: '50%' }}>
                    <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                        <Link to='/largemodel/enlightenment_lists' style={{ cursor: 'pointer' }}>录音列表</Link>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16 }}></ArrowForwardIosIcon></div>
                        <div style={{ cursor: 'pointer' }}>详情</div>
                    </div>
                    <div style={{ paddingTop: 20 }}>
                        <div>
                            <TextField
                                id="outlined-basic"
                                value={info_data?.name}
                                label="名字"
                                focused
                                onChange={name_value}
                                style={{ width: '100%' }}
                                variant="outlined"
                            />
                        </div>
                        <div style={{ padding: '20px 0' }}>
                            <TextField
                                id="outlined-multiline-flexible"
                                label="介绍"
                                focused
                                multiline
                                rows={2}
                                style={{ width: '100%' }}
                                onChange={info_value}
                                value={info_data?.info}
                            />
                        </div>
                        <div>
                            {
                                !_.isNil(info_data?.audio_link) ? <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                                    <div style={{ paddingRight: 10 }}>识别内容:</div>
                                    <div>
                                        <audio ref={audioRef} src={info_data?.audio_link}></audio>
                                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', borderRadius: 10, justifyContent: 'center' }} onClick={handlePlayPause}>
                                            <IconButton style={{ padding: 0 }}>
                                                {isPlaying ? <PauseCircleOutlineIcon style={{ color: MODEL_COLOR }} /> : <PlayCircleOutlineIcon style={{ color: MODEL_COLOR }} />}
                                            </IconButton>
                                            {/* <div style={{ fontSize: 15, paddingLeft: 5 }}>{isPlaying ? '暂停录音' : '播放录音'}</div> */}
                                        </div>
                                    </div>
                                </div> : ''
                            }
                            <TextField
                                disabled
                                id="outlined-disabled"
                                // label="识别内容"
                                multiline
                                focused
                                className={classes.disabled_input}
                                rows={10}
                                style={{ width: '100%', maxHeight: '50vh' }}
                                // onChange={text_value}
                                defaultValue={info_data?.content}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.disabledLabel, // 使用你的CSS模块类
                                    },
                                }}
                            />
                        </div>


                        <div style={{ padding: '20px 0' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">状态</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={!_.isNil(info_data?.type) ? info_data?.type : 'CUSTOMER'}
                                    label="Age"
                                    onChange={InfoTypeChange}
                                >
                                    {
                                        type_lists.map((item, index) => <MenuItem value={item.value}>{item.label}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ padding: '20px 0', paddingTop: 0, display: 'flex' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" shrink={selectedCustomerId !== ''}>绑定客户</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedCustomerId}
                                    label="绑定客户"
                                    onChange={handleChange}
                                    MenuProps={MenuProps}
                                >
                                    <div style={{ paddingBottom: 10, }}>
                                        <Paper component="form" className={classes.root}>
                                            <IconButton className={classes.iconButton} aria-label="menu">
                                                <SearchIcon style={{ fontSize: 32 }} />
                                            </IconButton>

                                            <InputBase
                                                className={classes.input}
                                                placeholder=""
                                                value={searchValue}
                                                onChange={searchChange}
                                                onKeyDown={Enter_searchChange}
                                            />
                                            <InputBase
                                                className={classes.input}
                                                placeholder="隐藏框，防止按回车刷新页面"
                                                value={searchValue}
                                                onKeyDown={Enter_searchChange}
                                                onChange={searchChange}
                                                style={{ display: 'none' }}
                                            />
                                            <Button className={classes.searchClickButton}
                                                onClick={handleSearch} disableElevation style={{ background: base_setting?.primary_color }}>
                                                搜索
                                            </Button>
                                        </Paper>
                                    </div>
                                    {crm_user_lists?.map((item, index) => (
                                        <MenuItem
                                            key={item?._id}
                                            value={item?._id}
                                        >
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div style={{ padding: '0 20px', display: 'flex', alignItems: 'center' }}>or</div>
                            <div>
                                <Button variant="contained" color='success' onClick={creation_user} style={{ background: base_setting?.primary_color, width: 100, height: '100%' }}>新建客户</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            <Button variant="contained" color='success' onClick={update_info} style={{ background: base_setting?.primary_color, width: 150 }}>修改</Button>
                        </div>
                        <div>
                            <Button variant="contained" color='success' onClick={delete_info} style={{ background: 'red', width: 150 }}>删除</Button>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"关联"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            您确认要把该录音删除吗？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>取消</Button>
                        <Button onClick={affirm} autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={create_user_dialog}
                    onClose={userhandleClose}
                    fullWidth={true}
                    maxWidth={maxDialogWidth}
                >
                    <DialogTitle id="alert-dialog-title">
                        <div style={{ textAlign: 'center' }}>{"新建客户"}</div>
                    </DialogTitle>
                    <DialogContent>
                        <div style={{ padding: '20px 0' }}>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="名称"
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="工作头衔"
                                    variant="outlined"
                                    value={job_title}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="公司名"
                                    variant="outlined"
                                    value={company_info}
                                    onChange={(e) => setCompanyInfo(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="公司规模"
                                    variant="outlined"
                                    value={company_size}
                                    onChange={(e) => setCompanySize(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="行业"
                                    variant="outlined"
                                    value={industry}
                                    onChange={(e) => setIndustry(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="地址"
                                    variant="outlined"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="电话"
                                    variant="outlined"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="微信id"
                                    variant="outlined"
                                    value={wechat}
                                    onChange={(e) => setWechat(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="内部笔记"
                                    variant="outlined"
                                    value={internal_notes}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setInternalNotes(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="介绍"
                                    variant="outlined"
                                    value={brief}
                                    multiline
                                    rows={4}
                                    onChange={(e) => setBrief(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="喜好"
                                    variant="outlined"
                                    value={preference}
                                    onChange={(e) => setPreference(e.target.value)}
                                    inputProps={{ style: { fontSize: 13 } }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">重要性程度</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={importance_level}
                                        label="Age"
                                        style={{ width: '100%' }}
                                        onChange={(e) => setImportanceLevel(e.target.value)}
                                    >
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={7}>7</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={9}>9</MenuItem>
                                        <MenuItem value={10}>10</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div style={{ paddingBottom: 20 }}>
                                <FormControlLabel control={<Switch defaultChecked onChange={(e) => setIsManagerEditableOnly(e.target.checked)} checked={is_manager_editable_only} />} label="唯有管理员能修改" />
                            </div>
                            <div style={{ textAlign: 'left', width: '100%', display: 'flex' }}>
                                <Button variant="contained" style={{ width: '100%', height: 40, }} onClick={create_user}>创建</Button>
                            </div>
                        </div>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={handleClose}>取消</Button>
                        <Button onClick={affirm} autoFocus>
                            确认
                        </Button>
                    </DialogActions> */}
                </Dialog>
            </div>
            <RecordingDrawer ></RecordingDrawer>
        </div>
    )
}
export default EnlightenmentInfo