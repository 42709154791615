/**
 * Created on 6/23/21.
 * @author Xihao
 */

 import React from 'react';
 import { makeStyles } from '@mui/styles';
 import Paper from '@mui/material/Paper';
 import Chip from '@mui/material/Chip';
 import ButtonBase from '@mui/material/ButtonBase';
 import { useDispatch } from 'react-redux';
 import {agent_info} from '../../../actions/tax_accountant'
 
 const _ = require('micro-dash');
 
 
 const useStyles = makeStyles((theme) => ({
     root: {
         marginBottom: 26,
         
         width: '20%',
         marginInline:'2%',
         minHeight: 100,
         display: 'flex',
         flexDirection: 'column',
         border: '2px solid #3D1198',
         borderRadius:'25px',
         cursor: "pointer"
        //  backgroundColor:'#3D1198'
         // justifyContent: 'space-between',
     }
 }));
 
  
 export default function PreviewItem({id,avatar_url,full_name,other_information,tax_agent_type,working_age,official_rating}) {
     const classes = useStyles();
     const dispatch = useDispatch();
     return (
         <Paper className={classes.root}>
             <div container style={{paddingRight: 8,paddingTop: 10,paddingBottom: 10,}} onClick={()=>dispatch(agent_info(id))}>
                <div style={{display:'flex',width:'100%'}}>
                    <div>
                        {
                            _.isNil(avatar_url)?<img src='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png' style={{borderRadius:'50%',width:80,height:80,marginLeft:'-20px',marginTop:'-20px',background:'#fff'}} />:<img src={avatar_url} style={{borderRadius:'50%',width:80,height:80,marginLeft:'-20px',marginTop:'-20px',background:'#fff'}} />
                            
                        }
                    </div>
                    <div style={{width:'calc(100% - 60px)'}}>
                        <div style={{marginLeft:'20px',lineHeight:'40px',overflow:'hidden',whiteSpace: 'nowrap',textOverflow:'ellipsis'}}>{full_name}</div>
                        {
                            tax_agent_type===1?<div style={{fontSize:'14px',paddingLeft:'20px'}}>个人</div>:<div style={{fontSize:'14px',paddingLeft:'20px'}}>机构</div>
                        }
                    </div>
                </div>
                <div style={{overflow:'hidden',textOverflow:'ellipsis',display:'-webkit-box','-webkit-line-clamp':'2','-webkit-box-orient':'vertical',margin:'10px',fontSize:14}}>
                    {other_information}
                </div>
             </div>
         </Paper>
     );
 }