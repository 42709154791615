import React, {useEffect} from "react";
import {useDispatch,useSelector} from "react-redux";
import { Link ,Route,Switch, useHistory,useLocation,Redirect} from "react-router-dom";

import logo from '../../../static/logo/shui_engine.png'
import member_icon_selected from '../../../static/icon/member.png'
import pay_icon_selected from '../../../static/icon/pay.png'
import suggest_icon_selected from '../../../static/icon/suggest.png'
import my_questions_icon_selected from '../../../static/icon/my_questions.png'
import map_icon_selected from '../../../static/icon/map.png'

import member_icon from '../../../static/icon/member_selected.png'
import pay_icon from '../../../static/icon/pay_selected.png'
import suggest_icon from '../../../static/icon/suggest_selected.png'
import my_questions_icon from '../../../static/icon/my_questions_selected.png'
import map_icon from '../../../static/icon/map_selected.png'

import Member from './member';
import Suggest from './suggest';
import Payment from './payment';
import MyQuestions from './my_questions';
import UserMap from "./user_map";
import {web_qr_code,user_member_info,site} from '../../../actions/mine'
import {logout} from '../../../actions/user'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const _ = require('micro-dash');

function Index(){
    const [lefTab, setlefTab] = React.useState(0);
    const token = useSelector((state) => state.user.token);
    const avatar = useSelector((state) => state.user.avatar);
    const nick_name = useSelector((state) => state.user.nick_name);
    const user_id = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const location = useLocation();
    console.log(`locayion: ${location.pathname}`)
    const history = useHistory();


    useEffect(() => {
        // dispatch(web_qr_code({mini_type:1,coupon_uid:null,level:1}))
        switch(location.pathname){
            case "/mine/payment":
                setlefTab(1);
                break;
            case "/mine":
                setlefTab(0);
                break;
            case "/mine/suggest":
                setlefTab(3);
                break;
            case "/mine/my_questions":
                setlefTab(2);
                break;
            case "/mine/user_map":
                setlefTab(4);
                break;
            default:
                setlefTab(0);
        }
    }, []);
    // useEffect(() => {
    //     console.log('mine_index')
    //     dispatch(user_member_info(1))
    // }, []);

    useEffect(() => {
        console.log(`token is ${token}`)
        if(_.isNil(token)){
            return <Redirect to={'/'} />;
        }
    }, [token]);
    useEffect(()=>{
        dispatch(site())
    },[])

    useEffect(() => {
        switch(location.pathname){
            case "/mine/payment":
                setlefTab(1);
                break;
            case "/mine":
                setlefTab(0);
                break;
            case "/mine/suggest":
                setlefTab(3);
                break;
            case "/mine/my_questions":
                setlefTab(2);
                break;
            // case "/mine/user_map":
            //     setlefTab(4);
            //     break;
            default:
                setlefTab(0);
        }
    }, [location.pathname]);

    const drop_out_login=()=>{
        window.localStorage.clear()
        dispatch(logout())
        history.replace('/')
    }
    console.log(`token is ${token}`)
    if(_.isNil(token)){
        return <Redirect to={'/'} />;
    }
    return (
        <div style={{display:'flex'}}>
            <div style={{minHeight:'100vh',width:'15vw',position:'relative',minWidth:'150px'}}>
                <div style={{height:'calc(80% - 1px)',borderBottom:'1px solid rgba(0,0,0,0.1)'}}>
                    <Link to='/'>
                        <div style={{display:'flex',padding:20, cursor: "pointer",paddingRight:0,paddingTop:15,paddingBottom:15}}>
                            <div>
                                <img style={{width:30,height:30}} src={logo} alt='' />
                            </div>
                            <div style={{paddingLeft:10,lineHeight:'30px',fontSize:'15px'}}>博槐</div>
                        </div>
                    </Link>
                    <Link to='/mine'>
                        <div onClick={()=>setlefTab(0)} style={{cursor: "pointer",display:'flex',padding:'15px 20px',margin:'10px 0',background:lefTab===0?'#3D1198':''}}>
                            <div>
                                <img src={lefTab===0?member_icon:member_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} />
                            </div>
                            <div style={{paddingLeft:10,fontSize:14,color:lefTab===0?'#fff':''}}>会员</div>
                        </div>
                    </Link>
                    <Link to='/mine/payment'>
                        <div onClick={()=>setlefTab(1)} style={{cursor: "pointer",display:'flex',padding:'15px 20px',margin:'10px 0',background:lefTab===1?'#3D1198':''}}>
                            <div>
                                <img src={lefTab===1?pay_icon:pay_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} />
                            </div>
                            <div style={{paddingLeft:10,fontSize:14,color:lefTab===1?'#fff':''}}>支付</div>
                        </div>
                    </Link>
                    <Link to='/mine/my_questions'>
                    <div onClick={()=>setlefTab(2)} style={{cursor: "pointer",display:'flex',padding:'15px 20px',margin:'10px 0',background:lefTab===2?'#3D1198':''}}>
                            <div>
                                <img src={lefTab===2?my_questions_icon:my_questions_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} />
                            </div>
                            <div style={{paddingLeft:10,fontSize:14,color:lefTab===2?'#fff':''}}>我的问题</div>
                        </div>
                    </Link>
                    <Link to='/mine/suggest'>
                        <div onClick={()=>setlefTab(3)} style={{cursor: "pointer",display:'flex',padding:'15px 20px',margin:'10px 0',background:lefTab===3?'#3D1198':''}}>
                            <div>
                                <img src={lefTab===3?suggest_icon:suggest_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} />
                            </div>
                            <div style={{paddingLeft:10,fontSize:14,color:lefTab===3?'#fff':''}}>产品建议</div>
                        </div>
                    </Link>
                    {/* <Link to='/mine/user_map'>
                        <div onClick={()=>setlefTab(4)} style={{cursor: "pointer",display:'flex',padding:'15px 20px',margin:'10px 0',background:lefTab===4?'#3D1198':''}}>
                            <div>
                                <img src={lefTab===4?map_icon:map_icon_selected} alt='' style={{width:20,height:20,paddingTop:2}} />
                            </div>
                            <div style={{paddingLeft:10,fontSize:14,color:lefTab===4?'#fff':''}}>周边用户</div>
                        </div>
                    </Link> */}
                </div>
                <div style={{width:'100%',textAlign:'right',height:'20%'}}>
                    <div>
                        <div style={{display:'flex',justifyContent:'center',padding:'20px'}}>
                            <div>
                                {
                                    avatar===''?<AccountCircleIcon sx={{ fontSize: 30,color: 'rgba(0, 0, 0, 0.26)'}} color="disabled" />:<img style={{width:30,height:30,borderRadius:'50%'}} src={avatar} alt='' />
                                }
                            </div>
                            {
                                _.isNil(nick_name) || nick_name === "null"?<div style={{lineHeight:'30px',paddingLeft:'10px', fontSize: 15}}>{'用户00'+user_id}</div>:<div style={{lineHeight:'30px',paddingLeft:'10px', fontSize: 13}}>{nick_name}</div>
                            }
                        </div>
                        <div onClick={drop_out_login} style={{padding:'8px 10px',background:'#3D1198',color:'#fff',borderRadius:8,cursor: "pointer",width:'50%',margin:'0 auto',textAlign:'center',fontSize:'13px'}}>退出登录</div>
                    </div>
                </div>
            </div>
            <div style={{width:'85vw'}}>
                {/* 会员信息 */}
                <div style={{padding:'40px 50px',background:'rgba(204,153,204,0.1)',minHeight:'calc(100vh - 80px)'}}>
                    <Switch>
                        <Route exact path="/mine" component={Member} />
                        <Route path="/mine/payment" component={Payment} />
                        <Route path="/mine/my_questions" component={MyQuestions} />
                        <Route path="/mine/suggest" component={Suggest} />
                        {/* <Route path="/mine/user_map" component={UserMap} /> */}
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default Index;