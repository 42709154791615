import React, { useEffect } from "react";
import { MODEL_COLOR } from '../../../configs/constants'
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory} from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import shui_engine from '../../../static/logo/logo_2.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import user_img from '../../../static/image/user_img.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import { get_user_linked } from '../../../actions/admin'
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from "../../headHavigation/tabble_lists";
import { link_user_agent_phone, get_employees } from '../../../actions/user'
import { message } from 'antd';

const _ = require('micro-dash');


function People() {
    const dispatch = useDispatch();
    const tax_agent_list = useSelector((state) => state.user.tax_agent_list)
    const manager_lists = useSelector((state) => state.user.manager_lists)
    const staff_lists = useSelector((state) => state.user.staff_lists)
    const token = useSelector((state) => state.user.token);
    const base_setting=useSelector((state) => state.user.base_setting);
    const org_id=useSelector((state) => state.user.org_id)
    const history=useHistory()
    const [company, setCompany] = React.useState('');
    const [staff_id, setStaff] = React.useState('');
    const [super_id, setSuperId] = React.useState('');
    const super_link=()=>{
        if (super_id !== '') {
            setDialog(3)
            setOpen(true)
        } else {
            message.error('用户手机号不能为空')
        }
    }
    const staff_link = () => {
        if (staff_id !== '') {
            setDialog(2)
            setOpen(true)
        } else {
            message.error('用户手机号不能为空')
        }
    }
    const [manager_id, setManager] = React.useState('');
    const manager_link = () => {
        console.log(manager_id)
        if (manager_id !== '') {
            setDialog(1)
            setOpen(true)
        } else {
            message.error('用户手机号不能为空')
        }
    }
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])
    useEffect(() => {
        dispatch(get_employees())
    }, []);


    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [dialog_start, setDialog] = React.useState(0);

    const [isCheckingValue, setIsCheckingValue] = React.useState('');
    useEffect(() => {
        if (isCheckingValue !== '') {
            message.success(isCheckingValue)
            setIsCheckingValue('')
        }
    }, [isCheckingValue])
    const affirm = () => {
        setOpen(false);
        setIsCheckingValue('')
        if (dialog_start === 1) {
            if (_.isNil(manager_id)) {
                message.error('请填写正确手机号')
            } else {
                // 170 管理员  100 员工
                dispatch(link_user_agent_phone({ phone_number: manager_id, role: 170 }, (v) => setIsCheckingValue(v)))
            }
        } else if (dialog_start === 2) {
            if (_.isNil(staff_id)) {
                message.error('请填写正确手机号')
            } else {
                dispatch(link_user_agent_phone({ phone_number: staff_id, role: 100 }, (v) => setIsCheckingValue(v)))
            }
        }else if(dialog_start === 3){
            if (_.isNil(super_id)) {
                message.error('请填写正确手机号')
            } else {
                dispatch(link_user_agent_phone({ phone_number: super_id, role: 199 }, (v) => setIsCheckingValue(v)))
            }
        }
    }
    return (
        <div>
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex',width:'100%' }}>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 54px)' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', padding: '8px 0px', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minWidth: 220 }}>
                            <TabbleLists type='4'></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(90% - 220px)',display: 'flex',padding: '3% 5%', justifyContent: "space-between"}}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex',width: '100%' }}>
                        {
                            _.isNil(org_id)?<div style={{marginRight: '5%',maxWidth:'30%'}}>
                            <div style={{ textAlign: 'center', background: '#fff', padding: 10, fontWeight: 'bold', fontSize: 18 }}>超级管理员</div>
                            <div style={{textAlign: 'center',fontSize:12,color:'#6b6666'}}>(享有最高权限，可以对网站进行设置)</div>
                            <div style={{ padding: 10, display: 'flex', background: '#fff' }}>
                                <div style={{ marginBottom: 10 }}>
                                    <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户手机号" variant="outlined" size="small" value={super_id} onChange={(event) => { setSuperId(event.target.value) }} />
                                </div>
                                <div style={{ paddingLeft: 10 }}>
                                    <Button variant="contained" style={{ background: base_setting?.primary_color }} size="medium" onClick={super_link}>关联</Button>
                                </div>
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>超级管理员</TableCell>
                                            <TableCell align="center">手机号</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            staff_lists?.length > 0 ? staff_lists?.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        用户
                                                    </TableCell>
                                                    <TableCell align="center">{row.phone_number}</TableCell>
                                                </TableRow>
                                            )) : ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>:""
                        }
                        
                            <div style={{ marginRight: '5%',maxWidth:_.isNil(org_id)?'30%':'50%' }}>
                                <div style={{ textAlign: 'center', background: '#fff', padding: 10, fontWeight: 'bold', fontSize: 18 }}>管理员</div>
                                <div style={{textAlign: 'center',fontSize:12,color:'#6b6666'}}>(可以使用CRM功能)</div>
                                <div style={{ padding: 10, display: 'flex', background: '#fff' }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户手机号" variant="outlined" size="small" value={manager_id} onChange={(event) => { setManager(event.target.value) }} />
                                    </div>
                                    <div style={{ paddingLeft: 10 }}>
                                        <Button variant="contained" style={{ background: base_setting?.primary_color }} size="medium" onClick={manager_link}>关联</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>管理员</TableCell>
                                                <TableCell align="center">手机号</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                manager_lists?.length > 0 ? manager_lists.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            用户
                                                        </TableCell>
                                                        <TableCell align="center">{row.phone_number}</TableCell>
                                                    </TableRow>
                                                )) : ''
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div style={{maxWidth:_.isNil(org_id)?'30%':'50%'}}>
                                <div style={{ textAlign: 'center', background: '#fff', padding: 10, fontWeight: 'bold', fontSize: 18 }}>员工</div>
                                <div style={{textAlign: 'center',fontSize:12,color:'#6b6666'}}>(普通员工账号)</div>
                                <div style={{ padding: 10, display: 'flex', background: '#fff' }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户手机号" variant="outlined" size="small" value={staff_id} onChange={(event) => { setStaff(event.target.value) }} />
                                    </div>
                                    <div style={{ paddingLeft: 10 }}>
                                        <Button variant="contained" style={{ background: base_setting?.primary_color }} size="medium" onClick={staff_link}>关联</Button>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>员工</TableCell>
                                                <TableCell align="center">手机号</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                staff_lists?.length > 0 ? staff_lists?.map((row, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            用户
                                                        </TableCell>
                                                        <TableCell align="center">{row.phone_number}</TableCell>
                                                    </TableRow>
                                                )) : ''
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            
                        </div>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"关联"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {dialog_start === 1 ? '您确认把手机号为' + manager_id + '的用户关联为管理员吗？' : "您确认把手机号为" + staff_id + '的用户关联为员工吗？'}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>取消</Button>
                                <Button onClick={affirm} autoFocus>
                                    确认
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default People