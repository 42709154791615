
import React from 'react';
import { makeStyles } from '@mui/styles';
import {useDispatch, useSelector} from 'react-redux';

import ArticleIcon from '@mui/icons-material/Article';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Rating from '@mui/material/Rating';
import QRCode from 'qrcode.react';
import qr_tax_lists from '../../../static/image/qr_tax.png'

const _ = require('micro-dash');
const useStyles = makeStyles((theme) => ({
    root: {
        paddingInline: '3.5%',
        width: '92%',
        minHeight: '100%',
        // borderLeft: '1px solid #E6E5EB',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderLeft: '8px solid #3D1198',
    },
}));

export default function DrawerContent() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const tax_accountant_info = useSelector((state) => state.tax_accountant.tax_accountant_info);
    return (
        <div className={classes.root}>
            <div style={{display:'flex',width:'100%'}}>
                <div>
                    {
                         _.isNil(tax_accountant_info.avatar_url)?<img style={{width:200,height:200,borderRadius:'50%'}} src='https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/user_img.png' alt='' />:<img src={tax_accountant_info.avatar_url} style={{width:200,height:200,borderRadius:'50%'}} alt='' />
                    }
                    
                </div>
                <div style={{fontSize:24,display: 'flex',justifyContent: 'center',flexDirection: 'column',paddingLeft: '5%'}}>
                    <div>{tax_accountant_info.full_name}</div>
                    <div style={{paddingTop: '8px',fontSize: '20px',color: 'rgba(0, 0, 0, 0.6)'}}>
                        {
                            tax_accountant_info.tax_agent_type===1?'个人':'机构'
                        }
                    </div>
                </div>
            </div>
            {
                tax_accountant_info.tax_agent_type===1?<div style={{display:'flex',paddingTop:'5%',width:'100%'}}>
                <div>
                    <ArticleIcon color='rgba(0, 0, 0, 0.6)'/>
                </div>
                <div style={{paddingLeft:'20px'}}>从业年龄：{tax_accountant_info.working_age}</div>
            </div>:<div style={{display:'flex',paddingTop:'5%',width:'100%'}}>
                <div>
                    <ArticleIcon color='rgba(0, 0, 0, 0.6)'/>
                </div>
                <div style={{paddingLeft:'20px',display:'flex'}}>官方评级：<div><Rating name="read-only" value={tax_accountant_info.official_rating} readOnly /></div></div>
            </div>
            }
            
            <div style={{display:'flex',width:'100%',paddingTop:'5%'}}>
                <div>
                    <FolderSharedIcon/>
                </div>
                <div style={{display:'flex',paddingLeft:'20px'}} >
                    <div style={{width:60}}>简介：</div>
                    <div style={{width:'calc(100% - 60px)'}}>{tax_accountant_info.other_information}</div>
                </div>
            </div>
            <div style={{paddingTop:'5%',textAlign:'center',margin:'0 auto'}}>
                <img src={qr_tax_lists} alt='' style={{width:'200px',height:'200px'}}/>
            </div>
        </div>
    );
}