function footer(){
    // console.log('location footer',window.location)
    const host_name=window.location.host
    const host_type=host_name.indexOf("huainor.com")
    return (
        <div style={{display:'flex',justifyContent:'space-between',padding:'10px 20px'}}>
            <div style={{   lineHeight:'50px' }}>
                <div style={{textAlign:'left',color:'grey',fontSize:12,display:'flex'}}>
                    <a href="https://beian.miit.gov.cn/" style={{ marginRight: 10,cursor: "pointer",width:'150px',color:'grey'}} >
                        {
                            host_type==-1?"京ICP备2021025663号-2":"京ICP备2021025663号-1"
                        }
                    </a>
                    <div>博槐（北京）互联网科技有限责任公司</div>
                </div>
                <div style={{textAlign:'left',color:'grey',fontSize:12,display:'flex'}}>
                    <div style={{marginRight:10,width:'150px'}}>电话：18910186883</div>
                    <div>邮箱：zhuxihao@huainor.com</div>
                </div>
            </div>
            <div>
                <div>
                    <img src={'https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/web_content/web_content.png?time='+parseInt((new Date().getTime() / 1000).toString())} style={{ width: 80 }} alt='' />
                </div>
                <div style={{color:'grey',fontSize:12,textAlign:'center'}}>加入我们</div>
            </div>
        </div>
    )   
}
export default footer;