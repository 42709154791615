/**
 * Created on 8/5/20.
 * @author Xihao
 */

import {
    GET_PROFILE_INFO_SUCCESS,
} from '../actions/types';

const _ = require('micro-dash');
const STORAGE_TOPLEVELUSERTYPE = 'topLevelUserType';


const initialState = {
    user: null,
    isLoadingProfile: true,
    error: null,
    topLevelUserType: null,
    userType: null,
    listings: null,
    isLoadingListings: true,
    avatarLocalUrl: null,
    userNonSelf: null,
    isLoadingProfileNonSelf: true,
    paymentProfile: null,
    paperInfo: null,
};


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE_INFO_SUCCESS:
            console.log(`in profile reducer, ${state.userType}, ${state.topLevelUserType}`);
            if(state.userType == 3){
                return {...state, user: action.user,
                    isLoadingProfile: false,
                    userType: _.get(action.user, ['user_type'], null),
                };
            }
            else{
                localStorage.setItem(STORAGE_TOPLEVELUSERTYPE, _.get(action.user, ['top_level_user_type_name'], null));
                return {...state, user: action.user,
                    isLoadingProfile: false,
                    topLevelUserType: _.get(action.user, ['top_level_user_type_name'], null),
                    userType: _.get(action.user, ['user_type'], null),
                };
            }
        default:
            return state;
    }
};