import React, {useEffect,useCallback} from "react";
import {useDispatch,useSelector} from "react-redux";
import {WECHAT_LOGIN_URL} from '../../../configs/constants'
import {Link} from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Countdown from 'react-countdown';
import {useHistory} from 'react-router-dom';
import {question_details,risk_details} from '../../../actions/forum'
import Button from '@mui/material/Button';
import Footer from "../../footer/Footer";
import HeadHavigation from "../../headHavigation/headHavigation";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        maxWidth: '100%',
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
        minHeight:'75vh'
    },
    searchClickButton:{
        backgroundColor:"#3D1198 !important",
        color: 'white !important',
        fontSize: '17px !important',
        paddingInline: '36px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#3D1198 !important',
        },
    },
}));
function InformDetails(e){
    const index=e.location.state.index
    console.log('index',index)
    const shui_inform_details=index
    console.log(shui_inform_details)
    const dispatch = useDispatch();
    // const type=shui_inform_details.node.action.split('/')[0]==='question'
    // console.log('type',type)
    const classes = useStyles();
    return (
        <div>
            <HeadHavigation provenance={0} />
            <div className={classes.root}>
                {/* <Link to='/inform/details'> */}
                    <div className={classes.lists}>
                        <div style={{fontWeight:'bold',fontSize:'20px'}}>{shui_inform_details.node.title}</div>
                        <div style={{paddingTop:'10px'}}>{shui_inform_details.node.content}</div>
                        <div style={{marginTop:'5%'}}>
                            {
                                (()=>{
                                    if(shui_inform_details.node.action.split('/')[0]==='question'){
                                        return <Link to='/question'>
                                                    <Button className={classes.searchClickButton} disableElevation onClick={() => dispatch(question_details({ from: 0, id: shui_inform_details.node.action.split('/')[1] }))}>查看详情</Button>
                                                </Link>
                                    }else if(shui_inform_details.node.action.split('/')[0]==='risk'){
                                        return <Link to='/riskAssessment'>
                                                    <Button className={classes.searchClickButton} disableElevation onClick={() => dispatch(risk_details({ id: shui_inform_details.node.action.split('/')[1] }))}>查看详情</Button>
                                                </Link>
                                    }
                                })()
                            }
                            {/* <Button className={classes.searchClickButton} disableElevation>查看详情</Button> */}
                        </div>
                    </div>
                {/* </Link> */}
                
            </div>
            <div style={{padding:'0 12%'}}>
                <Footer />
            </div>
        </div>
    )
}
export default  InformDetails