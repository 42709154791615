
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as mapUtils from '../../../utils/TMap/index'
import userimg from '../../../static/icon/10.png'

function UserMap() {
    const mapId = useRef() //  地图实例

    // useEffect(() => {
    //     mapUtils
    //         .TMapGL() // 开始加载腾讯地图gl文件
    //         .then(() => { // 完成加载后，开始渲染地图
    //             let center = new window.TMap.LatLng(39.984120, 116.307484)
    //             let map = new window.TMap.Map(document.querySelector('#container'), {
    //                 center,
    //                 zoom: 16.2,
    //                 baseMap: [
    //                     { type: 'vector' }, //设置矢量底图
    //                     // { type: 'traffic' }, //设置路况底图
    //                 ],
    //             })
    //             mapId.current = map //存储当前的map
    //         })
    // }, [])

    // //创建点聚合对象
    // let markerCluster = new window.TMap.MarkerCluster({
    //     id: 'cluster', //图层id
    //     map: mapId.current,       //设置点聚合显示在哪个map对象中（创建map的段落省略）
    //     enableDefaultStyle: true,   //使用默认样式
    //     minimumClusterSize: 2,  //最小聚合点数：2个
    //     geometries: [
    //         { position: new window.TMap.LatLng(39.982829, 116.306934), id: 1 },
    //         { position: new window.TMap.LatLng(39.989011, 116.323586), id: 2 },
    //         { position: new window.TMap.LatLng(39.978751, 116.313286), id: 3 },
    //         { position: new window.TMap.LatLng(39.980593, 116.304359), id: 4 },
    //         //....将您所有要打到图中的坐标点传入进来
    //     ],
    //     zoomOnClick: true,  //点击聚合数字放大展开
    //     gridSize: 60,       //聚合算法的可聚合距离，即距离小于该值的点会聚合至一起，默认为60，以像素为单位
    //     averageCenter: false, //每个聚和簇的中心是否应该是聚类中所有标记的平均值
    //     maxZoom: 16 //采用聚合策略的最大缩放级别，若地图缩放级别大于该值，则不进行聚合，标点将全部被展开
    // });
    // //监听回调函数（非匿名函数）
    // var clickHandler = function (evt) {
    //     console.log(evt)
    //     // alert(evt.geometry.properties.title)
    // }
    // //监听marker点击事件
    // markerCluster.on("click", clickHandler)
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div id="container" style={{ width: '100%', height: '100%' }}>

            </div>
            <div>
                <div style={{ textAlign: 'center', padding: '10px 0' }}>
                    <text style={{ color: 'rgb(61, 17, 152)', fontSize: 14 }}>请点击地图中您需要发送的用户标点，输入内容后点击发送推荐免费方案</text>
                </div>
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div style={{ width: '40%' ,marginRight:'5%'}}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="邮箱"
                                multiline
                                rows={4}
                                style={{ width: '100%' }}
                                defaultValue=""
                            />
                        </div>
                        <div style={{ textAlign: 'center', padding: '10px 0' }}>
                            <Button variant="contained">发送邮件</Button>
                        </div>
                    </div>
                    <div style={{ width: '40%' }}>
                        <div style={{ width: '100%' }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="短信"
                                multiline
                                rows={4}
                                style={{ width: '100%' }}
                                defaultValue=""
                            />
                        </div>
                        <div style={{ textAlign: 'center', padding: '10px 0' }}>
                            <Button variant="contained">发送短信</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserMap;
