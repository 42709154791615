import {SETSUGGEST,PRICES,WEBQRCODE,MEMBERINFOS,CHANGE_PAY_PRODUCT,GETDISCOUNT,USEDDISCOUNT,LLM_PRICE_LISTS,LLM_QR_CODE,LLM_MEMBERSHIP_INFO} from './types';
import {MIDDLE_EARTH_URL, TAX_NAME_MAP, INDUSTRY_NAME_MAP,KY_URL} from '../configs/constants'
import fetchJsonp from 'fetch-jsonp'
import axios from 'axios';
const _ = require('micro-dash');
const $http=axios.create();
$http.interceptors.request.use(config => {
    // config.headers.Authorization='token ' + localStorage.getItem('token')
    config.headers.Authorization='token jarod1' 
    return config
})

export function suggest(is_suggest,error_value){
    return {
        type: SETSUGGEST,
        is_suggest,
        error_value
    }
}
export function prices(shui_price){
    return {
        type:PRICES,
        shui_price
    }
}
export function qr_code(code_url,qr_code_uid){
    return {
        type:WEBQRCODE,
        code_url,
        qr_code_uid
    }
}
export function user_member_infos(data,pay_history){
    return {
        type:MEMBERINFOS,
        data,
        pay_history
    }
}
export function change_pay_product(pay_product){
    return {
        type:CHANGE_PAY_PRODUCT,
        pay_product
    }
}
export function discount_measure(discount,message,discount_value){
    return {
        type:GETDISCOUNT,
        discount,
        message,
        discount_value
    }
}
export function used_discount(){
    return {
        type:USEDDISCOUNT
    }
}

// llm
export function llm_price_lists(data){
    return {
        type:LLM_PRICE_LISTS,
        data
    }
}
export function llm_qr_code(data){
    return {
        type:LLM_QR_CODE,
        data
    }
}
export function llm_membership_info(data){
    return {
        type:LLM_MEMBERSHIP_INFO,
        data
    }
}

export function set_suggest({product_value,platform_value,value}){
    let suggest_value=''
    if(parseInt(platform_value)===0){
        suggest_value='小程序：'+value
    }else if(parseInt(platform_value)===1){
        suggest_value='网站：'+value
    }
    // console.log(suggest_value)
    return (dispatch, getState) => {
        axios.post(`${MIDDLE_EARTH_URL}/user/suggestion/`,{
            suggestion:suggest_value,
            product_type:product_value
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(
            response=>{
                if(response.data.code===200){
                    dispatch(suggest(true))
                }else{
                    dispatch(suggest(false,response.data.message))
                }
            }
        )
    }
}

export function price_list(){
    return (dispatch,getState)=>{
        axios.get(`${MIDDLE_EARTH_URL}/payment/get-payment-price?mini_type=1`,{

        }).then(response=>{
            dispatch(prices(response.data.data))
        })
    }
}
export function web_qr_code({level,coupon_uid,mini_type}){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/payment/wechat-web-payment/`,{
            level:level,
            coupon_uid:coupon_uid,
            mini_type:mini_type
        },{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            // console.log('web_qr_code',res)
            if(res.data.code===200){
                dispatch(qr_code(res.data.data.code_url,res.data.data.uid))
            }else{
                dispatch(discount_measure(null,res.data.message,''))
            }
        })
    }
}
export function user_member_info(mini_type){
    console.log('user_member_info',parseInt(mini_type))
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/user/member-info/`,{
                
            },{
                headers:{
                    'content-type': 'application/json', // 默认值
                    'Authorization':'token '+localStorage.getItem('token')
                }
            }).then(res=>{
                if(parseInt(mini_type)===1){
                    $http.get(`${MIDDLE_EARTH_URL}/payment/get-user-charge-record`,{

                    },{
                        headers:{
                            'content-type': 'application/json', // 默认值
                            'Authorization':'token '+localStorage.getItem('token')
                        }
                    }).then(rs=>{
                        console.log('shui支付历史',rs)
                        dispatch(user_member_infos(JSON.stringify(res.data.data),rs.data.data.user_charge_record))
                    })
                }
            })
    }
}
export function discounts(data){
    return (dispatch,getState)=>{
        $http.get(`${MIDDLE_EARTH_URL}/payment/get-coupon?uid=`+data.coupon_uid).then(res=>{
            console.log(res)
            if(res.data.code===200){
                dispatch(discount_measure(res.data.data.discount,'领取成功',data.coupon_uid))
                axios.post(`${MIDDLE_EARTH_URL}/payment/wechat-web-payment/`,{
                    level:data.level,
                    coupon_uid:data.coupon_uid,
                    mini_type:data.mini_type
                },{
                    headers:{
                        'content-type': 'application/json', // 默认值
                        'Authorization':'token '+localStorage.getItem('token')
                    }
                }).then(response=>{
                    console.log(response)
                    if(!_.isNil(response.data.data.is_free)){
                        if(response.data.data.is_free){
                            dispatch(user_member_info(data.mini_type))
                        }
                    }else{
                        dispatch(qr_code(response.data.data.code_url,response.data.data.uid))
                    }
                })
            }else{
                dispatch(discount_measure(null,res.data.message,''))
            }
        })
    }
}


export function site(){
    return (dispatch,getState)=>{
        // fetchJsonp('https://apis.map.qq.com/ws/location/v1/ip?ip=175.0.53.90&key=OKWBZ-XNM3X-JOP4H-7GIPM-UW4GO-W4BDK&sig=ab1OntRZJbrpgUGlyTHql3pZySIkPCJ',
        axios.get(`${MIDDLE_EARTH_URL}/user/user-locayion?ip=223.89.86.204`,
        // axios.get('http://81.70.0.148:3000/user/user-locayion?ip=175.0.53.90',
        {},{
            
        }).then(res=>{
            console.log('地址转经纬度',res)
        })
    }
}

// llm
export function llm_membership(data){
    return (dispatch,getState)=>{
        $http.get(`${KY_URL}/api/user/llm/llm-membership-type/`).then(res=>{
            console.log(res)
            dispatch(llm_price_lists(res.data.data))
        })
    }
}
export function get_llm_qr_code(data){
    return (dispatch,getState)=>{
        axios.post(`${KY_URL}/api/user/llm/llm-membership-recharge/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log('web_qr_code',res)
            if(res.data.code===200){
                dispatch(llm_qr_code(res.data.data.code_url))
            }
        })
    }
}
export function llm_member_info(data){
    return (dispatch,getState)=>{
        $http.get(`${KY_URL}/api/user/llm/llm-membership-info/`).then(res=>{
            console.log(res)
            dispatch(llm_membership_info(res.data.data))
        })
    }
}

export function consume_question(data){
    return (dispatch,getState)=>{
        axios.post(`${KY_URL}/api/user/llm/consume-question-count/`,data,{
            headers:{
                'content-type': 'application/json', // 默认值
                'Authorization':'token '+localStorage.getItem('token')
            }
        }).then(res=>{
            console.log('consume_question',res)
            dispatch(llm_member_info())
        })
    }
}