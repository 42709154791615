import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory, useLocation, Redirect } from "react-router-dom";
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {get_user_linked,snack_open,admin_loading,link_user_tax_agent} from '../../../actions/admin'

const _ = require('micro-dash');

function TaxAgent() {
    console.log('TaxAgent')
    const dispatch = useDispatch();
    const tax_agent_list=useSelector((state) => state.user.tax_agent_list)
    const salesman_lists=useSelector((state) => state.admin.salesman_lists)
    const staff_lists=useSelector((state) => state.admin.staff_lists)
    const [company,setCompany]=React.useState('');
    const [market_id,setMarket]=React.useState('');
    const handleChange=()=>{
        dispatch(get_user_linked(company))
    }
    const market_link=()=>{
        if(market_id!==''){
            setDialog(0)
            setOpen(true)
            // dispatch(staff_lists(company,market_id,0))
        }else{
            alert('用户id不能为空')
        }
    }
    const [staff_id,setStaff]=React.useState('');
    const staff_link=()=>{
        if(staff_id!==''){
            setDialog(2)
            setOpen(true)
            // dispatch(staff_lists(company,staff_id,2))
        }else{
            alert('用户id不能为空')
        }
    }
    const [manager_id,setManager]=React.useState('');
    const manager_link=()=>{
        if(staff_id!==''){
            setDialog(1)
            setOpen(true)
            // dispatch(staff_lists(company,staff_id,2))
        }else{
            alert('用户id不能为空')
        }
    }

    useEffect(() => {
        if(tax_agent_list!=null){
            if (tax_agent_list.length > 0) {
                setCompany(tax_agent_list[0].tax_agent_id);
                dispatch(get_user_linked(tax_agent_list[0].tax_agent_id))
            }
        }
        
    }, [dispatch, tax_agent_list]);    
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [state, setState] = React.useState({
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal } = state;
    const loading_open = useSelector((state) => state.admin.loading_open);
    const is_relevancy = useSelector((state) => state.admin.is_relevancy);
    const snack_opens = useSelector((state) => state.admin.snack_open);
    const [dialog_start, setDialog] = React.useState(0);
    
    const alert_handleClose = () => {
        dispatch(snack_open())
    }
    
    const affirm=()=>{
        setOpen(false);
        dispatch(admin_loading(true))
        if(dialog_start === 0){
            if(_.isNil(market_id)){
                alert('请填写正确id')
            }else{
                dispatch(link_user_tax_agent(company,market_id,0))
            }
        }else if(dialog_start === 1){
            if(_.isNil(manager_id)){
                alert('请填写正确id')
            }else{
                dispatch(link_user_tax_agent(company,manager_id,1))
            }
        }else if(dialog_start === 2){
            if(_.isNil(staff_id)){
                alert('请填写正确id')
            }else{
                dispatch(link_user_tax_agent(company,staff_id,2))
            }
        }
    }
    return (
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div style={{ width: '70%' }}>
                <div style={{ display: 'flex', paddingBottom: 20 }}>
                    {/* <div style={{display:'flex',alignItems:'center',fontSize:14}}>店铺：</div> */}
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">公司</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={company}
                                label="Age"
                                onChange={handleChange}
                            >
                                {
                                    tax_agent_list!=null?
                                    tax_agent_list.map((item, index) =>
                                        <MenuItem value={item.tax_agent_id} key={index}>{item.full_name}</MenuItem>
                                    ):''
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                <div style={{marginRight:'5%'}}>
                        <div style={{textAlign:'center',background:'#fff',padding:10,fontWeight:'bold',fontSize:18}}>店长</div>
                        <div style={{ padding: 10 ,display:'flex',background:'#fff'}}>
                            <div style={{ marginBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" size="small" value={manager_id} onChange={(event) => { setManager(event.target.value) }} />
                            </div>
                            <div style={{paddingLeft:10}}>
                                <Button variant="contained" style={{ background: '#3D1198' }} size="medium" onClick={manager_link}>关联</Button>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>店长</TableCell>
                                        {/* <TableCell align="center">手机号</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        salesman_lists.map((row) => (
                                            <TableRow
                                                key={row.user_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                用户id{row.user_id}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div style={{marginRight:'5%'}}>
                        <div style={{textAlign:'center',background:'#fff',padding:10,fontWeight:'bold',fontSize:18}}>销售员</div>
                        <div style={{ padding: 10 ,display:'flex',background:'#fff'}}>
                            <div style={{ marginBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" size="small" value={market_id} onChange={(event) => { setMarket(event.target.value) }} />
                            </div>
                            <div style={{paddingLeft:10}}>
                                <Button variant="contained" style={{ background: '#3D1198' }} size="medium" onClick={market_link}>关联</Button>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>销售员</TableCell>
                                        {/* <TableCell align="center">手机号</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        salesman_lists.map((row) => (
                                            <TableRow
                                                key={row.user_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                用户id{row.user_id}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div>
                        <div style={{textAlign:'center',background:'#fff',padding:10,fontWeight:'bold',fontSize:18}}>员工</div>
                        <div style={{ padding: 10 ,display:'flex',background:'#fff'}}>
                            <div style={{ marginBottom: 10 }}>
                                <TextField id="outlined-basic" style={{ width: '100%' }} label="关联用户id" variant="outlined" size="small" value={staff_id} onChange={(event) => { setStaff(event.target.value) }} />
                            </div>
                            <div style={{paddingLeft:10}}>
                                <Button variant="contained" style={{ background: '#3D1198' }} size="medium" onClick={staff_link}>关联</Button>
                            </div>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 350 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>员工</TableCell>
                                        {/* <TableCell align="center">手机号</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        staff_lists.map((row) => (
                                            <TableRow
                                                key={row.user_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                用户id1000{row.user_id}
                                                </TableCell>
                                                <TableCell align="center">{row.phone}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"关联"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialog_start === 0 ? '您确认把id为' + market_id + '的用户关联为销售员吗？' : "您确认把id为" + staff_id + '的用户关联为员工吗？'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>取消</Button>
                        <Button onClick={affirm} autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading_open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Snackbar open={snack_opens} autoHideDuration={3000} onClose={alert_handleClose} anchorOrigin={{ vertical, horizontal }}>
                    <Alert icon={false} onClose={alert_handleClose} severity="success" sx={{ width: '100%' }}>
                        {is_relevancy ? '关联成功' : '关联失败'}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}
export default TaxAgent;