import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { multiple_upload } from '../../../actions/user'
import './test.css'
const _ = require('micro-dash');
const useStyles = makeStyles((theme) => ({
    'ant-upload-wrapper':{
        width:'100%'
    },
    upload_dragger:{
        '&.ant-upload-wrapper':{
            width:'100%'
        }
    }
}));

const type_lists = [
    {
        label: '客户拜访(公开存入CRM)',
        value: 'CUSTOMER'
    },
    {
        label: '会议记录(公开存入CRM)',
        value: 'MEETING'
    },
    {
        label: '个人（数据私有不公开）',
        value: 'PERSONAL'
    }
]

function UpdateEnlightenment(e) {
    const { Dragger } = Upload;
    const classes = useStyles();
    const history = useHistory()
    const base_setting = useSelector((state) => state.user.base_setting);
    const dispatch = useDispatch();
    const socket_id = useSelector((state) => state.user.socket_id);
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [data_lists, setDataLists] = React.useState({
        selectedFiles: [],
        names: [],
        file_type_lists: []
    });
    const generateUid = () => {
        return `uid_${new Date().getTime()}`;
    };
    const handleBeforeUpload = (file) => {
        console.log(file);
        const fileWithUid = {
            ...file,
            uid: generateUid(), // 为文件生成一个唯一的uid
            originFileObj: file
        };
        setSelectedFiles(prev => [...prev, fileWithUid]);
        setDataLists(prev => ({
            ...prev,
            selectedFiles: [...prev.selectedFiles, fileWithUid],
            names: [...prev.names, file.name],
            file_type_lists: [...prev.file_type_lists, 'CUSTOMER']
        }));
        return false; // 阻止自动上传
    };
    const props = {
        name: 'file',
        multiple: true,
        maxCount: 10,
        beforeUpload: handleBeforeUpload,
        fileList: selectedFiles
    };
    console.log(selectedFiles)
    const InfoTypeChange = (e, index) => {
        console.log(e, index)
        setDataLists(prev => {
            let tmp = JSON.parse(JSON.stringify(prev))
            tmp.file_type_lists[index] = e.target.value
            tmp.selectedFiles = selectedFiles
            return tmp
        })
    }
    const info_value = (e, index) => {
        console.log(e, index)
        setDataLists(prev => {
            console.log(prev)
            let tmp = JSON.parse(JSON.stringify(prev))
            tmp.names[index] = e.target.value
            tmp.selectedFiles = selectedFiles
            console.log(selectedFiles)
            return tmp
        })
    }
    const delete_info = (e) => {
        console.log(e)
        setDataLists(prev => {
            let tmp = JSON.parse(JSON.stringify(prev))
            let file = [...selectedFiles]
            file.splice(e, 1)
            tmp.names.splice(e, 1)
            tmp.file_type_lists.splice(e, 1)
            tmp.selectedFiles = [...file]
            selectedFiles.splice(e, 1)
            console.log(selectedFiles, tmp)
            return { ...prev, selectedFiles: tmp.selectedFiles, names: tmp.names, file_type_lists: tmp.file_type_lists }
        })
    }
    const [is_file,setIsFile]=React.useState('');
    const issue_question = () => {
        // let data=[...data_lists]
        console.log(data_lists)
        const formData = new FormData();
        data_lists.selectedFiles.forEach((file) => {
            formData.append('files', file.originFileObj)
        })
        // data_lists.names.forEach((file) => {
        //     formData.append('names', JSON.stringify(file))
        // })
        // data_lists.file_type_lists.forEach((file) => {
        //     formData.append('types', JSON.stringify(file))
        // })
        formData.append('socket_id', socket_id)
        formData.append('names', JSON.stringify(data_lists.names))
        formData.append('types', JSON.stringify(data_lists.file_type_lists))
        dispatch(multiple_upload(formData,(v)=>setIsFile(v)))
    }
    useEffect(()=>{
        if(is_file!=''&&!is_file){
            console.log(is_file)
            message.success('音频处理中')
            history.replace('/largemodel/enlightenment_lists')
        }
    },[is_file])
    return (
        <div >
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                            <TabbleLists></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ padding: '3% 5%', width: '70%' }}>
                    <div style={{ display: 'flex', fontSize: 16, alignItems: 'center' }}>
                        <Link to='/largemodel/enlightenment_lists' style={{ cursor: 'pointer' }}>录音列表</Link>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 5px', paddingTop: 4 }}><ArrowForwardIosIcon style={{ fontSize: 16,color:base_setting?.primary_color }}></ArrowForwardIosIcon></div>
                        <div style={{ cursor: 'pointer' }}>上传录音文件</div>
                    </div>
                    <div style={{ width: '70%', height: 160, padding: '40px 0',display:'flex',justifyContent:'center',margin:'0 auto' }}>
                        <Dragger {...props}
                            multiple
                            accept=".mp3,.m4a,.wav,.flv,.mp4,.wma,.3gp,.amr,.aac,.ogg-opus,.flac"
                            showUploadList={false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">
                                拖拽文件上传或点击上传文件
                            </p>
                            <p className="ant-upload-hint">
                                单次最多上传10个录音文件
                            </p>
                            <p className="ant-upload-hint">支持文件：.mp3, .m4a, .wav, .flv, .mp4, .wma, .3gp, .amr, .aac, .ogg-opus, .flac</p>
                        </Dragger>
                    </div>
                    <div style={{ maxHeight: '50vh', overflowY: 'auto',width:'70%',margin:'0 auto' }}>
                        {
                            data_lists.selectedFiles.map((item, index) => <div style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }} key={'data_lists' + index}>
                                <div style={{ padding: 10, width: '40%' }}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="录音文件名"
                                        style={{ width: '100%' }}
                                        onChange={(e) => info_value(e, index)}
                                        value={data_lists.names[index]}
                                        size="small"
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                    />
                                </div>

                                <div style={{ paddingRight: 10 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">类型</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={data_lists.file_type_lists[index]}
                                            // label="Age"
                                            size="small"
                                            onChange={(e => InfoTypeChange(e, index))}
                                        >
                                            {
                                                type_lists.map((item, index1) => <MenuItem value={item.value}>{item.label}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                <div>
                                    <Button variant="contained" color='success' onClick={() => delete_info(index)} style={{ background: 'red' }}>删除</Button>
                                </div>
                            </div>)
                        }

                    </div>
                    <div style={{paddingTop:10,display:'flex',justifyContent:'center'}}>
                        <div style={{paddingRight:20,width:'70%'}}>
                            <Button variant="contained" onClick={issue_question} style={{width:'100%',background:base_setting?.primary_color}}>确认上传</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UpdateEnlightenment