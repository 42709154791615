import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Markdown from 'react-markdown'
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import { message } from 'antd';
import { get_user_agent_list, create_agent, delete_user_agent } from '../../../actions/user'

const $http = axios.create();
$http.interceptors.request.use(config => {
    config.headers.Authorization = 'token ' + localStorage.getItem('token')
    return config
})
const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton: {
        backgroundColor: "#4844f3 !important",
        color: 'white !important',
        fontSize: '15px !important',
        paddingInline: '20px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#4844f3 !important',
        },
    },
    clearButton: {
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));



const _ = require('micro-dash');
function AgentParticulars(data) {
    console.log(data)
    const dispatch = useDispatch();
    const agent_details=data.location.state.lists
    const classes = useStyles();
    const history = useHistory();
    const user_type = useSelector((state) => state.user.user_type);
    const token = useSelector((state) => state.user.token);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    useEffect(() => {
        dispatch(get_user_agent_list())
    }, [])
    useEffect(()=>{
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    },[token])

    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex',width:'100%' }}>
                        <div style={{ background: '#fff',paddingTop: 8, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', overflow: 'auto', minWidth: 220, }}>
                            <TabbleLists type='3'></TabbleLists>
                        </div>
                        <div >
                            <div style={{paddingTop: '2%',paddingLeft:'3%'}}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to='/largemodel/intelligent_agent' style={{color:'rgba(0,0,0,0.6)'}}>
                                        智能体
                                    </Link>
                                    <Typography color="text.primary">{agent_details.name}</Typography>
                                </Breadcrumbs>
                            </div>
                            <div style={{ padding: '5%',paddingTop: '2%'}}>
                                <div style={{display:'flex'}}>
                                    <div style={{fontWeight:'bold'}}>名称：</div>
                                    <div>{agent_details.name}</div>
                                </div>
                                <div style={{padding:'15px 0',display:'flex'}}>
                                    <div style={{fontWeight:'bold'}}>介绍：</div>
                                    <div>{agent_details.introduction}</div>
                                </div>
                                <div>
                                    <div style={{fontWeight:'bold'}}>文件列表：</div>
                                    <div>
                                        {
                                            agent_details?.history?.map((item,index)=><div key={'history'+index} style={{padding:10}}>
                                                {item.role=='user'?<div>
                                                    <div style={{paddingBottom:10,fontWeight:'bold'}}>文件名：</div>
                                                    <div style={{paddingLeft:10}}>{item.content}</div>
                                                </div>:<div>
                                                        <div style={{paddingBottom:10,fontWeight:'bold'}}>文件内容：</div>
                                                        <div style={{height:'30vh',overflow:'auto',width:'100%',paddingLeft:10}}>
                                                            <Markdown>{item.content}</Markdown>
                                                        </div>
                                                    </div>}
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div style={{padding:'15px 0'}}>
                                    <div style={{fontWeight:'bold'}}>步骤列表</div>
                                    <div style={{padding:10}}>
                                        {
                                            agent_details?.prompt_list?.map((item,index)=><div key={'prompt_list'+index} style={{display:'flex',paddingBottom:10}}>
                                                <div>步骤{index+1}：</div>
                                                <div>{item}</div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div style={{padding:'15px 0',display:'flex'}}>
                                    <div style={{fontWeight:'bold'}}>总结提示词：</div>
                                    <div>{agent_details.prompt_list_summary}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default AgentParticulars