/**
 * Created on 6/23/21.
 * @author Xihao
 */

import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import ButtonBase from '@mui/material/ButtonBase';
import {open_drawer} from '../../../actions/search';
import { useDispatch } from 'react-redux';
import Grid from "@mui/material/Grid/Grid";
import Container from "@mui/material/Container/Container";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import GavelIcon from '@mui/icons-material/Gavel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MenuBookIcon from '@mui/icons-material/MenuBook';import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Divider from '@mui/material/Divider';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const _ = require('micro-dash');


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 26,
        paddingRight: 8,
        paddingTop: 10,
        paddingBottom: 10,
        width: '99%',
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        borderLeft: '6px solid #3D1198',
        // justifyContent: 'space-between',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2
    },
    title: {
        fontSize: 16,
        margin: 0,
        justifyContent: 'start',
        alignItems:'center'
    },
    tags_container:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 6,
    },
    content: {
        fontSize: 13,
        justifyContent: 'start',
        paddingRight: 16
    },
}));

// const getColorAndContent = (search_index) => {
//     switch (search_index) {
//         case 'detail_concepts':
//             return {main_color: '#1C84EB', light_color: '#DBEBFF', content: '税务概念'};
//         case 'solutions':
//             return {main_color: '#EB841C', light_color: '#FFF1E5', content: '指导方案'};
//         case 'solutions+examples':
//             return {main_color: '#5E3DCD', light_color: '#EFECFD', content: '筹划案例'};
//         case 'questions+answers':
//             return {main_color: '#1AF1C0', light_color: '#D5F5EE', content: '问答'};
//         default:
//             return {main_color: '#1C84EB', light_color: '#DBEBFF', content: '税务概念'};
//     }
// };

const getColorAndContent = (search_index) => {
    switch (search_index) {
        case 'detail_concepts':
            return {main_color: '#3D1198', light_color: 'rgb(61, 17, 152,0.2)', content: '税务概念'};
        case 'solutions':
            return {main_color: '#3D1198', light_color: 'rgb(61, 17, 152,0.2)', content: '指导方案'};
        case 'solutions+examples':
            return {main_color: '#3D1198', light_color: 'rgb(61, 17, 152,0.2)', content: '筹划案例'};
        case 'questions+answers':
            return {main_color: '#3D1198', light_color: 'rgb(61, 17, 152,0.2)', content: '问答'};
        case 'tax_law':
            return {main_color: '#3D1198', light_color: 'rgb(61, 17, 152,0.2)', content: '税法条款'};
        default:
            return {main_color: '#3D1198', light_color: 'rgb(61, 17, 152,0.2)', content: '税务概念'};
    }
};




export default function PreviewItem({title, content, tags, tax_category, industry_category, search_index,  hit}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const is_categories_empty = (_.isEmpty(tax_category)|| _.isNil(tax_category)) &&
        (_.isEmpty(industry_category)|| _.isNil(industry_category));


    const getTitle =() => {
        let parent_title = _.get(hit, ['_source', 'parent_title'], '');
        parent_title = parent_title.length>0?parent_title+'---':parent_title;
        if(_.get(hit, ['highlight', 'title', '0'], null)){
            return parent_title+_.get(hit, ['highlight', 'title', '0'], null);
        }
        else{
            return parent_title+title;
        }
    };

    const getContent =() => {
        if(_.get(hit, ['highlight', 'content', '0'], null)){
            return _.get(hit, ['highlight', 'content', '0'], null).substring(0, 100)+ '...';
        }
        else{
            return content.substring(0, 100)+'...';
        }
    };

    const sortTaxCategoryArray = () => {
        let tax_category_sorted = tax_category;
        if(tax_category.includes('个人所得税')){
            tax_category_sorted = tax_category_sorted.filter(value => value !== '个人所得税');
            tax_category_sorted.unshift('个人所得税')
        }
        if(tax_category.includes('增值税')){
            tax_category_sorted = tax_category_sorted.filter(value => value !== '增值税');
            tax_category_sorted.unshift('增值税')
        }
        if(tax_category.includes('企业所得税')){
            tax_category_sorted = tax_category_sorted.filter(value => value !== '企业所得税');
            tax_category_sorted.unshift('企业所得税')
        }
        return tax_category_sorted;
    };

    const getTaxCategoryIcon = (name) => {
        let randIcon = [(<AccountBalanceWalletIcon/>), (<BusinessCenterIcon/>)];

        switch(name){
            case '个人所得税':
                return (<AccountCircleIcon/>);
            case '企业所得税':
                return (<LocationCityIcon/>);
            case '增值税':
                return (<AddLocationIcon/>);
            case '契税':
                return (<LandscapeIcon/>);
            case '印花税':
                return (<ReceiptIcon/>);
            case '房产税':
                return (<ApartmentIcon/>);
            case '车船税':
                return (<AirportShuttleIcon/>);
            default:
                return _.sample(randIcon);

        }
    };

    const IndexIcon = ({search_index})=> {
        let TaxIcon =null;
        const {main_color, light_color, content} = getColorAndContent(search_index);
        // console.log(search_index)
        if(search_index=== 'detail_concepts'){
            TaxIcon = (<MenuBookIcon style={{ fontSize: 39, color:main_color }}/>)

        }
        else if(search_index==='solutions'){
            TaxIcon = (<AssignmentTurnedInIcon style={{ fontSize: 39, color:main_color }}/>)
        }
        else if(search_index==='solutions+examples'){
            TaxIcon = (<VpnKeyIcon style={{ fontSize: 39, color:main_color }}/>)
        }
        else if(search_index==='tax_law'){
            TaxIcon = (<GavelIcon style={{ fontSize: 39, color:main_color }}/>)
        }
        else{
            TaxIcon = (<QuestionAnswerIcon style={{ fontSize: 39, color:main_color }}/>)
        }

        return(
                <ButtonBase onClick={()=>dispatch(open_drawer(hit))} style={{display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                    {
                        TaxIcon
                    }
                    <Divider light />
                    <Chip

                        label={content}
                        size='small'
                        style={{
                            backgroundColor: light_color,
                            color: main_color,
                            marginTop: 8
                        }}
                    />
                </ButtonBase>
                );
    };


    return (
        <Paper className={classes.root} elevation={3}>
            <Grid container style={{}}>
                <Grid item xs={2} style={{justifyContent: 'center', alignItems: 'center'}}>
                    <IndexIcon search_index={search_index}/>
                </Grid>
                <Grid item xs={is_categories_empty?10:8} container  style={{display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <ButtonBase onClick={()=>dispatch(open_drawer(hit))} className={classes.title}>
                        <p style={{fontSize: 16, textAlign: 'left', marginTop: 5, marginBottom: 5}} dangerouslySetInnerHTML={{ __html: getTitle() }}/>
                        {
                            industry_category && industry_category.slice(0,4).map(
                                (item, index)=> <Chip key={index}
                                                      size="small"
                                                      label={item}
                                                      // color="primary"
                                                      style={{backgroundColor: '#3D1198',color: 'white',  marginLeft: '0.5vw'}}
                                />
                            )
                        }
                    </ButtonBase>
                    <div
                        onClick={()=>dispatch(open_drawer(hit))}
                        className={classes.tags_container}>
                        {
                            tags && tags.map(
                                (item, index) =>
                                    <Chip
                                        key={`preview-tag-${index}`}
                                        label={item.length>20?item.substring(0,20)+'...':item}
                                        disabled
                                        // variant="outlined"
                                        size='small'
                                        style={{
                                            marginRight: 6,
                                            marginBottom: 2,
                                            // color: '#1C84EB',
                                            // borderColor: '#1C84EB'
                                        }}
                                    />
                            )
                        }
                    </div>
                    <ButtonBase onClick={()=>dispatch(open_drawer(hit))} className={classes.content}>
                       <p style={{fontSize: 14, textAlign: 'left', marginBottom: 2}} dangerouslySetInnerHTML={{ __html: getContent() }}/>
                    </ButtonBase>
                </Grid>
                {
                    !is_categories_empty &&
                    <Grid item md={2} container className='d-md-block d-sm-none'
                          style={{display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        paddingLeft: 2
                        // borderLeft: '1px solid #E6E5EB',
                    }}>
                        <div style={{padding: '4px 12px 4px 12px', marginBottom: 6, borderRadius: 4, fontSize: 15, backgroundColor: 'rgb(61, 17, 152,0.2)', color: 'rgb(61, 17, 152)'}}>
                            税种
                        </div>

                        <div style={{display: 'flex',
                            flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',flexWrap: 'wrap'}}>
                        {
                            sortTaxCategoryArray().filter(v=>v!=="教育费附加" && v!=="城市维护建设税").slice(0,4).map(
                                (item, index)=> <Chip key={index}
                                                      size="small"
                                                      label={item}
                                                      icon={getTaxCategoryIcon(item)}
                                                      color="primary"
                                                      style={{backgroundColor: 'white', color: 'grey', marginInline: '0.1vw'}}
                                />
                            )
                        }
                        </div>

                    </Grid>
                }


            </Grid>
        </Paper>
    );
}