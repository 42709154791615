import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import SearchBar from './SearchBar';
import SearchFilter from './SearchFilter';
import ResultList from './ResultList';
import DrawerContent from './DrawerContent';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import { close_drawer, search, search_all,goto_page } from '../../../actions/search';
import { wechat_web_login } from '../../../actions/user';
import {price_list} from '../../../actions/mine'
import { Link,Route,Switch } from "react-router-dom";

import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import Footer from "../../footer/Footer";
import HeadHavigation from "../../headHavigation/headHavigation";
import { Redirect, useLocation } from "react-router-dom";
import Slider from "react-slick";

import kuai_lucency_logo from '../../../static/logo/kuai_lucency_logo.png'
import {HOME_TITLE} from "../../../configs/constants";
import {emit, initSocket} from "../../../actions/chat";

const _ = require('micro-dash');

const sliderStyles = makeStyles((theme) => ({
    slickUl: {
        '& li': {
            width: '30px !important',
            height: '15px !important',
            paddingTop: '5px !important',

        },
        '& .slick-active': {
            margin: '0 8px !important',
            '& div': {
                background: '#3D1198 !important',
                padding: '6px 15px !important',
                margin:'0 5px !important'
            }
        }
    }
}));

function SimpleSlider() {
    const slider_classes = sliderStyles();
    const dispatch = useDispatch();
    const shui_price=useSelector((state) => state.mine.shui_price);
    useEffect(() => {
        dispatch(price_list())
    }, []);
    var settings = {
        dots: true,
        infinite: true,
        arrows:false,
        speed: 500,
        autoplay:true,
        pauseOnDotsHover: true,
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // 自定义dot
        appendDots: dots => (
            <div
                style={{
                    // backgroundColor: "#ddd",
                    borderRadius: "10px",
                    padding: "10px",
                    paddingTop: 0
                }}
            >
                <ul style={{ padding: '2px 5px' }} className={slider_classes.slickUl}> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    // width: "30px",
                    color: "blue",
                    // border: "1px blue solid",
                    padding: '5px 8px',
                    background: '#707070',
                    margin: '0 10px',
                    borderRadius: '8px'
                }}
            >
            </div>
        )
    };
    
    return (
        <Slider {...settings}>
            <div>
                <Link to='/tax/intro'>
                    <div style={{ padding: 20, margin: '20px 0', background: '#3D1198', color: '#fff', borderRadius: 8, position: 'relative' }}>
                        <div style={{ padding: 10 }}>
                            &bull;税引擎智能提供各行业税务筹划方案，并提供税务筹划以及相关税法知识库搜索
                        </div>
                        <div style={{ padding: 10 }}>
                            {/* &bull;以往动辄几万元的税务筹划方案只用十几块就可以无限次使用。 */}
                            &bull;以往动辄几万元的税务筹划方案可以无限次免费查看。
                        </div>
                        {
                             _.isNil(shui_price)?'':
                            <div style={{ padding: 10 }}>
                              {/* &bull;5分钟免费试用，一月会员仅需{shui_price[1]/100}元！ */}
                              &bull;免费使用搜题功能！
                            </div>
                        }
                        <div style={{ width: 200, height: 200, position: 'absolute', right: '-30px', bottom: '-60px', zIndex: 99 }}>
                            <img src={kuai_lucency_logo} alt='' style={{ width: '100%', height: '100%' }} />
                        </div>
                    </div>
                </Link>
            </div>
            {/* <div>
                <h3>2</h3>
            </div> */}
        </Slider>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        width: '100%',
        maxWidth: '100%',
        paddingLeft: '12%',
        paddingRight: '12%',
        marginTop: 39,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    }
}));


function Home() {
    const [isFirst, setIsFirst] = React.useState(true);
    const result_list = useSelector((state) => state.search.search_result_list);
    const result_count = useSelector((state) => state.search.result_count);
    const drawer_open = useSelector((state) => state.search.drawer_open);
    const detailed_info = useSelector((state) => state.search.hit);
    const total_page_count = useSelector((state) => state.search.total_page_count);
    const page_index = useSelector((state) => state.search.page_index);
    const search_words = useSelector((state) => state.search.search_words);
    const token = useSelector((state) => state.user.token);
    const shui_intro_show=useSelector((state)=>state.search.shui_intro_show)

    const query = new URLSearchParams(useLocation().search);
    const page = query.get("page");

    const code = query.get("code");
    const login_state = query.get("state")
    const [isCheckingCode, setIsCheckingCode] = React.useState(null);
    const [needRedirect, setNeedRedirect] = React.useState(false);
    const user_id = useSelector((state) => state.user.user);
    const user_type = useSelector((state) => state.user.user_type);

    const dispatch = useDispatch();
    const history = useHistory();
    const handleChange = (event, value) => {
        // history.push(`/?page=${value}`)
        dispatch(search({ page_index: value, words: search_words }))
    };

    // useEffect(() => {
    //     console.log(`code is ${code}, ischecking: ${isCheckingCode}, token: ${token}, ${_.isNil(isCheckingCode)}, ${_.isNil(token)}`)
    //     document.title = HOME_TITLE;

    //     if (!_.isNil(code)) {
    //         setIsCheckingCode(true);
    //         if (login_state == 'development') {

    //         } else {
    //             dispatch(wechat_web_login(code, (v) => setIsCheckingCode(v)));
    //         }
    //     };
    //     console.log(`code is ${code}, ischecking: ${isCheckingCode}, token: ${token}, ${_.isNil(isCheckingCode)}, ${_.isNil(token)}`)

    //     if (_.isNil(isCheckingCode)) {
    //         if (_.isNil(token)) {
    //             setNeedRedirect(true)
    //         }
    //     }

    // }, []);

    // useEffect(() => {
    //     if (isCheckingCode === false) {
    //         if (_.isNil(token)) {
    //             setNeedRedirect(true)

    //         }
    //     }

    // }, [isCheckingCode]);

    // useEffect(() => {
    //     console.log('token', token)
    //     if (!_.isNil(token)) {
    //         dispatch(initSocket());
    //         emit('socket_login', { user_id: user_id, user_type: user_type, terminal_type: 2 });
    //     }
    // }, [token])

    useEffect(() => {
        document.title = "税引擎-税务筹划与税法搜索引擎-博槐";
    }, []);


    useEffect(() => {
        if (isFirst) {
            if(!_.isNil(page)){
                let pages=parseInt(page);
                dispatch(search({ page_index: pages, words: search_words }))
            }else{
                dispatch(search_all());
            }
            
            setIsFirst(false);
        }

    }, [isFirst, dispatch]);

    const classes = useStyles();


    return (
        <div>
            <HeadHavigation provenance={0} />
            <Container className={classes.root}>
                {/* <Link to='../Chat'>聊天</Link> */}
                <SearchBar />
                {
                    shui_intro_show?<SimpleSlider />:''
                }
                <Grid container style={{ marginTop: 22, paddingBottom: 50 }}>
                    <Grid item xs={3} container>
                        <Grid item xs={10}>
                            <SearchFilter />
                        </Grid>
                        <Grid item xs={2} style={{ borderLeft: '1px solid #E6E5EB', marginTop: 26 }} />
                    </Grid>
                    <Grid item xs={9}>
                        <ResultList
                            search_result_list={result_list}
                        />
                        <Pagination count={total_page_count}
                            page={page_index}
                            onChange={handleChange}
                            variant="outlined" shape="rounded" />
                        {/* {
                            (()=>{
                                if(!_.isNil(token)){
                                    return <Pagination count={total_page_count}
                                            page={page_index}
                                            onChange={handleChange}
                                            variant="outlined" shape="rounded" />
                                }else{
                                    return <Pagination count={total_page_count}
                                            page={page_index}
                                            onChange={()=>{dispatch(open_hints(true))}}
                                            variant="outlined" shape="rounded" />
                                }
                            })()
                        } */}
                    </Grid>
                </Grid>
                <Drawer
                    anchor={'right'}
                    open={drawer_open}
                    onClose={() => dispatch(close_drawer())}
                >
                    <div style={{ width: window.innerWidth * 2 / 3, height: '100%' }}>
                        <DrawerContent
                            hit={detailed_info}
                        />
                    </div>
                </Drawer>
                <div>
                    <Footer />
                </div>
                {/* <div style={{position:'fixed',right:'5%',bottom:'20%',borderRadius:'50%',
                            boxShadow:'0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
                            backgroundColor:'#fff',padding:5,width:70,height:70,textAlign:'center'
                            }}>
                    <Link to='../cs/chat'>
                        <img src={chat_icon} alt='' style={{width:50,height:50,paddingTop:8}} />
                    </Link>
                </div> */}
            </Container>
        </div>
    );
}

export default Home;
