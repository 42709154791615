
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import QRCode from 'qrcode.react';
import Divider from '@mui/material/Divider';
import shui_engine from '../../../static/logo/logo_2.jpg'
import user_img from '../../../static/image/user_img.png'
import shui_engine3 from '../../../static/logo/logo3.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'
import LaunchIcon from '@mui/icons-material/Launch';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import amend_img from '../../../static/image/amend_img.png'
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from "../../headHavigation/tabble_lists";
import { message } from 'antd';
import { initSocket, emit } from '../../../actions/chat'
import { price_list, user_member_info, llm_membership, get_llm_qr_code, llm_member_info } from '../../../actions/mine'
import { logout, cut_tab_action, get_update_user_info } from '../../../actions/user'


const _ = require('micro-dash');
// const QRCode = require('qrcode.react');
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#3D1198',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#3D1198',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#3D1198',
        },
        '&:hover fieldset': {
            borderColor: '#3D1198',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#3D1198',
        },
    },
});

const product = [
    {
        value: '1',
        label: '税捷大模型',
    },
];


function UserMine() {
    const user_id = useSelector((state) => state.user.user)
    const user_type = useSelector((state) => state.user.user_type)
    const token = useSelector((state) => state.user.token);
    const [price_value, setPlatform] = React.useState('1');
    const qr_code_url = useSelector((state) => state.mine.llm_qr_code) || ''
    const is_paySuccess = useSelector((state) => state.chat.is_paySuccess)
    console.log('qr_code_url', qr_code_url)
    const shui_member_type_info = useSelector((state) => state.user.member_type_info)
    const shui_member_info = useSelector((state) => state.user.member_info)
    const [remind_suggest, setState] = React.useState(false);

    const history = useHistory()
    const llm_price_lists = useSelector((state) => state.mine.llm_price_lists)
    const membership_counts = useSelector((state) => state.mine.membership_counts)
    const membership_months = useSelector((state) => state.mine.membership_months)
    const user_info_particulars = useSelector((state) => state.user.user_info_particulars)
    const exceed_vip = useSelector((state) => state.mine.exceed_vip)
    const base_setting = useSelector((state) => state.user.base_setting);
    const company_quota=useSelector((state) => state.user.company_quota);
    const quota=useSelector((state) => state.user.quota);
    const [shui_price_list, setShuiPriceList] = React.useState([]);
    const [now_price, setNowPrice] = React.useState(0);
    console.log(user_info_particulars)
    useEffect(() => {
        setShuiPriceList([])
        if (llm_price_lists?.length > 0) {
            let year_card = []
            let order_card = []
            for (let i = 0; i < llm_price_lists?.length; i++) {
                if (llm_price_lists[i].member_type === 0) {
                    year_card.push(llm_price_lists[i])
                } else {
                    order_card.push(llm_price_lists[i])
                }
            }
            setShuiPriceList(llm_price_lists)
            setNowPrice(llm_price_lists[0].price)
            dispatch(get_llm_qr_code({ type_id: llm_price_lists[0].id }))
        }
    }, [llm_price_lists]);

    const dispatch = useDispatch();
    //价格切换
    const platformChange = (event) => {
        console.log('event', event)
        setOpen(true);
        setNowPrice(llm_price_lists[event.target.value - 1].price)
        setPlatform(event.target.value)
        dispatch(get_llm_qr_code({ type_id: event.target.value }))
    }
    // 进入页面第一次
    useEffect(() => {
        dispatch(llm_member_info())
        dispatch(llm_membership())
        dispatch(get_update_user_info())
    }, []);

    // 监听二维码链接
    useEffect(() => {
        setOpen(false);
    }, [dispatch, qr_code_url]);

    //刷新链接socket
    // useEffect(()=>{

    // },[])

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };
    // 监听支付成功
    useEffect(() => {
        if (is_paySuccess) {
            setState(true);
            dispatch(llm_member_info())
        }
    }, [dispatch, is_paySuccess]);
    useEffect(() => {
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    }, [token])
    const log_out = () => {
        localStorage.clear();
        dispatch(logout())
        dispatch(cut_tab_action(0))
        history.replace('/largemodel')
    }
    return (
        <div>
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 54px)' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', padding: '8px 0px', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minWidth: 220 }}>
                            <TabbleLists type='5'></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(90% - 220px)', padding: '3% 5%' }}>
                    <div style={{ width: '100%', height: '80%' }}>
                        <div>
                            <div style={{display:'flex',alignItems:'center',paddingBottom: 20,}}>
                                <div style={{  fontWeight: "bold" }}>个人信息</div>
                                <Link to='/largemodel/user_information' style={{paddingLeft:10,display:'flex',alignItems:'center'}}>
                                    <LaunchIcon style={{color: base_setting?.primary_color, fontSize: 20}}></LaunchIcon>
                                    {/* <img alt="" src={amend_img} style={{width:20,height:20}}/> */}
                                </Link>
                            </div>
                            <div style={{ boxShadow: '2px 2px 10px #d6d6d6', padding: '25px 20px', marginBottom: '5%', width: '80%', minWidth: '450px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20,paddingBottom:20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={user_info_particulars?.avatar} style={{ width: 80, height: 80, borderRadius: 20 }} alt="" />
                                    </div>
                                    <div style={{ paddingLeft: 20,paddingRight:20 }}>
                                        <div style={{ display: 'flex', paddingBottom: 5 }}>
                                            <div style={{ fontWeight: 'bold', width: 50 }}>名字：</div>
                                            <div>{user_info_particulars?.nick_name}</div>
                                        </div>
                                        <div style={{ display: 'flex', paddingTop: 5 }}>
                                            <div style={{ fontWeight: 'bold', width: 50 }}>id：</div>
                                            <div>{user_info_particulars?.id}</div>
                                        </div>
                                    </div>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <div style={{paddingLeft:20,paddingRight:20 }}>
                                        <div style={{ display: 'flex', paddingBottom: 10 }}>
                                            <div style={{ fontWeight: 'bold', width: 80 }}>电话：</div>
                                            <div>{user_info_particulars?.phone_number}</div>
                                        </div>
                                        <div style={{ display: 'flex',  }}>
                                            <div style={{ fontWeight: 'bold', width: 80 }}>权限等级：</div>
                                            <div>{user_info_particulars?.user_type}</div>
                                        </div>
                                    </div>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <div style={{paddingLeft:20}}>
                                        <div style={{ display: 'flex', paddingBottom: 10 }}>
                                            <div style={{ fontWeight: 'bold', width: 80 }}>工作头衔：</div>
                                            <div>{user_info_particulars?.job_title}</div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ fontWeight: 'bold', width: 80 }}>介绍：</div>
                                            <div>{user_info_particulars?.brief}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ paddingBottom: 20, fontWeight: "bold" }}>会员信息</div>
                            <div style={{ boxShadow: '2px 2px 10px #d6d6d6', padding: '25px 20px', display: 'flex', alignItems: 'center', marginBottom: '5%', width: '80%', minWidth: '450px' }}>
                                <div style={{ flexDirection: "row", paddingBottom: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img src={base_setting?.logo_url} style={{ width: 80, height: 80 }} alt="" />
                                    </div>
                                    <div style={{ padding: "0 10px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <h3>{base_setting?.brand_name}</h3>
                                    </div>
                                </div>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <div>
                                    <div style={{ paddingBottom: 10, paddingLeft: 16, fontWeight: 'bold' }}>会员数据</div>
                                    <div style={{ paddingLeft: 16, paddingBottom: 10 }}>
                                        {
                                            exceed_vip === 1 ? <div>
                                                <div>会员到期时间</div>
                                                <div>{membership_months.expire_time.split(" ")[0]}</div>
                                            </div> : <div>您还不是会员</div>
                                        }
                                    </div>

                                    <div style={{ paddingLeft: 16,paddingRight:5, paddingBottom: 10 ,display:'flex'}}>
                                        <div style={{ paddingRight: 5, paddingBottom: 10 }}>
                                            本月可提问总次数：{company_quota*1 + quota*1}
                                        </div>
                                        <Tooltip title={<div>1.个人提问次数：{quota}<br/>2.公司提问次数：{company_quota}
                                                        <br/>优先消耗公司提问次数</div>}>
                                            <HelpIcon style={{ color: '#858383de', fontSize: 20 }}>
                                            </HelpIcon>
                                        </Tooltip>
                                    </div>
                                </div>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <div style={{ display: 'flex', paddingLeft: 20, alignItems: 'center' }}>
                                    <div>
                                        <div style={{ fontWeight: 'bold' }}>一次性充值卡/订阅</div>
                                        <div style={{ padding: '20px 0', paddingRight: '10%', display: 'flex' }}>
                                            <div>
                                                <Box sx={{ minWidth: 120, maxWidth: 150 }}>
                                                    <CssTextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="类型"
                                                        value={price_value}
                                                        onChange={platformChange}
                                                        helperText=""
                                                        focused
                                                        style={{ width: 120 }}
                                                    >
                                                        {shui_price_list?.map((option, index) => (
                                                            <MenuItem key={index} value={option.id}>
                                                                {option.member_type_string}
                                                            </MenuItem>
                                                        ))}
                                                    </CssTextField>
                                                </Box>
                                            </div>
                                            <div style={{ paddingLeft: '10%' }}>
                                                <Box sx={{ minWidth: 120, maxWidth: 150 }}>
                                                    <CssTextField
                                                        labelId="demo-simple-select-label"
                                                        label="价格"
                                                        value={now_price / 100 + '￥'}
                                                        helperText=""
                                                        disabled
                                                        focused
                                                        style={{ width: 120 }}
                                                    >
                                                    </CssTextField>
                                                </Box>
                                            </div>
                                        </div>
                                        {/* <div style={{ fontSize: 12 }}>介绍</div> */}
                                    </div>
                                    <div style={{ position: 'relative', marginLeft: 50 }}>
                                        <QRCode value={qr_code_url} style={{ width: 100, height: 100 }} />
                                        {
                                            remind_suggest ?
                                                <div style={{ position: 'absolute', width: 100, height: 100, left: 0, top: 0, background: 'rgba(255,255,255,0.95)', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: "pointer" }}>
                                                    <RefreshIcon style={{ color: 'rgb(61, 17, 152)' }} />
                                                </div> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{paddingTop:20}}>
                        <Button variant="contained" style={{ width: 200, height: 50, background: base_setting?.primary_color }} onClick={log_out}>退出登陆</Button>
                    </div>
                </div>
            </div>

            {/* <Divider>博槐税捷大模型-支付历史</Divider>
            {
                pay_history.length > 0 ?
                    <div style={{ marginTop: '20px' }}>
                        <TableContainer component={Paper} style={{ maxHeight: '40vh' }}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">价格</TableCell>
                                        <TableCell align="center">时间</TableCell>
                                        <TableCell align="center">会员等级</TableCell>
                                        <TableCell align="center">产品类别</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pay_history.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center" component="th" scope="row">
                                                {row.price / 100 + '元'}
                                            </TableCell>
                                            <TableCell align="center">{row.create_time.split('T')[0]}</TableCell>
                                            <TableCell align="center">
                                                {
                                                    (() => {
                                                        if (row.level === 1) {
                                                            return '月'
                                                        } else if (row.level === 2) {
                                                            return '年'
                                                        } else {
                                                            return '季'
                                                        }
                                                    })()
                                                }
                                            </TableCell>
                                            <TableCell align="center">{product_name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div> :
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>暂无支付历史</div>
            } */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
export default UserMine;