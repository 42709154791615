import {CREATE_DATASET,GET_DATASET,COLLECTION_LISTS,COLLECTION_DATA} from '../actions/types';
import { isUndefined } from 'micro-dash';
import { act } from 'react-dom/test-utils';
const _ = require('micro-dash');

const initialState = {
    dataset_lists:[],
    is_dataset:true,
    collection_lists:[],
    collection_data:[],
    company_dataset_lists:[],
    user_is_enabled:true,
    company_is_enabled:true
};

 // eslint-disable-next-line import/no-anonymous-default-export
export default function(state=initialState, action) {
    switch(action.type) {
        case CREATE_DATASET:
            return {...state,is_dataset:action.data}
        case GET_DATASET:
            console.log(action.data)
            if(action.get_type==='user'){
                let user_is_enabled=true
                for(let i=0;i<action.data.length;i++){
                    if(!action.data[i].is_enabled){
                        user_is_enabled=false
                    }
                }
                return {...state,dataset_lists:action.data,user_is_enabled:user_is_enabled}
            }else{
                let company_is_enabled=true
                for(let i=0;i<action.data.length;i++){
                    if(!action.data[i].is_enabled){
                        company_is_enabled=false
                    }
                }
                return {...state,company_dataset_lists:action.data,company_is_enabled:company_is_enabled}
            }
        case COLLECTION_LISTS:
            return {...state,collection_lists:action.data}
        case COLLECTION_DATA:
            return {...state,collection_data:action.data}
        default:
            return state
    }
}