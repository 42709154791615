import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import {useDispatch,useSelector} from "react-redux";
import {question_lists, search_shui, show_search} from "../../../actions/forum";
import Dialogs from './Dialog'
import {open_hints} from '../../../actions/user';


const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 55,
    },
    input: {
       // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
    iconButton: {
        padding: 10,

    },
    divider: {
        height: 28,
        margin: 4,
    },
    searchClickButton:{
        backgroundColor:"#3D1198 !important",
        color: 'white !important',
        fontSize: '17px !important',
        paddingInline: '36px !important',
        marginInline: '6px !important',
        '&:hover': {
            backgroundColor: '#3D1198 !important',
        },
    },
    clearButton:{
        color: '#3D1198 !important',
        fontSize: 14,
        paddingBottom: 2,
        marginRight: 6,
        '&:hover': {
            backgroundColor: 'white',
            color: 'grey !important',
        },
    }
}));

export default function SearchBarQA() {
    const [searchValue, setSearchValue] = React.useState('');
    const [multiWords, setMultiWords] = React.useState([]);
    const token=useSelector((state) => state.user.token);
    const tax_vip=useSelector((state) => state.user.member_type_info);
    const classes = useStyles();
    const dispatch = useDispatch();

    const get_search_words = () => {
        return [...multiWords, searchValue].join(' ').replace(/\s*/g,"");
    };

    const handleSearch = () => {
        get_search_words();
        dispatch(question_lists([]));
        dispatch(search_shui({words:get_search_words()}));
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if(_.isNil(token)){
                dispatch(open_hints(true))
                e.preventDefault();
            }else{
                console.log(tax_vip)
                if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                    dispatch(open_hints(true))
                    e.preventDefault();
                }else{
                    e.preventDefault();
                    dispatch(question_lists([]));
                    dispatch(search_shui({words:get_search_words()}));
                }
            }
        }
    };

    const clearAll = () => {
        setMultiWords([]);
        setSearchValue("");
    };



    return (
        <div>
            <Paper component="form" className={classes.root}>
                <InputBase
                    className={classes.input}
                    placeholder="税务问答搜索"
                    inputProps={{ 'aria-label': '税务问答搜索引擎' }}
                    value={searchValue}
                    onChange={(event)=>setSearchValue(event.target.value)}
                    onKeyDown={_handleKeyDown}
                />
                <Button className={classes.clearButton} onClick={clearAll} >清空搜索</Button>
                {
                    (()=>{
                        if(!_.isNil(token)){
                            if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                                return <Button className={classes.searchClickButton}
                                        onClick={()=>{dispatch(open_hints(true))}} disableElevation>
                                            搜索
                                        </Button>
                            }else{
                                return  <Button className={classes.searchClickButton}
                                        onClick={handleSearch} disableElevation>
                                            搜索
                                        </Button>
                            }
                        }else{
                            return      <Button className={classes.searchClickButton}
                                        onClick={()=>{dispatch(open_hints(true))}} disableElevation>
                                            搜索
                                        </Button>
                        }
                    })()
                }
            </Paper>
            <p style={{marginTop: 8, marginBottom: 2, color: '#B5B4B9', fontSize: 14}}>
                按回车键直接进行搜索
            </p>

            <Dialogs />
        </div>
    );
}