/**
 * Created on 6/23/21.
 * @author Xihao
 */
import {
    CHANGE_INDUSTRY_CATEGORY,
    CHANGE_TAX_CATEGORY,
    CLOSE_DRAWER,
    GET_PARENT_RESULT_SUCCESS,
    GET_SEARCH_RESULT_SUCCESS,
    GO_TO_PAGE,
    LOADING_PARENT_RESULTS,
    LOADING_SEARCH_RESULTS,
    OPEN_DRAWER,
    SET_SEARCH_WORDS,
    TOGGLE_CONCEPTS,
    TOGGLE_QA,
    TOGGLE_SE,
    TOGGLE_SOLUTIONS,
    TOGGLE_LAW,
    SHUIINTROSHOW,
    SCENELISTS,
    VIDEO_LISTS
} from './types';
import {MIDDLE_EARTH_URL} from '../configs/constants'
import axios from 'axios';

const _ = require('micro-dash');


export function search_success(search_result_list, result_count, page_index=1) {
    return {
        type: GET_SEARCH_RESULT_SUCCESS,
        search_result_list,
        result_count,
        page_index
    }
}

export function open_drawer(hit) {
    return {
        type: OPEN_DRAWER,
        hit
    }
}

export function close_drawer() {
    return {
        type: CLOSE_DRAWER,
    }
}

export function goto_page(page_index) {
    return {
        type: GO_TO_PAGE,
        page_index
    }
}

// indexes
export function toggle_concepts() {
    return {
        type: TOGGLE_CONCEPTS,
    }
}

export function toggle_solutions() {
    return {
        type: TOGGLE_SOLUTIONS,
    }
}

export function toggle_qa() {
    return {
        type: TOGGLE_QA,
    }
}

export function toggle_se() {
    return {
        type: TOGGLE_SE,
    }
}

export function toggle_law() {
    return {
        type: TOGGLE_LAW,
    }
}

export function set_search_words(search_words) {
    return {
        type: SET_SEARCH_WORDS,
        search_words
    }
}

export function loading_search_results() {
    return {
        type: LOADING_SEARCH_RESULTS
    }
}

//industry category
export function change_industry(value) {
    return {
        type: CHANGE_INDUSTRY_CATEGORY,
        value
    }
}

// tax category
export function change_tax(value) {
    return {
        type: CHANGE_TAX_CATEGORY,
        value
    }
}

// parent
export function search_parent(value){
    return {
        type: GET_PARENT_RESULT_SUCCESS,
        value
    }
}

export function load_parent_data(){
    return {
        type: LOADING_PARENT_RESULTS
    }
}
export function shui_swiper_show(show){
    return {
        type:SHUIINTROSHOW,
        shui_intro_show:show
    }
}
export function scene_lists(company_category_data,individual_category_data,business_type_data,all_category_data){
    return {
        type:SCENELISTS,
        company_category_data,
        individual_category_data,
        business_type_data,
        all_category_data
    }
}
export function video_lists(data){
    return {
        type:VIDEO_LISTS,
        data
    }
}


// dispatch
export function search_all(page_index=1) {
    // console.log(`Inside search_all for ${page_index}`);
    return (dispatch, getState) => {
        dispatch(shui_swiper_show(true))
        dispatch(set_search_words(""));
        dispatch(loading_search_results());
        const index_url =get_index_url_part(_.get(getState(), ['search','search_indexes'],{}));
        // console.log(`index url is ${index_url}`);
        axios.post(`${MIDDLE_EARTH_URL}/es/show-all/`,
            {
                from: (page_index-1)*10,
                index_url,
                query: get_search_query(null, getState())

            })
            .then(
                response => {
                    // console.log(JSON.stringify(response));
                    dispatch(search_success(response.data.hits, response.data.total.value, page_index));
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search_all`);
                if( error.response ){
                    console.log(error.response.data); // => the response payload
                }
            })
    };
}
//点击搜索
export function search({page_index=1, words=""}) {
    console.log(`Inside search for ${page_index}, ${words}`);

    return (dispatch, getState) => {
        if(words.length===0){
            return dispatch(search_all(page_index));
        }
        dispatch(shui_swiper_show(false))
        dispatch(set_search_words(words));
        dispatch(loading_search_results());
        const index_url =get_index_url_part(_.get(getState(), ['search','search_indexes'],{}));
        console.log(`index url is ${index_url}`);
        axios.post(`${MIDDLE_EARTH_URL}/es/search/`,
            {
                from: (page_index-1)*10,
                query: get_search_query(words, getState()),
                index_url
            })
            .then(
                response => {
                    // console.log(JSON.stringify(response));
                    dispatch(search_success(response.data.hits, response.data.total.value, page_index));
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search`);
                if( error.response ){
                    console.log(error.response.data); // => the response payload
                }
            })
    };
}

export function get_by_id({index_url='concepts', id}) {

    return (dispatch, getState) => {

        dispatch(load_parent_data());
        console.log(`index url is ${index_url}`);
        axios.post(`${MIDDLE_EARTH_URL}/es/get_by_id/`,
            {
                id,
                index_url
            })
            .then(
                response => {
                    console.log(JSON.stringify(response));
                    dispatch(search_parent(response.data));
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search`);
                if( error.response ){
                    console.log(error.response.data); // => the response payload
                }
            })
    };
}

export function search_by_id_cross_indexes({id}) {

    return (dispatch, getState) => {

        dispatch(load_parent_data());
        axios.post(`${MIDDLE_EARTH_URL}/es/search_by_id_cross_indexes/`,
            {
                id
            })
            .then(
                response => {
                    console.log(JSON.stringify(response));
                    dispatch(search_parent(response.data.data));
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in search`);
                if( error.response ){
                    console.log(error.response.data); // => the response payload
                }
            })
    };
}

const get_search_query = (words, state) => {
    return {
        "bool": {
            "must":get_filter_query(words, state)
        }
    }
};


const get_filter_query = (words, state)=> {
    let filter_query = [];
    let tax_category = _.get(state, ['search', 'search_tax_category'], {});
    let industry_category = _.get(state, ['search', 'search_industry_category'], '全部');


    // normal multi-word search
    if(!(_.isNil(words) || words.length===0)){
        words.trim().split(" ").map(
            word => filter_query.push({
                "multi_match" : {
                    "type": "phrase",
                    "query": word,
                    "lenient": true,
                    "fields": [ "title^3", "tags", "content", "parent_title" ]
                }
            })
        )
    }

    // industry category filter
    if(industry_category !== '全部' && !_.isNil(industry_category)){
        filter_query.push(
            {"match":{"industry_category":industry_category.trim()}}
        )
    }

    // tax category filter
    if(tax_category !== '全部' && !_.isNil(tax_category)){
        filter_query.push(
            {"match":{"tax_category":tax_category.trim()}}
        )
    }

    return filter_query;
};

const get_index_url_part = (search_indexes) =>  {
    console.log(search_indexes);
    let index_arr = [];
    if(_.get(search_indexes, ['qa'], true)){
        index_arr.push('questions+answers');
    }
    if(_.get(search_indexes, ['solutions'], true)){
        index_arr.push('solutions');
    }
    if(_.get(search_indexes, ['concepts'], true)){
        index_arr.push('detail_concepts');
    }
    if(_.get(search_indexes, ['se'], true)){
        index_arr.push('solutions+examples');
    }
    if(_.get(search_indexes, ['law'], true)){
        index_arr.push('tax_law');
    }
    return index_arr.toString();
};
// 情景列表
export function home_data() {
    return (dispatch, getState) => {
        dispatch(load_parent_data());
        axios.post(`${MIDDLE_EARTH_URL}/app-data/get-mini-program-home-data/`,)
            .then(
                response => {
                    // console.log(response);
                    let company_category_data=response.data.company_category_data.map(item=>{
                        return {
                          title:item,
                          img_url:response.data.data_icons[item]
                        }
                      })
                      let individual_category_data=response.data.individual_category_data.map(item=>{
                        return {
                          title:item,
                          img_url:response.data.data_icons[item]
                        }
                      })
                      let business_type_data=response.data.business_type_data.map(item=>{
                        return {
                          title:item,
                          img_url:response.data.data_icons[item]
                        }
                      })
                      axios.post(`${MIDDLE_EARTH_URL}/app-data/get-mini-program-category-mapping-data`,)
                        .then(res=>{
                            dispatch(scene_lists(company_category_data,individual_category_data,business_type_data,res.data));
                      })
                    
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in home_data`);
            })
    };
}
export function scene_open_drawer(id){
    return (dispatch, getState) => {
        dispatch(load_parent_data());
        axios.post(`${MIDDLE_EARTH_URL}/es/search_by_id_cross_indexes/`,{
                id
            })
            .then(
                response => {
                    console.log(response);
                    dispatch(open_drawer(response.data.data))
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in home_data`);
            })
    };
}
export function all_scene_lists(id){
    return (dispatch, getState) => {
        dispatch(load_parent_data());
        // let id=id
        console.log('id',id)
            axios.post(`${MIDDLE_EARTH_URL}/es/get_by_ids/`,{
            // axios.post(`http://127.0.0.1:3005/es/get_by_ids`,{
                id:id
            })
            .then(
                response => {
                    console.log(response);
                    dispatch(search_success(response.data, 0, 0));
                    // dispatch(open_drawer(response.data.data))
                })
            .catch((error) => {
                console.log(`ERROR is: ${error}  in home_data`);
            })
    };
}
// 视频列表
export function video_data(){
    return (dispatch,getState)=>{
        axios.post(`${MIDDLE_EARTH_URL}/app-data/get-mini-program-video-data/`,{
            career_id:0
        })
        .then(
            response => {
                console.log(response);
                dispatch(video_lists(response.data.video_data))
            })
        .catch((error) => {
            console.log(`ERROR is: ${error}  in home_data`);
        })
    }
}
