import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { styled, alpha } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import {get_outsider_settings,cut_tab_action} from '../../../actions/user'

const useStyles = makeStyles((theme) => ({
    agent_name:{
        color: '#000',
        textAlign:'center',
        fontSize:16,
        width:120
    }
}))

function AgentList(e) {
    const classes = useStyles();
    const user_type = useSelector((state) => state.user.user_type);
    const agent_menus=useSelector((state) => state.user.agent_menus);
    const base_setting=useSelector((state) => state.user.base_setting);
    const other_menus=useSelector((state) => state.user.other_menus);
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(get_outsider_settings())
    },[])
    const cut_tab=(event)=>{
        console.log(event)
        dispatch(cut_tab_action(event))
    }
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minHeight: 'calc(100vh - 62px)', overflow: 'auto', minWidth: 220, paddingTop: 8 }}>
                            <TabbleLists type='3'></TabbleLists>
                        </div>
                    </div>
                    <div style={{ padding:'3% 5%' ,display:'flex',flexWrap:'wrap',height:'90%'}}>
                        {
                            agent_menus?.length>0?agent_menus.map((item,index)=><Link key={'tab'+index} to={item.url} onClick={()=>cut_tab(0)} style={{ height:120,marginBottom:30,marginRight:30 }}>
                            <div style={{ padding: 10 ,boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)',borderRadius:8,}}>
                                <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center',paddingBottom:20 }}>
                                    <img src={item.img} alt='' style={{ width: 80, height: 80 }} />
                                </div>
                                <div className={classes.agent_name}>{item.name}</div>
                            </div>
                        </Link>):user_type>=190?<Link to='/largemodel/setting_block' onClick={()=>cut_tab(other_menus?.length+4)}>
                            <div style={{width:150,height:20,background:base_setting?.primary_color,color:'#fff',borderRadius:8,padding:'10px 15px'}}>点击前往设置页设置</div>
                        </Link>:<div>暂未设置应用列表</div>
                        }
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default AgentList