
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import QRCode from 'qrcode.react';
import shui_engine from '../../../static/logo/logo_2.jpg'
import user_img from '../../../static/image/user_img.png'
import shui_engine3 from '../../../static/logo/logo3.jpg'
import home from '../../../static/image/home.png'
import library from '../../../static/image/library.png'
import monitoring from '../../../static/image/monitoring.png'
import people from '../../../static/image/people.png'
import mine_img from '../../../static/image/mine_img.png'
import intelligent from '../../../static/image/intelligent.png'

import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from "../../headHavigation/tabble_lists";
import { message } from 'antd';
import { price_list, user_member_info, llm_membership, get_llm_qr_code, llm_member_info } from '../../../actions/mine'
import { logout, cut_tab_action,get_update_user_info ,update_user_basic,upload_company_logo} from '../../../actions/user'


const _ = require('micro-dash');


function UserInformation() {
    const user_id = useSelector((state) => state.user.user)
    const user_type = useSelector((state) => state.user.user_type)
    const token = useSelector((state) => state.user.token);
    const is_paySuccess = useSelector((state) => state.chat.is_paySuccess)
    const history = useHistory()
    const user_info_particulars=useSelector((state) => state.user.user_info_particulars)
    const base_setting = useSelector((state) => state.user.base_setting);
    console.log(user_info_particulars)
    const dispatch = useDispatch();
    const [name,setName]=React.useState('');
    const [user_info_id,setUserInfoId]=React.useState('');
    const [username,setUsername]=React.useState('');
    const [avatar,setAvatar]=React.useState('');
    const [phone_number,setPhoneNumber]=React.useState('');
    const [is_staff,setIsStaff]=React.useState(false);
    const [job_title,setJobTitle]=React.useState('');
    const [address,setAddress]=React.useState('');
    const [email,setEmail]=React.useState('');
    const [wechat,setWechat]=React.useState('');
    const [brief,setBrief]=React.useState('');
    const [org_id,setOrgId]=React.useState('');
    // 进入页面第一次
    useEffect(() => {
        dispatch(get_update_user_info())
    }, []);
    useEffect(()=>{
        setName(user_info_particulars?.name)
        setUserInfoId(user_info_particulars?.id)
        setUsername(user_info_particulars?.nick_name)
        setAvatar(user_info_particulars?.avatar)
        setPhoneNumber(user_info_particulars?.phone)
        setIsStaff(user_info_particulars?.is_staff)
        setJobTitle(user_info_particulars?.job_title)
        setAddress(user_info_particulars?.address)
        setEmail(user_info_particulars?.email)
        setWechat(user_info_particulars?.wechat)
        setBrief(user_info_particulars?.brief)
        setOrgId(user_info_particulars?.org_id)
    },[user_info_particulars])
    useEffect(() => {
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    }, [token])
    const [is_amend,setIsAmend]=React.useState(false);
    const amend=()=>{
        if(name==''){
            message.error('名字不能为空')
        }else{
            let data={
                name:name,
                phone:phone_number,
                email:email,
                wechat:wechat,
                address:address,
                job_title:job_title,
                org_id:org_id,
                brief:brief,
                avatar:avatar
            }
            dispatch(update_user_basic(data,(v)=>setIsAmend(v)))
        }
    }
    useEffect(()=>{
        if(is_amend){
            message.success('修改成功')
            setIsAmend(false)
        }
    },[is_amend])
    const uploadFileInput = React.useRef();
    const handleUploadButtonClick = () => {
        uploadFileInput.current.click();
    }
    const handleFileChange = (event) => {
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            const formData = new FormData();
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(upload_company_logo(formData, (v) => setAvatar(v)))
        }
    }
    return (
        <div>
            <HeadLargemodel></HeadLargemodel>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', minHeight: 'calc(100vh - 62px)' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ background: '#fff', padding: '8px 0px', boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', minWidth: 220 }}>
                            <TabbleLists type='5'></TabbleLists>
                        </div>
                    </div>
                </div>
                <div style={{ width: 'calc(100% - 220px)',height:'calc(94vh - 62px)',overflowY:'auto', padding: '3% 5%' }}>
                    <div style={{ width: '100%', height: '100%' }}>
                        <div>
                            <div>
                                <Link to='/largemodel/mine'>
                                    <Button variant="contained" style={{background: base_setting?.primary_color}} startIcon={<ArrowBackIosIcon />}>
                                        返回
                                    </Button>
                                </Link>
                            </div>
                            <div style={{ padding: '20px 0', fontWeight: "bold" }}>个人信息</div>
                            <div style={{ boxShadow: '2px 2px 10px #d6d6d6', padding: '25px 40px', marginBottom: '5%', width: '70%', minWidth: '450px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' ,paddingBottom: 20,justifyContent:'center'}}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div onClick={handleUploadButtonClick} style={{ width: 100, height: 100, borderRadius: 20, border: '1px dashed #666',  display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            {/* <img src={avatar} alt='' style={{ width: '100%', height: '100%' }}></img> */}
                                            <img src={avatar} style={{ width: 100, height: 100 ,borderRadius:20}} alt="" />
                                        </div>
                                        <input type="file" accept='.png,.jpg' ref={uploadFileInput} onChange={handleFileChange} />
                                    </div>
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="名字"
                                        variant="outlined"
                                        value={name}
                                        onChange={(e)=>setName(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="id"
                                        variant="outlined"
                                        disabled
                                        value={user_info_id}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="原始名："
                                        variant="outlined"
                                        disabled
                                        value={username}
                                        onChange={(e)=>setUsername(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="电话"
                                        variant="outlined"
                                        value={phone_number}
                                        onChange={(e)=>setPhoneNumber(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="邮箱"
                                        variant="outlined"
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="微信id"
                                        variant="outlined"
                                        value={wechat}
                                        onChange={(e)=>setWechat(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="权限等级"
                                        variant="outlined"
                                        disabled
                                        value={user_type}
                                        // onChange={(e)=>setName(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="地址"
                                        variant="outlined"
                                        value={address}
                                        onChange={(e)=>setAddress(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="工作头衔"
                                        variant="outlined"
                                        value={job_title}
                                        onChange={(e)=>setJobTitle(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="企业id"
                                        variant="outlined"
                                        value={org_id}
                                        onChange={(e)=>setOrgId(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="是否为超级用户"
                                        variant="outlined"
                                        disabled
                                        value={is_staff}
                                        // onChange={(e)=>setName(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div style={{ paddingBottom: 20 }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="介绍"
                                        variant="outlined"
                                        value={brief}
                                        onChange={(e)=>setBrief(e.target.value)}
                                        inputProps={{ style: { fontSize: 13 } }}
                                        InputLabelProps={{ style: { fontSize: 13 } }}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                
                                <div style={{ display: 'flex', paddingBottom: 10 }}>
                                    <Button onClick={amend} variant="contained" style={{ width: '100%', height: 50, background: base_setting?.primary_color }} >确认修改</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserInformation;