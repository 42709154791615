import React, { useEffect, useRef, useCallback } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Chip from '@mui/material/Chip';
import WebAudioSpeechRecognizer from './app/webaudiospeechrecognizer'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ai_img from '../../../static/image/ai_img.png'
import { MODEL_COLOR, MIDDLE_EARTH_URL, SOCKET_EARTH_URL, socket } from '../../../configs/constants'
import copy from 'copy-to-clipboard'
import { get_speech_token, send_socket_id, text_content_scribe, get_chunks_from } from '../../../actions/user'

import './app/speechrecognizer'
import './cryptojs'
// import './jquery'
const _ = require('micro-dash');
let { secretId, secretKey, appId } = {
    secretKey: '01ALUgy66wa5Mcgk4CB3nV5hFh1830sd',
    secretId: 'AKIDa9bzbAcx56vgO5tY7ZtJbhYHuV2c2JRU',
    appId: 1305959397,
}
const params = {
    // signCallback: signCallback, // 鉴权函数，若直接使用默认鉴权函数。可不传此参数
    // 用户参数
    secretid: secretId,
    secretkey: secretKey,
    appid: appId,
    // 临时密钥参数，非必填
    // token: config.token,
    // 实时识别接口参数
    engine_model_type: '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
    // 以下为非必填参数，可跟据业务自行修改
    // voice_format : 1,
    // hotword_id : '08003a00000000000000000000000000',
    // needvad: 1,
    // filter_dirty: 1,
    // filter_modal: 2,
    // filter_punc: 0,
    // convert_num_mode : 1,
    // word_info: 2
}
// const webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
let webAudioSpeechRecognizer;
const who_are_you = 'who_are_you',
    AGENT_RECEIVE_PROGRESSING_STATUS_AUDIO_SCRIBE = 'AGENT_RECEIVE_PROGRESSING_STATUS_AUDIO_SCRIBE',
    AGENT_RECEIVE_MESSAGE_IN_PROGRESS_AUDIO_SCRIBE = 'AGENT_RECEIVE_MESSAGE_IN_PROGRESS_AUDIO_SCRIBE',
    AGENT_RECEIVE_MESSAGE_ENDS_AUDIO_SCRIBE = 'AGENT_RECEIVE_MESSAGE_ENDS_AUDIO_SCRIBE';

export default function RecordingDrawer() {
    // let isCanStop;
    const dispatch = useDispatch();
    const divRef = useRef(null)
    const [isCanStop,setIsCanStop] = React.useState(false);
    const base_setting = useSelector((state) => state.user.base_setting);
    // const [webAudioSpeechRecognizer, setWebAudioSpeechRecognizer] = React.useState(null);
    const [recognizeText, setRecognizeText] = React.useState(''); //录音内容 腾讯云
    const [recognizeType, setRecognizeType] = React.useState('');
    const [currentCursorContent, setCurrentCursorContent] = React.useState('');;
    const [forbid_click, setForbidClick] = React.useState(false);
    const secretid = useSelector((state) => state.user.secretid);
    const secretkey = useSelector((state) => state.user.secretkey);
    const secrettoken = useSelector((state) => state.user.secrettoken);
    const socket_id = useSelector((state) => state.user.socket_id);

    const [open, setOpen] = React.useState({draw_open:false,start_end:0});
    const toggleDrawer = (newOpen) => () => {
        // setOpen(newOpen);
        setOpen(prev=>{
            let tmp = JSON.parse(JSON.stringify(prev))
            if(isCanStop){
                end_recording()
            }
            tmp.start_end=0
            tmp.draw_open=newOpen
            return tmp
        })
        // recording_click()
        setRecognizeText('')
    };
   
    useEffect(() => {
        dispatch(get_speech_token())
    }, [])
    useEffect(() => {
        if (socket === null) {
            console.log('socket1')
        }
        if (socket) {
            console.log('socket')
            socket.emit(who_are_you, {})
            socket.on(who_are_you, (msg) => {
                console.log(msg)
                dispatch(send_socket_id(msg.socket_id))
            })
            socket.on(AGENT_RECEIVE_PROGRESSING_STATUS_AUDIO_SCRIBE, (msg) => {
                console.log(msg)
                setRecognizeType(msg.content)
                setCurrentCursorContent("")
                setRecognizeText(prev => {
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp = ''
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_MESSAGE_IN_PROGRESS_AUDIO_SCRIBE, (msg) => {
                console.log(msg)
                setRecognizeText(prev=>{
                    let tmp = JSON.parse(JSON.stringify(prev))
                    tmp=tmp+ msg.content
                    return tmp
                })
            })
            socket.on(AGENT_RECEIVE_MESSAGE_ENDS_AUDIO_SCRIBE, (msg) => {
                console.log(msg)
                setRecognizeType('结束')
                setForbidClick(false)
            })
        }
    }, [socket])
    useEffect(() => {
        if (!_.isNil(secretid)) {
            let params = {
                // signCallback: signCallback, // 鉴权函数，若直接使用默认鉴权函数。可不传此参数
                // 用户参数
                secretid: secretid,
                secretkey: secretkey,
                appid: appId,
                // 临时密钥参数，非必填
                token: secrettoken,
                // 实时识别接口参数
                engine_model_type: '16k_zh', // 因为内置WebRecorder采样16k的数据，所以参数 engineModelType 需要选择16k的引擎，为 '16k_zh'
            }
            // setWebAudioSpeechRecognizer(new WebAudioSpeechRecognizer(params))
            // webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
        }
    }, [secretid])
    const start_recording = () => {
        webAudioSpeechRecognizer = new WebAudioSpeechRecognizer(params);
        setIsCanStop(true)
        let resultText = '';
        // console.log(webAudioSpeechRecognizer)
        // webAudioSpeechRecognizer.start();
        // setRecognizeType('建立连接中')
        // 开始识别
        webAudioSpeechRecognizer.OnRecognitionStart = (res) => {
            console.log('开始识别', res);
            // setRecognizeType('开始识别')
        };
        // 一句话开始
        webAudioSpeechRecognizer.OnSentenceBegin = (res) => {
            console.log('一句话开始', res);
            // isCanStop = true;
            // setRecognizeType('一句话开始')
        };
        // 识别变化时
        webAudioSpeechRecognizer.OnRecognitionResultChange = (res) => {
            console.log('识别变化时', res);
            let currentText = `${res.result.voice_text_str}`;
            setCurrentCursorContent(currentText)
        };
        // 一句话结束
        webAudioSpeechRecognizer.OnSentenceEnd = (res) => {
            console.log('一句话结束', res);
            setRecognizeText(prev => {
                let tmp = JSON.parse(JSON.stringify(prev))
                let currentText = ''
                // ${tmp.lists[tmp.recognize_index]}
                currentText = `${tmp}${res.result.voice_text_str}`;
                tmp = currentText
                dispatch(text_content_scribe({ content: tmp, socket_id: socket_id }))
                setForbidClick(true)
                return tmp
            })
            setCurrentCursorContent("")
        };
        // 识别结束
        webAudioSpeechRecognizer.OnRecognitionComplete = (res) => {
            console.log('识别结束', res);
            setIsCanStop(false)
        };
        // 识别错误
        webAudioSpeechRecognizer.OnError = (res) => {
            console.log('识别失败', res)
            // setRecognizeType('识别失败')
        };
        console.log(webAudioSpeechRecognizer)
        webAudioSpeechRecognizer.start();
    }
    const end_recording = () => {
        // setRecognizeType('结束识别')
        // console.log(webAudioSpeechRecognizer)
        webAudioSpeechRecognizer.stop();
    }
    const recording_click = () => {
        setOpen(prev => {
            let tmp=JSON.parse(JSON.stringify(prev))
            console.log(prev)
            if (tmp.start_end == 0) {
                start_recording()
                setForbidClick(true)
                tmp.start_end=1
            } else {
                end_recording()
                tmp.start_end=0
            }
            return tmp
        })
    }
    const handleKeyDown = (event) => {
        // 检查是否同时按下了Ctrl和l键
        if (event.ctrlKey && event.key === 'l') {
            console.log(open)
            setOpen(prev=>{
                let tmp=JSON.parse(JSON.stringify(prev))
                if(tmp.draw_open){
                    event.preventDefault(); // 阻止默认行为，比如保存网页
                    console.log('Ctrl + l pressed!');
                    // 这里可以实现你想要执行的操作
                    // recording_click()
                    if(tmp.start_end==0&&!isCanStop){
                        start_recording()
                        setForbidClick(true)
                        tmp.start_end=1
                    }else{
                        end_recording()
                        tmp.start_end=0
                    }
                }else{
                    message.error('请先打开抄录功能（ctrl+k）')
                }
                return tmp
            })
            
        }else if(event.ctrlKey && event.key === 'k'){
            setOpen(prev=>{
                return {...prev,draw_open:true}
            })
            event.preventDefault(); // 阻止默认行为，比如保存网页
        }
    };

    useEffect(() => {
        // 组件挂载后添加事件监听器
        window.addEventListener('keydown', handleKeyDown);

        // 组件卸载前移除事件监听器
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            end_recording()
        };
    }, []); // 空依赖数组表示这个effect只在组件挂载和卸载时运行

    const copy_lists = () => {
        if (forbid_click) {
            message.error('解析完现有录音后才能进行操作')
        } else {
            copy(recognizeText)
            message.success('复制成功')
        }

    }
    const DrawerList = (
        <Box sx={{ width: 300 }} style={{padding:20,position:'relative',height:'100vh',overflowY:'auto'}} role="presentation" >
            <div style={{}}>
                <div style={{ fontWeight: 'bold', fontSize: 18, paddingBottom: 10 }}>AI抄录（ctrl+k快捷键）</div>
                <div style={{fontSize:14}}>点击下方按钮开始录音抄录</div>
                <div style={{ padding: '20px 0' }}>
                    <div style={{ paddingBottom: 10 }}>录音内容：</div>
                    <TextField
                        id="outlined-multiline-static"
                        label=""
                        multiline
                        variant='standard'
                        disabled
                        InputProps={{
                            disableUnderline: true
                        }}
                        value={recognizeText+currentCursorContent}
                        rows={recognizeText.length<200?5:''}
                        size="small"
                        // onChange={url_Change}
                        style={{ width: '100%', fontSize: '12px !important' }}
                    />
                </div>
                <div style={{position: 'absolute', bottom: 10, left: 50, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', padding: 10, borderRadius: 40, background: '#fff' }}>
                        <div style={{ display: 'flex', }}>
                            {
                                open.start_end == 0 ? <div onClick={recording_click} style={{ background: base_setting?.primary_color, width: 200, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                    <div style={{ color: '#fff', fontSize: 14, paddingLeft: 10 }}>点击开始/ctrl+L</div>
                                </div> : <div onClick={recording_click} style={{ background: base_setting?.primary_color, width: 200, borderRadius: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                    <IconButton aria-label="delet" style={{ padding: 2, borderRadius: 10 }}>
                                        <Spin indicator={<LoadingOutlined spin style={{ color: '#fff', fontSize: 20 }} />} />
                                    </IconButton>
                                    <div style={{ color: '#fff', fontSize: 14, paddingLeft: 10 }}>点击结束/ctrl+L</div>
                                </div>
                            }
                            <div>
                                <Tooltip title="复制">
                                    <IconButton aria-label="copy" onClick={copy_lists}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
            </div>
        </Box>
    );

    return (
        <div >
            {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
            <Drawer open={open.draw_open} anchor='right' onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    );
}