
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Link, Route, useHistory, useLocation, Redirect } from "react-router-dom";
import HeadLargemodel from '../../headHavigation/headLargemodel';
import TabbleLists from '../../headHavigation/tabble_lists';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import { get_user_agent_list, upload_company_logo, update_outsider_settings } from '../../../actions/user'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '60%',
        boxShadow: "0 1px 12px #D6D6D6",
        zIndex: 6,
        height: 50,
    },
    input: {
        // marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: 18,
    },
}));



const _ = require('micro-dash');
function AgentShare() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const uploadFileInput = React.useRef();
    const user_type = useSelector((state) => state.user.user_type);
    const base_setting=useSelector((state) => state.user.base_setting);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const agent_menus=useSelector((state) => state.user.agent_menus);
    const token = useSelector((state) => state.user.token);
    // const [hint_agent,setHintAgent]=React.useState('');
    const history = useHistory()

    useEffect(() => {
        if (_.isNil(token)) {
            message.error('请先登陆')
            history.replace('/largemodel')
        }
    }, [token])
    useEffect(() => {
        dispatch(get_user_agent_list())
    }, [])
    const handleUploadButtonClick = () => {
        uploadFileInput.current.click();
    }
    const handleFileChange = (event) => {
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            const formData = new FormData();
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(upload_company_logo(formData))
        }
    }
    const MaxContent = (props) => {
        const e = props.e
        console.log(e)
        if (e?.length > 10) {
            return <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{e}</div>
                <div>
                    <Tooltip title={e}>
                        <HelpIcon style={{ color: 'rgba(215, 215, 215)', fontSize: 20 }}>
                        </HelpIcon>
                    </Tooltip>
                </div>
            </div>
        } else {
            return <div style={{ width: 100 }}>{e}</div>
        }
    }
    const [lists, setLists] = React.useState([]);
    const [message_value,setMessageValue]=React.useState(false);
    const share_lists = (name, id) => {
        console.log(name, id)
        let is_add=true
        for(let i=0;i<agent_menus?.length;i++){
            if(agent_menus[i].id==id){
                message.error('以添加过该智能体，请勿重复添加')
                is_add=false
            }
        }
        if(is_add){
            setAgentName(name)
            setAgentId(id)
            setOpen(true)
        }
    }
    useEffect(()=>{
        if(message_value){
            message.success('添加成功')
            setOpen(false)
            setMessageValue(false)
        }
    },[message_value])
    const handleAgent = () => { }
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const agent_ref_lists=React.useRef();
    const [agent_id,setAgentId]=React.useState('');
    const [agent_name,setAgentName]=React.useState('');
    const [img_url,setImgUrl]=React.useState('https://bohuai-picture-1305959397.cos.ap-shanghai.myqcloud.com/icons/share_img.png');
    const agenthandleFileChange=(event)=>{
        console.log(event)
        if (event.target.files[0].size > 500 * 1024 * 1024) {
            message.error('仅支持500mb以内文件')
        } else {
            const formData = new FormData();
            formData.append('file', event.target.files[0], event.target.files[0].name);
            dispatch(upload_company_logo(formData,(v)=>setImgUrl(v)))
        }
    }
    const agent_add=()=>{
        let agent_menus_lists=[...agent_menus]
        copy('http://localhost:3001/largemodel?agent_name=' + agent_name + '&agent_id=' + agent_id)
        let agent={
            name: agent_name,
            url: '/largemodel?agent_name=' + agent_name + '&agent_id=' + agent_id,
            id:agent_id,
            img: img_url,
            user_level: 1,
            hint: '您没有权限'
        }
        agent_menus_lists.push(agent)
        let data={
            settings: {
                agent_menus:agent_menus_lists,
                // other_menus: [
                //     {
                //         name: '智能对话',
                //         url: '/largemodel',
                //         img: '/images/home.png',
                //         user_level: 1,
                //         type:1,
                //         location:0
                //     },
                //     {
                //         name: '知识库',
                //         url: '/largemodel/knowledge_base',
                //         img: '/images/library.png',
                //         user_level: 1,
                //         type:0,
                //         location:1
                //     },
                //     {
                //         name: '定时AI任务',
                //         url: '/largemodel/monitoring',
                //         img: '/images/monitoring.png',
                //         user_level: 1,
                //         type:0,
                //         location:2
                //     },
                //     {
                //         name: '智能体',
                //         url: '/largemodel/intelligent_agent',
                //         img: '/images/intelligent.png',
                //         user_level: 1,
                //         type:0,
                //         location:3
                //     },
                //     {
                //         name: '人员管理',
                //         url: '/largemodel/intelligent_agent',
                //         img: '/images/people.png',
                //         user_level: 2,
                //         type:1,
                //         location:4
                //     },
                //     {
                //         name: '会员',
                //         url: '/largemodel/intelligent_agent',
                //         img: '/images/mine_img.png',
                //         user_level: 1,
                //         type:1,
                //         location:5
                //     },
                //     {
                //         name: '设置',
                //         url: '/largemodel/setting_block',
                //         img: '/image/set_img.png',
                //         user_level: 1,
                //         type:1,
                //         location:6
                //     },
                // ]
            }
        }
        dispatch(update_outsider_settings(data,(v)=>setMessageValue(v)))
    }
    const agent_amend_name=(e)=>{
        setAgentName(e.target.value)
    }
    const maxWidth='xs'
    return (
        <div>
            <div>
                <HeadLargemodel></HeadLargemodel>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div style={{ background: '#fff',paddingTop: 8, boxShadow: '0px 3px 10px 0px rgba(51,51,51,0.3)', overflow: 'auto', minWidth: 220, minHeight: 'calc(100vh - 54px)' }}>
                            <TabbleLists type='6'></TabbleLists>
                        </div>
                        <div>
                            <div style={{ paddingTop: '2%',paddingLeft:'3%' }}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link to='/largemodel/setting_block' style={{ color: 'rgba(0,0,0,0.6)' }}>
                                        设置
                                    </Link>
                                    <Typography color="text.primary">智能体列表</Typography>
                                </Breadcrumbs>
                            </div>
                            <div style={{ padding: '5%', paddingTop: 0, width: '100%' }}>
                                
                                <div style={{ paddingTop: '5%' }}>
                                    <div>智能体列表</div>
                                    <TableContainer>
                                        <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>名称</TableCell>
                                                    {/* <TableCell align="left">耗费提问次数</TableCell> */}
                                                    <TableCell align="left">介绍</TableCell>
                                                    <TableCell align="left">提示</TableCell>
                                                    <TableCell align="left">类型</TableCell>
                                                    <TableCell align="left">设置成目录栏</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {hint_agent?.map((row) => (
                                                    <TableRow
                                                        hover
                                                        key={row.name}
                                                        onClick={(e) => handleAgent(row.prompt)}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        {/* <TableCell align="left">{row.cost}</TableCell> */}
                                                        <TableCell align="left"><MaxContent e={row?.introduction} /></TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                row.type == 'prompt_list' ? <Link to={{ pathname: `/largemodel/agent_particulars`, state: { lists: row } }}><Button>查看详情</Button></Link> : <MaxContent e={row?.prompt} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {
                                                                (() => {
                                                                    if (row.type === "api") {
                                                                        return <Chip label="api" color="primary" variant="outlined" />
                                                                    } else if (row.type === "vector_db") {
                                                                        return <Chip label="知识库" color="success" variant="outlined" />
                                                                    } else {
                                                                        return <Chip label="多智能体" color="secondary" variant="outlined" />
                                                                    }
                                                                })()
                                                            }
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Button onClick={() => share_lists(row.name, row._id)}>点击添加</Button>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        maxWidth={maxWidth}
                        fullWidth={maxWidth}
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>
                        添加智能体
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div style={{ width:'100%'}}>
                                    <div style={{paddingRight:20,display:'flex',justifyContent:'center'}}>
                                        <div onClick={handleUploadButtonClick} style={{ width: 50, height: 50, borderRadius: '50%',border: '1px dashed #666',  padding: 10, display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                            <img src={img_url} alt='' style={{ width: '100%', height: '100%' }}></img>
                                        </div>
                                        <input type="file" accept='.png,.jpg' ref={agent_ref_lists} onChange={(event)=>agenthandleFileChange(event)} />
                                    </div>
                                    <div style={{padding:'20px 0',width:'100%'}}>
                                        id:{agent_id}
                                    </div>
                                    <div>
                                        <TextField id="outlined-basic" label="名称" style={{width:'100%'}} variant="outlined"   value={agent_name} onChange={agent_amend_name} />
                                    </div>
                                    <div style={{paddingTop:20}}>
                                        <Button variant="contained" size="large" style={{background:base_setting?.primary_color,width:'100%'}} onClick={agent_add}>添加</Button>
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
            </div >
        </div>
    )
}

export default AgentShare