
import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Snackbar from '@mui/material/Snackbar';

import {set_suggest} from '../../../actions/mine'

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#3D1198',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#3D1198',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
        borderColor: '#3D1198',
        },
        '&:hover fieldset': {
        borderColor: '#3D1198',
        },
        '&.Mui-focused fieldset': {
        borderColor: '#3D1198',
        },
    },
});
const product = [
    {
      value: '1',
      label: '税引擎',
    },
  ];
const platform=[
    {
        value: '0',
        label: '小程序',
    },
    {
        value: '1',
        label: '网站',
    }
]

function Suggest(){
    const [value, setValue] = React.useState('');
    const [platform_value, setPlatform] = React.useState('0');
    const is_suggest = useSelector((state) => state.mine.is_suggest);
    const error_value=useSelector((state) => state.mine.error_value);
    const [remind_suggest, setState] = React.useState(false);
    const vertical='top'
    const horizontal='center'
    const product_value = '1'
    
    const handleClose = () => {
        setState(false);
    };

    const dispatch = useDispatch();
    

    const [open, setOpen] = React.useState(false);
    const platformChange=(event)=>{
        setPlatform(event.target.value)
    }
    const suggest_value=(event)=>{
        setValue(event.target.value);
    }
    const submit_suggest=()=>{
        console.log(product_value,platform_value,value)
        if(value===''){
            setOpen(true)
        }else{
            setOpen(false)
            setState(true);
            dispatch(set_suggest({product_value,platform_value,value}))
        }
    }
    return (
        <div>
            <div style={{display:'flex'}}>
                <div style={{width:'20%'}}>
                    <Box sx={{ minWidth: 120,maxWidth:150 }}>
                        <CssTextField
                            id="outlined-select-currency"
                            select
                            label="产品"
                            value={product_value}
                            helperText=""
                            focused
                            style={{width:120}}
                            >
                            {product.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Box>
                </div>
                <div style={{width:'20%',marginLeft:40}}>
                    <Box sx={{ minWidth: 120,maxWidth:150 }}>
                        <CssTextField
                            id="outlined-select-currency"
                            select
                            label="平台"
                            value={platform_value}
                            onChange={platformChange}
                            helperText=""
                            focused
                            style={{width:120}}
                            >
                            {platform.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                {option.label}
                                </MenuItem>
                            ))}
                        </CssTextField>
                    </Box>
                </div>
            </div>
            <div style={{paddingTop:'5%',height:'40%'}}>
            <CssTextField
                id="outlined-multiline-static"
                label="建议"
                multiline
                focused 
                rows={8}
                color=''
                value={value}
                onChange={suggest_value}
                defaultValue=""
                style={{width:'80%'}}
            />
            </div>
            <div style={{marginTop:'10px'}}>
                <Collapse in={open} style={{width:'20%',minWidth:170}}>
                <Alert variant="outlined" severity="error">
                    请勿提交空建议
                </Alert>
                </Collapse>
            </div>
            <Button variant="outlined" onClick={()=>{submit_suggest()}} style={{border:'1px solid rgb(61, 17, 152)',marginTop:'8%',minWidth:'80px',color:'rgb(61, 17, 152)'}}>提 交</Button>
            {
                is_suggest?<Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={remind_suggest}
                    onClose={handleClose}
                    severity="info"
                    autoHideDuration={3500}
                    key={vertical + horizontal}
                ><Alert severity="info">提交成功</Alert></Snackbar>:
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={remind_suggest}
                    onClose={handleClose}
                    autoHideDuration={3500}
                    severity="error"
                    key={vertical + horizontal}
                ><Alert severity="error">{error_value}</Alert></Snackbar>
            }
        </div>
    )
}
export default Suggest;
