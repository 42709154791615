/**
 * Created on 6/23/21.
 * @author Xihao
 */

import React from 'react';
import {makeStyles, withStyles} from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useDispatch, useSelector} from "react-redux";
import {open_hints} from '../../../actions/user';
import {
    change_industry,
    change_tax,
    search,
    toggle_concepts,
    toggle_qa,
    toggle_se,
    toggle_solutions,
    toggle_law
} from "../../../actions/search";
import Button from "@mui/material/Button/Button";

const _ = require('micro-dash');


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 10,
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2
    },
    title: {
        fontSize: 19,
    },
    filterButton:{
        backgroundColor:"#3D1198 !important",
        width: '50%',
        color: 'white',
        fontSize: 14,
        paddingInline: 13,
        paddingTop: 3,
        paddingBottom: 3,
        marginTop: 9,
        '&:hover': {
            backgroundColor: '#3D1198 !important',
        },
    },
}));


const BlueCheckbox = withStyles({
    root: {
        // color: green[400],
        '&$checked': {
            color: '#3D1198',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const BlueRadio = withStyles({
    root: {
        '&$checked': {
            color: '#3D1198',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default function SearchFilter() {
    const classes = useStyles();
    const search_indexes = useSelector((state) => state.search.search_indexes);
    const search_tax_category = useSelector((state) => state.search.search_tax_category);
    const search_industry_category = useSelector((state) => state.search.search_industry_category);
    const search_words = useSelector((state) => state.search.search_words);

    
    const token=useSelector((state) => state.user.token);
    const tax_vip=useSelector((state) => state.user.member_type_info);


    const dispatch = useDispatch();

    const handleIndustryChange = (event) => {
        console.log(`event is ${event.target.value}`);
        dispatch(change_industry(event.target.value));
    };

    const handleTaxChange = (event) => {
        console.log(`event is ${event.target.value}`);
        dispatch(change_tax(event.target.value));
    };

    const check_search_indexes = (checked, fn) => {
        console.log(checked);
        let count_true = Object.values(search_indexes).reduce(
            (a, item) => (item?a+1:a), 0
        );

        if(count_true>1 || !checked){
            fn();
        }
        else{
            alert("请至少选择一个选项");
        }
    };


    const handleSearchIndexes = ()  => {
        dispatch(search({words: search_words}));
    };

    return (
        <div  className={classes.root}>
            <div className={classes.row}>
                <p className={classes.title}>
                    按照内容类型搜索
                </p>

            </div>
            <div className={classes.row}>
                <FormControlLabel control={<BlueCheckbox name="checkedC" checked={_.get(search_indexes, ['concepts'], true)} onChange={()=>check_search_indexes(_.get(search_indexes, ['concepts'], true), ()=>dispatch(toggle_concepts()))}/>}
                                  label={<span style={{ fontSize: 15 }}>税务概念</span>} />
            </div>
            <div className={classes.row}>
                <FormControlLabel control={<BlueCheckbox name="checkedC" checked={_.get(search_indexes, ['solutions'], true)} onChange={()=>check_search_indexes(_.get(search_indexes, ['solutions'], true), ()=>dispatch(toggle_solutions()))}/>}
                                  label={<span style={{ fontSize: 15 }}>筹划指导方案</span>} />
            </div>
            <div className={classes.row}>
                <FormControlLabel control={<BlueCheckbox name="checkedC" checked={_.get(search_indexes, ['se'], true)} onChange={()=>check_search_indexes(_.get(search_indexes, ['se'], true), ()=>dispatch(toggle_se()))}/>}
                                  label={<span style={{ fontSize: 15 }}>筹划案例</span>} />
            </div>
            <div className={classes.row}>
                <FormControlLabel control={<BlueCheckbox name="checkedC" checked={_.get(search_indexes, ['law'], true)} onChange={()=>check_search_indexes(_.get(search_indexes, ['law'], true), ()=>dispatch(toggle_law()))}/>}
                                  label={<span style={{ fontSize: 15 }}>税法条款</span>} />
            </div>
            <div className={classes.row}>
                <FormControlLabel control={<BlueCheckbox name="checkedC" checked={_.get(search_indexes, ['qa'], true)} onChange={()=>check_search_indexes(_.get(search_indexes, ['qa'], true), ()=>dispatch(toggle_qa()))}/>}
                                  label={<span style={{ fontSize: 15 }}>问答</span>} />
            </div>


            <div>
                <p className={classes.title} style={{marginTop:32}}>
                    根据税种搜索
                </p>
            </div>


            <FormControl component="fieldset1">
                <RadioGroup aria-label="gender1" name="gender1" value={search_tax_category} onChange={handleTaxChange}>
                    <FormControlLabel value="全部" control={<BlueRadio />} label="全部" />
                    <FormControlLabel value="企业所得税" control={<BlueRadio />} label="企业所得税" />
                    <FormControlLabel value="个人所得税" control={<BlueRadio />} label="个人所得税" />
                    <FormControlLabel value="契税" control={<BlueRadio />} label="契税" />
                    <FormControlLabel value="印花税" control={<BlueRadio />} label="印花税" />
                    <FormControlLabel value="房产税" control={<BlueRadio />} label="房产税" />
                    <FormControlLabel value="其他" disabled control={<BlueRadio />} label="其他" />

                </RadioGroup>
            </FormControl>


            <div>
                <p className={classes.title} style={{marginTop:32}}>
                    根据行业搜索
                </p>
            </div>

            <FormControl component="fieldset2">
                <RadioGroup aria-label="gender2" name="gender2" value={search_industry_category} onChange={handleIndustryChange}>
                    <FormControlLabel value="全部" control={<BlueRadio />} label="全部" />
                    <FormControlLabel value="农林牧渔" control={<BlueRadio />} label="农林牧渔" />
                    <FormControlLabel value="科技" control={<BlueRadio />} label="科技" />
                    <FormControlLabel value="房地产" control={<BlueRadio />} label="房地产" />
                    <FormControlLabel value="制造业" control={<BlueRadio />} label="制造业" />

                </RadioGroup>
            </FormControl>
            <Button variant="contained" className={classes.filterButton}
                onClick={handleSearchIndexes} disableElevation>
                确认选择
            </Button>
            {/* {
                (()=>{
                    if(!_.isNil(token)){
                        if(tax_vip.member_type===0||(tax_vip.member_type===2&&tax_vip.remaining_time<=0)){
                            return  <Button variant="contained" className={classes.filterButton}
                                        onClick={()=>{dispatch(open_hints(true))}} disableElevation>
                                        确认选择
                                    </Button>
                        }else{
                            return <Button variant="contained" className={classes.filterButton}
                                        onClick={handleSearchIndexes} disableElevation>
                                        确认选择
                                    </Button>
                        }
                    }else{
                        return  <Button variant="contained" className={classes.filterButton}
                                    onClick={()=>{dispatch(open_hints(true))}} disableElevation>
                                    确认选择
                                </Button>
                    }
                })()
            } */}
        </div>
    );
}