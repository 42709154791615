import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODEL_COLOR } from '../../../configs/constants'
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Markdown from 'react-markdown'
import { quick_tax_plan_api } from '../../../actions/dataset'
import { agent_list } from '../../../actions/user'
import { message } from "antd";
const _ = require('micro-dash');

const useStyles = makeStyles((theme) => ({
    over_value: {

    }
}))
function ResultLists(data) {
    console.log(data)
    let propmpt_function = data.propmpt_value
    let delete_propmpt = data.delete_propmpt
    const dispatch = useDispatch();
    const messagesListEnd = useRef(null);
    const [value_test, setValueText] = React.useState('');
    const base_setting=useSelector((state) => state.user.base_setting);
    useEffect(() => {
        console.log(data.message_value)
        setValue(data.message_value)
    }, [data])
    // useEffect(() => {
    //     // 滚动到底部
    //     messagesListEnd.current.scrollIntoView({ behavior: 'smooth' });
    // }, [value_test]);
    const [value, setValue] = React.useState('');
    const [prompt_dialog, setPromptDialog] = React.useState(false);
    const hint_agent = useSelector((state) => state.user.hint_agent);
    const value_change = (e) => {
        console.log(e)
        if (e.nativeEvent.data === '@') {
            dispatch(agent_list())
            setPromptDialog(true)
        }
        setValue(e.target.value)
        propmpt_function(e.target.value, data.id)
    }
    const promptClose = () => {
        setPromptDialog(false)
    }
    const handleAgent = (e, expression, intr) => {
        console.log(e)
        let mess_value = value.slice(0, -1)
        if(expression=='@文件助手'){
            if(!_.isNil(e)){
                mess_value = mess_value + expression + e
            }else{
                mess_value = mess_value + expression
            }
        }else{
            if(!_.isNil(e)){
                mess_value = mess_value + expression + ' ' + e
            }else{
                mess_value = mess_value + expression
            }
        }
        setValue(mess_value)
        propmpt_function(mess_value, data.id)
        setPromptDialog(false)
    }
    const test_click = () => {
        if (value != '') {
            dispatch(quick_tax_plan_api({ prompt: value }, (v) => setValueText(v)))
        } else {
            message.error('内容不能为空')
        }

    }
    const delete_lists = () => {
        delete_propmpt(data.id)
    }
    const [is_file_dialog,setIsFileDialog]=React.useState(false);
    const result_click = (e) => {
        setIsFileDialog(true)
    }
    const fileClose=()=>{
        setIsFileDialog(false)
    }
    return (
        <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', width: '100%' }}>
                <div style={{width: '50%',}}>
                    <div style={{ display: 'flex',alignItems:'center',justifyContent:'space-between',paddingRight:'5%' }}>
                        <div>步骤{data.index+1}</div>
                        <div style={{ padding: 10, display: 'flex',paddingRight:0 }}>
                            <div style={{ paddingRight: 8 }}>
                                <Button variant="contained" onClick={test_click} style={{ background: base_setting?.primary_color }}>步骤测试</Button>
                            </div>
                            <div>
                                <Button variant="contained" color="error" onClick={delete_lists}>删除</Button>
                            </div>
                        </div>
                    </div>  
                    <div style={{ paddingRight:'5%' }}>
                        <TextField
                            id="outlined-multiline-static"
                            // label='本步骤提示词'
                            multiline
                            rows={4}
                            style={{ width: '100%', fontSize: 14 }}
                            onChange={value_change}
                            value={value}
                        />
                    </div>
                </div>
                {
                    value_test!=''?<div style={{width: '40%',}}>
                        <div style={{ padding: 10,paddingRight:0 ,display:'flex',justifyContent:'end'}}>
                            <Button variant="contained" onClick={result_click} style={{ background: base_setting?.primary_color }}>点击查看</Button>
                        </div>
                        <div style={{ height: 115, overflow: 'auto', fontSize: 14, border: '1px dashed rgba(0,0,0,0.2)', padding: 5 }}>
                            <Markdown>{value_test}</Markdown>
                            <div ref={messagesListEnd}></div>
                        </div>
                    </div>:''
                }
            </div>
            <Dialog onClose={fileClose} open={is_file_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                <div style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer' }} onClick={fileClose}>
                    <CancelIcon style={{ fontSize: 20 }} />
                </div>
                <DialogTitle style={{ textAlign: 'center', padding: 20, fontWeight: 'bold', fontSize: 20 }}>调用结果</DialogTitle>
                <div style={{ padding: '30px 60px', paddingTop: 0, minWidth: '500px', minHeight: '20vh' }}>
                    <div>
                        <div style={{ fontSize: 16, background: '#f2f4f8', padding: 10, borderRadius: 8 }}>
                            <Markdown>{value_test}</Markdown>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Dialog onClose={promptClose} open={prompt_dialog} maxWidth='lg' style={{ padding: '5%', width: '100%' }}>
                        <div style={{ position: 'absolute', right: 20, top: 20 }} onClick={promptClose}>
                            <CancelIcon style={{ fontSize: 20 }} />
                        </div>
                        <DialogTitle style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>博槐智能体列表</DialogTitle>
                        <div style={{ padding: 50, paddingTop: 0 }}>
                            <TableContainer>
                                <Table sx={{ minWidth: '70vw' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>名称</TableCell>
                                            <TableCell align="left">耗费提问次数</TableCell>
                                            <TableCell align="left">介绍</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {hint_agent?.map((row) => (
                                            <TableRow
                                                hover
                                                key={row.name}
                                                onClick={(e) => handleAgent(row.prompt, row.expression, row.introduction)}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.cost}</TableCell>
                                                <TableCell align="left">{row.introduction}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Dialog>
        </div>
    )
}
export default ResultLists