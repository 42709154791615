import {
    SHUI_UNREAD_NUM,
    SHUI_CLEAR_NUM,
    SHUI_INFORM_LIST,
} from '../actions/types';

const initialState = {
    shui_unread_num:0,
    shui_inform_lists:[],
};
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SHUI_UNREAD_NUM:
            return {...state,shui_unread_num:action.num}
        case SHUI_CLEAR_NUM:
            return {...state,shui_unread_num:0}
        case SHUI_INFORM_LIST:
            return {...state,shui_inform_lists:action.lists.edges}
        default:
            return state;
    }
}