/**
 * Created on 6/22/21.
 * @author Xihao
 */

import { combineReducers } from 'redux';
import search from './search';
import profile from './profile';
import user from './user';
import chat from './chat';
import mine from './mine'
import forum from './forum'
import inform from './inform'
import plan from './plan'
import tax_accountant from './tax_accountant';
import admin from './admin'
import dataset from './dataset'


const rootReducer = combineReducers({
    search,
    profile,
    user,
    chat,
    mine,
    forum,
    inform,
    plan,
    tax_accountant,
    admin,
    dataset
});

export default rootReducer;